import {all, call, fork, put, select, takeLatest} from 'redux-saga/effects';
import {FETCH_ERROR, fetchEnd, fetchStart, fetchUtils, showNotification} from 'react-admin';
import OdataServiceProvider from "../Helpers/odataServiceProvider";
import {push, replace} from 'connected-react-router'
import compareVersions from 'compare-versions';

function addName(items) {
    return items.map(i=>({...i,name:i.name||""}))
}


function * initialSaga() {


    try{

        yield put(fetchEnd());

        yield put({type:'SET_APP_CLIENT',payload:process.env.REACT_APP_CLIENT});
        yield put({type:'SET_APP_SERVER',payload:localStorage.getItem('server')||process.env.REACT_APP_CLIENT});
        // if( !localStorage.getItem('server'))
        // {
        //     localStorage.setItem('server',process.env.REACT_APP_CLIENT)
        // }

        if( !localStorage.getItem('company'))

           {
               localStorage.setItem('company',"ROOT")
           }

        if(!localStorage.getItem('domain'))
        {   localStorage.setItem('domain',process.env.REACT_APP_HOST);}

        localStorage.setItem('timeAttendanceApi',process.env.REACT_APP_TIKAPI);
        localStorage.setItem('matesApi',process.env.REACT_APP_MATESAPI||'https://dama-mates.herokuapp.com/api');
        if(!localStorage.getItem('localServer'))
        {   localStorage.setItem('localServer',process.env.REACT_APP_LOCAL_HOST);}

        if(!localStorage.getItem('serverType'))
        {   localStorage.setItem('serverType',(process.env.REACT_APP_CLIENT==='xork'?'paydaycloud':'local'));
        }


       if(window.ipcRenderer)

      {
          const ipcRendererOn = (channel) => (new Promise((resolve, reject) => window.ipcRenderer.on(channel, (event, arg) => resolve({event, arg}))));
          window.ipcRenderer.send('app_version');

        //   window.ipcRenderer.on('app_version', (event, arg) => {
        //   window.ipcRenderer.removeAllListeners('app_version');
        //   console.log("current_version",arg.version)
        //
        //   //  document.title = 'PayDay V ' + arg.version;
        // });

          const { event, arg }  = yield ipcRendererOn('app_version');

          yield put({type:'SET_CURRENT_VERSION',payload:arg.version})
        }



          const  version =  yield  call(OdataServiceProvider.getOne,'globalSettings',{id:100})
         //console.log(version)
        yield put({type:"SET_MIN_VERSION",payload:version.data})


        const currentVersion = yield select(state=>state.ui.currentVersion);
        //console.log("current_version",currentVersion)
        if(compareVersions(currentVersion, version.data)<0)
        {

            yield put(push("/outdated"))
            yield put(showNotification(`Outdated Version at least ${version.data} is required`, "error"))
        }
        //console.log(localStorage.getItem('company'))
        if( !localStorage.getItem('company'))
        {
            localStorage.setItem('company',"ROOT")
        }

        let  res =  yield  call(OdataServiceProvider.getList,'currencyRates',{pagination:{},sort:{},filter:{queryString:'$orderby=Date desc &$top=1'}})
       // console.log("currency",res)
        if(!res.data.length)
        {
            let  res =  yield  call(OdataServiceProvider.getList,'currencyRates',{company:"ROOT",pagination:{},sort:{},filter:{queryString:'$orderby=Date desc &$top=1'}})
          // console.log("currency",res)
            if(res.data && res.data.length)
            yield put({type:'CURRENCY_RATES_LOADED',payload:res.data});
        }
        else
        {
            yield put({type:'CURRENCY_RATES_LOADED',payload:res.data});
        }
        yield put(fetchEnd());


        // yield put({type:'TEMPLATE_LOANS_LOADED',payload:templateLoans.data});

        // const { data:templateEarnings } = useQueryWithStore({
        //     type: 'getList',
        //     resource: 'earnings',
        //     payload: {filter:{filter:"Scope eq 'Template'"},pagination:{},sort:{}}
        // });

    }
    catch (e) {
        yield put(showNotification(`Unable to connect to the server`, "error"))
       // yield put(fetchEnd())
    }
}

export default function* appSaga() {
  //  console.log('App init')
    yield fork(initialSaga);

    yield takeLatest(FETCH_ERROR, function* (action) {
        const { error} = action;

       // console.log('Fech-error',error)
        if(error.status === 412)
        {
         //   yield put(clearState());
            if( localStorage.getItem('company') && localStorage.getItem('company')!=="ROOT")
            {

                yield put(replace('/companyError'))
            }
            else {

                yield put(replace('/selectCompanies'))
            }

        }
        if(error=='TypeError: Failed to fetch' || error.status === 404 ) {
            yield put(fetchEnd());
            const currnetPath = yield select(state=>state.router.location.pathname);

            yield put(replace(`/serverError?redirectPath=${currnetPath}`))


        }
    })

    yield takeLatest('LOAD_TEMPLATES',function* (action)  {
            yield put(fetchStart());
        const [templateEarnings,templateDeductions,templateExpenses,templateLoans]= yield all([
            call(OdataServiceProvider.getList,'earnings',{pagination:{},sort:{},filter:{filter:"Scope eq 'Template'"}}),
            call(OdataServiceProvider.getList,'deductions',{pagination:{},sort:{},filter:{filter:"Scope eq 'Template'"}}),
            call(OdataServiceProvider.getList,'expenses',{pagination:{},sort:{},filter:{filter:"Scope eq 'Template'"}}),
            call(OdataServiceProvider.getList,'loanTemplates',{pagination:{},sort:{},filter:{}})
        ])

        // const  templateEarnings  =  yield
        yield put({type:'TEMPLATES_LOADED',payload: {templateEarnings:addName(templateEarnings.data), templateDeductions:addName(templateDeductions.data), templateExpenses:addName(templateExpenses.data), templateLoans:addName(templateLoans.data)}});
        // const  templateLoans  =  yield
            yield put(fetchEnd())
    })


    yield takeLatest('LOAD_TEMPLATES_MULTIPLE',function* (action)  {
        const {payload}  = action
        yield put(fetchStart());
        const [templateEarnings,templateDeductions,templateExpenses,templateLoans]= yield all([
            call(OdataServiceProvider.getList,'earnings',{pagination:{},sort:{},filter:{filter:`Scope eq 'Template' and companyId in (${payload.companyIds.map(x => "'" + x + "'").toString()})`}}),
            call(OdataServiceProvider.getList,'deductions',{pagination:{},sort:{},filter:{filter:`Scope eq 'Template' and companyId in (${payload.companyIds.map(x => "'" + x + "'").toString()})`}}),
            call(OdataServiceProvider.getList,'expenses',{pagination:{},sort:{},filter:{filter:`Scope eq 'Template' and companyId in (${payload.companyIds.map(x => "'" + x + "'").toString()})`}}),
            call(OdataServiceProvider.getList,'loanTemplates',{pagination:{},sort:{},filter:{filter:`companyId in (${payload.companyIds.map(x => "'" + x + "'").toString()})`}})
        ])

        // const  templateEarnings  =  yield
        yield put({type:'TEMPLATES_LOADED',payload: {templateEarnings:addName(templateEarnings.data), templateDeductions:addName(templateDeductions.data), templateExpenses:addName(templateExpenses.data), templateLoans:addName(templateLoans.data)}});
        // const  templateLoans  =  yield
        yield put(fetchEnd())
    })


    yield takeLatest('SWITCH_PAYDAY_SERVER', function* (action) {
        let {payload} = action
        if(payload.includes('server$')) {
            const command = payload.split('$')
           // localStorage.setItem('server',command[1]);
           yield put({type:'SET_APP_SERVER',payload:command[1]});
            payload='paydserver'
        }
        if(payload.includes('client$')) {
            const command = payload.split('$')
            // localStorage.setItem('server',command[1]);
            yield put({type:'SET_APP_CLIENT',payload:command[1]});
            payload='skip'
        }

        if(payload.includes('local$')) {
            const command = payload.split('$')
            // localStorage.setItem('server',command[1]);
       //     yield put({type:'SET_APP_CLIENT',payload:command[1]});
            localStorage.setItem('localServer',command[1])
            payload='local'
        }

        if(payload==='paydserver'||payload==='paydaycloud') {
           const server= yield select(state => state.ui.server);
           // const client = yield select(state => state.ui.client);
            const path = payload==='paydserver'?payload+'-'+server:payload;
            try {

                yield put(fetchStart());
                const {json: server} = yield call(fetchUtils.fetchJson, `${process.env.REACT_APP_KEY_VALUE_SERVER||'https://apis.truwrk.com/dama_quat'}/${path}`,{cache: "no-cache", headers:new Headers({'Bypass-Tunnel-Reminder':"true"})})
                //console.log(server)

                try {
                    yield call(fetchUtils.fetchJson, server.url + '/odata',{cache: "no-cache", headers:new Headers({'Bypass-Tunnel-Reminder':"true"})})
                    localStorage.setItem('domain', server.url)
                    yield put(showNotification(`Successfully Connected`, "info"))
                    yield  put({
                        type:'SET_SERVER_TYPE',
                        payload
                    })
                    yield put(fetchEnd());

                } catch (e) {
                    // console.log('cant connect')
                    yield put(showNotification(`Sorry, server not available right now `, "error"))
                    yield put(fetchEnd());
                }
            }
            catch (e) {
                yield put(showNotification(`Please check your internet connection`, "info"))
                yield put(fetchEnd());
            }

        }

        else if(payload ==='local') {
            try {
                yield put(fetchStart());
                yield call(fetchUtils.fetchJson, localStorage.getItem('localServer') + '/odata')
                localStorage.setItem('domain', localStorage.getItem('localServer'))
                yield put(showNotification(`Successfully Connected`, "info"))
                yield  put({
                    type:'SET_SERVER_TYPE',
                    payload
                })
                yield put(fetchEnd());
            } catch (e) {
                 console.log('cant connect')

               try {
                   const serverAddress = `https://${process.env.REACT_APP_LOCAL_HOST_IP}:${process.env.REACT_APP_LOCAL_HOST_PORT}`

                   yield put(showNotification(`Cant find local server, re-tying with IP Address`, "warning"))
                   yield call(fetchUtils.fetchJson, serverAddress + '/odata')
                   localStorage.setItem('domain', serverAddress)
                   yield put(showNotification(`Successfully Connected`, "info"))
                   yield  put({
                       type: 'SET_SERVER_TYPE',
                       payload
                   })
               }
               catch (e){
               //    console.log(e)
                   yield put(fetchEnd());
                   yield put(showNotification(`Sorry, local server is not available `, "error"))
               }

            }
        }

        else if(payload !=='skip')  {
            try {
                yield put(fetchStart());
                yield call(fetchUtils.fetchJson,payload+'/odata')
                localStorage.setItem('domain', payload)
                yield put(showNotification(`Successfully Connected`, "info"))
                yield  put({
                    type:'SET_SERVER_TYPE',
                    payload
                })
                yield put(fetchEnd());
            } catch (e) {

                yield put(showNotification(`Sorry, the server chosen is not available `, "error"))

                yield put(fetchEnd());
            }
        }

    });


    //   yield takeEvery('*', function* (action) {
  //
  //         console.log(action)
  //       }
  //   )
}
