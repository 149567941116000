import React, {useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Popover from "@material-ui/core/Popover";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu"
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
    typography: {
        padding: theme.spacing(2),
    },
}));

const EditValue = props => {
    const [value,setValue] = useState();


}

const EarningsPopover = props => {
    //console.log('eanringProps',props)
    const classes = useStyles();
    const [values,setValues] = useState(props.earnings)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleItemsChange = (index,field) => ot => {
        //console.log(ot)
        const newRoles = values.slice(0); // Create a shallow copy of the roles
        //const name = newRoles[index].name
        if(field==="Value") {
            newRoles[index] = {...newRoles[index], value: +ot.target.value, fullValue: +ot.target.value}; // Set the new value
        }       else if(field==='EntitledDays'){
            const val =  (newRoles[index].originalValue / props.totalDaysInPayPeriod) * ot.target.value;

                newRoles[index] = {...newRoles[index], entitledDays: +ot.target.value, fullValue: val, value:val}; // Set the new value
        }
       setValues(newRoles)
        props.onChange(newRoles)
        // console.log(payrollDeductions)
    }
    const handleClose = () => {
        //props.onChange(values)
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <div style={{marginTop:-20}}>
            <IconButton aria-describedby={id} variant="text" color="primary" onClick={handleClick}>
                <MenuIcon/>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Card>
                    <CardContent>

          {values.map((e,index) =>  <>
              <TextField style={{width:'75%'}} type='number' id="standard-required" color="primary" label={e.name && e.name.concat(e.originalValue&&(e.value.toFixed(2) !==e.originalValue.toFixed(2) )?`* (${e.originalValue})`:'')} value={e.value}  onChange={handleItemsChange(index,'Value')}/>
              {e.dayBased&&<TextField style={{width:'25%'}} type='number' id="standard-required" color="primary" label={`Entitled Days / ${props.totalDaysInPayPeriod}`} value={e.entitledDays}  onChange={handleItemsChange(index,'EntitledDays')}/>}

              <br/></>

          )  }
                    </CardContent>
                </Card>
            </Popover>
        </div>
    );
};

export default EarningsPopover;
