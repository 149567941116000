import React from 'react';
import {Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/SignalCellularConnectedNoInternet0BarOutlined"
import Box from "@material-ui/core/Box";
import RefreshIcon from "@material-ui/icons/Refresh"
import {parse} from "query-string";
import {useNotify, useRedirect} from 'react-admin'
import {useDispatch, useStore} from "react-redux";

const ServerErrorPage = props => {

     const redirect = useRedirect();
     const store = useStore();
     const dispatch = useDispatch();
     const notify = useNotify();

    const { redirectPath} =parse(props.location.search);
    const reload =()=>{
        try {
            const serverType = store.getState("admin").ui['serverType'];
            dispatch({type:'SWITCH_PAYDAY_SERVER',payload:serverType});
            redirect(redirectPath||'/')

        } catch (e) {
            notify('Sorry there is still something wrong, Please Retry Later','info')
        }


    }

    return (
        <Box textAlign="center" m={1}>
            <AddIcon color="secondary" style={{ fontSize: 200 }}/>
            <Typography color="textSecondary" variant="h4" paragraph>
              Oh no, We can't connect to the PayDay Server
            </Typography>
            <Typography color="textSecondary" variant="subtitle1">
                Sorry, connection lost to the server, please recheck and try again
            </Typography>

            <Button startIcon={<RefreshIcon/>} color='primary' onClick={reload}  >Reload</Button>

        </Box>
    );
};

export default ServerErrorPage;


