import _ from 'lodash'
const initialState = {
    calendar: 'GC',
    companyMateId: null,
    companySettings:{},
    payslipSettings: {}
}

export default (previousState = initialState, { type, payload }) => {
    if (type === 'COMPANY_SETTINGS_LOADED') {

        const companySettings =  Object.fromEntries(payload.filter(s=>s.settingType!=='Payslip').map((s) => [s.name, s]))//_.groupBy(payload.filter(s=>s.settingType==='General'),'name')
        const payslipSettings = Object.fromEntries(payload.filter(s=>s.settingType==='Payslip').map((s) => [s.name, s]))
        // console.log(companySettings,payload)
        return {...previousState, companySettings, payslipSettings};
    }
    return previousState;
}

