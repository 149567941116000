import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
import OverTimeItem from "./overTimeItem";
// import {Button} from 'react-admin'



//const Overtimes = [{rate:12, name:'After Work'},{rate:12,name:'Sunday'},{rate:12,name:'HollyDay'}]


export default function AddOverTimeDialog(props) {


    // const {overtimes} = props
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [overtimes, setOvertimes] = React.useState(props.overtimes);
    // const [oTType, setOTType] = React.useState(Overtimes[0]);
    // const [hrs, setHrs] = React.useState(0);
  //  console.log(overtimes)
    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };
    const handleAdd = () => {

       // console.log(overtimes)
         props.onAdd(overtimes)
        setDialogOpen(false);
    };
    // console.log('ottype',oTType)

    const handleOvertimesChange = index => ot => {
        const newRoles = overtimes.slice(0); // Create a shallow copy of the roles
        newRoles[index] = ot; // Set the new value
       setOvertimes(newRoles)
    }

    const handleRemove = index => () => {
        const newRoles = overtimes.slice(0); // Create a shallow copy of the roles
        newRoles.splice(index,1)
        setOvertimes(newRoles)
    }
    return (
        <div  style={{marginTop:-20}}>

            <Button variant='text' color="primary" onClick={handleClickOpen}>
                + Overtime
            </Button>
            <Dialog open={dialogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">

                <DialogTitle id="form-dialog-title">OverTimes</DialogTitle>
                <DialogContent>
                    {/*<DialogContentText>*/}
                        {/*To subscribe to this website, please enter your email address here. We will send updates*/}
                        {/*occasionally.*/}
                    {/*</DialogContentText>*/}

                    {overtimes.map((ot,index) =>
                    <OverTimeItem Overtimes={props.overtimeTypes} key={index} value={ot} onRemove={handleRemove(index)}  onChange={handleOvertimesChange(index)} />
                         )}

                    <Button onClick={()=>setOvertimes([...overtimes,{typeId:props.overtimeTypes[0].id,hrs:0}])} color="primary">
                        Add
                    </Button>




                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                    <Button onClick={handleAdd} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
