export const payrollApproveAction = (id, data, basePath) => ({
    type: 'PAYROLL_RUN_APPROVE',
    payload: { id, data: { ...data, status: 'approved' }, basePath },

});

export const payrollUnApproveAction = (id, data, basePath) => ({
    type: 'PAYROLL_RUN_UNAPPROVE',
    payload: { id, data: { ...data, status: 'unapproved' }, basePath },

});
