import React from 'react';
import {Datagrid, Edit, List, NumberField, NumberInput, SimpleForm} from 'react-admin';
import exporterCommon from "../import/exporterCommon";


export const IncomeTaxRuleList= props => (
    <List exporter={exporterCommon(props.resource)} {...props}>
        <Datagrid rowClick="edit">
            {/*<TextField source="id" />*/}
            <NumberField source="start" />
            <NumberField source="end" />
            <NumberField source="taxRate" />
            <NumberField source="deduction" />

        </Datagrid>
    </List>
);

export const IncomeTaxRuleEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <NumberInput source="start" />
            <NumberInput source="end" />
            <NumberInput source="taxRate" />
            <NumberInput source="deduction" />
        </SimpleForm>
    </Edit>
);
