import React from 'react';
import {
    Create,
    Datagrid,
    Edit,
    List,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';
import exporterCommon from "../import/exporterCommon";
import {parse} from "query-string";
import {RedirectToolbar} from "./common";

export const CompanyBankList= props => (
    <List exporter={exporterCommon(props.resource)}  {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <ReferenceField source="bankId" reference="banks">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="branch" />
        </Datagrid>
    </List>
);

export const CompanyBankEdit = props => {
    const { id } =parse(props.location.search);
    const redirect = id ? `/companies/${id}/show/banks` : "list";
    return <Edit  {...props}>
        <SimpleForm redirect={redirect} toolbar={<RedirectToolbar/>}>
            <ReferenceInput label="Bank" source="bankId" reference="banks" validate={[required()]}>
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <TextInput source="branch"/>
        </SimpleForm>
    </Edit>
};

export const CompanyBankCreate = props => {
    const { companyId} =parse(props.location.search);
    const redirect = companyId ? `/companies/${companyId}/show/banks` : "show";
    return <Create {...props}  >
        <SimpleForm redirect={redirect}>
            <ReferenceInput label="Bank" source="bankId" reference="banks" validate={[required()]}>
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <TextInput source="branch"/>
        </SimpleForm>
    </Create>
};

export const CompanyBankShow = props => (
    <Create {...props}  >
        <SimpleForm redirect='list' >
            <ReferenceField source="bankId" reference="banks">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="branch" />
        </SimpleForm>
    </Create>
);
