import _ from "lodash";
import moment from "moment";
import {select} from "@redux-saga/core/effects";
import goalSeek from "goal-seek";
import {Parser} from 'expr-eval';
export function calculateTaxableEarning(earning,base) {
    switch (earning.taxOption) {
        case 'NonTaxable':
            return 0
        case 'Taxable':
            return +earning.value
        case 'TaxableWithLimits':
        {
            let limit = earning.limitByValue
            if(limit>0&&limit<1) {
                limit = base * limit
            }
            const taxable= earning.value - limit
            return taxable > 0? taxable :0
        }
        // case 'TaxableWithPayback':
        //     return +earning.value
        default:
            return +earning.value
    }
}


export function calculateIncomeTax(taxableIncome=0,rules, option) {
    // console.log("taxabl",taxableIncome, rules)
    const  {useRule, context} = (option||{})

    if(context && context.customIncomeTaxExpression) {
       return  Parser.evaluate(context.customIncomeTaxExpression, context)
    }

    const rule = useRule || rules.find(r=>taxableIncome >= r.start && taxableIncome<=r.end)
    if(rule)
        return  (rule.taxRate||0) * taxableIncome - rule.deduction ;
    else return 0
}



export function convertCurrency(value,defaultCurrency='etb',usedCurrencyRates) {
    //console.log(payload.currencyRate)
    console.log({usedCurrencyRates})

    if(!usedCurrencyRates||!usedCurrencyRates.etb) {
        usedCurrencyRates = {
            etb:1
        }
    }
    return value * ((usedCurrencyRates)[defaultCurrency.toLowerCase()]) / ((usedCurrencyRates)['etb']);

}

export function getNetPay({basicSalary,earnings, deductions,taxRules, defaultCurrency, usedCurrencyRates }) {




    const fixedDeductions =  deductions.filter(d=>!d.cash).map(d=>{
        const value = d.valueType === 'Percentage'? d.value* basicSalary : convertCurrency(d.value,defaultCurrency,usedCurrencyRates);
           const rest =  (({deductionType,name,scope,templateId}) => ({deductionType,name,scope,templateId }))(d);
        return {...rest, value}
    })

    const cashDeductions =  deductions.filter(d=>d.cash).map(d=>{
        const value = d.valueType === 'Percentage'? d.value* basicSalary : convertCurrency(d.value,defaultCurrency,usedCurrencyRates);
        const rest =  (({deductionType,name,scope,templateId}) => ({deductionType,name,scope,templateId }))(d);
        return {...rest, value}
    })

    const fixedEarnings=  earnings.map(d=>{
        const value= d.valueType === 'Percentage'? d.value* basicSalary : convertCurrency(d.value,defaultCurrency,usedCurrencyRates);

        const rest =  (({limitByValue,name,taxOption,scope,earningType, templateId,dayBased}) => ({limitByValue,name,taxOption,scope,earningType, templateId,dayBased }))(d);
        return {...rest,value:value}
    })
    // console.log(fixedEarnings)
    const transportAllowances = fixedEarnings.filter(cv=>cv.earningType&&cv.earningType.replace(/\s+/g, '').toLowerCase().includes('transportallowance'))
    const totalTransportAllowance = transportAllowances.reduce((sum,cv)=>sum+cv.value,0)
    const limit = (_.maxBy(transportAllowances.filter(t=>t.taxOption==='TaxableWithLimits'),'limitByValue')||{limitByValue:0}).limitByValue;
    const taxableTransportAllowance = Math.max(totalTransportAllowance- limit,0);
    const taxableEarnings = Math.max(fixedEarnings
        .filter(e=>!transportAllowances.includes(e))
        .reduce((sum, cv)=>sum+calculateTaxableEarning(cv),0) + taxableTransportAllowance)
    const taxableIncome = basicSalary + taxableEarnings;
    const grossIncome =basicSalary + fixedEarnings.reduce((sum, cv) => sum + cv.value, 0)

    const incomeTax = calculateIncomeTax(taxableIncome, taxRules)
    // console.log({grossIncome,fixedDeductions,cashDeductions, taxableIncome,incomeTax})
     const fixedDeductionsTotals = fixedDeductions.concat(cashDeductions).reduce((sum, cv) => sum + cv.value, 0);
    const totalDeductions =  fixedDeductionsTotals +  incomeTax

   const net = grossIncome - totalDeductions
    // console.log({net})
    return net

}


export async  function  calculateNetPay(employeeId,dataProvider,store, basic) {
    const {data:employee} =  await dataProvider.getOne('employees',  {id: employeeId, expand:`earnings,deductions`});
    const {data:taxRules} =  await dataProvider.getList('incomeTaxRules',  {pagination:{},sort:{},filter:{}});
    const currencyRate = store.getState("currency").conversionRates
    // const currencyRate = yield select(state=>state.currency.conversionRates);
    const usedCurrencyRates = _.omit(currencyRate,["id", "createdDate", "createdBy",'modifiedBy',"modifiedDate", "version", "@odata.context", "@odata.etag","companyId", "isDeleted"])
    return getNetPay({...employee,basicSalary:basic||employee.basicSalary,taxRules,usedCurrencyRates})


}

export async  function  calculateGross(employeeId,netPay,dataProvider,store) {

       if(netPay>0)
       {
           const {data:employee} =  await dataProvider.getOne('employees',  {id: employeeId, expand:`earnings,deductions`});
           const {data:taxRules} =  await dataProvider.getList('incomeTaxRules',  {pagination:{},sort:{},filter:{}});
           const currencyRate = store.getState().currency.conversionRates
           // const currencyRate = yield select(state=>state.currency.conversionRates);
           const usedCurrencyRates = _.omit(currencyRate,["id", "createdDate", "createdBy",'modifiedBy',"modifiedDate", "version", "@odata.context", "@odata.etag","companyId", "isDeleted"])
           const basic = grossUp({...employee,netPay,taxRules,usedCurrencyRates})
         //  console.log({basic})
           return {basic,net:getNetPay({...employee,taxRules,usedCurrencyRates,basicSalary:basic})}
       }
       else {
           throw new Error("Invalid NetPay")
       }


}


export function grossUp({basicSalary,earnings, deductions,taxRules, defaultCurrency, usedCurrencyRates, netPay }){

    const fn = (basic) => getNetPay({basicSalary:basic,earnings, deductions,taxRules, defaultCurrency, usedCurrencyRates});
    const fnParams = [basicSalary];

    try {
        const result = goalSeek({
            fn,
            fnParams,
            percentTolerance: 0.001,
            maxIterations: 10000,
            maxStep: 100,
            goal: netPay,
            independentVariableIdx: 0
        })

        return  result
    } catch(e) {
        console.log(e)

    }
}
