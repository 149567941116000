import React from 'react';
import {Button} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {connect, useDispatch} from "react-redux";


const CurrencyMenu = ({conversionRates,changeCurrency,currentCurrency,hideSymbol}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = useDispatch();
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handelChangeCurrency = (currency) => {
        changeCurrency(currency)
        setAnchorEl(null);
    }
    const reloadCurrency = () => {
         dispatch({type:'COMPANY_CURRENCY_REFRESH'})
        setAnchorEl(null);
    }

    const toggleSymbol = () => {
        dispatch({type:'CHANGE_HIDE_SYMBOL',payload:!hideSymbol})
        setAnchorEl(null);
    }

    return (
        <>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                {currentCurrency}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >

                { Object.keys(conversionRates).filter(key=>['etb','usd','eur','gbp'].includes(key)).map(key=> <MenuItem  selected={currentCurrency === key} onClick={()=>handelChangeCurrency(key)}>{key.toUpperCase()}</MenuItem>)}
<MenuItem onClick={reloadCurrency}  >Reload</MenuItem>
                {currentCurrency.toLowerCase()==='etb'&&<MenuItem onClick={toggleSymbol}>{hideSymbol ? 'Show ETB Symbol' : 'Hide ETB Symbol'}</MenuItem>}
            </Menu>
        </>
    );
};

export default connect(state=>({
    currentCurrency : state.currency.currentCurrency,
    hideSymbol : state.currency.hideSymbol,
    conversionRates: state.currency.conversionRates,
}),dispatch=>({
    changeCurrency: (currency)=>dispatch({type:'CHANGE_CURRENCY',payload:currency})
}))(CurrencyMenu);
