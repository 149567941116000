import React from 'react';
import {Create, Datagrid, Edit, List, SimpleForm, TextField, TextInput, Show} from 'react-admin';
import exporterCommon from "../import/exporterCommon";
import DefaultListActions from "./common";


export const BankList= props => (
    <List exporter={exporterCommon(props.resource)} actions={<DefaultListActions/>} {...props}>
        <Datagrid rowClick="edit">
            {/*<TextField source="id" />*/}
            <TextField source="name" />
            <TextField source="swiftCode" />
        </Datagrid>
    </List>
);

export const BankEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="swiftCode" />
        </SimpleForm>
    </Edit>
);

export const BankCreate = props => (
    <Create {...props}  >
        <SimpleForm redirect='list' >
            <TextInput source="name" />
            <TextInput source="swiftCode" />
        </SimpleForm>
    </Create>
);

export const BankShow = props => (
    <Show {...props}  >
        <SimpleForm redirect='list' >
            <TextField source="name" />
            <TextField source="swiftCode" />
        </SimpleForm>
    </Show>
);
