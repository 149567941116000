import _ from 'lodash'
import moment from "moment";
import Zemen from "../UIComponents/ZemenCalendar/zemen";
import {calculateIncomeTax, calculateTaxableEarning, convertCurrency} from "../Helpers/salaryCalculator";
import {countDaysBetween} from "../Helpers/dateHelpers/dateFunctions";
import {Parser} from 'expr-eval'
const initialState = {
    payrolls: [],
    distEarnings: [],
    incomeTaxRules: [],
    totalIncome: 0,
    absentDeduction:0,
    taxableIncome: 0,
    incomeTax: 0,
    netPay:0,
    pensionEmployee: 0,
    pensionCompany: 0,
    pf: 0,
    pfCompany:0,
    payrollDate: moment().add(-8, 'days').startOf('month'),
    prevPayrollDate:null,
    runDate: new Date(),
    site: null,
    employmentType: null,
    costCenter:null,
    firstRun: false,
    periodStart: moment(),
    periodEnd: moment(),
    offsetDays: 0,
    calendar: '',
    excluded: [],
    basicAbsentDeduction:0,
    overtimeTotal:0,
    deductServiceHour: true,
    useServiceHours: false,
    ignoreLeaveOnServiceHours: false,
    settings: {},

};







function calculatePayDays (date,periodDaySetting=0, normalPay=0,backPay=0,forwardPay=0){

    if(periodDaySetting>0) {
        return normalPay + backPay + forwardPay
    }
    const m = moment(date);
    const prevMonth  = moment(date).subtract(1,"months");
    const nextMonth  = moment(date).add(1,"months");

    //console.log(prevMonth.format("MMMM"),nextMonth.format("MMMM"))
    return normalPay + backPay/prevMonth.daysInMonth()*m.daysInMonth() + forwardPay/nextMonth.daysInMonth()*m.daysInMonth();

}

function getOTBase(item) {
    switch (item.otBase) {
        case 'basic':
            return item.fullBasicSalary
        case 'gross':
            return item.fullBasicSalary + (item.additionalFixedEarningsOriginal||item.additionalFixedEarnings)
        default:
            return item.fullBasicSalary

    }
}

function calculatePayrollItem(item,field,value,taxRules, settings){


// console.log({field, value, item, taxRules})
    const newItem = {...item,[field]:value}
//console.log(field,newItem)
    // if(field==='backPayDays') {
    //     newItem.payDays = newItem.normalPayDays + newItem.backPayDays
    //
    // }
    // if(field!=="status"&&newItem.status==='Paid'){
    //      return item;
    //     // throw (new Error('Payroll is already paid'))
    // }

     if(field!=='calculated'&&field!=="emailSent")
     {
         newItem.emailSent = false
         newItem.publishId = 0
     }
     // console.log({field})


    if(field==='normalPayDays') {
        newItem.monthlyHours =  newItem.employmentType!=="Casual"?(!newItem.useServiceHours?newItem.fullMonthlyHours*newItem.normalPayDays/newItem.totalDaysInPayPeriod:newItem.dailyServiceHour*(Math.min(newItem.normalPayDays,newItem.daysInMonthForExpectedHours))):newItem.normalPayDays*newItem.dailyServiceHour; //get from database

        // newItem.monthlyHours = newItem.employmentType !== "Casual" ? newItem.fullMonthlyHours * newItem.normalPayDays / newItem.totalDaysInPayPeriod : newItem.payDays * 8
        newItem.backPayDays = Math.max(newItem.normalPayDays - newItem.totalDaysInPayPeriod, newItem.backPayDays);
        //newItem.adjustedDays = newItem.normalPayDays
    }
    if(field==='normalPayDays'&&!newItem.fromTimeAttendance) {

        newItem.hoursWorked =  newItem.monthlyHours
        newItem.actualHoursWorked = newItem.monthlyHours

    }
    if(field==='normalPayDays'||field==='calculated'||field==='backPayDays'||field==='forwardPayDays') {
       // newItem.payDays = newItem.normalPayDays


        newItem.normalPayDays = Math.min(newItem.normalPayDays,newItem.totalDaysInPayPeriod);
       // newItem.payDays = newItem.normalPayDays
        // backpay logic here
       // moment()

        newItem.payDays = calculatePayDays(newItem.payrollDate,newItem.periodDaySetting,newItem.normalPayDays,newItem.backPayDays,newItem.forwardPayDays)

        // newItem
        if(newItem.employmentType!=="Casual"){
            newItem.basicSalary = +((newItem.fullBasicSalary / newItem.totalDaysInPayPeriod) * newItem.payDays).toFixed(5)
        }
        newItem.totalWorkDays =  Math.min(newItem.payDays,newItem.fullWorkDays)
        newItem.daysWorked = Math.min(newItem.payDays,newItem.fullWorkDays)-newItem.absentDays+ newItem.penaltyAbsentDays-newItem.leaveDays

        // if(field!=='calculated') {
        //   //  newItem.hoursWorked = newItem.monthlyHours// Math.min(newItem.monthlyHours,newItem.hoursWorked);
        // }
        // if(field!=='calculated'&&!newItem.fromTimeAttendance) {
        //     newItem.hoursWorked = (+(newItem.monthlyHours / newItem.totalWorkDays).toFixed(5) * (newItem.daysWorked + newItem.leaveDays))
        //
        // }

        newItem.payrollFixedEarnings.map(ta=>{
            if(ta.fullValue===ta.originalValue) {
                if (ta.dayBased) {
                    ta.entitledDays = newItem.payDays
                    ta.value = (ta.fullValue / newItem.totalDaysInPayPeriod) * ta.entitledDays;

                }
            }
        })

        newItem.payrollFixedDeductions.map(ta=>{
           if(ta.fullValue===ta.originalValue) {
               if (ta.dayBased) {
                   ta.value = +((ta.fullValue / newItem.totalDaysInPayPeriod) * newItem.payDays).toFixed(5);
               }
           }
        })

        newItem.payrollFixedExpenses.map(ta=>{
            if(ta.fullValue===ta.originalValue) {
                if (ta.dayBased) {
                    ta.value = +((ta.fullValue / newItem.totalDaysInPayPeriod) * newItem.payDays).toFixed(5);
                }
            }
        })
        newItem.fixedDeductionsTotals= newItem.payrollFixedDeductions.reduce((sum, cv) => sum + cv.value, 0);
        newItem.fixedExpensesTotals= newItem.payrollFixedExpenses.reduce((sum, cv) => sum + cv.value, 0);

        newItem.empPension = newItem.payrollFixedDeductions.reduce((sum,cv)=>cv.deductionType === 'EmpPension'? sum+cv.value:sum,0)||0
        newItem.companyPension = newItem.payrollFixedExpenses.reduce((sum,cv)=>cv.type === 'CompanyPension'? sum+cv.value:sum,0)||0
        newItem.pf =  newItem.payrollFixedDeductions.reduce((sum,cv)=>cv.deductionType === 'PF'? sum+cv.value:sum,0)||0
        newItem.companyPf= newItem.payrollFixedExpenses.reduce((sum,cv)=>cv.type === 'CompanyPF'? sum+cv.value:sum,0)||0


        // newItem.payrollDeductions.map(ta=>{
        //     //  if(ta.value===ta.originalValue)
        //     if(ta.dayBased) {
        //         ta.value = (ta.fullValue / newItem.totalDaysInPayPeriod) * newItem.payDays;
        //     }
        // })
    }
    // if(newItem.employmentType !== 'Casual')
    // {
        // if(field==='fromTimeAttendance') {
        //     console.log("xxx fromTimeAttendance")
        // }

// if(field==='payrollFixedEarnings')
// {
//     newItem.payrollFixedEarnings.map(ta=>{
//         if(ta.fullValue===ta.originalValue) {
//             if (ta.dayBased) {
//                 //   ta.entitledDays = newItem.payDays
//                 ta.value = (ta.originalValue / newItem.totalDaysInPayPeriod) * ta.entitledDays;
//                // ta.fullValue = ta.value
//             }
//          }
//     })
// }



        if(field==='daysWorked'||field==='payDays') {
            newItem.absentDays = Math.max(newItem.totalWorkDays - newItem.daysWorked,0)
           // newItem.fromTimeAttendance = false
            if(+newItem.fromTimeAttendance!==+newItem.hoursWorked) {
                newItem.hoursWorked = newItem.monthlyHours / newItem.totalWorkDays * (newItem.daysWorked + newItem.leaveDays)
                newItem.actualHoursWorked =  newItem.hoursWorked
            }
        }
        if(field==='absentDays') {
            newItem.daysWorked = Math.max(newItem.totalWorkDays - newItem.absentDays + newItem.penaltyAbsentDays - newItem.leaveDays,0)
            if(+newItem.fromTimeAttendance!==+newItem.hoursWorked) {
                newItem.hoursWorked = (+(newItem.monthlyHours / newItem.totalWorkDays).toFixed(5) * (newItem.daysWorked + newItem.leaveDays))
                newItem.actualHoursWorked =  newItem.hoursWorked
             // console.log(newItem.hoursWorked,"hh")
            }
            }

    if(field==='penaltyAbsentDays') {
      //  console.log(newItem.penaltyAbsentDays)
        newItem.daysWorked = Math.max(newItem.totalWorkDays - newItem.absentDays +  newItem.penaltyAbsentDays - newItem.leaveDays,0)
       // if(+newItem.fromTimeAttendance!==+newItem.hoursWorked) {
            newItem.hoursWorked = (+(newItem.monthlyHours / newItem.totalWorkDays).toFixed(5) * (newItem.daysWorked - newItem.penaltyAbsentDays + newItem.leaveDays))
           // newItem.actualHoursWorked =  newItem.hoursWorked
            // console.log(newItem.hoursWorked,"hh")
        //}
    }


        if(field==='leaveDays') {
            newItem.leaveDaysTaken =    (newItem.leaveDaysTakenPrevious +newItem.leaveDays) || 0
            newItem.daysWorked = newItem.totalWorkDays - newItem.absentDays + newItem.penaltyAbsentDays - newItem.leaveDays
            if(+newItem.fromTimeAttendance!==+newItem.hoursWorked) {
                newItem.actualHoursWorked = newItem.monthlyHours - (newItem.monthlyHours / newItem.payDays) * newItem.leaveDays
            }         //   newItem.hoursWorked = Math.min(newItem.fromTimeAttendance + (8 * newItem.leaveDays), newItem.monthlyHours)
         //    if(newItem.fromTimeAttendance) {
         //        newItem.hoursWorked = Math.min(+newItem.fromTimeAttendance + (8 * newItem.leaveDays), newItem.monthlyHours)
         //    }

        }
        // if(field==='hoursWorked '||field==='daysWorked') {
        //     newItem.hoursWorked =   newItem.hoursWorked <180?  newItem.hoursWorked:180
        // }
        if(newItem.deductTransportIfAbsent)
        {
            const  dayBasedAllowanceTotal = newItem.payrollFixedEarnings.filter(e=>e.dayBased).reduce((sum, cv) => sum + cv.value, 0)
        //   console.log(dayBasedAllowanceTotal)
            //  const transportAllowance = newItem.payrollFixedEarnings.find(e=>e.earningType&&e.earningType==="Transport Allowance")
           //  if(transportAllowance)
           //  {
           //      transportAllowance.value = transportAllowance.value -(transportAllowance.value/newItem.totalWorkDays * (+newItem.leaveDays+(+newItem.absentDays)))
           //   //   newItem.payrollFixedEarnings.fin
           //      newItem.payrollFixedEarnings = [...newItem.payrollFixedEarnings.filter(a=>a!==transportAllowance),transportAllowance]
           //
           //  }
            newItem.additionalEarningsAbsentDeduction=  dayBasedAllowanceTotal/newItem.totalWorkDays * (Math.floor(+newItem.leaveDays)+(Math.floor(+newItem.absentDays)))
        } else {
            newItem.additionalEarningsAbsentDeduction = 0;
        }


        if(newItem.employmentType!=="Casual") {
            newItem.basicAbsentDeduction = +Math.max((newItem.monthlyHours - newItem.hoursWorked) * ((Math.min(newItem.basicSalary,newItem.fullBasicSalary)+(newItem.otBase==='gross'?newItem.additionalFixedEarnings:0))/ newItem.monthlyHours), 0).toFixed(1);
        }       // newItem.basicAbsentDeduction = newItem.basicAbsentDeduction >0?newItem.basicAbsentDeduction:0;
else {
            newItem.basicAbsentDeduction = 0
           // newItem.basicAbsentDeduction = +Math.max((newItem.monthlyHours - newItem.hoursWorked) * (Math.min(newItem.basicSalary,newItem.fullBasicSalary) / newItem.monthlyHours), 0).toFixed(2);
        }
        newItem.absenceDeduction =   (newItem.additionalEarningsAbsentDeduction||0)+(newItem.basicAbsentDeduction||0)


   // }
    // else {
    //     //newItem.daysWorked = item.da
    //     newItem.absenceDeduction = 0
    // }
   // console.log("bonues",newItem.bonuses)
    newItem.earnedSalary= newItem.employmentType==='Casual'? newItem.fullBasicSalary* newItem.daysWorked : (newItem.basicSalary - (settings.absentDeductionAfterTax?0:newItem.basicAbsentDeduction))

    newItem.bonusesTotal = newItem.bonuses.reduce((sum,cv)=>sum+(+cv.value),0)

    const transportAllowances =  newItem.payrollFixedEarnings.filter(cv=>cv.earningType&&cv.earningType.replace(/\s+/g, '').toLowerCase().includes('transportallowance'))

    newItem.additionalFixedEarnings = newItem.payrollFixedEarnings.reduce((sum, cv) => sum + cv.value, 0)
    newItem.serviceHours =  ((newItem.daysWorked+ (newItem.ignoreLeaveOnServiceHours?newItem.leaveDays:0)) * newItem.dailyServiceHour)-(newItem.deductServiceHour?(newItem.missedHours||0):0)

    // console.log(newItem.monthlyOTHours, getOTBase(newItem))
    const hourlyWage = newItem.employmentType!=='Casual'? getOTBase(newItem)/newItem.monthlyOTHours:newItem.fullBasicSalary/8;
try {
    newItem.overtimeTotal = newItem.overtimes.reduce((sum,cv)=>sum+cv.hrs*cv.rate*(hourlyWage),0)
}
catch (e) {
    console.log(newItem, e)
}



    if(newItem.omitTransportAllowance) {
        const  transportAllowance = newItem.payrollFixedEarnings.filter(e=>e.earningType&&e.earningType.toLowerCase().replace(/\s+/g, '').includes("transportallowance")).reduce((sum, cv) => sum + cv.value, 0)
        newItem.additionalFixedEarnings = newItem.payrollFixedEarnings.reduce((sum, cv) => sum + cv.value, 0) - transportAllowance
    }


    const benefitInKindTotal =  newItem.payrollFixedEarnings.reduce((sum,cv)=>sum+(( cv.earningType.toLowerCase().replace(/\s+/g, '').includes('benefitinkind'))?calculateTaxableEarning(cv,newItem.basicSalary):0),0) + newItem.bonuses.reduce((sum,cv)=>sum+(( cv.type.toLowerCase().replace(/\s+/g, '').includes('benefitinkind'))?calculateTaxableEarning(cv, newItem.basicSalary):0),0)

    newItem.additionalEarningsTotal = newItem.additionalFixedEarnings + newItem.bonusesTotal + newItem.overtimeTotal - benefitInKindTotal

    const totalTransportAllowance = transportAllowances.reduce((sum,cv)=>sum+cv.value,0)
    // console.log("tt",totalTransportAllowance)
    const limit = (_.maxBy(transportAllowances.filter(t=>t.taxOption==='TaxableWithLimits'),'limitByValue')||{limitByValue:0}).limitByValue;
// console.log('limi',limit,transportAllowances)

    const taxableTransportAllowance = Math.max(totalTransportAllowance- limit,0);



    newItem.taxableEarnings = Math.max(newItem.payrollFixedEarnings
        .filter(e=>!transportAllowances.includes(e))
        .reduce((sum, cv)=>sum+calculateTaxableEarning(cv,newItem.basicSalary),0) - ((settings.absentDeductionAfterTax&&newItem.additionalEarningsAbsentDeduction)||0),0) + taxableTransportAllowance



    newItem.taxableBonuses = newItem.bonuses.reduce((sum,cv)=>sum+calculateTaxableEarning(cv,newItem.basicSalary),0)





    //  if(newItem.employmentType !== 'Casual') {
        newItem.taxableIncome = newItem.earnedSalary + newItem.overtimeTotal + newItem.taxableEarnings + newItem.taxableBonuses
        newItem.nonTaxableIncome = newItem.additionalFixedEarnings + newItem.bonusesTotal - newItem.taxableEarnings - newItem.taxableBonuses
        newItem.totalIncome = (newItem.employmentType !== 'Casual'? newItem.basicSalary:newItem.earnedSalary) + newItem.additionalEarningsTotal//newItem.taxableIncome + newItem.nonTaxableIncome
   // }
    // else {
    //     newItem.taxableIncome = newItem.earnedSalary + newItem.overtimeTotal + newItem.taxableEarnings + newItem.taxableBonuses
    //     newItem.nonTaxableIncome = newItem.additionalFixedEarnings + newItem.bonusesTotal - newItem.taxableEarnings - newItem.taxableBonuses
    //     newItem.totalIncome = newItem.earnedSalary + newItem.additionalEarningsTotal//newItem.taxableIncome + newItem.nonTaxableIncome
    //
    // }

    // if(newItem.taxableIncome<0)
    // console.log(newItem.fullName, newItem.basicSalary, newItem.earnedSalary,newItem.basicAbsentDeduction)

   // newItem.incomeTax = newItem.extraIncomeTax calculateIncomeTax(newItem.taxableIncome, taxRules) + (newItem.extraIncomeTax || 0)


    // if(field==='bonuses'||field==='calculated') {
        const additionalIncomeTaxes = []
        newItem.bonuses.filter(b=>b.type==='backPay').forEach(bp=>{
            // console.log({bp,val:calculateIncomeTax(bp.extraData.taxableIncome+bp.value, taxRules) - bp.extraData.incomeTax})
            additionalIncomeTaxes.push(calculateIncomeTax(bp.extraData.taxableIncome+bp.value, taxRules) - bp.extraData.incomeTax)
        })

        newItem.bonuses.filter(b=>b.type==='bonus').forEach(bp=>{
           // console.log({bp}) //,val:bp.extraData.payrolls.reduce((sum,cv)=>sum+(calculateIncomeTax(cv.taxableIncome+(bp.value/bp.extraData.payrolls.length), taxRules) - cv.incomeTax),0)})
            if(bp.extraData.collection && bp.extraData.collection.length) {
                additionalIncomeTaxes.push(bp.extraData.collection.reduce((sum, cv) => sum + (calculateIncomeTax(cv.taxableIncome + (bp.value / bp.extraData.collection.length), taxRules) - cv.incomeTax), 0))
            }   else {
                additionalIncomeTaxes.push(calculateIncomeTax(newItem.taxableIncome+bp.value, taxRules) - newItem.incomeTax)
            }

        })
        // console.log({additionalIncomeTaxes})


      //console.log({extraIncome: newItem.extraIncomeTax})
    newItem.additionalIncomeTax =  additionalIncomeTaxes.reduce((sum,cv)=>sum+cv,0)

        newItem.incomeTax =   calculateIncomeTax(newItem.taxableIncome, taxRules, {context:newItem}) +     newItem.additionalIncomeTax    + (newItem.extraIncomeTax || 0)

    //console.log(newItem.incomeTax, newItem.additionalIncomeTax)

    const paybackTax =  newItem.bonuses.reduce((sum,cv)=>sum+(cv.taxOption==='TaxableWithPayback'?calculateTaxableEarning(cv,newItem.basicSalary):0),0)

    if(paybackTax>=0) {
        const withOut =    calculateIncomeTax(newItem.taxableIncome-paybackTax, taxRules, {context:newItem})+ (newItem.extraIncomeTax || 0) +    newItem.additionalIncomeTax
        const val = newItem.incomeTax - withOut;
        const index = newItem.payrollDeductions.findIndex(i=>i.type==='tax')
        const newRoles = newItem.payrollDeductions.slice(0); // Create a shallow copy of the roles
        if(index>=0) {
            newRoles[index] = {type: "tax", value:val, name: "Tax Paid on behalf", cash: true};
        }// Set the new value
        else if(val>0) {
            newRoles.push({type: "tax", value:val, name: "Tax Paid on behalf", cash: true})
        }
        // console.log({paybackTax,newRoles})

        newItem.payrollDeductions.length =0
        newItem.payrollDeductions.push(...newRoles)

    } else {
        const index = newItem.payrollDeductions.findIndex(i=>i.type==='tax')
        const newRoles = newItem.payrollDeductions.slice(0); // Create a shallow copy of the roles
        if(index>=0) {
            newRoles[index] = {type: "tax", value:0, name: "Tax Paid on behalf", cash: true};
        }// Set the new value
        newItem.payrollDeductions.length =0
        newItem.payrollDeductions.push(...newRoles)
    }



    const taxableCoveredEarnings =  newItem.payrollFixedEarnings.reduce((sum,cv)=>sum+(cv.taxOption==='TaxCovered'?calculateTaxableEarning(cv,newItem.basicSalary):0),0) + newItem.bonuses.reduce((sum,cv)=>sum+(cv.taxOption==='TaxCovered'?calculateTaxableEarning(cv,newItem.basicSalary):0),0)
    const taxableBenefit =  newItem.payrollFixedEarnings.reduce((sum,cv)=>sum+((cv.taxOption==='TaxCovered' && cv.earningType.toLowerCase().replace(/\s+/g, '').includes('benefitinkind'))?calculateTaxableEarning(cv,newItem.basicSalary):0),0) + newItem.bonuses.reduce((sum,cv)=>sum+((cv.taxOption==='TaxCovered' && cv.type.toLowerCase().replace(/\s+/g, '').includes('benefitinkind'))?calculateTaxableEarning(cv,newItem.basicSalary):0),0)


    const withOut = calculateIncomeTax(newItem.taxableIncome -  taxableCoveredEarnings, taxRules,{context:newItem})+ (newItem.extraIncomeTax || 0) + newItem.additionalIncomeTax
    newItem.coveredIncomeTax =newItem.incomeTax - withOut;

    const withOutBenefit = calculateIncomeTax(newItem.taxableIncome -  taxableBenefit, taxRules,{context:newItem})+ (newItem.extraIncomeTax || 0) + newItem.additionalIncomeTax
    // the rule say if the tax of benefit in kind is greater than 10% of basic salary use 10% of basic salary

   // const benefitTax = calculateIncomeTax(taxableBenefit, taxRules)
   // console.log(newItem.employee.name, taxableBenefit, newItem.taxableIncome -  taxableBenefit, newItem.incomeTax-withOutBenefit, withOutBenefit)

    if(taxableBenefit && newItem.basicSalary*0.1<=(newItem.incomeTax-withOutBenefit))
    {
        //console.log({x:newItem.incomeTax-withOutBenefit})
        newItem.coveredIncomeTax = (newItem.incomeTax - withOut) - (newItem.incomeTax -withOutBenefit) + newItem.basicSalary*0.1
        // console.log(newItem.coveredIncomeTax)
        newItem.incomeTax = withOutBenefit + newItem.coveredIncomeTax
    }



    // }

   // console.log(newItem.payrollDeductions)

    newItem.payrollLoanSettlement = newItem.payrollDeductions.reduce((sum,cv)=>cv.type==='loan'?sum+cv.value:sum,0)
    newItem.payrollAdvanceSettlement = newItem.payrollDeductions.reduce((sum,cv)=>cv.type==='advance'?sum+cv.value:sum,0)
    newItem.payrollDeductionTotal = newItem.payrollDeductions.reduce((sum,cv)=>!cv.cash?sum+cv.value:sum,0)// - newItem.payrollAdvanceSettelment- newItem.payrollLoanSettlement; //loan or advances are deducted from cash but not a netpay account
    newItem.payrollDeductionTotalCash = newItem.payrollDeductions.reduce((sum,cv)=>cv.cash?sum+cv.value:sum,0)// - newItem.payrollAdvanceSettelment- newItem.payrollLoanSettlement; //loan or advances are deducted from cash but not a netpay account
    newItem.totalDeduction =  newItem.fixedDeductionsTotals +  newItem.absenceDeduction + newItem.incomeTax +  newItem.payrollDeductionTotal
    newItem.fixedDeductionsTotalsAndIncomeTax = newItem.fixedDeductionsTotals + newItem.incomeTax
    newItem.grossIncome = newItem.earnedSalary + newItem.additionalEarningsTotal - newItem.additionalEarningsAbsentDeduction
    if(newItem.employmentType !== 'Casual') {
        newItem.netPay = newItem.basicSalary + newItem.additionalEarningsTotal - newItem.totalDeduction
    }
    else {
        newItem.netPay = newItem.earnedSalary + newItem.additionalEarningsTotal - newItem.totalDeduction
    }
    newItem.cash =  Math.max(( newItem.payGross?newItem.grossIncome:newItem.netPay) - newItem.payrollDeductionTotalCash + (newItem.coveredIncomeTax||0), 0)
    newItem.totalDeductionIncludingCash = newItem.totalDeduction + newItem.payrollDeductionTotalCash

    newItem.outstandingLoan =  newItem.prevOutstandingLoan- (newItem.payrollLoanSettlement +  newItem.payrollAdvanceSettlement)

    if(newItem.costCenterCosts&&newItem.costCenterCosts.length) {
        const cs = []
        const costs =  _.groupBy(newItem.costCenterCosts,'costCenterCode')
        Object.keys(costs).map((key, val)=> {
            const taxRule = taxRules.find(r=>newItem.taxableIncome >= r.start && newItem.taxableIncome<=r.end)
            const  totalIncome =  costs[key].filter(c=>c.type==='Earnings').reduce((sum, cv)=>sum+cv.value,0)

            const  taxableIncome =  costs[key].filter(c=>c.type==='Earnings').reduce((sum, cv)=>sum+calculateTaxableEarning(cv,newItem.basicSalary),0)

            const incomeTax = newItem.incomeTax - calculateIncomeTax(newItem.taxableIncome- taxableIncome, taxRules ,{context:newItem, taxRule})
            const costCenterCashDeduction  = newItem.payrollDeductions.reduce((sum,cv)=>(cv.cash&&cv.costCenterCode===key)?sum+cv.value:sum,0)||0
            cs.push({
                totalIncome,
                costCenterCode: key,
                taxableIncome,
                incomeTax,
                netPay: totalIncome-incomeTax,
                cash: totalIncome-incomeTax-costCenterCashDeduction

            })

        })
        cs.push({
            totalIncome: newItem.totalIncome - cs.reduce((sum,cv)=>sum+cv.totalIncome,0),
            costCenterCode: newItem.costCenterCode,
            taxableIncome: newItem.taxableIncome - cs.reduce((sum,cv)=>sum+cv.taxableIncome,0),
            incomeTax: newItem.incomeTax - cs.reduce((sum,cv)=>sum+cv.incomeTax,0),
            netPay: newItem.netPay - cs.reduce((sum,cv)=>sum+cv.netPay,0),
            cash: newItem.cash- cs.reduce((sum,cv)=>sum+cv.cash,0)
        })
        // console.log({cs})
        newItem.costCenterItems = cs

    }

    return newItem

}

function calculatePayrollTotals(payrollsFull,site,employmentType,selectedStatus,costCenter) {
    const payrolls = payrollsFull.filter(p=>(!site||p.siteName===site)&&(!employmentType||p.employmentType===employmentType)&&(!selectedStatus||p.status===selectedStatus)&&(!costCenter||p.costCenterCode===costCenter));
 // console.log(payrolls.filter(p=>!p.empPension))
    // if(site || employmentType) {
   //   run = {...run, description:  `Only ${site?`${site} site`:''} ${!!employmentType&&employmentType} Employees`}
   // }

   return {
       totalIncome: payrolls.reduce((sum, cv) => sum + cv.totalIncome, 0),
       absentDeduction: payrolls.reduce((sum, cv) => sum + cv.absenceDeduction, 0),
       taxableIncome: payrolls.reduce((sum, cv) => sum + cv.taxableIncome, 0),
       incomeTax: payrolls.reduce((sum, cv) => sum + cv.incomeTax, 0),
       netPay: payrolls.reduce((sum, cv) => sum + cv.netPay, 0),
       pensionEmployee: payrolls.reduce((sum, cv) => sum + (cv.empPension || 0), 0),
       pensionCompany: payrolls.reduce((sum, cv) => sum + cv.companyPension, 0),
       pf: payrolls.reduce((sum, cv) => sum + cv.pf, 0),
       pfCompany: payrolls.reduce((sum, cv) => sum + cv.companyPf, 0),
       cash: payrolls.reduce((sum, cv) => sum + cv.cash, 0),
       cashSettlement: payrolls.reduce((sum, cv) => sum + cv.payrollDeductionTotalCash, 0),
       advanceSettlement: payrolls.reduce((sum, cv) => sum + cv.payrollAdvanceSettlement, 0),
       loanSettlement: payrolls.reduce((sum, cv) => sum + cv.payrollLoanSettlement, 0),
       payrollCount: payrolls.length,
       basicAbsentDeduction: payrolls.reduce((sum, cv) => sum + cv.basicAbsentDeduction, 0),
       overtimeTotal: payrolls.reduce((sum, cv) => sum + cv.overtimeTotal, 0),
   }

}

function getRemaining (ref,loans,deduct=0) {
    if(ref >=0 && loans) {
        const loan = loans.find(l=>l.id===ref)
        if(loan) {
            return loan.disburesedAmount  - loan.repayedAmount
        }
    }
    return 0;
}


// function getTotalMonthDays(payrollDate) {
//     const m = moment(payrollDate)
//     return m.daysInMonth()
// }
function distributeEarnings( distEarning, previousState, settings) {
    // console.log({distEarnings})
    // const fullScale = {daysWorked: 'normalPayDays', hoursWorked:'monthlyHours', actualHoursWorked: 'monthlyHours' }
    if(distEarning) {
        // console.log(distEarning.appliesTo)
        const applicablePayrolls = (distEarning.appliesTo && previousState.payrolls.filter(p => distEarning.appliesTo.emps.includes(p.employeeId))) || []
        let total = applicablePayrolls? applicablePayrolls.reduce((sum, cv) => sum + (cv[distEarning.basedOn] || 0), 0) : 0
        if(distEarning.appliesTo.endDate&&distEarning.totalTime>0) {
            //todo grab the actual total time from previous payrolls
            total = distEarning.totalTime
        }


        const payrollsWithDeductions = applicablePayrolls.filter(p=>p.bonuses&&p.bonuses.some(v=>v.name.toLowerCase().replace(/\s+/g, '').includes(distEarning.name.toLowerCase().replace(/\s+/g, ''))&&v.name.toLowerCase().replace(/\s+/g, '').includes('deduction')))
        const totalDeduction = payrollsWithDeductions.reduce((sum,cv)=>sum + cv.bonuses.reduce((s,v)=>{
            if(v.name.toLowerCase().replace(/\s+/g, '').includes(distEarning.name.toLowerCase().replace(/\s+/g, ''))&&(v.name.toLowerCase().replace(/\s+/g, '').includes('deduction'))) {
                // console.log("true",v.name)
                return s + v.value
            }
            return s
        },0), 0)

        // const totalDistributed =  applicablePayrolls.reduce((sum,item)=>sum + distEarning.baseAmount/applicablePayrolls.length * (item[distEarning.basedOn]/item[fullScale[distEarning.basedOn]]),0)
        const payrollsWithDeductionsIds = payrollsWithDeductions.map(p=>p.employeeId)
        const applicablePayrollsIds = applicablePayrolls.map(p=>p.employeeId)
        // const earningDiff = distEarning.baseAmount - totalDistributed;
        // const fullPaidPayrolls = applicablePayrolls.filter(p=>p[distEarning.basedOn]===p[fullScale[distEarning.basedOn]]).map(p=>p.employeeId)



        // console.log({fullPaidPayrolls, earningDiff})
        //



       const res =  previousState.payrolls.map(item => {

               let extraEarnings = item.bonuses


            // if (distEarning.appliesTo.emps.includes(item.employeeId)) {
            //
            //     extraEarnings = item.bonuses.filter(e=>e.name.toLowerCase().replace(/\s+/g, '')!==distEarning.name.toLowerCase().replace(/\s+/g, ''))
            //
            //
            //     let value = distEarning.baseAmount/applicablePayrolls.length * (item[distEarning.basedOn]/item[fullScale[distEarning.basedOn]])  //total ? (distEarning.baseAmount / total) * +item[distEarning.basedOn] : 0
            //     if(!payrollsWithDeductionsIds.includes(item.employeeId)){
            //         value += (-totalDeduction)/(applicablePayrolls.length-payrollsWithDeductionsIds.length)
            //     }
            //
            //    if(fullPaidPayrolls.includes(item.employeeId)) {
            //        value += earningDiff/fullPaidPayrolls.length
            //    }
            //
            //     // console.log({total,base:e.baseAmount,baseOn:item[e.basedOn], value})
            //     extraEarnings.push({type:distEarning.type, taxOption: distEarning.taxOption, value: +value.toFixed(2), name: distEarning.name})
            // }


            if (applicablePayrollsIds.includes(item.employeeId)) {
                extraEarnings = item.bonuses.filter(e=>e.name.toLowerCase().replace(/\s+/g, '')!==distEarning.name.toLowerCase().replace(/\s+/g, ''))

              //  if(item[distEarning.basedOn]>0) {

                    let value = total ? (distEarning.baseAmount / total) * +item[distEarning.basedOn] : 0

                  //  console.log("dist",item.distEarningsOnActualDate, "days",item)
                    if(distEarning.appliesTo.endDate) {
                        //todo grab the actual base from previous payrolls
                         // newbase = servicePayroll[distEarning.basedOn]
                      //  value = total ? (distEarning.baseAmount / total) * newBase : 0
                        //When distrubuting earnings like service charge with endDate set, use the actual date to proret, if set false it will only qualify based on hired month
                        const daysWorked  = item.distEarningsOnActualDate?moment(distEarning.appliesTo.endDate).diff(moment(item.startDate), "days")+1:item.totalWorkDays
                        const basePerDay =  distEarning.basedOn === "serviceHours"?item.dailyServiceHour:distEarning.basedOn === "daysWorked"?1:item[distEarning.basedOn] / item.daysWorked

                        const newBase = daysWorked<item.totalWorkDays?basePerDay * daysWorked:basePerDay * item.totalWorkDays

                        value = total ? (distEarning.baseAmount / total) * newBase : 0
                      //  const
                     //   console.log({workDays:item.totalWorkDays,daysWorked})
                     //    if(daysWorked<item.totalWorkDays) {
                     //       //newItem.serviceHours =  (newItem.daysWorked * newItem.dailyServiceHour)-(newItem.deductServiceHour?(newItem.missedHours||0):0)
                     //        const newBase = basePerDay * daysWorked
                     //
                     //    }
                        // console.log({daysWorked,value, total})
                    }

                    if (!payrollsWithDeductionsIds.includes(item.employeeId)) {
                        value += (-totalDeduction) / (applicablePayrolls.length - payrollsWithDeductions.length)
                    }
                    // console.log({total,base:e.baseAmount,baseOn:item[e.basedOn], value})
                   if(value>0) {
                       extraEarnings.push({
                           type: distEarning.type,
                           taxOption: distEarning.taxOption,
                           value: +value.toFixed(2),
                           name: distEarning.name,

                       })
                   }
                }
          //  }




                // distEarnings.forEach(e => {
                //
                //     const total = e.appliesTo ? previousState.payrolls.filter(p => e.appliesTo.emps.includes(p.employeeId)).reduce((sum, cv) => sum + (cv[e.basedOn] || 0), 0) : 0
                //
                //
                //     if (e.appliesTo.emps.includes(item.employeeId)) {
                //
                //
                //         const value = total ? (e.baseAmount / total) * +item[e.basedOn] : 0
                //         // console.log({total,base:e.baseAmount,baseOn:item[e.basedOn], value})
                //         extraEarnings.push({taxOption: e.taxOption, value: +value.toFixed(2), name: e.name})
                //     }
                //
                // })
                // console.log({extraEarnings})
                return calculatePayrollItem(item, 'bonuses', extraEarnings, previousState.incomeTaxRules, settings)
            }
        );

        return  res;
    }
    else return  previousState.payrolls
}

export default (previousState = initialState, { type, payload }) => {
    if (type === 'RESET_PAYROLL') {
        return {...initialState}
    }

    if (type === 'PAYROLL_LOADED') {
        return {...previousState,payrolls:payload};
    }
    if (type === 'PAYROLL_DATE_CHANGED') {

        let {payrollDate=previousState.payrollDate,periodStart=previousState.periodStart,periodEnd=previousState.periodEnd} = payload

        let startOfMonth = previousState.payrollDate
       // console.log({payload,startOfMonth,off:previousState.offsetDays})
        if(payrollDate!==previousState.payrollDate) {

            const today = moment(payrollDate).add(15, 'days')
            const todayEc = Zemen.toEC(today.get('year'), today.get('month'), today.get('date'))
             startOfMonth = previousState.calendar === 'EC' ? Zemen.toGC(todayEc.getFullYear(), todayEc.getMonth(), 1) : today.startOf('month').toDate()
            const totalDaysInPayPeriod = previousState.periodDaySetting>0?previousState.periodDaySetting:moment(payrollDate).daysInMonth()
            const totalDaysInMonth = previousState.calendar === 'EC'?30:moment(payrollDate).daysInMonth()
            periodStart = moment(startOfMonth).add(previousState.offsetDays,'days').toDate();
            periodEnd = moment(periodStart).add(totalDaysInMonth-1,'days').set('h',23).set('minute',59).toDate()
            // console.log({totalDaysInPayPeriod, startOfMonth,periodStart,periodEnd})
            // periodEnd = previousState.periodDaySetting>0?moment(periodStart).add(previousState.periodDaySetting,'days'):moment(periodStart).add(moment(periodStart).daysInMonth(),'days').toDate()

            // periodStart = startOfMonth
            //  periodEnd = today.endOf('month').toDate()
        }
     //   const payrollDate =  payload.refresh?moment(previousState.payrollDate).toDate():startOfMonth;


        return {...previousState,payrollDate, periodEnd, periodStart, prevPayrollDate: previousState.payrollDate};
    }
    if(type === 'PAYROLLS_SET_FIELD') {
        const {field,val} = payload
        // console.log({field,val})
        const payrolls = previousState.payrolls.map(p=>{
            p[field] = val
            return p
        })




        return {...previousState, payrolls }

    }


    if( type === 'DIST_EARNINGS_CHANGED') {
        const {distEarnings, apply, saveOnly} = payload
        // console.log({distEarnings,apply})

        if(saveOnly) {
            return {...previousState,distEarnings }

        }

        const data = distributeEarnings(apply,previousState,previousState.settings)
        // console.log("data",data)


        return {...previousState,distEarnings, payrolls: data,...calculatePayrollTotals(data,previousState.site,previousState.employmentType,previousState.selectedStatus) }
    }

    if( type === 'DIST_EARNINGS_REMOVED') {
        const {distEarnings, apply, saveOnly} = payload
        // console.log({apply})
        const applicablePayrollsIds = (apply.appliesTo && previousState.payrolls.filter(p => apply.appliesTo.emps.includes(p.employeeId))).map(p=>p.employeeId) || []


        const data =  previousState.payrolls.map(item=>{
           return  calculatePayrollItem(item, 'bonuses',  item.bonuses.filter(b=>  !applicablePayrollsIds.includes(item.employeeId)|| b.name!==apply.name), previousState.incomeTaxRules, previousState.settings)
        })
        // console.log("data",data)


        return {...previousState,distEarnings, payrolls: data,...calculatePayrollTotals(data,previousState.site,previousState.employmentType,previousState.selectedStatus) }
    }

    if (type === 'CLEAR_PAYROLL') {
        return {...initialState}
    }

    if (type === 'GROUP_BONUS_CHANGED') {
        const {payrolls} = payload
        // console.log({payrolls})

        // const payrollLookup = Object.fromEntries(previousState.payrolls.map((p) => [p.employeeId, p]))


        const newPayrolls = previousState.payrolls.map(p=>{

            if(payrolls[p.employeeId]) {
                let bonuses = p.bonuses.filter(b=>(b.name).replace(/\s+/g, '').toLowerCase()==="annualbonus")

                const refPayroll = payrolls[p.employeeId]
                // console.log({refPayroll})
                const monthsSinceStart = moment(payload.lastDate).endOf('month').diff(moment(p.employee.startDate),'days')/30
                const monthsInBonusYear = Math.max(0,Math.min(monthsSinceStart,12))
                const effectiveRate = +refPayroll[0].bonusRate/(+refPayroll[0].bonusScale||1) *  monthsInBonusYear/12

                // console.log({monthsSinceStart,effectiveRate,bonusp,payload})
                bonuses.push({
                    type: 'bonus',
                    taxOption: 'NonTaxable',
                    value: effectiveRate * p.fullBasicSalary,
                    extraData:{collection:refPayroll.map(d=>({taxableIncome:d.taxableIncome, backpayPayroll:d.payrollRunId, incomeTax:d.incomeTax}))},
                    name: 'Annual Bonus'
                })

                p.bonusData = {
                    monthsInBonusYear,
                    bonusFactor:+refPayroll[0].bonusRate,
                    bonusScale:+refPayroll[0].bonusScale||1
                }

                return calculatePayrollItem(p, 'bonuses', bonuses, previousState.incomeTaxRules, previousState.settings)

            }
            else return p;

        })


        return {...previousState, payrolls: newPayrolls,...calculatePayrollTotals(newPayrolls,previousState.site,previousState.employmentType,previousState.selectedStatus) }




    }

    if (type === 'PAYROLL_ITEM_CHANGED') {
        const {event} = payload
        //console.log(event.value)
        const editedItemID = event.dataItem.employeeId;
        const data = previousState.payrolls.map(item =>
            item.employeeId === editedItemID ? calculatePayrollItem(item,event.field,event.value,previousState.incomeTaxRules, previousState.settings) : item
        );

        return {...previousState,payrolls:data,...calculatePayrollTotals(data,previousState.site,previousState.employmentType,previousState.selectedStatus)};
    }

    if( type==='PAYROLL_TIMESHEET_UPDATED') {
        const {timeSheets,idField,source} = payload
       // console.log(timeSheets)
        const lookup = {}

        for (let i = 0; i < timeSheets.length; i++) {
            if(timeSheets[i][idField]) {
                lookup[timeSheets[i][idField].toString().toLowerCase().replace(/\s+/g, '')] = timeSheets[i]
            }
            else {
                // console.log(timesheet[i])
            }
        }
        // console.log(lookup)

       const data = previousState.payrolls.map(p=>{
           // console.log(p[idField],idField)

            if(p&&p[idField]&&p.status!=='Paid') {
                const timeSheet = lookup[p[idField].toString().toLowerCase().replace(/\s+/g, '')]
                if (timeSheet) {
                    // console.log({timeSheet})


                    if (timeSheet.ot&&(timeSheet.overtimes||timeSheet.overtimes===0)) {
                        p = calculatePayrollItem(p, 'overtimes', timeSheet.overtimes, previousState.incomeTaxRules,previousState.settings)
                    }
                    if(!(p.absentDays>0&&timeSheet.absentDays<0)) {

                        if (timeSheet.absentDays>=0) {
                            // console.log(timeSheet.absentDays)
                            p = calculatePayrollItem(p, 'absentDays', timeSheet.absentDays, previousState.incomeTaxRules,previousState.settings)

                            // if(p.normalPayDays<p.totalDaysInPayPeriod) {
                            //     const absent = timeSheet.absentDays//- (p.totalDaysInPayPeriod -p.normalPayDays)
                            //     p = calculatePayrollItem(p, 'absentDays', absent, previousState.incomeTaxRules, previousState.settings)
                            // }
                            // else {
                            //     p = calculatePayrollItem(p, 'absentDays', timeSheet.absentDays, previousState.incomeTaxRules,previousState.settings)
                            //
                            // }
                        }


                        if (timeSheet.monthlyHours || timeSheet.monthlyHours === 0) {
                            // console.log("ss",p.useServiceHours)
                            if (p.useServiceHours) {
                                // console.log("helloo")
                                p = calculatePayrollItem(p, 'monthlyHours', p.dailyServiceHour * Math.min(p.normalPayDays, p.daysInMonthForExpectedHours), previousState.incomeTaxRules, previousState.settings)
                            }

                                // else if(p.normalPayDays<p.totalDaysInPayPeriod)
                                // {
                                //     const reducedMonthlyHours = timeSheet.monthlyHours/p.totalDaysInPayPeriod *(p.normalPayDays-1)
                                //     p = calculatePayrollItem(p, 'monthlyHours', Math.floor(reducedMonthlyHours), previousState.incomeTaxRules)
                                //
                            // }
                            else {
                                p = calculatePayrollItem(p, 'monthlyHours', +(+timeSheet.monthlyHours).toFixed(1), previousState.incomeTaxRules, previousState.settings)
                            }
                        }
                        if (timeSheet.normalPayDays >= 0) {
                            p = calculatePayrollItem(p, 'normalPayDays', +timeSheet.normalPayDays, previousState.incomeTaxRules, previousState.settings)
                        }

                        if (timeSheet.missedHours || timeSheet.missedHours === 0) {
                            //  if(!(p.absentDays>0&&timeSheet.absentDays<0)) {
                            p = calculatePayrollItem(p, 'missedHours', +(+timeSheet.missedHours).toFixed(1), previousState.incomeTaxRules, previousState.settings)
                            // }
                        }

                        if (timeSheet.hoursWorked || timeSheet.hoursWorked === 0) {
                            if (p.useServiceHours) {
                                p = calculatePayrollItem(p, 'hoursWorked', +(p.monthlyHours - p.missedHours - (p.absentDays * p.dailyServiceHour)).toFixed(1), previousState.incomeTaxRules, previousState.settings)
                            } else {

                                p = calculatePayrollItem(p, 'hoursWorked', +(+timeSheet.hoursWorked).toFixed(1), previousState.incomeTaxRules, previousState.settings)

                            }

                        }


                        if (source === "tik" && timeSheet.hoursWorked) {
                            p = calculatePayrollItem(p, 'fromTimeAttendance', +(+timeSheet.hoursWorked).toFixed(1), previousState.incomeTaxRules, previousState.settings)
                        }




                        if (timeSheet.penaltyAbsent>0) {
                            // console.log(timeSheet.absentDays)
                            p = calculatePayrollItem(p, 'penaltyAbsentDays', timeSheet.penaltyAbsent, previousState.incomeTaxRules,previousState.settings)

                            // if(p.normalPayDays<p.totalDaysInPayPeriod) {
                            //     const absent = timeSheet.absentDays//- (p.totalDaysInPayPeriod -p.normalPayDays)
                            //     p = calculatePayrollItem(p, 'absentDays', absent, previousState.incomeTaxRules, previousState.settings)
                            // }
                            // else {
                            //     p = calculatePayrollItem(p, 'absentDays', timeSheet.absentDays, previousState.incomeTaxRules,previousState.settings)
                            //
                            // }
                        }


                    }

                    if(timeSheet.leaveDays||timeSheet.leaveDays===0) {p = calculatePayrollItem(p,'leaveDays',timeSheet.leaveDays,previousState.incomeTaxRules,previousState.settings)}

                    if(timeSheet.transportPayableDays>=0) {
                        // if(!timeSheet.transportPayableDays>31){
                        //     console.log(p, timeSheet)
                        // }
                        // console.log(p, timeSheet)
                        const newEarnings = p.payrollFixedEarnings.slice(0); // Create a shallow copy of the roles
                        //const name = newRoles[index].name

                        const index = newEarnings.findIndex(e=>e.name.toLowerCase().replace(/\s+/g, '')==="transportallowance")
                        //console.log(index)
                        if(index>=0) {
                            const val = (newEarnings[index].originalValue / p.totalDaysInPayPeriod||30) * Math.min(p.totalDaysInPayPeriod,+timeSheet.transportPayableDays);

                            newEarnings[index] = {
                                ...newEarnings[index],
                                entitledDays:  Math.min(p.totalDaysInPayPeriod,+timeSheet.transportPayableDays),
                                fullValue: val,
                                value: val
                            }; // Set the new value
                            //console.log(newEarnings)
                            p = calculatePayrollItem(p,'payrollFixedEarnings',newEarnings,previousState.incomeTaxRules,previousState.settings)

                        }


                    }
                    if (timeSheet.actualHoursWorked||timeSheet.actualHoursWorked===0) {p = calculatePayrollItem(p,'actualHoursWorked',+(+timeSheet.actualHoursWorked).toFixed(1),previousState.incomeTaxRules,previousState.settings)}

                }
            }

            return p;
        })

        return {...previousState,payrolls:data,...calculatePayrollTotals(data,previousState.site,previousState.employmentType,previousState.selectedStatus)};


    }

    if( type==='PAYROLL_EARNINGS_DEDUCTIONS_UPDATED') {
        const {items,idField,deductionTypes, earningTypes} = payload

        // console.log(items)
        // const lookup = {}
        //
        // for (let i = 0; i < items.length; i++) {
        //     if(items[i][idField]) {
        //         lookup[items[i][idField].toString().toLowerCase()] = items[i]
        //     }
        //     else {
        //         // console.log(timesheet[i])
        //     }
        // }
        const lookup = _.groupBy(items, idField);
        // console.log({lookup})

        const data = previousState.payrolls.map(p=>{

            if(p&&p[idField]&&p.status!=='Paid') {
                const items = lookup[p[idField].toString().toLowerCase()]
                // console.log({items})
                items&&items.forEach(item=>{
                    if (item) {
                        // console.log({item})
                        if(item.category==='deduction')
                        {
                            const t = (item.type && deductionTypes.find(t=>t.type.toLowerCase()===item.type.toLowerCase()))||{type:'other', name:'Other', cash:true}
                            const deductions  = (p.payrollDeductions||[]).slice(0); // Create a shallow copy of the roles
                            const d = deductions.find(e=>e.name.toLowerCase().replace(/\s+/g, '')===item.name.toLowerCase().replace(/\s+/g, ''))
                            if(!d) {
                                deductions.push({
                                    type: t.type,
                                    name: item.name || t.name,
                                    cash: item.cash || t.cash,
                                    value: +item.value
                                })
                            }
                            else {
                                d.value = +item.value
                            }


                            p = calculatePayrollItem(p,'payrollDeductions',deductions,previousState.incomeTaxRules,previousState.settings)

                        }

                        if(item.category==='earning')
                        {
                            // console.log(item)

                            const t = (item.type && earningTypes.find(t=>t.id.toLowerCase().replace(/\s+/g, '')===item.type.toLowerCase().replace(/\s+/g, '')))||{id:'other', name:item.name||'Other Earning', taxOption: "Taxable"}
                            if(item.extraData && (item.type==='shiftAllowance'||item.type==='onCallAllowance')) {
                                item.value =  (p.fullBasicSalary)/(p.monthlyOTHours)*item.extraData.shiftRate * item.extraData.shiftHour
                            }
                            const v = (t&&t.name.toLowerCase().includes('deduction'))?-Math.abs(+item.value):+item.value
                            const earnings  = (p.bonuses||[]).slice(0); // Create a shallow copy of the earnings

                            const e = earnings.find(e=>e.name.toLowerCase().replace(/\s+/g, '')===item.name.toLowerCase().replace(/\s+/g, ''))
                            if(!e) {
                                earnings.push({
                                    type: t.id,
                                    extraData: item.extraData,

                                    name: item.name || t.name,
                                    taxOption: item.taxOption || t.taxOption,
                                    value: v
                                })
                            }
                            else {
                                e.value = v
                            }
                            // console.log(earnings)


                            p = calculatePayrollItem(p,'bonuses',earnings,previousState.incomeTaxRules,previousState.settings)

                        }
                    }

                })

            }

            return p;
        })

        return {...previousState,payrolls:data,...calculatePayrollTotals(data,previousState.site,previousState.employmentType,previousState.selectedStatus)};


    }



    if (type === 'PAYROLL_SITE_CHANGED') {
        const {site} = payload



        return {...previousState,site,description:site||previousState.employmentType||previousState.selectedStatus?`Only ${site?`${site} `:''} ${previousState.employmentType?previousState.employmentType:''} ${previousState.selectedStatus?previousState.selectedStatus:''} Employees`:'',...calculatePayrollTotals(previousState.payrolls,site,previousState.employmentType,previousState.selectedStatus,previousState.costCenter)};
    }
    if (type === 'PAYROLL_STATUS_CHANGED') {
        const {status} = payload



        return {...previousState,selectedStatus:status,description:status||previousState.employmentType||previousState.site?`Only ${previousState.site?`${previousState.site} `:''} ${previousState.employmentType?previousState.employmentType:''}  ${status?status:''} Employees`:'',...calculatePayrollTotals(previousState.payrolls,previousState.site,previousState.employmentType,status)};
    }
    if (type === 'PAYROLL_EMPLOYMENT_TYPE_CHANGED') {
        const {employmentType} = payload
        return {...previousState,employmentType,description:previousState.site||previousState.selectedStatus||employmentType?`Only ${previousState.site?`${previousState.site} `:''} ${employmentType?employmentType:''}  ${previousState.selectedStatus?previousState.selectedStatus:''} Employees`:'',...calculatePayrollTotals(previousState.payrolls,previousState.site,employmentType,previousState.selectedStatus,previousState.costCenter)};
    }
    if (type === 'PAYROLL_COST_CENTER_CHANGED') {
        const {costCenter} = payload

        // const payrolls = previousState.map(p=>{
        //     // console.log(p.costCenterCosts)
        //     if(costCenter&&p.costCenterItems&&p.costCenterItems.length){
        //         const cs = p.costCenterItems.find(c=>c.costCenterCode===costCenter)
        //         // console.log(cs)
        //         if(cs) {
        //             p.cash = cs.cash
        //             p.costCenterCode = cs.costCenterCode
        //             p.totalIncome = cs.totalIncome
        //             p.netPay= cs.netPay
        //             p.incomeTax = cs. incomeTax
        //             p.taxableIncome = cs.taxableIncome
        //         }
        //     }
        //     return p
        // })

        return {...previousState,costCenter,description:previousState.site||previousState.selectedStatus||costCenter?`Only ${previousState.site?`${previousState.site} `:''} ${costCenter?costCenter:''}  ${previousState.selectedStatus?previousState.selectedStatus:''} Employees`:'',...calculatePayrollTotals(previousState.payrolls,previousState.site,previousState.employmentType,previousState.selectedStatus,costCenter)};
    }
    if (type === 'PAYROLL_DESCRIPTION_CHANGED') {
        const {description} = payload
        return {...previousState,description};
    }
    if (type === 'PAYROLL_EXPAND_CHANGED') {
        const {event} = payload
        const editedItemID = event.dataItem.employeeId;
        const data = previousState.payrolls.map(item =>
            item.employeeId === editedItemID ? {...item, expanded: !event.dataItem.expanded} : item
        );
        return {...previousState,payrolls:data};
    }

    if(type === 'PAYROLLRUN_LOADED_FOR_MERGE'){
        const {payrollRun} = payload
        if(payrollRun.site) {
            const newPayrolls = payrollRun.payrolls.filter(p => p.siteName === payrollRun.site)
            const payrolls = previousState.payrolls.filter(p=>p.siteName !== payrollRun.site).concat(newPayrolls)
            // console.log(newPayrolls)
            return {...previousState, site:null, description:`Merged with ${payrollRun.site}`,  payrolls: payrolls,...calculatePayrollTotals(payrolls) }

        }

    }

//     if (type === 'PAYROLL_EMPLOYEES_LOADED') {
//     // console.log('root',payload.currencyState)
//     if(payload.employees)
//     {
//        // const incomeTaxRules = payload.incomeTaxRules.data.map(r=>({r.}))
//         const incomeTaxRules  = payload.incomeTaxRules.data.map(r=>(({ start, end,taxRate,deduction }) => ({ start, end,taxRate,deduction }))(r))
//
//         const contractEndEmployees = payload.employees.data.filter(e=>{
//            const endDate = new Date(new Date(e.contractEndDate).getFullYear(),new Date(e.contractEndDate).getMonth())
//             const payRollDate = new Date(new Date().getFullYear(),new Date().getMonth())
//             //          // endDate.set('')
//            return e.employmentType==="Contract" && endDate < payRollDate
//         } )
//
//         const filterIds = contractEndEmployees.map(e=>e.id);
//         const payrollData = payload.employees.data.filter(e=>!filterIds.includes(e.id)).map(e => {
//
//         // const commonDeductions=    payload.company.data.payrollFixedDeductions.map(d => {
//         //     const  value = d.valueType === 'Percentage'? d.value* e.basicSalary : d.value;
//         //     return {...d,value}
//         // })
//
//      const deductions =  e.deductions.filter(d=>!d.cash).map(d=>{
//               const  value = d.valueType === 'Percentage'? d.value* e.basicSalary : d.value;
//               const rest =  (({deductionType,name,scope,templateId}) => ({deductionType,name,scope,templateId }))(d);
//              return {...rest,value}
//      })
//
//      const cashDeductions =  e.deductions.filter(d=>d.cash).map(d=>{
//                 const  value = d.valueType === 'Percentage'? d.value* e.basicSalary : d.value;
//                 const rest =  (({deductionType:type,name,cash,ref,templateId}) => ({type,name,cash,ref,templateId }))(d);
//                 rest.remaining = getRemaining(rest.ref,e.loans,value)
//                  rest.estimatedMonth = Math.ceil(getRemaining(rest.ref,e.loans,value)/value)
//                 return {...rest,value}
//             });
//             const earnings=  e.earnings.map(d=>{
//                 const value= d.valueType === 'Percentage'? d.value* e.basicSalary : d.value;
//                 const rest =  (({limitByValue,name,taxOption,scope,earningType, templateId}) => ({limitByValue,name,taxOption,scope,earningType, templateId }))(d);
//                 return {...rest,value}
//             })
//
//
//             const expenses = e.expenses.map(d=>{
//                 const  value = d.valueType === 'Percentage'? d.value* e.basicSalary : d.value;
//                 const rest =  (({type,name,scope, templateId}) => ({type,name,scope, templateId }))(d);
//                 return {...rest,value}
//             })
//
//
//
//            // console.log('expenses',expenses)
//
//
//             const empPension = deductions.reduce((sum,cv)=>cv.deductionType === 'EmpPension'? sum+cv.value:sum,0)
//             const companyPension = expenses.reduce((sum,cv)=>cv.type === 'CompanyPension'? sum+cv.value:sum,0)
//             const pf = deductions.reduce((sum,cv)=>cv.deductionType === 'PF'? sum+cv.value:sum,0)
//             const companyPf= expenses.reduce((sum,cv)=>cv.type === 'CompanyPF'? sum+cv.value:sum,0)
// const leave = e.leaves.find(l=>l.typeId===1 && l.year===new Date().getFullYear() );
//            // console.log(payrollFixedDeductions)
// const monthyDaysSetting = payload.company.data.companySettings.find(s=>s.name==="Payroll_WorkingDays");
//             const monthyHoursSetting = payload.company.data.companySettings.find(s=>s.name==="Payroll_MonthlyHours");
//             const monthlyHoursForOT = payload.company.data.companySettings.find(s=>s.name==="Payroll_MonthlyHoursOT");
//             const deductTransportIfAbsent = payload.company.data.companySettings.find(s=>s.name==="Payroll_DeductTransportIfAbsent");
//
//
//             const bank = payload.banks.data.find(b=>b.id===e.companyBankId)
//      const fatherName = (!!e.fatherName)? e.fatherName: ''
//             const payroll = {
//             employeeId:e.id,
//             orgID: e.orgID,
//                 mateId: e.mateId,
//                 employmentType: e.employmentType,
//            // employee: e,
//             name:e.name,
//             positionName:e.position&&e.position.name,
//                 siteName:(e.site&&e.site.name)||'',
//             jobLevelName: (e.position&&e.position.jobLevel&&e.position.jobLevel.name)||'',
//              departmentName: (e.position&&e.position.department&&e.position.department.name)||'',
//             fullName: e.name + ' '+ fatherName +  ' ' + (!!e.gFatherName? e.gFatherName: ''),
//             bank: (bank && bank.bank) ? bank.bank.name : "",
//             bankBranch: e.bankBranch,
//             bankAccountNumber: e.bankAccountNumber,
//             tinNo:e.tinNo,
//             basicSalary: e.basicSalary,
//             overtimes: [],
//             bonuses: [],
//             additionalFixedEarnings: earnings.reduce((sum, cv) => sum + cv.value, 0),
//             fixedDeductionsTotals: deductions.reduce((sum, cv) => sum + cv.value, 0),
//             fixedExpensesTotals: expenses.reduce((sum, cv) => sum + cv.value, 0),
//             payrollFixedDeductions: deductions,
//             payrollFixedExpenses: expenses,
//             payrollFixedEarnings: earnings,
//             payrollDeductions: cashDeductions, //advances/loan or others
//             totalWorkDays: monthyDaysSetting?monthyDaysSetting.value : 26, //get from database
//             monthlyHours: monthyHoursSetting?monthyHoursSetting.value: 196, //get from database
//             monthlyOTHours: monthlyHoursForOT?monthlyHoursForOT.value: 208,
//              absentDays: 0,
//            leaveDays: 0,
//             daysWorked: monthyDaysSetting?monthyDaysSetting.value : 26,
//             hoursWorked: monthyHoursSetting?monthyHoursSetting.value: 196,
//            deductTransportIfAbsent: deductTransportIfAbsent?deductTransportIfAbsent.value==="true":false,
//             empPension,
//             companyPension,
//             pf,
//             companyPf,
//             leaveDaysTaken:(leave && leave.daysTaken)||0,
//              leaveDaysTakenPrevious:(leave && leave.daysTaken)||0,
//             leaveDaysEntitled:(leave && leave.entitledDays)||0,
//             prevOutstandingLoan: e.outstandingLoan,
//             outstandingLoan:e.outstandingLoan,
//             loans:e.loans,
//         }
//
//         return calculatePayrollItem(payroll,'calculated',true,incomeTaxRules)
//
//     })
//        // console.log(payload.currencyRate)
//         return {...previousState,runDate:new Date(),payrollDate: new Date(),payrolls:payrollData,...calculatePayrollTotals(payrollData,previousState.site),incomeTaxRules,currencyRateId:payload.currencyRate[0].id};
//
//     }
//

    function getWorkDays(totalDaysInPayPeriod, e, payrollStartDate, payrollEndDate, restDaysToSkip ) {

        const startDate =moment.max(moment(payrollStartDate,'YYYY-MM-DD'),moment(e.startDate,'YYYY-MM-DD')).startOf('day')

        const endDate =   moment(e.contractEndDate)>moment("1970-01-01")?moment.min(moment(payrollEndDate),moment(e.contractEndDate,'YYYY-MM-DD')):moment(payrollEndDate).startOf('day')

        // console.log({startDate,endDate})
        const totalDays = moment(payrollEndDate).diff(moment(payrollStartDate),'days')+1
        const workedDays =  endDate>startDate?endDate.diff(startDate,'days')+1:0 //including start and end dates
        // console.log({workedDays, diff:endDate.diff(startDate,'days')})
       // console.log({restDaysToSkip,workedDays,totalDays})
        if(restDaysToSkip && workedDays<totalDays) {
            return countDaysBetween(startDate,endDate,restDaysToSkip)
        }
        else
            return workedDays;
    }


//     function getWorkDays(totalDaysInPayPeriod, e, payrollDate) {
// //console.log(payrollDate)
//       const date = moment(payrollDate);
//     //    console.log(date.format("MM-YYYY"))
//     //  const totalWorkDays = monthyDaysSetting&&(monthyDaysSetting.value>0)?monthyDaysSetting.value : moment(payrollDate).daysInMonth();
//       let workDays = totalDaysInPayPeriod
//
//           // if(e&&(e.employmentType==="Casual"||e.employmentType==="Contract"))
//         // {
//             const startDate = new moment(e.startDate);
//         //    startDate.add(-1,'days')
//             const endDate = e.employmentType!=="FullTime" &&new moment(e.contractEndDate)
//      //   console.log(endDate)
//             if(date.format("MM-YYYY")===startDate.format("MM-YYYY"))
//             {
//                 workDays -= startDate.get('date')-1;
//              //   console.log( startDate.get('date'),startDate.toDate())
//             }
//
//             if(endDate&&date.format("MM-YYYY")===endDate.format("MM-YYYY"))
//             {
//              //   console.log("endDate contact")
//                 workDays -=  (totalDaysInPayPeriod - endDate.get('date'))
//             }
//
//
//        // }
//         return workDays
//      //   e.employmentType==="Casual"||e.employmentType==="Contract") (monthyDaysSetting?monthyDaysSetting.value : 26)
//     }

    // function convertCurrency(value,defaultCurrency='etb') {
    //     //console.log(payload.currencyRate)
    //     return value * ((payload.usedCurrencyRates)[defaultCurrency.toLowerCase()]) / ((payload.usedCurrencyRates)['etb']);
    //
    // }
//     }
    if (type === 'PAYROLL_EMPLOYEES_LOADED') {

        const calendarSetting = payload.company.data.companySettings.find(s=>s.name==="Payroll_Calendar");

        const calendar = (calendarSetting&&calendarSetting.value) || 'GC';
        const today = moment.utc().add(-8, 'days')

            const todayEc =   Zemen.toEC(today.get('year'),today.get('month'),today.get('date'))
        const startOfMonth = calendar==='EC'?Zemen.toGC(todayEc.getFullYear(),todayEc.getMonth(),1):today.startOf('month').toDate()
        // console.log({startOfMonth})


        const payrollDate =  payload.refresh?moment(previousState.payrollDate).toDate():startOfMonth;

        const monthyDaysSetting = payload.company.data.companySettings.find(s=>s.name==="Payroll_WorkingDays");
        const periodDaySetting = payload.company.data.companySettings.find(s=>s.name==="Payroll_PayPeriodDays");
        const absentDeductionAfterTaxSetting = payload.company.data.companySettings.find(s=>s.name==="absentDeductionAfterTax");

        const monthyHoursSetting = payload.company.data.companySettings.find(s=>s.name==="Payroll_MonthlyHours");
        const monthlyHoursForOT = payload.company.data.companySettings.find(s=>s.name==="Payroll_MonthlyHoursOT");
        const deductTransportIfAbsent = payload.company.data.companySettings.find(s=>s.name==="Payroll_DeductTransportIfAbsent");

        const useServiceHoursSetting = payload.company.data.companySettings.find(s=>s.name==="useServiceHour");
        const deductServiceHourSetting = payload.company.data.companySettings.find(s=>s.name==="deductServiceHour");
        const distEarningsOnActualDateSetting = payload.company.data.companySettings.find(s=>s.name==="distEarningsOnActualDate");

        const ignoreLeaveOnServiceHoursSetting = payload.company.data.companySettings.find(s=>s.name==="ignoreLeaveOnServiceHours");

        const daysInMonthForOTSetting = payload.company.data.companySettings.find(s=>s.name==="daysInMonthForOT");
        const daysInMonthForExpectedHoursSetting =   payload.company.data.companySettings.find(s=>s.name==="daysInMonthForExpectedHours");
        const settingFullMonth=  payload.company.data.companySettings.find(s=>s.name==='Pension_FullMonth')
        const StartFullMonth = settingFullMonth?settingFullMonth.value==='true':true
        const restDaysToSkipSetting = payload.company.data.companySettings.find(s=>s.name&&s.name.toLowerCase()==="restdaystoskip");
        const restDaysToSkip = restDaysToSkipSetting&&restDaysToSkipSetting.value&&restDaysToSkipSetting.value.split(',')
        // const omitTransportAllowance = false

        const absentDeductionAfterTax = absentDeductionAfterTaxSetting?absentDeductionAfterTaxSetting.value==='true':false

        const offsetDaysSetting = payload.company.data.companySettings.find(s=>s.name==="Payroll_Start_Offset");
        const offsetDays = +(offsetDaysSetting&&offsetDaysSetting.value) || 0;

        // console.log({useServiceHoursSetting})

        const totalDaysInPayPeriod = periodDaySetting&&(periodDaySetting.value>0)?+periodDaySetting.value : moment(payrollDate).daysInMonth();
        const totalDaysInMonth = calendar==='EC'?30:moment(payrollDate).daysInMonth();
        const useServiceHours = useServiceHoursSetting&&useServiceHoursSetting.value==='true'
        const ignoreLeaveOnServiceHours = ignoreLeaveOnServiceHoursSetting &&ignoreLeaveOnServiceHoursSetting.value==='true'
        const deductServiceHour = deductServiceHourSetting&&deductServiceHourSetting.value==='true'
        //When distrubuting earnings like service charge with endDate set, use the actual date to proret, if set false it will only qualify based on hired month
        const distEarningsOnActualDate = distEarningsOnActualDateSetting&&distEarningsOnActualDateSetting.value==='true'

        const daysInMonthForOT = (daysInMonthForOTSetting&&daysInMonthForOTSetting.value) || 26
        const daysInMonthForExpectedHours = (daysInMonthForExpectedHoursSetting&&daysInMonthForExpectedHoursSetting.value) || 26

        // const payrollStartDate = payload.refresh&&moment(previousState.prevPayrollDate).format('YYYY-MM')===moment(payrollDate).format('YYYY-MM')?moment(previousState.periodStart).toDate():moment(payrollDate).add(offsetDays,'days');
        // const payrollEndDate = payload.refresh&&moment(previousState.prevPayrollDate).format('YYYY-MM')===moment(payrollDate).format('YYYY-MM')?moment(previousState.periodEnd).toDate():moment(payrollStartDate).add(totalDaysInPayPeriod-1,'days').set('h',23)


        const payrollStartDate = payload.refresh?moment(previousState.periodStart).toDate():moment(payrollDate).add(offsetDays,'days').toDate();
        const payrollEndDate = payload.refresh?moment(previousState.periodEnd).toDate():moment(payrollStartDate).add(totalDaysInMonth-1,'days').set('h',23).set('minute',59).toDate()


        const fullWorkDays = monthyDaysSetting&&(monthyDaysSetting.value>0)?monthyDaysSetting.value : moment(payrollDate).daysInMonth();
        const fullMonthlyHours = (monthyHoursSetting?monthyHoursSetting.value: 196)



        const site = payload.refresh?previousState.site:null;
        const description = payload.refresh?previousState.description:null;
        const employmentType = payload.refresh?previousState.employmentType:null;

        const settings = {absentDeductionAfterTax}



        //console.log('root',payload)
        if(payload.employees)
        {
            // const incomeTaxRules = payload.incomeTaxRules.data.map(r=>({r.}))
            const incomeTaxRules  = payload.incomeTaxRules.data.map(r=>(({ start, end,taxRate,deduction }) => ({ start, end,taxRate,deduction }))(r))

            // const filteredEmployeesByDate = payload.employees.data.filter(e=>{
            //     const startDate =  new Date(new Date(e.startDate).getFullYear(),new Date(e.startDate).getMonth())
            //     const endDate = e.employmentType!=="FullTime" && new Date(new Date(e.contractEndDate).getFullYear(),new Date(e.contractEndDate).getMonth())
            // //     console.log(startDate)
            //     const payRollMonth = new Date(payrollDate.getFullYear(),payrollDate.getMonth())
            //     //          // endDate.set('')
            //
            //  //   return e.employmentType==="Contract" && (startDate > payRollMonth || endDate < payRollMonth)
            //     return startDate > payRollMonth || (endDate && endDate < payRollMonth)
            //
            //
            // } )

      //      const filterIds = filteredEmployeesByDate.map(e=>e.id);
            const payrollData = payload.employees.data.filter(e=>{
            //    console.log(moment(e.contractEndDate).toDate(),"?",payrollStartDate.toDate())
                // console.log(e.contractEndDate,e.startDate)
               return (!e.startDate || moment(e.startDate) <= moment(payrollEndDate)) && (!e.contractEndDate || moment(e.contractEndDate)<moment("1970/1/1") ||  moment(e.contractEndDate) >= moment(payrollStartDate))

            }).map(e => {

                const prevPayroll = payload.refresh && previousState.payrolls.find(p=>p.employeeId === e.id)

                // const commonDeductions=    payload.company.data.payrollFixedDeductions.map(d => {
                //     const  value = d.valueType === 'Percentage'? d.value* e.basicSalary : d.value;
                //     return {...d,value}
                // })
                e.startDate = e.startDate || moment("1970/1/1")
                e.contractEndDate = e.contractEndDate || moment("3000/1/1")

                //currency conversion
                e.basicSalary =  convertCurrency(e.basicSalary,e.defaultCurrency,payload.usedCurrencyRates)/// e.basicSalary * ((payload.currencyRate)[e.defaultCurrency.toLowerCase()]) / ((payload.currencyRate)['etb']);



                const totalWorkDays = Math.min(fullWorkDays,getWorkDays(totalDaysInPayPeriod,e,payrollStartDate,payrollEndDate,restDaysToSkip));
                const payDays = getWorkDays(totalDaysInPayPeriod,e,payrollStartDate,payrollEndDate,restDaysToSkip)

                const basicSalary = e.employmentType!=="Casual"?(+(e.basicSalary / totalDaysInPayPeriod )* getWorkDays(totalDaysInPayPeriod,e,payrollStartDate,payrollEndDate).toFixed(5)):e.basicSalary
                const costCenterCosts = []

                const deductions =  e.deductions.filter(d=>!d.cash&&d.value).map(d=>{
                   const val = d.valueType === 'Percentage'? d.value* e.basicSalary : convertCurrency(d.value,e.defaultCurrency, payload.usedCurrencyRates);
                    let startDate = moment(d.startDate||'1,1,1')

                    let value = val
                    //const now = moment(payrollDate).endOf('month')
                    const diff = moment(payrollEndDate).diff(startDate,'day')//+1
                  //  console.log(diff,'diff')

                    if( diff<totalDaysInPayPeriod) {
                      //  if(!StartFullMonth) {
                            value = diff <= 0 ? 0 : (d.deductionType==="EmpPension"&&StartFullMonth)? value : (value / totalDaysInPayPeriod) * (diff + 1)
                       // }
                    }
                    const rest =  (({deductionType,name,scope,templateId}) => ({deductionType,name,scope,templateId }))(d);
                    return {...rest,dayBased:d.valueType === 'Percentage'?true:d.dayBased, value,fullValue:value, originalValue:val}
                })

                const cashDeductions =  e.deductions.filter(d=>d.cash&&d.value).map(d=>{
                    let  value = d.valueType === 'Percentage'? d.value* e.basicSalary : convertCurrency(d.value,e.defaultCurrency,payload.usedCurrencyRates);

                    if(prevPayroll){

                        const deduct = prevPayroll.payrollDeductions.find(de=>de.ref && d.ref && de.ref === d.ref )

                        if(deduct){

                            value = deduct.value
                        }
                    }

                    const rest =  (({deductionType:type,name,cash,ref,templateId,dayBased}) => ({type,name,cash,ref,templateId,dayBased }))(d);

                    if(d.costCenterId&&d.costCenterId!==e.costCenterId) {
                        const costCenter = payload.costCenters.data.find(c=>c.id===d.costCenterId)
                        if(costCenter) {
                            costCenterCosts.push({
                                costCenterId: d.costCenterId,
                                costCenterCode: costCenter.code,
                                name: d.name,
                                type: 'Deduction',
                                value
                            })
                            rest.costCenterCode = costCenter.code
                        }
                    }


                   const remain = getRemaining(rest.ref,e.loans)
                    if(d.ref) {
                        value = Math.min(value, remain)
                    }
                    rest.remaining = remain - value
                    rest.estimatedMonth = Math.ceil( +(rest.remaining/value).toFixed(5))
                    return {...rest,value}
                });

                const freshEarnings=  e.earnings.map(d=>{

                    const value= d.valueType === 'Percentage'? d.value* e.basicSalary : d.valueType === 'Hour'?e.basicSalary/(monthlyHoursForOT.value||208)*+d.value: convertCurrency(d.value,e.defaultCurrency,payload.usedCurrencyRates);

                    const rest =  (({limitByValue,name,taxOption,scope,earningType, templateId,dayBased, showOriginal}) => ({limitByValue,name,taxOption,scope,earningType, templateId,dayBased,showOriginal }))(d);
                    if(d.costCenterId&&d.costCenterId!==e.costCenterId) {
                        const costCenter = payload.costCenters.data.find(c=>c.id===d.costCenterId)
                       if(costCenter) {
                           costCenterCosts.push({
                               costCenterId: d.costCenterId,
                               costCenterCode: costCenter.code,
                               name: d.name,
                               type: 'Earnings',
                               taxOption: rest.taxOption,
                               value
                           })
                           rest.costCenterCode = costCenter.code
                       }
                    }

                    return {...rest,dayBased:d.valueType === 'Percentage'?true:d.dayBased,value:value, originalValue:value, fullValue:value, refId:d.id}
                })

                // if(costCenterCosts.length) {
                //     costCenterCosts.push({costCenterId:d.costCenterId, costCenterCode:costCenter.code, name:d.name, type:'Earnings', taxOption:rest.taxOption,
                //
                //     }

                const expenses = e.expenses.map(d=>{
                    const  val = d.valueType === 'Percentage'? d.value* e.basicSalary : convertCurrency(d.value,e.defaultCurrency,payload.usedCurrencyRates);
                    const startDate = moment(d.startDate||'1,1,1')
                    let value = val
                  //  const now = moment(payrollDate).endOf('month')
                    const diff = moment(payrollEndDate).diff(startDate,'day')

                    // if( diff<totalDaysInPayPeriod) {
                    //     value = diff<=0? 0 :(value / totalDaysInPayPeriod) * (diff+1)
                    // }
                    if( diff<totalDaysInPayPeriod) {
                        //  if(!StartFullMonth) {
                        value = diff <= 0 ? 0 : (d.type==="CompanyPension"&&StartFullMonth)? value : (value / totalDaysInPayPeriod) * (diff + 1)
                        // }
                    }

                    const rest =  (({type,name,scope, templateId,dayBased}) => ({type,name,scope, templateId,dayBased }))(d);
                    return {...rest,dayBased:d.valueType === 'Percentage'?true:d.dayBased, value,fullValue:value, originalValue:val}
                })



                // console.log('expenses',expenses)


                // const empPension = deductions.reduce((sum,cv)=>cv.deductionType === 'EmpPension'? sum+cv.value:sum,0)
                // const companyPension = expenses.reduce((sum,cv)=>cv.type === 'CompanyPension'? sum+cv.value:sum,0)
                // const pf = deductions.reduce((sum,cv)=>cv.deductionType === 'PF'? sum+cv.value:sum,0)
                // const companyPf= expenses.reduce((sum,cv)=>cv.type === 'CompanyPF'? sum+cv.value:sum,0)
                const leave = e.leaves.find(l=>l.typeId===1 && l.year===new Date().getFullYear() );
                // console.log(payrollFixedDeductions)




                const bank = payload.banks.data.find(b=>b.id===e.companyBankId)

                // const fatherName = (
                const fullName = e.name + ' '+ (!!e.fatherName? e.fatherName: '')  +  ' ' + (!!e.gFatherName? e.gFatherName: '')



                // if(e.id===262) {
                //     console.log(prevPayroll)
                // }
            //    const prevPayDays = prevPayroll && getWorkDays(totalDaysInPayPeriod,prevPayroll.em,payrollDate)


                const addedDeductions = prevPayroll?_.differenceWith(prevPayroll.payrollDeductions,cashDeductions,(a,b)=>(a.ref && b.ref && a.ref===b.ref)||(a.name===b.name&&a.value===b.value)).filter(b=>!b.ref):[]
                  // if(e.id===271) {
                  //     console.log({addedDeductions,prevPayroll,cashDeductions})
                  // }


                const earnings = freshEarnings.map(e=>{
                    const prevEarning = prevPayroll&&prevPayroll.payrollFixedEarnings.find(fe=>fe.refId===e.refId)
                    let value = e.value;
                   let name = e.name;
                    let fullValue = e.fullValue;
                   let  entitledDays = payDays;
                    if(prevEarning&&(prevEarning.originalValue||+prevEarning.originalValue===0)&&prevEarning.originalValue!==prevEarning.fullValue)
                    {
                       value = prevEarning.value;
                       fullValue = prevEarning.value;
                       entitledDays = prevEarning.entitledDays
                       name = prevEarning.name;

                    }
                    return {...e,value, name,fullValue,entitledDays}
                })
                const normalPayDays = prevPayroll && prevPayroll.originalPayDays && prevPayroll.originalPayDays!==prevPayroll.normalPayDays?prevPayroll.normalPayDays:payDays
                const dailyServiceHour =  (prevPayroll &&prevPayroll.dailyServiceHour!==((prevPayroll.employee&&prevPayroll.employee.dailyServiceHour)||8))?prevPayroll.dailyServiceHour : ((!!e.dailyServiceHour||e.dailyServiceHour===0)?e.dailyServiceHour:8)
                const monthlyHours =  e.employmentType!=="Casual"?(!useServiceHours?fullMonthlyHours*normalPayDays/totalDaysInPayPeriod:dailyServiceHour*(Math.min(normalPayDays,daysInMonthForExpectedHours))):normalPayDays*dailyServiceHour; //get from database
// console.log({id:e.id, monthlyHours, normalPayDays})
                // console.log(previousState.payrollDate.toDate(),payrollDate)
                // console.log(prevPayroll&&prevPayroll.daysWorked,getWorkDays(monthyDaysSetting,e,previousState.payrollDate.toDate()))
              //console.log({e})
                const payroll = {
                    employeeId:e.id,
                    orgID: e.orgID,
                    mateId: e.mateId,
                    employmentType: e.employmentType,
                    employee: e,
                    startDate: e.startDate,
                    contractEndDate: e.contractEndDate,
                    name:e.name,

                    positionName:e.position&&e.position.name,
                    siteName:(e.site&&e.site.name)||'',
                    jobLevelName: (e.position&&e.position.jobLevel&&e.position.jobLevel.name)||'',
                    departmentName: (e.department&&e.department.name)||(e.position&&e.position.department&&e.position.department.name)||'',
                    costCenterCode: e.costCenter&&(e.costCenter.code||e.costCenter.name),
                    //  employeementTypeName: (e.position&&e.position.department&&e.position.department.name)||'',

                    fullName,
                    emailAddress:e.address&&e.address.email,
                    bank: (bank && bank.bank) ? bank.bank.name : "",

                    bankBranch: e.bankBranch,
                    bankAccountNumber: e.bankAccountNumber,
                    bankAccountHolderName: e.bankAccountHolderName||fullName,
                    pensionNo: e.pensionNo,
                    tinNo:e.tinNo,
                    fullBasicSalary: e.basicSalary,
                    overtimes: (prevPayroll &&prevPayroll.overtimes) || [],
                    bonuses: (prevPayroll &&prevPayroll.bonuses) || [],
                    additionalFixedEarningsOriginal: earnings.reduce((sum, cv) => sum + cv.originalValue, 0),
                    additionalFixedEarnings: earnings.reduce((sum, cv) => sum + cv.value, 0),
                    fixedDeductionsTotals: deductions.reduce((sum, cv) => sum + cv.value, 0),
                    fixedExpensesTotals: expenses.reduce((sum, cv) => sum + cv.value, 0),
                    payrollFixedDeductions: deductions,
                    payrollFixedExpenses: expenses,
                    payrollFixedEarnings: earnings,
                    payrollDeductions:  cashDeductions.concat(addedDeductions), //advances/loan or others
                    totalWorkDays, //get from database
                    totalDaysInPayPeriod,
                    fullWorkDays,
                    fullMonthlyHours,
                    monthlyHours:  (prevPayroll && prevPayroll.payDays===payDays &&prevPayroll.monthlyHours) || monthlyHours ,//: monthyHoursSetting?monthyHoursSetting.value: 196, //get from database
                    monthlyOTHours: !useServiceHours?monthlyHoursForOT?monthlyHoursForOT.value: 208:Math.min(11,dailyServiceHour)*daysInMonthForOT,
                    absentDays: (prevPayroll &&prevPayroll.absentDays) || 0,
                    penaltyAbsentDays: (prevPayroll &&prevPayroll.penaltyAbsentDays) || 0,
                    leaveDays: (prevPayroll &&prevPayroll.leaveDays) || 0,
                    // daysWorked: (prevPayroll &&prevPayroll.daysWorked!==getWorkDays(monthyDaysSetting,e,previousState.prevPayrollDate)&&prevPayroll.daysWorked) || getWorkDays(monthyDaysSetting,e,payrollDate),// getWorkDays(monthyDaysSetting,e,payrollDate),

                    daysWorked: (prevPayroll &&prevPayroll.daysWorked) || totalWorkDays,// getWorkDays(monthyDaysSetting,e,payrollDate),
                    payDays,
                    originalPayDays: payDays,
                    normalPayDays:normalPayDays,
                    hoursWorked: prevPayroll &&prevPayroll.normalPayDays=== normalPayDays ? prevPayroll.hoursWorked : monthlyHours,
                    actualHoursWorked: prevPayroll &&prevPayroll.actualHoursWorked !== prevPayroll.monthlyHours ? prevPayroll.actualHoursWorked : monthlyHours,
                    serviceHours:(prevPayroll &&prevPayroll.serviceHours) || totalWorkDays*dailyServiceHour,
                    dailyServiceHour,
                    useServiceHours,
                    ignoreLeaveOnServiceHours,
                    deductServiceHour,
                    distEarningsOnActualDate,
                    daysInMonthForOT,
                    daysInMonthForExpectedHours,
                    deductTransportIfAbsent: (prevPayroll &&prevPayroll.deductTransportIfAbsent) ||(deductTransportIfAbsent?deductTransportIfAbsent.value==="true":false),
                    omitTransportAllowance: (prevPayroll &&prevPayroll.omitTransportAllowance) || false,
                    // empPension,
                    // companyPension,d
                    // pf,
                    // companyPf,
                    leaveDaysTaken:(leave && leave.daysTaken)||0,
                    leaveDaysTakenPrevious:(leave && leave.daysTaken)||0,
                    leaveDaysEntitled:(leave && leave.entitledDays)||0,
                    prevOutstandingLoan: Math.max(e.outstandingLoan,0),
                    outstandingLoan:Math.max(e.outstandingLoan,0),
                    loans:e.loans,
                    basicSalary,
                    backPayDays: (prevPayroll && prevPayroll.backPayDays) || 0,
                    forwardPayDays:(prevPayroll && prevPayroll.forwardPayDays) || 0,
                    fromTimeAttendance: (prevPayroll && prevPayroll.fromTimeAttendance) || 0,
                    status: (prevPayroll && prevPayroll.status) || "Processing",
                    payrollDate:payrollDate,
                    periodDaySetting:periodDaySetting&&+periodDaySetting.value,
                    payGross: e.payGross || false,
                    otBase: e.otBase || 'basic',
                    excludeFromDistEarnings:  e.excludeFromDistEarnings,
                    costCenterCosts,
                    extraIncomeTax: e.extraIncomeTax||0,
                    customIncomeTaxExpression: e.customIncomeTaxExpression,
                }

                // const payroll = prevPayroll;
                // payroll.payrollDeductions = cashDeductions//.concat(addedDeductions)
                // payroll.loans =  e.loans


                return calculatePayrollItem(payroll, 'calculated', true, incomeTaxRules, settings)

            })
            const excluded = payload.employees.data.filter(e=>!payrollData.map(p=>p.employeeId).includes(e.id))
            // console.log({excluded})
            // console.log(payload.currencyRate)
            //console.log("date",previousState.payrollDate)
            // console.log(payload.refresh,previousState.distEarnings)
            const distEarnings = payload.refresh?previousState.distEarnings||[]: (payload.company.data.defaultDistEarnings||[]).map(e=>({...e,appliesTo:{...e.appliesTo,
                    emps:payrollData.filter(p=>!p.excludeFromDistEarnings&&e.appliesTo.groups.map(g=>g.toLowerCase()).includes(p.siteName.toLowerCase())&&(!e.appliesTo.endDate||moment(p.startDate)<=moment(e.appliesTo.endDate))).
                    map(p=>p.employeeId)

            }}))
            // console.log(distEarnings,payload.company.data.defaultDistEarnings)
            // const withDistEarnings = distributeEarnings(distEarnings,{...previousState,payrolls:payrollData,incomeTaxRules})
            return {...previousState, excluded, settings,   site,description,employmentType,runDate:new Date(),payrollDate:moment(payrollDate), periodStart:payrollStartDate, periodEnd:payrollEndDate, calendar, distEarnings, payrolls:payrollData,...calculatePayrollTotals(payrollData,previousState.site,previousState.employmentType,previousState.selectedStatus),incomeTaxRules,currencyRateId:payload.currencyRate.id,usedCurrencyRates:payload.usedCurrencyRates, firstRun: !payload.refresh, offsetDays, periodDaySetting:(periodDaySetting?+periodDaySetting.value||0:0), useServiceHours, ignoreLeaveOnServiceHours, deductServiceHour, daysInMonthForOT,daysInMonthForExpectedHours,distEarningsOnActualDate};

        }

    }
    if(type==='PAYROLLRUN_DATA_LOADED') {
        // console.log(payload.payrollRun)
        if(payload.payrollRun) {
          //  return calculatePayrollItem(payload.payrollRun,'calculated',true,incomeTaxRules)
             return {...previousState, ...payload.payrollRun}
        }
    }
    if(type==='PAYROLLRUN_SET_FIRST_RUN') {
        // if(payload.payrollRun) {
        //     //  return calculatePayrollItem(payload.payrollRun,'calculated',true,incomeTaxRules)
        //     return {...payload.payrollRun}
        // }
        return {...previousState, firstRun: payload}
    }
    return previousState;
}
