const initialState = {
   newHires: [],
   endEmployments: [],
    payrollStatement: []

};

export default (previousState = initialState, { type, payload }) => {
    if (type === 'EMPLOYEE_VARIANCE_LOADED') {
        const {newHires, endEmployments} = payload
        return {...previousState, newHires, endEmployments};
    }
    if (type === 'PAYROLL_STATEMENT_LOADED') {

        return {...previousState, payrollStatement: payload};
    }

    return previousState;
}
