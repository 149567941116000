import React from 'react';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import {connect} from 'react-redux';
import {parse as convertFromCSV} from 'papaparse';
import moment from "moment";
import Zemen from "../../UIComponents/ZemenCalendar/zemen";

function empty(data)
{

    if(typeof(data) == 'number' || typeof(data) == 'boolean')
    {
        return false;
    }
    if(data === '-')
    {
        return true;
    }
    if(data === '')
    {
        return true;
    }
    if(data === 0 || data === '0')
    {
        return true;
    }
    if(typeof(data) == 'undefined' || data === null)
    {
        return true;
    }
    if(typeof(data.length) != 'undefined')
    {
        return data.length === 0;
    }
    var count = 0;
    for(var i in data)
    {
        if(data.hasOwnProperty(i))
        {
            count ++;
        }
    }
    return count == 0;
}


export const ImportButton = (props) => {
    const { resource,  basePath, onImport ,label} = props;

    const handleChange = (e) => {
       //console.log('import',e)
        const file = e.target.files && e.target.files[0];
       // console.log('file',file)
        if (file) {
            convertFromCSV(file, {
                delimiter: ',',
                skipEmptyLines: 'greedy',
                complete: (result) => {
                    const { data } = result;
                   //  console.log('data',data)
                    const keys = data[0];
                    const primaryKey = keys[0];



                    const values = data.slice(1).map(row => {


                        const value = {};

                        keys.forEach((key, index) => {
                           // console.log("val",row[index]?0:1)
                            if(!empty(row[index].trim()) )

                           {
                               if(key.toLowerCase().includes('date'))
                            {
const dateFormat = keys.indexOf('dateFormat')
                                // console.log(dateFormat)
                                const calendar= row[keys.indexOf('calendar')]
                                // console.log(calendar)
                                if(calendar&&calendar==='EC') {
                                    if(row[dateFormat]) {
                                        const m = moment(row[index], row[dateFormat])
                                        value[key] = Zemen.toGC(m.year(),m.month(),m.date())
                                    }
                                    else {
                                        const m = moment(row[index])
                                        value[key] = Zemen.toGC(m.year(),m.month(),m.date())
                                    }

                                }
                                else {
                                    value[key] = (row[dateFormat] ? moment(row[index], row[dateFormat]).toDate() : moment(row[index]).toDate())
                                }

                            }
                            else {
                                value[key.trim()] = row[index].trim();
                            }}

                        });


                        //console.log("val",value)
                        return value;
                    });

                   // const ids = values.map(v => v[primaryKey]);
                   props.onImport(resource,values,basePath)
                }
            });
            e.target.value = null;
        }
    };

    return (
        <div>
            <input
                type="file"
                id={`${label}-import-input`}
                style={{ display: 'none' }}
                accept='.csv'
                onChange={handleChange}
            />
            <label htmlFor={`${label}-import-input`} style={{ display: 'inline-flex', alignItems: 'center' }}>
                <Button
                    color="primary"
                    component="span"
                >
                    <GetAppIcon style={{ transform: 'rotate(180deg)', fontSize: 20 }} />
                    <span style={{ paddingLeft: '0.5em' }}>{label||'Upload'}</span>
                </Button>
            </label>
        </div>
    );
};

export default connect(null,dispatch=>({
    onImport: (resource,data,basePath) => dispatch({
        type:'IMPORT_DATA',
        payload: {resource,data,basePath}
    })
}))(ImportButton);
// export default ImportButton
