import React from 'react';
import ExcelExportColumn from "@progress/kendo-react-excel-export/dist/es/ExcelExportColumn";
import ExcelExportColumnGroup from "@progress/kendo-react-excel-export/dist/es/ExcelExportColumnGroup";
import ExcelExport from "@progress/kendo-react-excel-export/dist/npm/ExcelExport";


const PayrollExcelExport = React.forwardRef((props, ref) => {




  //  if(!templateEarnings) return  null


    const {data, fileName} = props
    //console.log(data)
    // props.templates = props.templates || {}
    // const {templateEarnings, templateLoans,templateExpenses,templateDeductions} =  (props.templates || {})

    //console.log("templates",props.templates)
    const getSum = ({column}) => {
       // console.log(field)
         return data.reduce((sum,cv)=>sum+cv[column.field],0).toFixed(2)
    }
  //  console.log(data)
    return (
        <ExcelExport
            data={data}
            ref={ref}
            fileName={fileName}
        >
            <ExcelExportColumn width={50} field="sn" title="SN" />
            <ExcelExportColumn width={50} field="orgID" title="Work Id"/>
            <ExcelExportColumn width={150} field="fullName" title="Employee Name" footer={val=>'Totals'}/>

            <ExcelExportColumn width={100} field="basicSalary" title="Basic Salary" cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}} footerCellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}} footer={getSum}/>
            {/*<ExcelExportColumn field="daysWorked" title="Days Worked" cellOptions={{format: '##0'}}/>*/}
            <ExcelExportColumn width={50} field="absentDays" title="Absent Days" cellOptions={{format: '##0'}}/>
            <ExcelExportColumn width={50}  field="leaveDays" title="Leave Days(This month)" cellOptions={{format: '##0'}}/>

            {/*    {templateEarnings && templateEarnings.map(e=>*/}
            {/*        <ExcelExportColumn*/}
            {/*            width={100}*/}
            {/*            field={e.name.replace(/\s+/g, '').toLowerCase()}*/}
            {/*            title={e.name}*/}
            {/*            footerCellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}*/}
            {/*            cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}*/}
            {/*            footer={getSum}*/}
            {/*        />*/}

            {/*    )}*/}

            {/*{templateEarnings && templateEarnings.length >0 &&*/}
            {/*<ExcelExportColumn*/}
            {/*    width={100}*/}
            {/*    field="otherEarnings"*/}
            {/*    title="Other Monthly fixed earnings"*/}
            {/*    cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}*/}
            {/*    footerCellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}*/}
            {/*    footer={getSum}*/}
            {/*/>*/}
            {/*}*/}


             <ExcelExportColumn
                     width={100}
                    field="additionalFixedEarnings"
                    title="Total monthly fixed additional earnings"
                    cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}
                    footerCellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}
                    footer={getSum}
                />


                <ExcelExportColumn width={100} field="overtimeTotal" title="Overtime Total"
                                   footerCellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}
                                   cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}} footer={getSum}/>
                <ExcelExportColumn width={100} field="bonusesTotal" title="Benefits and Bonuses"
                                   footerCellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}
                                   cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}} footer={getSum}/>
            {/*</ExcelExportColumnGroup>*/}
            <ExcelExportColumn width={100} field="totalIncome" title="Total Income"
                               footerCellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}
                               cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}} footer={getSum} />
            <ExcelExportColumn width={100} field="basicAbsentDeduction" title="Absent Deduction (Basic)" cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}} footer={getSum} footerCellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}/>
            <ExcelExportColumn width={100} field="additionalEarningsAbsentDeduction" title="Absent Deduction (Allowance)" cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}} footer={getSum} footerCellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}/>
            <ExcelExportColumn width={100} field="grossIncome" title="Gross Income" cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}} footer={getSum} footerCellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}/>
            <ExcelExportColumn width={100} field="taxableIncome" title="Taxable Income" cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}} footer={getSum} footerCellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}/>

            <ExcelExportColumnGroup title="Deductions" headerCellOptions={{textAlign: 'center'}}>

                <ExcelExportColumn
                    width={100}
                    field="empPension"
                    title='Pension (Employee)'
                    cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}
                    }
                    footer={getSum}
                    footerCellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}
                />
                {/*<ExcelExportColumn field="companyPension" title="Pension (Company)" cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}} footer={getSum}/>*/}
                <ExcelExportColumn width={100} field="pf" title="Provident Fund" cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}} footer={getSum} footerCellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}/>
                <ExcelExportColumn width={100} field="incomeTax" title="Income Tax" cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}} footer={getSum} footerCellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}/>
            </ExcelExportColumnGroup>



            {/*{templateDeductions && templateDeductions.map(e=>*/}
            {/*    <ExcelExportColumn*/}
            {/*        width={100}*/}
            {/*        field={e.name.replace(/\s+/g, '').toLowerCase()}*/}
            {/*        title={e.name}*/}
            {/*        cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}*/}
            {/*        footer={getSum}*/}
            {/*        footerCellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}*/}
            {/*    />*/}

            {/*)}*/}

            {/*{templateDeductions && templateDeductions.length>0 &&*/}
            {/*<ExcelExportColumn*/}
            {/*    width={100}*/}
            {/*    field="otherDeductions"*/}
            {/*    title="Other Monthly fixed deductions"*/}
            {/*    cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}*/}
            {/*    footer={getSum}*/}
            {/*    footerCellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}*/}
            {/*/>*/}
            {/*}*/}





            <ExcelExportColumn width={100} field="fixedDeductionsTotalsAndIncomeTax" title="Total Deductions from Gross" cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}} footer={getSum} footerCellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}/>
            <ExcelExportColumn width={100} field="payrollDeductionTotal" title="Other Payroll Deductions" cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}} footer={getSum} footerCellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}/>

            <ExcelExportColumn width={100} field="companyPension" title="Pension (Company)" cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}} footer={getSum} footerCellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}/>
            <ExcelExportColumn width={100} field="companyPf" title="Provident Fund (Company)" cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}} footer={getSum} footerCellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}/>

            {/*{templateExpenses && templateExpenses.map(e=>*/}
            {/*    <ExcelExportColumn*/}
            {/*        width={100}*/}
            {/*        field={e.name.replace(/\s+/g, '').toLowerCase()}*/}
            {/*        title={e.name}*/}
            {/*        cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}*/}
            {/*        footer={getSum}*/}
            {/*        footerCellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}*/}
            {/*    />*/}

            {/*)}*/}

            {/*{templateExpenses && templateExpenses.length>0 &&*/}
            {/*<ExcelExportColumn*/}
            {/*    width={100}*/}
            {/*    field="otherExpenses"*/}
            {/*    title="Other Monthly fixed expenses"*/}
            {/*    cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}*/}
            {/*    footer={getSum}*/}
            {/*    footerCellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}*/}
            {/*/>*/}
            {/*}*/}



            <ExcelExportColumn width={100} field="netPay" title="Net (before settlement)" cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}} footer={getSum} footerCellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}/>

            {/*{templateLoans && templateLoans.map(e=>*/}
            {/*    <ExcelExportColumn*/}
            {/*        width={100}*/}
            {/*        field={e.name.replace(/\s+/g, '').toLowerCase()}*/}
            {/*        title={e.name}*/}
            {/*        cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}*/}
            {/*        footer={getSum}*/}
            {/*        footerCellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}*/}
            {/*    />*/}

            {/*)}*/}

            {/*{templateLoans && templateLoans.length >0 &&*/}
            {/*<ExcelExportColumn*/}
            {/*    width={100}*/}
            {/*    field="otherSettlements"*/}
            {/*    title="Other Settlements"*/}
            {/*    cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}*/}
            {/*    footer={getSum}*/}
            {/*    footerCellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}*/}
            {/*/>*/}
            {/*}*/}



            <ExcelExportColumn width={100} field="payrollDeductionTotalCash" title="Total Settlements" cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}} footer={getSum} footerCellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}/>



            <ExcelExportColumn width={100} field="cash" title="Net Pay" cellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}} footer={getSum} footerCellOptions={{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}}/>
        </ExcelExport>

    );
})

export default PayrollExcelExport;
