import moment from "moment";

export const countDaysBetween = function (startDate, endDate, skipDates) {
    const skipDatesLower = skipDates.map(s=>s.replace(/\s+/g, '').toLowerCase())
    const now = moment(startDate);
    let dates = 0
    // const mEndDate = moment(endDate)

    while (now.isSameOrBefore(endDate)) {
       if(!skipDatesLower.includes(now.format('ddd').toLowerCase()))
       {
           dates++

       }
        now.add(1, 'days')
    }

    return dates
}
