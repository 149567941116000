import {parse} from "query-string";
import {regex} from 'react-admin';

 const scopeEnum = ['Company','Position', 'Employee','Template']

const scopeResourcesEnum = ['companies','positions', 'employees','companies']

function getScope(props,resource) {
    const { scopeId: scopeId_string, scope } =parse(props.location.search);
    let scopeId = scopeId_string ? parseInt(scopeId_string, 10) : "";
    if(+scope===0||+scope===3) //company id is string
    {
        scopeId = scopeId_string;
    }
    const redirect = scopeId ? `/${scopeResourcesEnum[+scope]}/${scopeId}/show/${resource}` : "show";

    // console.log(scope)
    const scopeText = +scope===3?'Company':scopeEnum[+scope] || ''
    const scopeName = scopeEnum[+scope]
    return {redirect,scopeText,scopeId,scopeName,scope}
}


const passwordValidator = [regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.@$!%*?&#-])[A-Za-z\d@$!#%.*?&]{6,}$/, 'Password must be at least 8 characters and  have at least 1 capital 1 number and 1 special character')];

export {getScope,scopeResourcesEnum,scopeEnum, passwordValidator}
