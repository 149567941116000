import {call, put, takeLatest} from 'redux-saga/effects';
import {fetchEnd, fetchStart, refreshView, showNotification , unselectAll} from 'react-admin';
import OdataServiceProvider from "../Helpers/odataServiceProvider";

export default function* loanSaga() {
    yield takeLatest('DISBURSE_LOANS', function* (action) {
            const {payload} = action
        //console.log(payload)
            try {
                yield put(fetchStart());
              // yield all( payload.ids.map(id =>
              //     call(OdataServiceProvider.serverAction, 'loans', {
              //           action: 'disburse',
              //           id: id,
              //           data: {
              //               DisburesedAmount: payload.data &&  payload.data.find(d=>d.id ===id).amount,
              //               DisburesedDate: new Date()
              //           }
              //       })
              //   ));



                for (const i in  payload.ids) {

                    if (payload.ids.hasOwnProperty(i)) {
                        yield call(OdataServiceProvider.serverAction, 'loans', {
                            action: 'disburse',
                            id: payload.ids[i],
                            data: {
                                DisburesedAmount: payload.data && payload.data.find(d => d.id === payload.ids[i]).amount,
                                DisburesedDate: new Date()
                            }
                        })
                    }

                }

             // payload.ids.map(id =>
             //      yield call(OdataServiceProvider.serverAction, 'loans', {
             //            action: 'disburse',
             //            id: id,
             //            data: {
             //                DisburesedAmount: payload.data &&  payload.data.find(d=>d.id ===id).amount,
             //                DisburesedDate: new Date()
             //            }
             //        })
             //    );

                yield put(fetchEnd())
                yield put(showNotification('Loan Disbursed', "info"))
                yield put(refreshView())
                yield put({
                    type: 'LOAN_DISBURSED',
                    payload: {payload},
                });


            } catch (e) {
                yield put({
                    type: 'LOAN_DISBURSE_FAILlED'
                });

                const message = e.body.value;


                yield put(showNotification(`Loan Disburse Failed `, "error"))
                yield put(fetchEnd())
            }
        }
    );

    yield takeLatest('CANCEL_LOANS', function* (action) {
            const {payload} = action
            //console.log(payload)
            try {
                yield put(fetchStart());
                // yield all( payload.ids.map(id =>
                //     call(OdataServiceProvider.serverAction, 'loans', {
                //           action: 'disburse',
                //           id: id,
                //           data: {
                //               DisburesedAmount: payload.data &&  payload.data.find(d=>d.id ===id).amount,
                //               DisburesedDate: new Date()
                //           }
                //       })
                //   ));



                for (const i in  payload.ids) {

                    if (payload.ids.hasOwnProperty(i)) {
                        yield call(OdataServiceProvider.serverAction, 'loans', {
                            action: 'cancel',
                            id: payload.ids[i],
                          })
                    }

                }

                // payload.ids.map(id =>
                //      yield call(OdataServiceProvider.serverAction, 'loans', {
                //            action: 'disburse',
                //            id: id,
                //            data: {
                //                DisburesedAmount: payload.data &&  payload.data.find(d=>d.id ===id).amount,
                //                DisburesedDate: new Date()
                //            }
                //        })
                //    );

                yield put(fetchEnd())
                yield put(showNotification(`${payload.ids.length} Loan Canceled`, "info"))
                yield put(refreshView())
               // yield put(unselectAll("loans"))
                // yield put({
                //     type: 'LOAN_DISBURSED',
                //     payload: {payload},
                // });


            } catch (e) {
                // yield put({
                //     type: 'LOAN_DISBURSE_FAILlED'
                // });

                const message = e.body.value;


                yield put(showNotification(`Loan Cancel Failed `, "error"))
                yield put(fetchEnd())
            }
        }
    );
}
