import {fetchUtils} from 'react-admin';
import {stringify} from "query-string";
import moment from "moment";
const fetchJson = (url, options = {}) => {
    const api = process.env.REACT_APP_PUBLISH_API || 'https://payslips.hst-et.com/api/v1' //'http://localhost:40018/api'//
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    // let query = ''
    // if(options.query)
    // {
    //     query = "?" + stringify(options.query)
    // }
    // add your own headers here
     const token = localStorage.getItem('publishToken');
    options.headers.set('Authorization', `Bearer ${token}`);
    options.headers.set('x-company', localStorage.getItem('companyId'));
    return fetchUtils.fetchJson(api+url, options);
}


export function login({email,password}) {
    return fetchJson('/login', {
        method: 'POST',
        body: JSON.stringify({email,password}),
    }).then(_a => {
        let json =_a.json;
        localStorage.setItem('publishToken',json.token);
        return {data:json}
    })
}

export function postPayroll({payroll}) {
    return fetchJson('/payrolls', {
        method: 'POST',
        body: JSON.stringify(payroll),
    }).then(_a => {
        let json =_a.json;
        return {data:json}
    }).catch(e => {
        return {error:e,req:payroll}
    })
}

export function getPayroll(id,payrollId,email) {
    return fetchJson(`/payrolls/${id}`, {
        method: 'GET',
    }).then(_a => {
        let json =_a.json;
        return {...json[0],payrollId,email}
    }).catch(e => {
        return {error:e,req:id}
    })
}

export function updateCompany({id,name, slipSetting}) {
    return fetchJson(`/companies/${id}`, {
        method: 'PUT',
        body: JSON.stringify({slipSetting, name}),
    }).then(_a => {
        let json =_a.json;
        return {data:json}
    }).catch(e => {
        return {error:e,req:slipSetting}
    })
}

export function publishPayrolls({payrolls}) {
    return fetchJson('/payrolls/many', {
        method: 'POST',
        body: JSON.stringify(payrolls),
    }).then(_a => {
        let json =_a.json;
        return {data:json}
    })
}

export function getFrontEnd() {
    return  process.env.REACT_APP_PUBLISH_FRONT_END ||'http://myPayday.truwrk.com'
}
