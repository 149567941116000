import React, {useState} from 'react';
import {Button, useNotify, useRefresh, useUnselectAll, useUpdateMany} from "react-admin";
import Popover from "@material-ui/core/Popover";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import PayrollDeductionItem from "./payrollDeductionItem";
import CardActions from "@material-ui/core/CardActions";
import {useQueryWithStore} from "react-admin";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import ActiveIcon from "@material-ui/icons/CalendarToday";
import {DatePicker} from "@material-ui/pickers";
import moment from "moment";
const ChangeEndDatePopover = props => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [date, setDate] = useState(moment().endOf("month").toDate());
  const {selectedIds} = props;
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const [updateMany] = useUpdateMany(
        'employees',
        selectedIds,
        { contractEndDate:date },
        {
            onSuccess: () => {
                refresh();
                notify(` ${selectedIds.length} Employees End Date Changed`);
                unselectAll('employees');
            },
            onFailure: error => notify('Error: Some Employees end date cannot be changed', 'warning'),
        }
    );


    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMove= () => {
        updateMany()
        setAnchorEl(null);
    };
    // const { data: sites} = useQueryWithStore({
    //     type: 'getList',
    //     resource: 'sites',
    //     payload: {filter:{},pagination:{},sort:{}}
    // });

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <div>
            <Button label={props.label} aria-describedby={id} variant="text" color="primary" onClick={handleClick}>
                <ActiveIcon/>
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Card style={{minWidth:100}}>
                    <CardContent>
                        <FormControl margin='normal' >

                            <DatePicker
                                label="End Date"
                                margin="dense"
                                clearable

                                // minDate={new Date("2018-03-01")}
                                // maxDate={new Date("2018-06-01")}

                                value={date}
                                onChange={ setDate}
                            />
                        </FormControl>

                    </CardContent>
                    <CardActions>
                        <Button label='OK'  style={{ marginLeft: 'auto'}} onClick={handleMove} variant="text" color="primary">

                        </Button>
                    </CardActions>
                </Card>

            </Popover>
        </div>
    );
};

export default ChangeEndDatePopover;
