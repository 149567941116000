import React, {useEffect, useState} from 'react';
import {Tab, TabbedShowLayout, useQueryWithStore} from "react-admin";
import {connect, useDispatch} from "react-redux";
import {DatePicker} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import moment from 'moment'
import PayrollSummeryReport from "../summery/PayrollSummeryReport";
import PayrollSheetReport from "../payrolSheetReport";
import PayrollBankList from "../payrollBankList";
import PayslipList from "../payslipList";
import TaxDeclarationReport from "../incomeTaxDeclaration";
import PensionDeclarationReport from "../pensionDeclaration";
import PayrollJournal from "../journal/payrollJournal";
import PayrollLoan from "../payrollLoan/payrollLoan";
import CardContent from "@material-ui/core/CardContent";
import _ from "lodash";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Typography} from "../../../UIComponents/Wrappers";

const ConsolidatedPayrollReport = ({company,payrollDate, payrollRuns, payrollRun, ...props}) => {
    // const { loaded, error, data } = useQueryWithStore({
    //     type: 'getList',
    //     resource: 'payrollRuns',
    //     payload: {filter:{filter:`companyId eq a78d55a7-e4ac-4192-81ec-8e5095070315`},pagination:{},sort:{}}
    //
    //     // payload: { id: id, expand:`currencyRate,payrolls($orderby=fullName; $expand=payrollFixedEarnings ,payrollDeductions,payrollFixedDeductions,bonuses,employee($select=OrgID,Id,Name ,FatherName ,GFatherName,OutstandingLoan ,RemainingLeaveDays,BasicSalary ,BankAccountNumber,DefaultCurrency,Fullname, Gender ,address,TinNo,PensionNo,BankBranch ;$expand=leaves($filter=TypeId eq 1 and Year eq ${new Date().getFullYear()} ),position($select=name;$expand=department($select=name))))` }
    // })

   const dispatch = useDispatch();
    const {templateEarnings, templateLoans, templateDeductions, templateExpenses} = props;
    const [costCenter, setCostCenter] = useState(0);
    const [tinStatus, setTinStatus] = useState(0);
    const [filteredPayrolls, _setFilteredPayrolls] = useState();
    const onPayrollDateSelected = payrollDate => {
        dispatch({type:"LOAD_PAYROLL_RUNS", payload: {payrollDate,companyIds:company.childCompanies.map(c=>c.id)}})
    }

    useEffect(() => {

        if(!templateEarnings || templateEarnings.length ===0)
        {
            dispatch({type: 'LOAD_TEMPLATES_MULTIPLE',payload:{companyIds:company.childCompanies.map(c=>c.id)}})
        }


    }, [])


    useEffect(() => {
        console.log({payrollRun})
        if(payrollRun.payrolls && payrollRun.payrolls.length>0 ) {
            filterPayrolls({payrolls: payrollRun.payrolls})
            console.log(payrollRun)
        }


    }, [payrollRun])

    function refresh() {
        // console.log("forceUpdate")
        // forceUpdate()
        props.history.push("/");
        props.history.goBack();

    }

    function  setFilteredPayrolls(filteredPayrolls) {

        filteredPayrolls && _setFilteredPayrolls(filteredPayrolls.map((f, index) => {
                f.sn = index + 1;
                return f
            })
        )



    }
    const [sort,setSort] = useState({ field: 'fullName', order: 'DESC' });
    const handelSort = (field,data=filteredPayrolls)=> {
        // console.log(field)
        let order = 'ASC';
        if(field===sort.field)
        {
            order = sort.order==='DESC'?'ASC':'DESC';
            // setSort({ field, order: sort.order==='DESC'?'ASC':'DESC' })
        }

        setSort({ field, order})


        setFilteredPayrolls(_.orderBy(data, [item => isNaN(item[field])?item[field].toString().toLowerCase():item[field]], [order.toLowerCase()]));

    }

    function filterPayrolls({payrollRun1=payrollRun,value,field,journalEntries}) {

        const toBeFiltered =  payrollRun1.payrolls
        const checkTin = (v,p)=>(v==='Without Tin'?(!p.tinNo || p.tinNo.length === 0):(p.tinNo && p.tinNo.length))

        const fPayrolls = toBeFiltered.filter(p=>

            // (field==='site'?!value||p.siteName===value:!site||p.siteName===site)&&
            // (field==='employmentType'?!value||p.employmentType===value:!employmentType||p.employmentType===employmentType)&&
            // (field==='department'?!value||p.departmentName===value:!department||p.departmentName===department)&&
            // (field==='payrollStatus'?!value||p.status===value:!payrollStatus||p.status===payrollStatus)&&
            // (field==='costCenter'?!value||value<0||p.costCenterCode===value:!costCenter||p.costCenterCode===costCenter)&&
            (field==='tinStatus'?!value||checkTin(value,p):!tinStatus||checkTin(tinStatus,p))
            // (field==='employeeIds'?!value||!value.length||value.some(v=>v.value===p.employeeId):!selectedEmployees||!selectedEmployees.length||selectedEmployees.some(v=>v.value===p.employeeId))
        )
        // console.log({fPayrolls})
        setFilteredPayrolls(
            fPayrolls
        )
        // recalculate(payrollRun1.journalEntries&&payrollRun1.journalEntries.length?payrollRun1.journalEntries:journalEntries,payrollRun1,fPayrolls,value===-1)


        return fPayrolls

    }


    return (
        <div>
            {/*{payrollRuns.length}*/}
            <DatePicker
                views={["year", "month"]}
                label="Payroll Month"
                margin="dense"
                // minDate={new Date("2018-03-01")}
                // maxDate={new Date("2018-06-01")}

                value={payrollDate} onChange={onPayrollDateSelected} />
            {/*{payrollDate.toString()}*/}
            <Select
                value={tinStatus}

                onChange={ev=>{
                    setTinStatus(ev.target.value)
                    filterPayrolls({value:ev.target.value,field:'tinStatus'})
                    // setFilteredPayrolls(payrollRun.payrolls.filter(p=>(!employmentType||p.employmentType===employmentType)&&(!department||p.departmentName===department)&&(!site||p.siteName===site)&&(!ev.target.value||p.status===ev.target.value)))
                    // payrollRun.filteredPayrolls=filteredPayrolls
                    //   props.onChange({type:ev.target.value,hrs})
                }}
            >
                <MenuItem key={0} value={0}>With or Without Tin</MenuItem>
                {['With Tin', 'Without Tin'].map(ot=><MenuItem key={ot} value={ot}>{ot}</MenuItem>)}
            </Select>
            {payrollRun.payrolls && payrollRun.payrolls.length>0?<Grid container>
                <Grid item>
                    <TabbedShowLayout>
                        {/*<Tab label="Payroll Summery" path="summery">*/}
                        {/*    <PayrollSummeryReport  sort={sort} handelSort={handelSort}  emps={emps} logo={company&&company.logo} companyName={props.companyName} payrollRun={payrollRun}  site={site} employmentType={employmentType} department={department}  filteredPayrolls={filteredPayrolls} />*/}
                        {/*</Tab>*/}
                        {/*<Tab label="Payroll Sheet" path="sheet">*/}
                        {/*    <PayrollSheetReport onSeparateByCostCenter={onSeparateByCostCenter} calendar={props.calendar} logo={company&&company.logo} companyName={props.companyName} templates={{templateEarnings, templateLoans, templateDeductions, templateExpenses}} sort={sort} handelSort={handelSort} site={site} employmentType={employmentType} department={department}  filteredPayrolls={filteredPayrolls} payrollRun={payrollRun}/>*/}
                        {/*</Tab>*/}
                        {/*<Tab label="Bank Transfer List" path="bank">*/}
                        {/*    <PayrollBankList   logo={company&&company.logo} sort={sort} handelSort={handelSort}  filteredPayrolls={filteredPayrolls} payrollRun={payrollRun} costCenter={costCenter}/>*/}
                        {/*</Tab>*/}
                        {/*<Tab label="Payslip" path="slips">*/}
                        {/*    <PayslipList  logo={company&&company.logo} refresh={refresh} filteredPayrolls={filteredPayrolls} payrollRun={payrollRun} costCenter={costCenter}/>*/}
                        {/*</Tab>*/}
                        <Tab label="IncomeTax Declaration" path="tax">
                            <TaxDeclarationReport groupBy='companyName' tinStatus={tinStatus} company={company || {address: {}}}
                                                  filteredPayrolls={filteredPayrolls} payrollRun={payrollRun}
                                                  costCenter={costCenter}/>
                        </Tab>
                        <Tab label="Pension Declaration" path="pension">
                            <PensionDeclarationReport  groupBy='companyName'  tinStatus={tinStatus} company={company || {address: {}}}
                                                      filteredPayrolls={filteredPayrolls} payrollRun={payrollRun}
                                                      costCenter={costCenter}/>
                        </Tab>
                        {/*<Tab label="Payroll Journal" path="journal">*/}
                        {/*    <PayrollJournal logo={company&&company.logo} showHeader setPayrollJournal={x=>recalculate(x,payrollRun,filteredPayrolls)} settingId={settingId} company={company||{address:{}}}  filteredPayrolls={filteredPayrolls} payrollRun={payrollRun} templates={{templateEarnings, templateLoans, templateDeductions, templateExpenses}} />*/}
                        {/*    /!*<PayrollJournal  settingId={settingId} company={company||{address:{}}}  filteredPayrolls={filteredPayrolls.filter(p=>p.costCenterCode==='SALES')} payrollRun={payrollRun} templates={{templateEarnings, templateLoans, templateDeductions, templateExpenses}} />*!/*/}

                        {/*</Tab>*/}
                        {/*<Tab label="Payroll Loans" path="loans">*/}
                        {/*    <PayrollLoan  logo={company&&company.logo}  sort={sort} handelSort={handelSort}  settingId={settingId} company={company||{address:{}}}  filteredPayrolls={filteredPayrolls} payrollRun={payrollRun} templates={{templateEarnings, templateLoans, templateDeductions, templateExpenses}} />*/}
                        {/*</Tab>*/}
                    </TabbedShowLayout>
                </Grid>
            </Grid>:<div>
                <Typography style={{padding:10}} variant='h3'>Please Select a Payroll Month</Typography>
            </div>}
        </div>
    );
};

export default connect(state=>({
    company:state.user.currentCompany,
    payrollDate: state.consolidatedPayroll.payrollDate,
    payrollRuns:  state.consolidatedPayroll.payrollRuns,
    payrollRun: state.consolidatedPayroll.payrollRun,
    templateEarnings: state.payrollStatus.templateEarnings,templateDeductions: state.payrollStatus.templateDeductions,templateExpenses: state.payrollStatus.templateExpenses,templateLoans: state.payrollStatus.templateLoans

}))(ConsolidatedPayrollReport);
