import React from 'react';
import {Error, Loading, Title, useQueryWithStore} from 'react-admin';
import moment from 'moment'
import _ from 'lodash'
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import {Grid} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PayrollRunSummary from "../payroll/payrollRunSummery";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}
const ListPayrolls = () => {
    // const dataProvider = useDataProvider();
    // const [payrollRuns, setPayrollRuns] = useState();
    // const [loading, setLoading] = useState(true);
    // const [error, setError] = useState();
    // useEffect(() => {
    //     dataProvider.getList('payrollRuns',{filter:{},pagination:{},sort:{}})
    //         .then(({ data }) => {
    //             setPayrollRuns(data);
    //             setLoading(false);
    //         })
    //         .catch(error => {
    //             setError(error);
    //             setLoading(false);
    //         })
    // }, []);
    //
    // if (loading) return <Loading />;
    // if (error) return <Error />;
    // if (!payrollRuns) return null;

    const { loaded, error, data: payrollRuns } = useQueryWithStore({
        type: 'getList',
        resource: 'payrollRuns',
        payload: {filter:{},pagination:{},sort:{}}
    });
    if (!loaded) { return <Loading />; }
    if (error) { return <Error />; }
    if (!payrollRuns) return null;




    const sorted = _.orderBy(payrollRuns,['payrollDate'],['desc']).map(p=>{
       p.payYearMonth =moment(p.payrollDate).format('MMMM, YYYY ')
       p.year =moment(p.payrollDate).format('YYYY ')
       p.month =moment(p.payrollDate).format('MMMM')
       return p
   })


   const x= _.chain(sorted)
       .groupBy("year")
       .mapValues(values => _.chain(values)
           .groupBy('month')
           .value()
       ).value()

    const lastRun = sorted.filter(r=>r.status==='Approved')[0]


    //console.log(x)

    return (
        <Card>
            <Title title="Payroll Runs"/>
            {/*{payrollRuns.map(payrollRun=><div>*/}
                {/*{payrollRun.payMonth}*/}

            {/*</div>)}*/}
            <PayrollRunSummary payrollRun={lastRun}/>

            {
                Object.entries(x).map(([year,months])=>  <ExpansionPanel>
                    <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>
                   {year}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={3}>
                    {
                        Object.entries(months).map(([month,runs])=><Grid item>

                             <PayrollRunSummary payrollRun={runs.find(r=>r.status==='Approved')}>
                                 Others
                                 <List component="nav" aria-label="secondary mailbox folders">
                                 {runs.map(r=>
                                     <ListItemLink href={`#/reports/payroll/${r.id}/sheet`}>
                                         <ListItemText primary={moment(r.runDate).format('DD MMMM YYYY')} secondary={r.status} />
                                     </ListItemLink>
                                 )}

                                      {/*<Link to={`/reports/payroll/${r.id}/sheet`}>{moment(r.runDate).format('DD MMMM YYYY')}</Link>)}*/}
                                 </List>

                             </PayrollRunSummary>


                        </Grid>)
                    }
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>)
            }
        </Card>
    );
};

export default ListPayrolls;
