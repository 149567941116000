const b64toBiteArray = (b64Data,  sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

   return byteArrays
}

const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file.rawFile);
    });

function getImageDimensions(file) {

    return new Promise (function (resolved, rejected) {
        var i = new Image()
        i.onload = function(){
           // console.log(i)
            resolved({w: i.naturalWidth, h: i.naturalHeight})
        };
        i.src = file
    })
}
export  {b64toBiteArray, convertFileToBase64, getImageDimensions}
