import React from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Divider from "@material-ui/core/Divider";
import ListValues from "../../UIComponents/listValues";
import MultiCurrency from "../multiCurrency";
import CheckIcon from "@material-ui/icons/Check"
import ErrorIcon from "@material-ui/icons/Error"
import {listOvertimes} from "./common";
import MultiDate from "../../UIComponents/multiDate";
import {getFrontEnd} from "../../Helpers/publishApi";
import Loader from "react-loader-spinner";

const Payslip = props => {
    const {payroll,payrollDate,companyName,payslipSettings,logo,showFooter,payrollRun,columns,loanTemplates} = props
  //console.log(payslipSettings)

        payroll.earnings = [{name:`Basic Salary ${payroll.employmentType === "Casual"?`(${payroll.wadge} * ${payroll.daysWorked} days)`:`${payroll.basicSalary!==payroll.fullBasicSalary?` (of ${payroll.fullBasicSalary.toFixed(2)})`:''}`}`,value: payroll.basicSalary},{name:`Overtime ${listOvertimes(payroll.overtimes)}`,value: payroll.overtimeTotal}].concat(payroll.bonuses).concat(payroll.payrollFixedEarnings)
    payroll.deductions =  [{name:'Income Tax',value: payroll.incomeTax}].concat(payroll.payrollFixedDeductions).concat( payroll.payrollDeductions.filter(d=>!d.cash)).concat([{name:'Absence Deduction(Basic)',value: payroll.basicAbsentDeduction},{name:'Absence/Leave Deduction From Additional Earnings',value: payroll.additionalEarningsAbsentDeduction}])
   payroll.expenses = payroll.payrollFixedExpenses;
    payroll.cashDeductions = payroll.payrollDeductions.filter(d=>d.cash)
// console.log(payroll)

  function getTitle(field,alternative) {
        return (columns[field]&&columns[field].title)||alternative
  }

    return (
        <Card>

            <CardContent>
                <Grid container>
                    <Grid item xs={8} container direction="column"
                          justify="center"
                          alignItems="center">

                            <Typography gutterBottom variant="h5" component="h6">
                            {companyName}
                        </Typography>


                        {payroll.publishId?<a href={`${getFrontEnd()}/payslips/${payroll.publishId}`} target="_blank"
                            rel="noopener noreferrer">

                            <Typography gutterBottom variant="subtitle1">
                                Payslip for  <strong><MultiDate gcDate={payrollDate} format={'MMMM YYYY'}/></strong>
                            </Typography>
                        </a>:<Typography gutterBottom variant="subtitle1">
                             Payslip for <strong><MultiDate gcDate={payrollDate} format={'MMMM YYYY'}/></strong>
                        </Typography>}



                    </Grid>
                    <Grid item xs={4}
                          justify="start"
                          alignItems="center">
                        {logo&&<img alt='company_logo' height={50} src={logo}/>}
                    </Grid>
                    <Grid item container xs={12} >
                        <Grid item xs={4}>
                            <Typography variant="body1">
                                <strong> {payroll.fullName} </strong>  <br/>
                                {payslipSettings.orgID!==false&&<> ID: {payroll.orgID||payroll.employee.orgID} </>}


                            </Typography>

                            <Typography variant="body1">
                                {payslipSettings.daysWorked&&<> Days Worked {payroll.daysWorked} <br/></>}
                                {((payslipSettings.hoursWorked)&&(payroll.hoursWorked!==payroll.monthlyHours))&&<> Payable Hours {payroll.hoursWorked.toFixed(1)} of {payroll.monthlyHours} <br/></>}
                                {payslipSettings.absentDays&&<>  Absent Days {payroll.absentDays}<br/></>}
                                {payslipSettings.monthLeaveDays&&<>  Leave Days {payroll.leaveDays}</>}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body1">
                                {payslipSettings.tinNo&&<> {getTitle('tinNo','TIN No')}: {payroll.tinNo ||(payroll.employee&&payroll.employee.tinNo)}  <br/></>}
                                {payslipSettings.pensionNo&&<>{getTitle('pensionNo','Pension No')} : {payroll.pensionNo || (payroll.employee&&payroll.employee.pensionNo)}  <br/></>}
                                {payslipSettings.employmentType&&<>{getTitle('employmentType','Employment Type')} :{payroll.employmentType}  <br/></>}
                                {payslipSettings.department&&<>{getTitle('departmentName','Department')}: {payroll.departmentName}  <br/></>}
                                    {payslipSettings.position&&<>{getTitle('positionName','Position')}: {payroll.positionName} <br/></>}
                                {payslipSettings.site&&<>{getTitle('siteName','Current Site')}: {payroll.siteName} <br/></>}
                                        {payslipSettings.email&&<>{getTitle('email','Email')}: {(payroll.employee&&payroll.employee.address) ? payroll.employee.address.email: payroll.emailAddress || "-"} {payroll.emailSent&&<CheckIcon/> } {payroll.emailSent&&payroll.sentSlipType }
                                            {
                                                payroll.sentSlipType==='Failed' && <ErrorIcon/>
                                            }
                                        {payroll.sentSlipType==='Processing'&& <Loader
                                            color="blue"
                                            height={20}
                                            width={20}
                                        />}<br/></>}
                                {payroll.publishId?payslipSettings.publishLink&&<>link: <a href={`${getFrontEnd()}/payslips/${payroll.publishId}`} target="_blank" rel="noopener noreferrer" >{getFrontEnd()}/payslips/{payroll.publishId}</a> ({payroll.sentSlipType}) <br/></>:''}
                            </Typography>
                        </Grid>

                        <Grid item xs={4}>


                            <Typography variant="body1">
                                {payslipSettings.startDate&&<> {getTitle('startDate','Employment Date')} :  {payroll.startDate} <br/></>}
                                {payslipSettings.dateOfBirth&&<> {getTitle('dateOfBirth','Date Of Birth')}:  {payroll.dateOfBirth} <br/></>}
                                {payslipSettings.gender&&<> {getTitle('gender','Gender')} :  {payroll.gender} <br/></>}
                                {payslipSettings.citizenship&&<>  {getTitle('citizenship','Citizenship')} {payroll.citizenship}<br/> <br/></>}
                                {payslipSettings.bank&&<>  {getTitle('bank','Bank')}: {payroll.bank} <br/></>}
                                {payslipSettings.swiftCode&&<> {getTitle('swiftCode','Bank Swift Code')}: {payroll.swiftCode} <br/></>}
                                {payslipSettings.bankAccountNumber&&<> {getTitle('startDate','Bank Account Number')}: {payroll.bankAccountNumber} <br/></>}

                            </Typography>
                        </Grid>
                        {payroll.publishId?payslipSettings.publishLink&&<Grid item xs={1}>
                            <a href={`${getFrontEnd()}/payslips/${payroll.publishId}`} target="_blank" rel="noopener noreferrer"><img
                                src={`https://api.qrserver.com/v1/create-qr-code/?data=${encodeURI(`${getFrontEnd()}/payslips/${payroll.publishId}`)}&size=100x100`}
                                alt={`${getFrontEnd()}/payslips/${payroll.publishId}`} title="Publish QR" width={100} height={100}/></a>
                        </Grid>:''}
                    </Grid>

                        <Grid xs={12}>
                            <Divider/>
                        </Grid>
                    <Grid item container xs={12} >
                        <Grid item xs={6}>
                         Earnings
                            <ListValues hideTotal title="Earnings" items={payroll.earnings} total={payroll.totalIncome}/>


                        </Grid>
                        <Grid item xs={6}>
                            Deductions

                            <ListValues hideTotal  title="Deductions" items={payroll.deductions} total={payroll.totalDeduction}/>

                            {payroll.cashDeductions &&payroll.cashDeductions.length>0 &&
                            <div>
                                Settlement
                                <ListValues title="Settlements"  type="loan" items={payroll.cashDeductions} total={payroll.payrollDeductionTotalCash}/>
                            </div>

                            }



                        </Grid>
                        <Grid item container xs={12} >
                        <Grid container item xs={6} justify="space-between" style={{paddingLeft:20,paddingRight:20}}>
                            <Grid item xs>
                                Total Earnings
                            </Grid>

                               <Grid item>
                                <MultiCurrency
                                    value= {payroll.totalIncome}

                                />
                               </Grid>



                        </Grid>
                        <Grid container item xs={6} justify="space-between" style={{paddingLeft:20,paddingRight:20}} >
                            <Grid item xs>
                                Total Deductions
                            </Grid>
                            <Grid item>
                                <MultiCurrency
                                    value= {payroll.totalDeduction}

                                />

                            </Grid>

                        </Grid>
                        </Grid>
                            <Grid xs={12} style={{marginBottom:30}}>
                            <Divider/>
                        </Grid>
                        {payslipSettings.expenses&&<>
                            <Grid container xs={6}>
                                Company Expenses
                                <ListValues title="Company Expenses" items={payroll.expenses}
                                            total={payroll.expenses.reduce((sum, cv) => sum + cv.value, 0)}/>

                            </Grid>
                            <Grid container xs={6}>

                            </Grid>
                            <Grid xs={12} style={{marginBottom: 30}}>
                                <Divider/>
                            </Grid>
                        </>}
                        <Grid item xs={6}>
                            <Grid container direction="column" style={{paddingLeft:40, paddingRight:10}} >
                                {payslipSettings.OutstandingLoan&&<Grid item container justify="space-between">
                                   <Grid item>
                                       <strong>Outstanding Loan/Advance: </strong>
                                   </Grid>
                                    <Grid item>
                            <MultiCurrency
                            value= {Math.max(payroll.cashDeductions.filter(d=>{
                                // const template= loanTemplates.find(l=>l.id===d.templateId)||{}
                                // console.log({template})
                                return d.templateType==='Loan'||d.templateType==='Advance'
                            }).reduce((sum,cv)=>sum+cv.remaining||0,0),0)}
                        />
                                    </Grid>
                                </Grid>}
                                {payslipSettings.OutstandingLoan&&<Grid item container justify="space-between">
                                    <Grid item>
                                        <strong>Other Settlements </strong>
                                    </Grid>
                                    <Grid item>
                                        <MultiCurrency
                                            value= {Math.max(payroll.cashDeductions.filter(d=>{
                                                // const template= loanTemplates.find(l=>l.id===d.templateId)||{}
                                                // console.log({template})
                                                return d.templateType!=='Loan'&&d.templateType!=='Advance'
                                            }).reduce((sum,cv)=>sum+cv.remaining||0,0),0)}
                                        />
                                    </Grid>
                                </Grid>}
                                {payslipSettings.annualLeave&&<Grid item container justify="space-between">
                                    <Grid item>
                                    <strong>Annual Leave:   </strong>
                                    </Grid>
                                    <Grid item>

                                    {payroll.leaveDaysEntitled>0?payroll.leaveDaysTaken + '/' + payroll.leaveDaysEntitled + 'Days':''}

                                    </Grid>
                                </Grid>}

                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container direction="column" style={{paddingLeft:40, paddingRight:10}}  >
                                {payslipSettings.net&&<Grid container justify="space-between">
                                        <Grid item >
                                        <strong>Net</strong>
                                    </Grid>
                                    <Grid item  >
                                        <strong><MultiCurrency
                                            value= {payroll.netPay}

                                        /></strong>

                                    </Grid>
                                 </Grid>}
                                {payslipSettings.LoanSettlement&& <Grid container justify="space-between">
                                    <Grid item >
                                        <strong>Settlement</strong>
                                    </Grid>
                                    <Grid  item  >
                                        <strong><MultiCurrency
                                            value= {payroll.payrollDeductionTotalCash}

                                        /></strong>

                                    </Grid>
                                </Grid>}
                                {payslipSettings.netPay&& <Grid container justify="space-between">
                                    <Grid item >
                                        <strong>Net Pay</strong>
                                    </Grid>
                                    <Grid  item  >
                                        <strong><MultiCurrency
                                            value= {payroll.cash}

                                        /></strong>

                                    </Grid>
                                </Grid>}
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

                {!!showFooter&&<div style={{marginTop: 50}}>
                    <Grid container justify="space-between">
                        <Grid item>
                            Prepared by: ___________
                            {/*Prepared by:_{payrollRun.createdBy?payrollRun.createdBy:'___'}___*/}
                        </Grid>
                        <Grid item>
                            Approved By: ____________
                            {/*Approved By __{payrollRun.approvedBy?payrollRun.approvedBy:''}_______________*/}
                        </Grid>
                        <Grid item>
                            Sign. {payroll.fullName} _________________
                        </Grid>
                    </Grid>
                </div>}
            </CardContent>

        </Card>
    );
};

Payslip.propTypes = {
    payroll:Object,

};

export default Payslip;
