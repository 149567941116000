// in src/posts.js
import React from 'react';
import {
    ArrayInput,
    AutocompleteInput, Create,
    Datagrid, Edit,
    EditButton, FormDataConsumer, FormTab,
    List,
    ReferenceField,
    ReferenceInput, required, SelectInput,
    Show, SimpleForm, SimpleFormIterator,
    SimpleShowLayout, TabbedForm,
    TextField, useDataProvider, DateField,
    NumberInput, CheckboxGroupInput, useQueryWithStore, NumberField,
    BooleanInput, TextInput, minLength, maxLength
} from 'react-admin';

import createDecorator from 'final-form-calculate'


import exporterCommon from "../import/exporterCommon";
import {FormSpy, useForm, useFormState} from "react-final-form";
import {parse} from "query-string";
import {useStore} from "react-redux";
import {DateInput} from "../../UIComponents/materialDateInput";
import Grid from "@material-ui/core/Grid";
import Moment from "moment";
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
export const EmployeeExpenseList = props => (
    <List  exporter={exporterCommon(props.resource)} {...props}>
        <Datagrid >
            {/*<TextField source="id" />*/}
            <ReferenceField source="employeeId" reference="employees">
                <TextField source="name" />
            </ReferenceField>
            <DateField source="disbursedDate" />
            <TextField source="type" />
            <TextField source="remark" />
            <DateField source="startDate" />
            <TextField source="totalDays" />
            <NumberField source='disbursedAmount' />

            <EditButton />
        </Datagrid>
    </List>
);


// const decorator= createDecorator(
//     {
//         field: 'expenseItems', // when minimum changes...
//         updates: {
//             // ...update maximum to the result of this function
//             disbursedAmount: (minimumValue, allValues) =>
//                122
//         }
//     },
// );

const EmployeeExpenseForm = ({create, formData, perdiemRates, employeeId, setNewData, ...rest  }) => {
    //setNewData(formData)
    const form = useForm();
    // const undecorate = decorator(form)
    const store = useStore();
    // console.log(formData)
    const dataProvider = useDataProvider();
    function employeeSelected(ev) {
       const employee = store.getState("admin").admin.resources['employees']['data'][ev];
       form.change('siteId', employee.siteId);
        // form.change('disbursedAmount',2232)
        if( employee.positionId) {
            dataProvider.getOne('positions', {id: employee.positionId})
                .then(({data}) => {
                    form.change('jobLevelId', data.jobLevelId);
                })
        }
      // console.log(employee)
    }

   const generatePerdiems = () =>{
     const startDate = moment(form.getFieldState('startDate').value);
       const endDate = moment(form.getFieldState('endDate').value);
       //     console.log("startdd",startDate,"   end",endDate)
       const range = moment.range(startDate, endDate);
       const array =  Array.from(range.by('days'));
       const totalDays= range.diff('days')+1;
       if(totalDays>0)
       {
           form.change('totalDays',totalDays)
           const siteId = form.getFieldState('siteId').value
           const jobLevelId = form.getFieldState('jobLevelId').value
           const applicablePerdiems = perdiemRates.filter(p=>(!siteId || p.siteId===siteId)&&(!jobLevelId|| p.jobLevelId===jobLevelId))
           const details = applicablePerdiems.map(p=>`'${JSON.stringify({type:p.type,rate:p.rate})}'`)
           const amount = applicablePerdiems.reduce((sum,cv)=>sum+cv.rate,0)
          const items = array.map(d=>({type:form.getFieldState('type').value,details,amount,date:d.toISOString()}))
           form.change('employeeExpenseItems',items)
       }


    }
    // const { values } = useFormState({ onChange:e=>console.log(e),   subscription:{ values: true }})

  const calculateTotal = items => {
        return items &&  items.reduce((sum,cv)=>cv && sum+cv.amount,0)
  }
  // form.change('disbursedAmount',calculateTotal(formData.expenseItems));
  // console.log(calculateTotal(formData.expenseItems))

       // const totalAmount =
        // console.log("chainging",totalAmount)
        // if(!values.disbursedAmount)
        // {
        //     form.change('disbursedAmount',formData.expenseItems && calculateTotal(formData.expenseItems))
        //}


        // formData.disbursedAmount = totalAmount;


    // console.log("render")

    return  <FormTab {...rest}   label="Basic Info">
        <FormSpy
            subscription={{ values: true  }}
            onChange={({values})=> {
             //   formData.disbursedAmount = 212
               // console.log(values,formData)
                if(values.employeeExpenseItems) {


                    const totalAmount = values.employeeExpenseItems.reduce((sum,cv)=>cv && sum+cv.amount,0)
                //   console.log("chainging",totalAmount)
                    if(!values.disbursedAmount || (values.disbursedAmount === formData.disbursedAmount))
                    {
                       // console.log(totalAmount)
                        form.change('disbursedAmount',totalAmount ||0)
                    }


                   // formData.disbursedAmount = totalAmount;
                }
             //  setNewData(props.values)
            }}
        />

        <Grid container style={{width:"100%"}}  >
        <ReferenceInput onChange={employeeSelected} {...rest} perPage={1000} disabled={employeeId}   label="Employee" source="employeeId" reference="employees" validate={[required()]}>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput {...rest}     label="Site" source="siteId" reference="sites">
            <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput {...rest}     label="JobLevel" source="jobLevelId" reference="jobLevels" >
            <SelectInput optionText="name" />
        </ReferenceInput>
        </Grid>

        <SelectInput defaultValue={"Perdiem"}    {...rest}  label="Type" source="type"  choices={[
            { id: 'Perdiem', name: 'Perdiem' },
            { id: 'Reimbursement', name: 'Reimbursement' }]}  />

        <TextInput label="Purpose/Remark" multiline resettable source="remark"/>

        <Grid container style={{width:"100%"}}  >
            {formData.type==='Perdiem'&&
            <>
                     <DateInput
                        onChange={generatePerdiems} source="startDate"
                        label="Start date" options={{format: 'DD/MM/YYYY',   inputVariant: 'filled'}}/>
                <DateInput  onChange={generatePerdiems} source="endDate" label="End date" options={{ format: 'DD/MM/YYYY', inputVariant:'filled' }}   />
              <NumberInput source='totalDays'  />
            </>
            }

        </Grid>

        <Grid container style={{width:"100%"}}  >
        <NumberInput  readOnly source='disbursedAmount' />
        <BooleanInput  source='isDisbursed' />
        <DateInput   source="disbursedDate" label="Date Disbursed" options={{ format: 'DD/MM/YYYY', inputVariant:'filled' }}   />
        </Grid>




    </FormTab>
}
function getDetailChoices(change,perdiemRates) {
    // console.log(change)
    // console.log('called')
    const applicablePerdiems = perdiemRates.filter(p=>(!change.siteId || p.siteId===change.siteId)&&(!change.jobLevelId|| p.jobLevelId===change.jobLevelId))

    return applicablePerdiems.map(p=>({id: `'${JSON.stringify({type:p.type,rate:p.rate})}'`,name:`${p.type} : ${p.rate}`}))
}



const EmployeeExpenseDetailForm = ({formData,scopedFormData, getSource, perdiemRates, ...rest }) => {
    const form = useForm();

    const detailChanged= (d) => {
        //c//onst detail = JSON.parse(d)
        //console.log(d)
       const vals = form.getFieldState(getSource('details')).value
       const details = vals.map(v=> {
           return JSON.parse(v.match(/'([^']+)'/)[1])
       })
       const total = details.reduce((sum,cv)=>sum+cv.rate,0)
        form.change(getSource('amount'),total);
    }


    return  <Grid container style={{width:"100%"}} >

        <Grid item md={4}>
                <DateInput   source={getSource('date')} label="Date" options={{ format: 'ddd MMM, DD', inputVariant:'filled' }}   />
        </Grid>
                {/*<ReferenceInput label="Details" source="details" reference="perdiemRates">*/}
                {/*    <CheckboxGroupInput optionValue="id" optionText={choice => `${choice.type} : ${choice.rate}`} />*/}
                {/*</ReferenceInput>*/}

        <Grid item md={5}>
                <CheckboxGroupInput label='Details' onChange={detailChanged} source={getSource('details')} choices={getDetailChoices(formData,perdiemRates)}/>
        </Grid>
        <Grid item md={3}>
                <NumberInput label="Amount" source={getSource('amount')} />
        </Grid>
    </Grid>
}

export const EmployeeExpenseShow = props => (
    <Show  {...props}>
        <SimpleShowLayout>
            <ReferenceField source="employeeId" reference="employees">
                <TextField source="name" />
            </ReferenceField>
        </SimpleShowLayout>
    </Show>
);


export const EmployeeExpenseEdit = props => {
    const { employeeId } =parse(props.location.search);
    const redirect = employeeId ? `/employees/${employeeId}/show/loans` : "list";

    return <Edit {...props}>
        <SimpleForm redirect={redirect} >
            <FormDataConsumer>
                {formDataProps => (
                    <EmployeeExpenseForm employeeId={employeeId}  {...formDataProps}  />
                )}
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
};


export const EmployeeExpenseCreate = props => {
    // const [newData, setNewData] = useState({});
    // const increment = useCallback(() => {
    //     setNewData(count + 1)
    // }, [count])


    const { loaded, error, data: perdiemRates} = useQueryWithStore({
        type: 'getList',
        resource: 'perdiemRates'
    });
    if(!loaded) return null

    const { employeeId } =parse(props.location.search);
    const redirect = employeeId ? `/employees/${employeeId}/show/loans` : "list";
    return <Create {...props}  >

        <TabbedForm  redirect={redirect} initialValues={{employeeId:+employeeId}}  >
            <FormDataConsumer>
                {formDataProps => {
                    // console.log(formDataProps)
                    //  formDataProps.formData.estimateMonths = 3
                    return <EmployeeExpenseForm  perdiemRates={perdiemRates} employeeId={employeeId} create {...formDataProps}  />
                }}
            </FormDataConsumer>


            <FormTab   label="Details">
                <ArrayInput label='Items'  source="employeeExpenseItems">
                    <SimpleFormIterator    >
                        <FormDataConsumer>
                            {formDataProps => (
                                <EmployeeExpenseDetailForm {...formDataProps} perdiemRates={perdiemRates}  />
                            )}
                        </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>

            {/*<FormDataConsumer>*/}
            {/*    {({formData, dispatch, ...rest}) => {*/}
            {/*        return <FormTab>*/}
            {/*            <NumberInput source='disburessedAmount'/>*/}
            {/*        </FormTab>*/}
            {/*        // if(formData){*/}
            {/*       //     setNewData(formData)*/}
            {/*       // }*/}

            {/*    }}*/}
            {/*</FormDataConsumer>*/}

            {/*<FormDataConsumer>*/}
            {/*    {({ formData }) => formData &&*/}
            {/*        <>*/}
            {/*           */}
            {/*            {setNewData(formData)}*/}
            {/*        </>*/}
            {/*    }*/}
            {/*</FormDataConsumer>*/}



            {/*<FormDataConsumer>*/}
            {/*    {formDataProps => {*/}
            {/*        // console.log(formDataProps)*/}
            {/*        //  formDataProps.formData.estimateMonths = 3*/}
            {/*        return <EmployeeExpenseDetailForm {...formDataProps}  />*/}
            {/*    }}*/}
            {/*</FormDataConsumer>*/}
        </TabbedForm>

    </Create>
};
