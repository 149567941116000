const initialState = {
     newEmps: [],
     idleEmps: [],
     inactiveEmps: [],
     updatedEmps: [],
     timesheet:[],
     groupedTimesheet:[],
     loading: false,
     saving: false,
     done: false,
     atDate: null,
     updateSites: true,
     allSelected: true,

};

export default (previousState = initialState, { type, payload }) => {
    //  console.log(pa)
    if (type === 'SET_MATES_LOADING') {
        return {...previousState, done:!payload,  loading: payload, error: !payload};
    }
    if (type === 'SET_MATES_SAVING') {
        return {...previousState, done:!payload, saving: payload, error: !payload};
    }
    if (type === 'SET_MATES_ERROR') {
        return {...previousState, done:!payload, error: payload, loading: !payload};
    }
    if (type === 'SET_MATES_DONE') {
        return {...previousState, saving:!payload, done: payload};
    }
    if (type === 'SET_MATES_ATDATE') {
        return {...previousState, atDate: payload};
    }
    if (type === 'SET_MATES_EMPS_SAVED') {
       // const ids =
        const newEmps = previousState.newEmps.map(e=>({...e,payrollStatus:payload.appliedIds.includes(e.id)?'Applied':'Pending'}))
        return {...previousState, newEmps};
    }
    if (type === 'SET_MATES_EMPS') {
        // console.log(payload)
        const {newEmps,inactiveEmps, updatedEmps} = payload
        return {...previousState, newEmps: newEmps.map(p=>({...p,source:'mates'})), inactiveEmps:inactiveEmps.map(p=>({...p,source:'mates'})), updatedEmps:updatedEmps.map(p=>({...p,source:'mates'}))};
    }

    if (type === 'SET_MATES_UPDATE_SITES') {
        return {...previousState, updateSites: payload};
    }

    if(type==='TIMESHEET_GROUP_SELECTED_CHANGED') {
        const {group, selected} = payload
        //console.log({group,selected})

        const timesheet = previousState.timesheet.map(e=>{
            if(e.team.toLowerCase()===group.toLowerCase()){
                e.selected = selected

            }
            return e
        })
        const groupedTimesheet = previousState.groupedTimesheet.map(g=>{
            return {
                group: g.group,
                selected: g.group===group?selected:g.selected,
                emps: timesheet.filter(t=>t.team && t.team.toLowerCase()===g.group.toLowerCase())
            }
        })
        return {...previousState, groupedTimesheet, timesheet}
    }

    if(type==='TIMESHEET_SELECTED_CHANGED_ALL') {
        const {selected} = payload
        const timesheet = previousState.timesheet.map(e=>{

                e.selected = selected

            return e
        })
        const groupedTimesheet = previousState.groupedTimesheet.map(g=>{
            return {
                group: g.group,
                selected: selected,
                emps: timesheet.filter(t=>t.team && t.team.toLowerCase()===g.group.toLowerCase())
            }
        })
        return {...previousState, groupedTimesheet, timesheet, allSelected:selected}
    }

    if(type==='TIMESHEET_EMPLOYMENT_TYPE_CHANGED') {
        const {employmentType} = payload
        // console.log({item,selected})
        const timesheet = previousState.timesheet.map(e => {
            e.selected = !!(!employmentType || (e.member && e.member.employmentType.toLowerCase() === employmentType.toLowerCase()));
            return e
        })

        const groupedTimesheet = previousState.groupedTimesheet.map(g => {
            // const emps =timesheet.filter(t=>t.team && t.team.toLowerCase()===g.group.toLowerCase())
            return {
                group: g.group,
                selected: g.selected,
                emps: timesheet.filter(t => t.team && t.team.toLowerCase() === g.group.toLowerCase())
            }
        })
        return {...previousState, groupedTimesheet, timesheet}

    }


    if(type==='TIMESHEET_SELECTED_CHANGED') {
        const {timesheet:item, selected} = payload
        // console.log({item,selected})
        const timesheet = previousState.timesheet.map(e=>{
            if(e.id===item.id){
            e.selected = selected

        }
            return e
        })

        const groupedTimesheet = previousState.groupedTimesheet.map(g=>{
            const emps =timesheet.filter(t=>t.team && t.team.toLowerCase()===g.group.toLowerCase())
            return {
                group: g.group,
                selected: g.group.toLowerCase()===item.team.toLowerCase()? emps.length:g.selected,
                emps
            }
        })
        return {...previousState, groupedTimesheet, timesheet}
    }

    if (type === 'SET_MATES_TIMESHEET') {
        const {timesheet, allSites, payrolls} = payload
       // const lookUpPayrolls = payrolls.map()
       //  console.log({payrolls})
        const lookUpPayrolls = Object.fromEntries(payrolls.map((b) => [b.mateId&&b.mateId.replace(/\s+/g, '').toLowerCase(), b.employeeId]))

        //console.log({lookUpPayrolls})

        const time = timesheet.filter(t=>t.mateId).map(t=>{

            if(!t.team) {
                t.team = "Other"
            }

            t.employeeId = lookUpPayrolls[t.mateId.replace(/\s+/g, '').toLowerCase()] // &&  lookUpPayrolls[t.mateId.replace(/\s+/g, '').toLowerCase()].employeeId
            t.selected = true
            return t
        })
        const groupedTimesheet = allSites.map(s=>({
            group: s.name,
            selected: true,
            emps: time.filter(t=>t.team && t.team.toLowerCase()===s.name.toLowerCase())
        }))
        // groupedTimesheet.push({
        //     group:'Other',
        //     emps: time.filter(t=>t.team==="Other")
        // })
        groupedTimesheet.push({
            group:'Idle',
            selected: true,
            emps: time.filter(t=>t.team==='Idle')
        })

        return {...previousState, groupedTimesheet, timesheet: time, loading: false};
    }


    return previousState;
}
