import moment from "moment";
import _ from "lodash";

const initialState = {
   payrollDate: null,
   payrollRuns: [],
    payrollRun: {payrolls:[]}

};
export default (previousState = initialState, { type, payload }) => {
    if (type === 'CONSOLIDATED_PAYROLL_DATE_CHANGED') {
        return {...previousState, payrollDate: payload};
    }

    if (type === 'SET_CONSOLIDATED_PAYROLL_RUNS') {
        console.log({payload})
        const {templateEarnings, templateLoans, templateDeductions, templateExpenses} = payload.templates;
        const emps = []
       const payrolls = payload.runs.map(r=>r.payrolls).flat();
      const companies = JSON.parse(localStorage.getItem('childCompanies'))
        // console.log({companies})
       payrolls.map((p,index)=>{


            // if(!p.employee){
            //     p.employee= {}
            // }
            const company = companies.find(c=>c.id===p.companyId)
            p.companyName = company&&company.name
            p.employee = (emps && emps.find(e=>e.id === p.employeeId)) || {}
            p.tinNo = p.tinNo || p.employee.tinNo

            // p.employee.sendPayslipVia = p.employee.sendPayslipVia && p.employee.sendPayslipVia.length? p.employee.sendPayslipVia : ['Publish', 'EmailLink']
            if(new Date(p.employee.startDate)<new Date('1/1/1900')||moment(p.employee.startDate).format('YYYY-MM-DD')==='2015-01-01'){
                p.employee.startDate=null
            }
            p.OTTotalPayHours = 0;
            if(p.overtimes && p.overtimes.length)
            {
                p.OTTotalPayHours = p.overtimes.reduce((sum,cv)=>sum + (cv.type.rate*cv.hrs), 0);
            }

            p.otRest = p.overtimes.filter(o=>o.type.name.toLowerCase()==='sunday'||o.type.name.toLowerCase()==='rest').reduce((sum,cv)=>sum + (cv.hrs), 0);
            p.otNormal = p.overtimes.filter(o=>o.type.name.toLowerCase()==='after work').reduce((sum,cv)=>sum + (cv.hrs), 0);
            p.otHollyDay = p.overtimes.filter(o=>o.type.name.toLowerCase()==='hollyday').reduce((sum,cv)=>sum + (cv.hrs), 0);
            p.otNight = p.overtimes.filter(o=>o.type.name.toLowerCase()==='night').reduce((sum,cv)=>sum + (cv.hrs), 0);
            p.missedHours = Math.max((p.monthlyHours||0)-(p.hoursWorked||0),0)
            p._fullName = p.fullName;
            p.status = p.status || 'Processing';
            if(p.employmentType === "Casual" ) {
                p._fullName = `${p.fullName} -C- ${p.basicSalary} * ${p.daysWorked} days`
            }
            // console.log(templateEarnings)
            if(templateEarnings && templateEarnings.length)
            {
                const commonTypes = templateEarnings.map(t=>t.id.toString())
                // p.commonEarnings = templateEarnings.map(e=>{
                //      return {
                //          name: e.name,
                //          value: p.payrollFixedEarnings.reduce((sum,cv)=>{
                //              if(cv.earningType===e.id.toString()) {
                //                  return sum+cv.value;
                //              }
                //              return sum
                //          },0)
                //      }
                //
                //  })
                templateEarnings.map(e=>{


                        p[e.name.replace(/\s+/g, '').toLowerCase()]=p.payrollFixedEarnings.reduce((sum,cv)=>{
                            if(cv.earningType===e.id.toString() || cv.templateId==e.id ) {
                                return sum+cv.value;
                            }
                            return sum
                        },0)

                    }

                )

                p.commonEarnings = p.payrollFixedEarnings.reduce((sum,cv)=>{
                    if(commonTypes.includes(cv.earningType) || (cv.templateId && commonTypes.includes(cv.templateId.toString()))) {
                        return sum+cv.value;
                    }
                    return sum
                },0)

                p.otherEarnings = p.additionalFixedEarnings - p.commonEarnings
                //
            }

            if(templateDeductions && templateDeductions.length)
            {
                const commonTypes = templateDeductions.map(t=>t.id.toString())
                // p.commonEarnings = templateEarnings.map(e=>{
                //      return {
                //          name: e.name,
                //          value: p.payrollFixedEarnings.reduce((sum,cv)=>{
                //              if(cv.earningType===e.id.toString()) {
                //                  return sum+cv.value;
                //              }
                //              return sum
                //          },0)
                //      }
                //
                //  })
                templateDeductions.map(e=>{


                        p[e.name.replace(/\s+/g, '').toLowerCase()]=p.payrollFixedDeductions.reduce((sum,cv)=>{
                            if(cv.deductionType===e.id.toString() || cv.templateId==e.id ) {
                                return sum+cv.value;
                            }
                            return sum
                        },0)

                    }

                )

                p.commonDeductions = p.payrollFixedDeductions.reduce((sum,cv)=>{
                    if(commonTypes.includes(cv.deductionType) || (cv.templateId && commonTypes.includes(cv.templateId.toString()))) {
                        return sum+cv.value;
                    }
                    return sum
                },0)

                p.otherDeductions = p.fixedDeductionsTotals - p.commonDeductions - p.empPension - p.pf
            }

            if(templateExpenses && templateExpenses.length)
            {
                const commonTypes = templateExpenses.map(t=>t.id.toString())
                // p.commonEarnings = templateEarnings.map(e=>{
                //      return {
                //          name: e.name,
                //          value: p.payrollFixedEarnings.reduce((sum,cv)=>{
                //              if(cv.earningType===e.id.toString()) {
                //                  return sum+cv.value;
                //              }
                //              return sum
                //          },0)
                //      }
                //
                //  })
                templateExpenses.map(e=>{


                        p[e.name.replace(/\s+/g, '').toLowerCase()]=p.payrollFixedExpenses.reduce((sum,cv)=>{
                            if(cv.type===e.id.toString() || cv.templateId==e.id ) {
                                return sum+cv.value;
                            }
                            return sum
                        },0)

                    }

                )

                p.commonExpenses = p.payrollFixedExpenses.reduce((sum,cv)=>{
                    if(commonTypes.includes(cv.type) || (cv.templateId && commonTypes.includes(cv.templateId.toString()))) {
                        return sum+cv.value;
                    }
                    return sum
                },0)

                p.otherExpenses = p.fixedExpensesTotals - p.commonExpenses - p.companyPension - p.companyPf
            }

            if(templateLoans)
            {
                const commonTypes = templateLoans.filter(t=>t.name).map(t=>t.name.replace(/\s+/g, '').toLowerCase())
                // p.commonEarnings = templateEarnings.map(e=>{
                //      return {
                //          name: e.name,
                //          value: p.payrollFixedEarnings.reduce((sum,cv)=>{
                //              if(cv.earningType===e.id.toString()) {
                //                  return sum+cv.value;
                //              }
                //              return sum
                //          },0)
                //      }
                //
                //  })
                templateLoans.map(e=>{


                        p[e.name.replace(/\s+/g, '').toLowerCase()]=p.payrollDeductions.reduce((sum,cv)=>{
                            if(cv.cash && cv.name && cv.name.replace(/\s+/g, '').toLowerCase()===e.name.replace(/\s+/g, '').toLowerCase() ) {
                                return sum+cv.value;
                            }
                            return sum
                        },0)

                        p[e.name.replace(/\s+/g, '').toLowerCase()+'Remaining']=p.payrollDeductions.reduce((sum,cv)=>{
                            if(cv.cash && cv.name && cv.name.replace(/\s+/g, '').toLowerCase()===e.name.replace(/\s+/g, '').toLowerCase() ) {
                                return sum+(cv.remaining||0);
                            }
                            return sum
                        },0)

                    }

                )

                p.commonLoans = p.payrollDeductions.reduce((sum,cv)=>{
                    if(cv.cash &&cv.name && commonTypes.includes(cv.name.replace(/\s+/g, '').toLowerCase())) {
                        return sum+cv.value;
                    }
                    return sum
                },0)
                p.otherSettlements = p.payrollDeductionTotalCash- p.commonLoans
            }







            //  p.sn = index+1
            //p.orgID = p.orgID ///"111"// p.employee.orgID
            //  p.department = p.employee.position.department.name
            //  p.position = p.employee.position.name
            p.employmentType =  p.employmentType || p.employee.employmentType
            if(p.employmentType === 'Casual')
            {
                p.wadge =  p.basicSalary;
                p.basicSalary = p.earnedSalary;

            }


            p.totalTransportAllowance = p.payrollFixedEarnings.reduce((sum,cv)=>{
                if(cv.earningType&&cv.earningType.replace(/\s+/g, '').toLowerCase()==='transportallowance') {
                    return sum+cv.value;
                }
                return sum
            },0)


            // p.taxableTransportAllowance= p.payrollFixedEarnings.reduce((sum,cv)=>{
            //     if(cv.earningType.replace(/\s+/g, '').toLowerCase()==='transportallowance') {
            //         const amount = cv.value >(cv.limitByValue||0)?cv.value-(cv.limitByValue||0):0;
            //         return sum+amount
            //     }
            //     return sum
            // },0)
            //  const exemptedAllowance =  _.maxBy(p.payrollFixedEarnings.filter(cv=>cv.earningType.replace(/\s+/g, '').toLowerCase().includes('transportallowance')),'limitByValue')

            const transportAllowances = p.payrollFixedEarnings.filter(cv=>cv.earningType&&cv.earningType.replace(/\s+/g, '').toLowerCase().includes('transportallowance'))



            let totalTransportAllowance = transportAllowances.reduce((sum,cv)=>sum+cv.value,0)
            if((p.additionalFixedEarnings -  totalTransportAllowance)<0)
            {
                totalTransportAllowance +=(p.additionalFixedEarnings -  totalTransportAllowance)
            }
            // console.log("tt",totalTransportAllowance)
            const limit = (_.maxBy(transportAllowances.filter(t=>t.taxOption==='TaxableWithLimits'),'limitByValue')||{limitByValue:0}).limitByValue;
// console.log("limit",limit)

            p.taxableTransportAllowance = Math.max(totalTransportAllowance- limit,0)||0;

            p.declarationTransportAllowance = totalTransportAllowance - p.additionalEarningsAbsentDeduction;

            const overtimeAllowance =  p.payrollFixedEarnings.filter(cv=>cv.earningType&&cv.earningType.replace(/\s+/g, '').toLowerCase().includes('overtime')).reduce((sum,cv)=>sum+cv.value,0)


            // const taxableExtraEarnings =  _.sumBy(p.bonuses.filter(b=>b.taxOption&&b.taxOption.toLowerCase().includes('taxable')),'value');

            // const taxableEarnings = p.payrollFixedEarnings.filter
            p.otherEarningsThanTransport =  p.taxableEarnings + p.taxableBonuses -  p.taxableTransportAllowance - overtimeAllowance // p.additionalFixedEarnings + (taxableExtraEarnings||0) -  totalTransportAllowance - overtimeAllowance
            p.totalOvertimeAllowance = p.overtimeTotal + overtimeAllowance

            // const totalTransportAllowance = p.payrollFixedEarnings.filter(cv=>cv.earningType.replace(/\s+/g, '').toLowerCase().includes('transportallowance')).reduce((sum,cv)=>sum+cv.value,0)
            // console.log("tt",totalTransportAllowance)






            //   p.taxableTransportAllowance = Math.max(totalTransportAllowance- 2200,0);

            p.costSharing= p.payrollFixedDeductions.reduce((sum,cv)=>{
                if((cv.deductionType&&cv.deductionType.toLowerCase().replace(/\s+/g, '')==='costsharing')||cv.name.toLowerCase().replace(/\s+/g, '').includes('costshar')) {
                    return sum+cv.value;
                }
                return sum
            },0)



            p.fixedDeductionsTotalsAndIncomeTax = p.fixedDeductionsTotals + p.incomeTax
            p.paybackTax = p.payrollDeductions.reduce((sum,cv)=>{
                if(cv.type==='tax') {
                    return sum+cv.value;
                }
                return sum
            },0)

            p.nonTaxDeductions = p.payrollDeductionTotalCash - p.paybackTax

            p.fixedGrossIncome = p.basicSalary+  p.additionalFixedEarnings
            p.serviceCharge  = p.bonuses && p.bonuses.filter(b=>(b.type||b.name).replace(/\s+/g, '').toLowerCase()==="servicecharge").reduce((sum,cv)=>sum+cv.value,0)
            p.serviceChargeDeduction  = p.bonuses && p.bonuses.filter(b=>(b.type||b.name).replace(/\s+/g, '').toLowerCase()==="servicechargededuction").reduce((sum,cv)=>sum+cv.value,0)
            p.otherEarningDeduction  = p.bonuses && p.bonuses.filter(b=>(b.type||b.name).replace(/\s+/g, '').toLowerCase()==="otherdeduction").reduce((sum,cv)=>sum+cv.value,0)
            p.backPay  = p.bonuses && p.bonuses.filter(b=>(b.type||b.name).replace(/\s+/g, '').toLowerCase()==="backpay").reduce((sum,cv)=>sum+cv.value,0)

            p.grossIncome = p.earnedSalary + p.additionalEarningsTotal - p.additionalEarningsAbsentDeduction
            p.totalPension = p.companyPension + p.empPension
            p.totalPf = p.companyPf + p.pf



            p.bank= p.bank || (p.employee && p.employee.bank)
            p.bankAccountNumber= p.bankAccountNumber || (p.employee && p.employee.bankAccountNumber)
            p.bankAccountHolderName= p.bankAccountHolderName || (p.employee && p.employee.bankAccountHolderName)||p.fullName

            p.formatedStartDate = p.employee.startDate ? moment(p.employee.startDate).format("DD/MM/YYYY"):''

            // p.overtimeTotalAll = p.overtimeTotal+p.additionalFixedEarnings
        })


        console.log({payrolls})

        return {...previousState, payrollRuns: payload.runs, payrollRun: {...payload.runs[0],payrolls}};
    }
    return previousState;

}
