import React from 'react';
import {Loading, useQueryWithStore} from "react-admin";
import {Card} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import MultiCurrency from "./multiCurrency";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import ApproveButton from "./payroll/approveButton";
import _ from "lodash";
import CaptionedField from "../UIComponents/captionedField";
import {useDispatch} from "react-redux";

const PayrollRunCard = ({payrollRun, onMerge})=>{
    return   <Card>
        <CardHeader
            title={`${moment(payrollRun.payrollDate).format('MMMM, YYYY')} `}
            subheader={`${payrollRun.status}`}
        />
        <CardContent>
            <Typography>
                Run By {payrollRun.createdBy}
            </Typography>
            <Typography variant="body2">
                On {moment(payrollRun.runDate).format('MMMM, DD, YYYY')}
            </Typography>
                <CaptionedField value={payrollRun.cash} label='Net Pay' />
            <Typography>
                {payrollRun.description||''}
            </Typography>

        </CardContent>
        <CardActions>

            <Button onClick={()=>onMerge(payrollRun)} size="small">Merge</Button>

        </CardActions>
    </Card>
}
const MergePayroll = ({payrollRun:currentPayrollRun,id, onMerge}) => {
    const { loaded, error,data: payrollRuns} = useQueryWithStore({
        type: 'getList',
        resource: 'payrollRuns',
        payload: {filter:{filter:`locked eq true and id ne ${id||0} and payrollDate ge ${moment(currentPayrollRun.payrollDate).startOf('month').format()} and payrollDate le ${moment(currentPayrollRun.payrollDate).endOf('month').format()}`}}

        // payload: {filter:{filter:`payrollDate eq ${currentPayrollRun.payrollDate} and id ne ${id||0}`}}
    });
    const dispatch = useDispatch();
    if (!loaded) { return <Loading />; }
    if (!payrollRuns ) return null;

    const mergeAction = (payrollRun) => {
        dispatch({type:'MERGE_WITH_PAYROLL',payload:payrollRun})
        onMerge()
    }

    return (
        <div>
            <Grid container>
                {_.orderBy(payrollRuns.filter(p=>moment(p.payrollDate).format('MM-YYYY')===moment(currentPayrollRun.payrollDate).format('MM-YYYY')),['runDate'],['desc']).map(pr=><Grid item spacing={3}> <PayrollRunCard onMerge={mergeAction} payrollRun={pr}/></Grid>)}
            </Grid>

        </div>
    );
};

export default MergePayroll;
