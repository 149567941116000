import {fetchUtils} from 'react-admin';
import {stringify} from "query-string";
import moment from "moment";
const fetchJson = (url, options = {}) => {
    const api =  `${localStorage.getItem('domain')}/api`
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    const company = localStorage.getItem('company');

    options.headers.set('Authorization', `Bearer ${token}`);
    options.headers.set('X-COMPANY', company);
    return fetchUtils.fetchJson(api+url, options);
}


export function publishAndSendPayslips({token,payrollId, payrollRunId, status, email, dontEmail}) {
    return fetchJson('/payslip', {
        method: 'POST',
        body: JSON.stringify({token,payrollId, payrollRunId, status, email, dontEmail}),
    }).then(_a => {
        let json =_a.json;
        return {data:json}
    })
}
