import React from 'react';
import {AppBar, Button, Buttton} from 'react-admin';
import MyUserMenu from "./userMenu";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CurrencyMenu from "./currencyMenu";
import ArrowBack from '@material-ui/icons/ArrowBack';
import HelpMenu from "./helpMenu";
import BackButton from "./backButton";
import {useDispatch} from "react-redux";
import SyncIcon from "@material-ui/icons/Sync";

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});
// const MyMenu = (props) => <Menu {...props} />
const MyAppBar = props => {
    const classes = useStyles();
    const dispatch = useDispatch();


    return (
        <AppBar userMenu={<MyUserMenu/>} {...props}>
            <BackButton >
             <ArrowBack />
            </BackButton>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />

            <span className={classes.spacer} />
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}

            >
                {localStorage.getItem('companyName')}
            </Typography>
            <span className={classes.spacer} />
            {localStorage.getItem('companyMateId')&&<Button startIcon={<SyncIcon/>} label="Sync" onClick={() => dispatch({type: 'LOAD_TIK_MATES_CHANGES'})}/>}
           <CurrencyMenu/>

          <HelpMenu/>
        </AppBar>
    );
};

export default MyAppBar;
