import React from "react";
import {Edit, email, FormTab, SimpleForm, Tab, TextField, TextInput} from 'react-admin';

export const AddressEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="email" />
        </SimpleForm>
    </Edit>
);

export  const AddressTab = props => {

    return    <FormTab {...props} label="Address">

        <TextInput label='Email' type='email' source="address.email" validate={[email()]} />
        <TextInput label='Phone Number'  source="address.phoneNumber" />
        {/*<ArrayInput  label='Phone Numbers'  source="address.phoneNumbers">*/}
            {/*<SimpleFormIterator>*/}
                {/*<TextInput label='Phone Number' source="number" />*/}
            {/*</SimpleFormIterator>*/}
        {/*</ArrayInput>*/}
        <TextInput  label='Address Line' source="address.addressLine" />
        <TextInput  label='City' source="address.city" />
        <TextInput  label='Sub-city' source="address.subcity" />
        <TextInput  label='Kebele' source="address.kebele" />
        <TextInput  label='Woreda' source="address.woreda" />
        <TextInput  label='House No' source="address.houseNumber" />
        <TextInput  label='Fax' source="address.fax" />

    </FormTab>


}

export  const AddressTabShow = props => {

    return    <Tab {...props} label="Address">

        <TextField  label='Email' type='email' source="address.email" />
        <TextField label='Phone Number'  source="address.phoneNumber" />
        {/*<ArrayField label='Phone Numbers'  source="address.phoneNumbers">*/}
            {/*<Datagrid>*/}
                {/*<TextField label='Phone Number' source="number" />*/}
            {/*</Datagrid>*/}
        {/*</ArrayField>*/}
        <TextField label='Address Line' source="address.addressLine" />
        <TextField   label='City' source="address.city" />
        <TextField   label='Sub-city' source="address.subCity" />
        <TextField   label='Kebele' source="address.kebele" />
        <TextField  label='Woreda' source="address.woreda" />
        <TextField   label='House No' source="address.houseNumber" />
        <TextField   label='Fax' source="address.fax" />
    </Tab>


}
