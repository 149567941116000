import React from 'react';
import {Chip, Divider, Tooltip} from "@material-ui/core";
import {Badge} from "../../UIComponents/Wrappers";
import Checkbox from "@material-ui/core/Checkbox";
import ErrorIcon from "@material-ui/icons/Error"

export const TimeSheetItem = ({timesheet, index, setSelected, totalDays=30}) => {
    const fNameSplit = (timesheet.fullName&&timesheet.fullName.replace(/\s+/g, " ").split(' '))||[]
    const fullName = fNameSplit[0]+' '+ fNameSplit[1]
    return (
        <div>
            <Checkbox
                color="primary"
                checked={timesheet.selected}
                onChange={ev=>setSelected(ev.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
            />

            {!timesheet.employeeId&&<ErrorIcon style={{ color:"orange"}} />}
            {index+1} {"  "}
           <strong>{fullName}</strong>

            {!!timesheet.absentDays &&   <Chip title={"Absent"} size='small' style={{backgroundColor:'red', color:"white"}} label={`A:${timesheet.absentDays}`} /> }
            {!!timesheet.leaveDays &&  <Chip title={"Leave"} size='small' style={{backgroundColor:'orange', color:"white"}} label={`L:${timesheet.leaveDays}`} /> }
            <Badge invisible={!timesheet.member||timesheet.member.employmentType!=="Casual"} badgeContent={timesheet.payableDays} color="warning"  >
                {timesheet.member&&timesheet.member.employmentType!=="FullTime"&&<Chip size={"small"} label={timesheet.member.employmentType} /> }
            </Badge>
            {/*{timesheet.member&&timesheet.member.employmentType==="Casual"&&<Chip size={"small"} label={timesheet.payableDays} /> }*/}

            {timesheet.hoursWorked && <Chip title={"Hours Worked"} size='small' style={{backgroundColor:'purple', color:"white", fontSize:"smaller" }} label={`⏰ ${(+timesheet.hoursWorked).toFixed()}`} /> }
            {timesheet.overtimes&&
            <div>
                {Object.keys(timesheet.overtimes).map(key=>
                    <Tooltip title={key}>
                        <Chip variant="outlined" size="small" label={`${key.substring(0,2)}:${timesheet.overtimes[key]}`} />

                    </Tooltip>
                )}
            </div>
            }
            {timesheet.member&&timesheet.member.employmentType==="FullTime"&&timesheet.transportPayableDays>=0&&timesheet.transportPayableDays<totalDays&&   <Chip title="Transport Allowance Days"  size='small' label={`T: ${timesheet.transportPayableDays}`} />}
            <Divider/>
        </div>
    );
};


