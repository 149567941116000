export const loanDisburseAction = (ids,  basePath, data) => ({
    type: 'DISBURSE_LOANS',
    payload: { ids, basePath, data },

});

export const loanCancelAction = (ids,  basePath, data) => ({
    type: 'CANCEL_LOANS',
    payload: { ids, basePath, data },

});
