import React from 'react';
import {connect} from "react-redux";
import Container from "@material-ui/core/Container";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import OutdatedIcon from "@material-ui/icons/MoveToInbox"
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {fetchUtils, useRedirect} from 'react-admin';


function getLatestRelease() {
    fetchUtils.fetchJson('https://api.github.com/repos/abenezer/payday/tags').then(({ json }) => json.value);
}



const OutdatedPage = props => {

    const redirect =  useRedirect()


    return (
        <Container>
            <Card>
                <CardContent>
                    <Box textAlign="center" m={1}>
                    <OutdatedIcon color="primary" style={{ fontSize: 200 }}/>
                    <Typography color="textSecondary" variant="subtitle1">
                        your version ({props.currentVersion}) is outdated please use the latest version <br/>
                        At least version {props.minVersion}  required
                    </Typography>

                        <Button variant="contained" onClick={()=>{
                            redirect('/')
                            window.location.reload()}} >Recheck</Button>
                    </Box>

                </CardContent>
            </Card>


        </Container>
    );
};

export default connect(state=>({
    minVersion: state.ui.minVersion,
    currentVersion: state.ui.currentVersion
}))(OutdatedPage);
