import React from 'react';
import {Button, useRefresh} from 'react-admin';
import {connect} from "react-redux";
import {payrollUnApproveAction} from "../../actions/payrollActions";

const UnApproveButton = props => {
     const refresh = useRefresh();
     const {onApprove, record,redirect,id, loading} = props
    // const notify = useNotify();
    // const redirect = useRedirect();
    // const [approve, { loading }] = useMutation(
    //     {
    //         type: 'update',
    //         resource: 'payrollRuns',
    //         payload: { id: record.id, data: { status: 'Approved' } },
    //     },
    //     {
    //         action: 'COMPLETE_PAYROLL_APPROVE',
    //         onSuccess: ({ data }) => {
    //             // redirect('/comments');
    //             notify('Payroll approved');
    //         },
    //         onFailure: (error) => notify(`Payroll approval error: ${error.message}`, 'warning'),
    //     }
    // );
    return <Button label="UnApprove" onClick={()=>{
        onApprove(record.id||id,record,redirect);
        refresh();
    }} disabled={loading||(record.site||record.employmentType)||record.status!=="Approved"} />;
};

 export default connect(state=>({loading:state.admin.loading}),dispatch=>({onApprove:(id,payrollRun,path)=>dispatch(payrollUnApproveAction(id,payrollRun,path))}))(UnApproveButton)
