import React from 'react';
import {Datagrid, Edit, List, NumberField, SimpleForm, TextField,Create,NumberInput,TextInput} from 'react-admin';
import exporterCommon from "../import/exporterCommon";


export const OverTimeTypeList= props => (
    <List exporter={exporterCommon(props.resource)} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <NumberField source="rate" />
           </Datagrid>
    </List>
);

export const OverTimeTypeEdit = props => (
    <Edit {...props}>
        <SimpleForm redirect='list'>
            <NumberInput source="rate" />
        </SimpleForm>
    </Edit>
);

export const OverTimeTypeCreate = props => (
    <Create {...props}>
        <SimpleForm redirect='list'>
            <TextInput source="name" />
            <NumberInput source="rate" />
        </SimpleForm>
    </Create>
);
