import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Popover from "@material-ui/core/Popover";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import CaptionedField from "../../UIComponents/captionedField";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu"

const useStyles = makeStyles(theme => ({
    typography: {
        padding: theme.spacing(2),
    },
}));


const DeductionsPopover = props => {
   // console.log('eanringProps',props)
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <div style={{marginTop:-20}}>
            <IconButton aria-describedby={id} variant="text" color="primary" onClick={handleClick}>
                <MenuIcon/>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Card >
                    <CardContent>

          {props.deductions.map(e =>  <CaptionedField label={`${e.name} ${e.value.toFixed(2) !==e.originalValue.toFixed(2) ?`*   (${e.originalValue.toFixed(2)})`:''}`} value={e.value}/>

          )  }
                    </CardContent>
                </Card>
            </Popover>
        </div>
    );
};

export default DeductionsPopover;
