import React from 'react';
import {
    Create,
    Datagrid,
    Edit,
    List, NumberField,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    NumberInput
} from 'react-admin';
import exporterCommon from "../import/exporterCommon";
import {parse} from "query-string";
import {RedirectToolbar} from "./common";

export const JobLevelList= props => (
    <List exporter={exporterCommon(props.resource)}  {...props}>
        <Datagrid rowClick="edit">
             <TextField source="name" />

        </Datagrid>
    </List>
);

export const JobLevelEdit = props => {
    const { id } =parse(props.location.search);
    const redirect = id ? `/companies/${id}/show/JobLevels` : "list";
    return <Edit  {...props}>
        <SimpleForm redirect={redirect} toolbar={<RedirectToolbar/>}>
            {/*<ReferenceInput label="JobLevel" source="jobLevelId" reference="jobLevels" >*/}
            {/*    <SelectInput optionText="name"/>*/}
            {/*</ReferenceInput>*/}
            {/*<ReferenceInput label="Site" source="siteId" reference="sites" >*/}
            {/*    <SelectInput optionText="name"/>*/}
            {/*</ReferenceInput>*/}
            {/*<TextInput source="type"/>*/}
            <TextInput source="name"/>
        </SimpleForm>
    </Edit>
};

export const JobLevelCreate = props => {
    const { companyId} =parse(props.location.search);
    const redirect = companyId ? `/companies/${companyId}/show/JobLevels` : "show";
    return <Create {...props}  >
        <SimpleForm redirect={redirect}>

            <TextInput source="name"/>

        </SimpleForm>
    </Create>
};

export const JobLevelShow = props => (
    <Create {...props}  >
        <SimpleForm redirect='list' >

            <TextField source="name" />

        </SimpleForm>

    </Create>
);
