import React, {useState} from 'react';
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {payslipToMJML} from "../../UIComponents/emailTemplate/payslipMJML";
import moment from "moment";
import {connect, useDispatch} from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import Popover from "@material-ui/core/Popover";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import {Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import CheckIcon from "@material-ui/icons/Check"
import { useHistory } from 'react-router'
import EmailSendButton from "./emailButton";
import renderTemplate from 'react-text-templates'
import {payslipToTelegraphPage} from "../../UIComponents/emailTemplate/telegraphPage";
const EmailSendDialog = props => {
    const [welcomeTemplate, setWelcomeTemplate] = React.useState("Hello {name}");
    const [isDraft, setIsDraft] = React.useState(false)
    const [sendType, setSendType] = React.useState('email')
    const [draftTemplate, setDraftTemplate] = React.useState("Hello {name} this is a Draft payslip, You can send your feedback before it's approved");
    const dispatch = useDispatch();
    const history = useHistory()
    const {payrolls, payslipSetting, onClose, payrollRun,logo, forceSend, rowsPerPage,page } = props;
  const [dialogOpened, setDialogOpened]= useState(false)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const showTemplateEdit = event => {
        setAnchorEl(event.currentTarget);
    };

    const closeTemplateEdit = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    const sendEmail = ()=> {
        setDialogOpened(true);
        setSendType('email')
        const status= isDraft?'Draft':'Final';
        const emailMessages = (rowsPerPage > 0
            ? payrolls.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : payrolls
        ).map(row=>{
            // const html = Inky.doctype + renderToString((
            //     <Inky>
            //         <Inky.Head>
            //             <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/foundation-emails/2.2.1/foundation-emails.css" />
            //         </Inky.Head>
            //         <Inky.Body preview="Preview text">
            //             <PayslipEmail companyName={localStorage.getItem('companyName')} payroll={row} payrollDate={props.payrollRun.payrollDate}/>
            //         </Inky.Body>
            //     </Inky>
            // ));

           //  console.log("mjml",mjml)
           //  console.log("emp:",row.employee)
            if(  (forceSend || (!props.emailsSent.some(r=>r&& r.ref===row.id) && !row.emailSent && row.sentSlipType !== 'Processing')) && ((row.employee.address && row.employee.address.email)|| row.emailAddress)) {
                const mjml = payslipToMJML({
                    companyName: localStorage.getItem('companyName'),
                    payroll: row,
                    payrollDate: props.payrollRun.payrollDate,
                    welcomeTemplate: isDraft ? draftTemplate : welcomeTemplate,
                    payslipSettings: payslipSetting,
                    logo,
                    isDraft,
                    user: {firstName: localStorage.getItem('firstName'), email: localStorage.getItem('userEmail')},
                    templateData: {name: row.name, fullName: row.fullName, netPay: row.cash, ...row}
                })

                return {
                    to: [{
                        name: row.fullName,
                        email: ((row.employee.address && row.employee.address.email) || row.emailAddress)
                    }],
                    MJML: mjml,
                    bcc: localStorage.getItem('userEmail2') ? [{
                        name: localStorage.getItem('firstName'),
                        email: localStorage.getItem('userEmail2')
                    }] : [],
                    replyTo: localStorage.getItem('userEmail') ? [{
                        name: localStorage.getItem('firstName'),
                        email: localStorage.getItem('userEmail')
                    }] : [],
                    from: localStorage.getItem('userEmail'),
                    subject: `Your ${isDraft ? 'Draft' : ''} Payslip for ${moment(payrollRun.payrollDate).format('MMMM, YYYY')}`,
                    messageType: isDraft ? 'DraftPayslip' :'Payslip',
                    ref: row.id
                }
            }

        }).filter(e=>e!=null)

        const telegramMessages = payrolls.map(row=>{
            // const html = Inky.doctype + renderToString((
            //     <Inky>
            //         <Inky.Head>
            //             <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/foundation-emails/2.2.1/foundation-emails.css" />
            //         </Inky.Head>
            //         <Inky.Body preview="Preview text">
            //             <PayslipEmail companyName={localStorage.getItem('companyName')} payroll={row} payrollDate={props.payrollRun.payrollDate}/>
            //         </Inky.Body>
            //     </Inky>
            // ));

            //  console.log("mjml",mjml)
            if( (forceSend || ( row.sentSlipType !== 'Processing' && row.sentSlipType!==status)) && ((row.employee.address && row.employee.address.phoneNumber && row.employee.address.phoneNumber.length===13)|| row.phoneNumber && row.phoneNumber.length===13)) {
                const page = payslipToTelegraphPage({
                    companyName: localStorage.getItem('companyName'),
                    payroll: row,
                    payrollDate: props.payrollRun.payrollDate,
                    welcomeTemplate: isDraft ? draftTemplate : welcomeTemplate,
                    payslipSettings: payslipSetting,
                    logo,
                    isDraft,
                    user: {firstName: localStorage.getItem('firstName'), email: localStorage.getItem('userEmail')},
                    templateData: {name: row.name, fullName: row.fullName, netPay: row.cash, ...row}
                })

                return {
                    body: `${moment(props.payrollRun.payrollDate).format('MMMM')} Payslip \n ${renderTemplate(isDraft ? draftTemplate : welcomeTemplate, {name: row.name, fullName: row.fullName, netPay: row.cash, ...row})}`,
                    to: ((row.employee.address && row.employee.address.phoneNumber) || row.phoneNumber),
                    methods: [
                        "TELEGRAPH"
                    ],
                    page,
                    name: `${row.fullName}-${localStorage.getItem('companyName')}`,
                    from: "Payday Payroll System",
                    date: new Date(),
                    ref: row.id
                }
            }

        }).filter(e=>e!=null)


        dispatch({
            type: "SEND_EMAILS",
            payload: {messages:emailMessages, telegramMessages, payrollRunId:props.payrollRun.id, isDraft}
        })


        // dispatch({
        //     type: "SEND_TELEGRAM",
        //     payload: {messages:telegramMessages, payrollRunId:props.payrollRun.id, isDraft}
        // })

    }
    const publish = (dontEmail) => {
        setDialogOpened(true);
        setSendType('publish')
        // console.log('publishing', dontEmail)
        dispatch({
            type: "PUBLISH_PAYROLLS",
            payload: {payrolls:(rowsPerPage > 0
                        ? payrolls.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : payrolls
                ),isDraft,payrollRun,welcomeTemplate:isDraft?draftTemplate:welcomeTemplate, logo,slipSetting:payslipSetting, dontEmail, forceSend}
        })
    }


    const onDialogClose = () => {

        setDialogOpened(false)
        dispatch({
            type: "CLEAR_EMAILS",
         })
       // history.go(0) //This causes your page to re-render automatically
        onClose()

    }

   // console.log({props})

    return (
        <>
            <ButtonGroup variant="contained"  aria-label="split button"  style={{marginRight:30}}>
            {/*<Button disabled={props.payrollRun.status!=='Approved'} onClick={sendEmail}>Send Email</Button>*/}
              <EmailSendButton status={props.payrollRun.status} locked={props.payrollRun.locked} showTemplateEdit={showTemplateEdit} onSendEmail={sendEmail} onSetDraft={v=>setIsDraft(v)} onPublish={publish} />
                {/*<Button*/}
                {/*    color="primary"*/}
                {/*    size="small"*/}
                {/*    aria-controls={open ? 'split-button-menu' : undefined}*/}
                {/*    aria-expanded={open ? 'true' : undefined}*/}
                {/*    aria-label="select merge strategy"*/}
                {/*    aria-haspopup="menu"*/}
                {/*    onClick={showTemplateEdit}*/}
                {/*>*/}
                {/*    <EditIcon />*/}
                {/*</Button>*/}
            </ButtonGroup>
            <Dialog fullWidth  maxWidth='sm' open={dialogOpened}>
                <DialogTitle id="form-dialog-title">{sendType==='email'?'Sending':'Publishing'} {isDraft?'Draft':'Approved'} Slips</DialogTitle>
                <DialogContent>
                    {sendType==='publish'&&<>
                        Found {props.publishQueued.length} Payslips to publish <br/>
                                               </>}
                    Found {props.emailsQueued.length} unsent Payslips with email address <br/>
                    {props.telegramsQueued.length>0&&<>Found {props.telegramsQueued.length} unsent Payslips with Phone Number <br/></>}
                    <LinearProgress style={{barColorSecondary:'red'}} color={props.emailsErrorProcessed.length>0||props.emailsErrorPublished.length?'secondary':'primary'} variant='determinate' value={props.emailProgress} />
                    Processing {props.emailsQueued.length} slips <br/>

                    {props.telegramsQueued.length>0&&<>  Sending {props.telegramsQueued.length} slips via Telegram <br/></>}

                    {sendType==='publish'&&props.emailsPublished.length>0 &&<>{props.emailsPublished.length} Slips Published <CheckIcon/> <br/> </>}
                    {sendType==='publish'&&props.emailsErrorPublished.length>0 &&<span style={{color:'red'}}>{props.emailsErrorPublished.length} Slips failed to Process, {props.errorMessage} <br/></span>}
                    {props.emailsProcessed.length>0 &&<>{props.emailsProcessed.length} emails Processed <CheckIcon/> <br/> </>}
                    {props.emailsErrorProcessed.length>0 &&<span style={{color:'red'}}>{props.emailsErrorProcessed.length} emails failed to Process, {props.errorMessage} <br/></span>}
                    {props.emailsSent.length>0 &&<span  style={{color:'blue'}}>{props.emailsSent.length} emails successfully queued to be sent</span>}
                    {props.telegramsSent.length>0 &&<span  style={{color:'blue'}}>{props.telegramsSent.length} slips successfully sent via Telegram </span>}
                    {props.emailsSentErrors.length>0&&<span style={{color:'red'}}>{props.emailsSentErrors.length} emails failed to send, {props.errorMessage} <br/></span>}
                </DialogContent>
                <DialogActions>
                      <Button disabled={props.emailProgress!==100} onClick={onDialogClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>


            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={closeTemplateEdit}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Card>
                    <CardContent>
                        <TextField multiline value={isDraft?draftTemplate:welcomeTemplate} onChange={e=>isDraft?setDraftTemplate(e.target.value):setWelcomeTemplate(e.target.value)}/>
                    </CardContent>
                </Card>
            </Popover>
        </>
    );
};

export default connect(state=>({

    emailsSent: state.payrollStatus.emailsSent,
    telegramsSent: state.payrollStatus.telegramsSent,
    emailsQueued: state.payrollStatus.emailsQueued,
    telegramsQueued: state.payrollStatus.telegramsQueued,
    emailsPublished: state.payrollStatus.emailsPublished,
    emailsErrorPublished: state.payrollStatus.emailsErrorPublished,
    emailsProcessed: state.payrollStatus.emailsProcessed,
    emailsErrorProcessed: state.payrollStatus.emailsErrorProcessed,
    emailsSentErrors: state.payrollStatus.emailsSentErrors,
    emailProgress:state.payrollStatus.emailProgress,
    publishQueued:state.payrollStatus.publishQueued,
    errorMessage:state.payrollStatus.errorMessage,
}))(EmailSendDialog);
