const initialState = {
    currentCurrency: 'etb',
    hideSymbol: true,
    conversionRates:
        {
            "@odata.etag": "W/\"YmluYXJ5J0FBQUFBQUFBbEhJPSc=\"",
            "etb": 1,
            "usd": 36.61,
            "eur": 43.35,
            "gbp": 47.29,
            "date": "2020-01-21T13:02:08.0306759+03:00",
            "source": null,
            "id": -1,
            "createdDate": "2020-01-21T10:02:08.5444652+03:00",
            "modifiedDate": null,
            "version": "AAAAAAAAlHI=",
            "createdBy": "super",
            "modifiedBy": null
        }

};

export default (previousState = initialState, { type, payload }) => {
  //  console.log(pa)
    if (type === 'CHANGE_CURRENCY') {
        return {...previousState, currentCurrency: payload};
    }
    if (type === 'CHANGE_HIDE_SYMBOL') {
        return {...previousState, hideSymbol: payload};
    }
    if (type === 'CURRENCY_RATES_LOADED') {
      //  console.log(payload)
        return {...previousState, conversionRates:payload[0]};
    }
    return previousState;
}
