import React, {useEffect, useRef, useState} from 'react';
import {NumberField, TextField, Title, useDataProvider, useQueryWithStore} from 'react-admin';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import MultiCurrency from "../multiCurrency";
import MultiCurrencyField from "../../UIComponents/multiCurrencyField";
import MyDatagrid from "../accountsGrid";
import CardHeader from "@material-ui/core/CardHeader";
import CaptionedField from "../../UIComponents/captionedField";
import classnames from "classnames";
import {ArrowForward as ArrowForwardIcon} from "@material-ui/icons";
import useStyles from "../PayrollStat/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {FormattedNumber} from "react-intl";
import {Typography} from "../../UIComponents/Wrappers";
import ReactToPrint from "react-to-print";
import Button from "@material-ui/core/Button";
import PrintIcon from "@material-ui/icons/Print"
import Switch from "@material-ui/core/Switch";
import PayrollLoanExcelExport from "./payrollLoan/payrollLoanExcelExport";
import VarianceReportExcelExport from "./varianceReportExcelExport";
import _ from "lodash";
import { get } from 'lodash';
function sort(x) {
console.log(x)
}

const labels = {
    totalEarnings: 'Total Income',
    taxableEarnings: 'Taxable Earnings',
    net: 'Net before loan settlement',
    netPay: 'Net Pay',
}

const DetailVariance = ({record}) => {
    return (
        <Card>

                <Table aria-label="simple table">

                    <TableBody>
                        {Object.keys(record).filter(r=>r!=='employee').map(key => record[key]&& (
                            <TableRow key={key}>
                                <TableCell >
                                    {labels[key]}
                                </TableCell>
                                <TableCell align="right"><MultiCurrency value={record[key].val1}/></TableCell>
                                <TableCell align="right"><MultiCurrency value={record[key].val2}/></TableCell>
                                <TableCell align="right"><MultiCurrency value={record[key].variance}/></TableCell>
                                <TableCell align="right"><FormattedNumber value={record[key].percentage}/></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

        </Card>
    );
};




const VarianceCard = ({header,data,p1,p2,style}) => {
  const classes = useStyles();
    return (
        <Card>
            <CardHeader title={header}/>
            <CardContent>
                <CaptionedField style={style} label= {moment(p1.payrollDate).format('MMMM')} value={data.val1}/>
                <CaptionedField style={style} label= {moment(p2.payrollDate).format('MMMM')} value={data.val2}/>

                <CaptionedField style={style} label="Variance" value={data.variance||data.val2-data.val1}/>
                {/*<CaptionedField label="Percentage" value={data.percentage||(data.val2-data.val1)/data.val1}/>*/}
                <ArrowForwardIcon

                    className={classnames(classes.profitArrow, {
                        [classes.profitArrowDanger]:(data.variance||data.val2-data.val1)<0,
                    })}
                /> <FormattedNumber  style="percent" value={data.percentage||(data.val2-data.val1)/data.val1}/>

            </CardContent>
        </Card>
    );
};



const VarianceReport = () => {
    const defaultFooterElements = [{field:"General Manager", value:""},{field:"HR Head", value:""}]
    const [payroll1, setPayroll1] = useState();
    const [payroll2, setPayroll2] = useState();
    const [variance,setVariance] = useState();
    const [currentSort,setCurrentSort] = useState({ field: 'employee', order: 'ASC' });
    const [statusFilter,setStatusFilter] = useState('Approved');
    const componentRef = useRef();
    const [summeryField,setSummeryField] = useState("totalEarnings");
    const dataProvider = useDataProvider();
    const [showFooter,setShowFooter] = useState(false);
    const [footerItems,setFooterItems] = useState(defaultFooterElements)

    const [columns, setColumns] = useState([])
    const [reportItems, setReportItems] = React.useState({
        totalEarnings: true,
        taxableEarnings:true,
        net: true,
        netPay: true,
        // totalDeductions:true
    });



    const { loaded, error, data: payrollRuns } = useQueryWithStore({
        type: 'getList',
        resource: 'payrollRuns',
        payload: {filter:{},pagination:{},sort:{field: 'runDate', order: 'DESC'}}
    });

    const { data: company } = useQueryWithStore({
        type: 'getOne',
        resource: 'companies',
        payload: {id:localStorage.getItem('companyId')}
    });

    const handleSort = (field,data=variance.payrolls)=> {
        // console.log(field)
        let order = 'ASC';
        if(field===sort.field)
        {
            order = sort.order==='DESC'?'ASC':'DESC';
            // setSort({ field, order: sort.order==='DESC'?'ASC':'DESC' })
        }

        setCurrentSort({ field, order})


        const payrolls = _.orderBy(data, [item => get(item,field)&&isNaN(get(item,field))?get(item,field).toString().toLowerCase():get(item,field)], [order.toLowerCase()]).map((r,i)=>{
            r.sn = i+1
            return r
        })
       setVariance({...variance,payrolls})

    }

    useEffect(() => {
        const filteredRuns = payrollRuns? payrollRuns.filter(p=>!statusFilter||p.status===statusFilter) : []
        if(filteredRuns.length>=2)
        {
            loadPayrollRun(filteredRuns[0].id,1)
            loadPayrollRun(filteredRuns[1].id,2)
        }

    }, [payrollRuns]);

    const varianceRow = (p1,p2,item) => p1&&p2&&({val1:p1[item], val2:p2[item], variance:p2[item]-p1[item], percentage:(p2[item]-p1[item])/p1[item]})

    useEffect(() => {
           if(payroll1&&payroll2)
           {
               const payrolls = payroll2.payrolls.map(p2=>{
                   const p1 =payroll1.payrolls.find(p=>p.employeeId===p2.employeeId)
                   return {
                       employee: p2.fullName,
                       totalEarnings: varianceRow(p1,p2,'totalIncome'),
                       net: varianceRow(p1,p2,'netPay'),
                       taxableEarnings:varianceRow(p1,p2,'taxableIncome'),
                       netPay: varianceRow(p1,p2,'cash'),
                       // totalDeductions:varianceRow(p1,p2,'totalDeductions'),
                   }
               })
               const field = currentSort.field;
               setVariance( {
                       payrolls:_.orderBy(payrolls, [item => isNaN(get(item,field))?get(item,field).toString().toLowerCase():get(item,field)], [currentSort.order.toLowerCase()]).map((r,i)=>{
                       r.sn = i+1
                       return r
                   }),
                       totalIncome: varianceRow(payroll1,payroll2,'totalIncome'),
                       net: varianceRow(payroll1,payroll2,'netPay'),
                       incomeTax: varianceRow(payroll1,payroll2,'incomeTax'),
                       taxableIncome:varianceRow(payroll1,payroll2,'taxableIncome'),
                       netPay: varianceRow(payroll1,payroll2,'cash'),



               }


               )
               reloadColumns(summeryField);
              // handleSort('employee')




           }

      // setVariance({
      //     totalEarnings: {val1:payroll1.totalEarnings, val2:payroll2.totalEarnings, variance:payroll1.totalEarnings-payroll2.totalEarnings},
      //     net: true,
      //     taxableEarnings:true,
      //     netPay: true,
      //     totalDeductions:true
      // })
    }, [payroll1,payroll2]);

    const handleChange = name => event => {
        setReportItems({ ...reportItems, [name]: event.target.checked });
    };

    const reloadColumns = summeryField => {
        const initColumns = [
            {field:"sn", width:80, show:true, title:"SN", type:"Text"},
            {show:true,field:"employee", width:230, title:"Full Name", type:"Text"},
            {show:true,field:`${summeryField}.val1`,  title:moment(payroll1.payrollDate).format('MMMM')+' Payrun', type:"Number"},
            {show:true,field:`${summeryField}.val2`,  title:moment(payroll2.payrollDate).format('MMMM')+' Payrun', type:"Number"},
            {show:true,field:`${summeryField}.variance`,  title:"Variance", type:"Number"},
            {show:true,field:`${summeryField}.percentage`,  title:"Percentage", type:"Percentage"},
        ]
        setColumns(initColumns)
    }
    const loadPayrollRun = (id,payrun)=> {
        // const { data } = useQueryWithStore({
        //     type: 'getOne',
        //     resource: 'payrollRuns',
        //     payload: {id,expand:'payrolls'}
        // });
        dataProvider.getOne('payrollRuns', { id,expand:'payrolls' })
            .then(({ data }) => {
                if(payrun === 1) {
                    setPayroll1(data)
                }
                else if(payrun === 2) {
                    setPayroll2(data)
                }
            })

    };
    if(!payrollRuns) return null;

    if(payrollRuns.length<2) {
        return <div>
            You need at least two months data
        </div>
    }
    if(!payroll1||!payroll2||!variance) return null;
   // console.log("variance",variance)
    return (
        <Card>
            <Title title="Variance Report"/>

            <Card>
                <CardContent>
                    <Grid container>
                        <Grid item>
                            <FormControl component="fieldset" >
                                <FormLabel component="legend">Payrun 1</FormLabel>
                            <Select
                                value={payroll1}
                                renderValue={value => `${moment(value.payrollDate).format('MMMM')} ${value.description||''}`}
                                onChange={ev=>{
                                  loadPayrollRun(ev.target.value.id,1)
                              //      setFilteredPayrolls(props.filteredPayrolls.filter(p=>ev.target.value===0||p.bank===ev.target.value))
                                    // payrollRun.filteredPayrolls=filteredPayrolls
                                    //   props.onChange({type:ev.target.value,hrs})
                                }}
                            >

                                {payrollRuns.filter(p=>(!statusFilter||p.status===statusFilter)&&p.id!==payroll2.id).map(item=><MenuItem key={item.id} value={item}>{moment(item.payrollDate).format('MMMM')}</MenuItem>)}
                            </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl component="fieldset" >
                                <FormLabel component="legend">Payrun 2</FormLabel>
                            <Select
                                value={payroll2}
                            renderValue={value =>  `${moment(value.payrollDate).format('MMMM')} ${value.description||''}`}
                                onChange={ev=>{
                                    loadPayrollRun(ev.target.value.id,2)
                                    //      setFilteredPayrolls(props.filteredPayrolls.filter(p=>ev.target.value===0||p.bank===ev.target.value))
                                    // payrollRun.filteredPayrolls=filteredPayrolls
                                    //   props.onChange({type:ev.target.value,hrs})
                                }}
                            >

                                {payrollRuns.filter(p=>(!statusFilter||p.status===statusFilter)&&p.id!==payroll1.id).map(item=><MenuItem key={item.id} value={item}>{moment(item.payrollDate).format('MMMM')}</MenuItem>)}
                            </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                labelPlacement='bottom'
                                control={<Switch
                                    checked={statusFilter === 'Approved'}
                                    onChange={ev=>setStatusFilter(ev.target.checked?'Approved':null)}
                                    value="Show Only Approved"
                                    color="primary"
                                    inputProps={{'aria-label': 'secondary checkbox'}}
                                />
                                }
                                label="Show Only Approved"
                            />
                        </Grid>

                    </Grid>


                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <Grid container>
                        <Grid item>
                            <VarianceCard style='number' p1={payroll1} p2={payroll2} data={{val1:payroll1.payrolls.length,val2:payroll2.payrolls.length}} header='Employees'/>


                        </Grid>

                        <Grid item>
                            <VarianceCard p1={payroll1} p2={payroll2} data={variance.totalIncome} header='Total Income'/>


                        </Grid>

                        <Grid item>
                            <VarianceCard p1={payroll1} p2={payroll2} data={variance.taxableIncome} header='Taxable Income'/>

                        </Grid>
                        <Grid item>
                            <VarianceCard p1={payroll1} p2={payroll2} data={variance.incomeTax} header='Income Tax'/>

                        </Grid>
                        <Grid item>
                            <VarianceCard p1={payroll1} p2={payroll2} data={variance.netPay} header='Net Pay'/>

                        </Grid>

                    </Grid>

                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <Grid container>
                        <Grid item>
                            <FormControl component="fieldset" >
                                <FormLabel component="legend">Summery Field</FormLabel>
                                <RadioGroup color="primary" row aria-label="summeryField" name="summeryField" value={summeryField} onChange={ev=>{setSummeryField(ev.target.value)
                                reloadColumns(ev.target.value)
                                }}>
                                    {Object.keys(reportItems).map(key=>
                                        <FormControlLabel
                                            value={key}
                                            control={<Radio color="primary"/>}
                                            label={labels[key]}
                                        />
                                    )}


                                </RadioGroup>

                                <FormHelperText>Pick a Summery Field to that is Displayed on Detail Variance Report Below</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item>
                            <ReactToPrint
                                trigger={() => <Button variant="outlined"  color="primary"  startIcon={<PrintIcon/>}  >Print/PDF</Button>}
                                content={() => componentRef.current}
                                pageStyle={""}
                            />
                        </Grid>
                        <Grid item>
                            <VarianceReportExcelExport  columns={columns} footerItems={footerItems} showFooter={showFooter}  title={`${moment(payroll2.payrollDate).format('MMMM')} ->  ${moment(payroll1.payrollDate).format('MMMM')} (${labels[summeryField]})`} logo={company&&company.logo} data={variance.payrolls} fileName={`Variance ${moment(payroll2.payrollDate).format('MMMM')} ->  ${moment(payroll1.payrollDate).format('MMMM')}- ${localStorage.getItem('companyName')}.xlsx`}/>
                    </Grid>
                    </Grid>


                    <div ref={componentRef}>
                    <Grid container direction="column" justify="center"
                          alignItems="center" >
                        <Grid item>
                            <Typography variant="h3">
                                Payroll Variance Report ({labels[summeryField]})

                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6">
                                {moment(payroll2.payrollDate).format('MMMM')} ->  {moment(payroll1.payrollDate).format('MMMM')}

                            </Typography>

                        </Grid>
                    </Grid>
                    <MyDatagrid checkField={true}  expand={<DetailVariance />} summeryFields={[`${summeryField}.val1`,`${summeryField}.val2`,`${summeryField}.variance`]}  ids={variance.payrolls.map((p,index)=>index)} currentSort={currentSort} setSort={handleSort} data={variance.payrolls}>
                        <NumberField  source="sn" />
                        <TextField  source="employee" />
                        <MultiCurrencyField label={moment(payroll1.payrollDate).format('MMMM')+' Payrun'} source={`${summeryField}.val1`} />
                        <MultiCurrencyField label={moment(payroll2.payrollDate).format('MMMM')+' Payrun'} source={`${summeryField}.val2`} />
                        <MultiCurrencyField label="Variance" source={`${summeryField}.variance`} />
                        <NumberField label="Percentage" source={`${summeryField}.percentage`} options={{ style: 'percent' }}  />
                        {/*<MultiCurrencyField label='Net Transfer Amount' source="cash" />*/}

                    </MyDatagrid>
                    </div>

                </CardContent>


            </Card>

        </Card>
    );
};

export default VarianceReport;
