import {all, call, put, takeLatest} from 'redux-saga/effects';
import {fetchEnd, fetchStart, refreshView, showNotification} from 'react-admin';
import OdataServiceProvider from "../Helpers/odataServiceProvider";
import _ from 'lodash';
import PlainAPIProvider from "../Helpers/plainAPIProvider";
import {sendEmails} from "../Helpers/emailHelper";
import {sendCatchel} from "../Helpers/catchelApi";



// const sendEmail  = email => PlainAPIProvider.create("emails",{data:email}).then(response => ({ response:response.data }))
//     .catch(error => {
//         // console.log('send failed', error)
//         //put(showNotification('Emails sent successfully ', "info"))
//         return {error}
//     })

// const sendEmail  = email => sendEmailNow([email]).then(response => ({ response:response.data }))
//     .catch(error => {
//         // console.log('send failed', error)
//         //put(showNotification('Emails sent successfully ', "info"))
//         return {error}
//     })




export default function* emailSaga() {
    yield takeLatest('SEND_EMAILS', function* (action) {
            const {payload} = action
       // const status= payload.isDraft?'Draft':'Final';
            yield put(fetchStart());
            try {
                yield  put({type:'START_SENDING_EMAIL',payload:payload.messages})
                yield  put({type:'START_SENDING_TELEGRAM',payload:payload.telegramMessages})
                // preprocessing an email

              //  const messages = []
              //   const messages = yield  all (
              //
              //      payload.messages.map(message => {
              //        //   console.log('PROCESSING_EMAIL',message.id)
              //        // return all([
              //        //     put(showNotification(`Sorry, email sending failed `, "error")),
              //        //     call(renderMJML,message)
              //        // ])
              //         // put({type:'PROCESSING_EMAIL',payload:{id:message.id}})
              //          return call(renderMJML,message)
              //      })
              //
              //   )


                // syncronus way too much performance cost
               // for (const message of payload.messages)
               // {
               //     const m = yield call(renderMJML,message)
               //     console.log(m)
               //     yield  put(showNotification(`Sorry, email sending failed `, "error"))
               // }

                // yield  put({type:'DONE_PROCESSING_PAYROLL',payload:{errors: messages.filter(m=>m.error),results: messages.filter(m=>m.response)}})

                // const renderErrors = messages.filter(m=>m.error);
                //
                // if(renderErrors.length>0) {
                //     yield  put({type:'ERROR_PROCESSING_EMAIL',payload:{errors:renderErrors}})
                // }
                // console.log(renderErrors)


                // const emails = []
                // payload.messages.forEach(m=>{
                //     if(m.response) {
                //         emails.push(m.response)
                //     }
                // })
                //
                // console.log({emails})

                const res = yield all(

                    _.chunk(payload.messages, 10).map(messages => {
                        return call(sendEmails,messages)
                    })
                )




                const resT = yield all(

                    payload.telegramMessages.map(message => {
                        return call(sendCatchel,message)
                    })
                )


               // const sendErrors = res.filter(m=>m.error);
              //console.log(res)
              //   const successResults = res.filter(m=>m.response);
              //   const successResultsT = resT.filter(m=>m.message_id||m.id);


                // console.log({res})
                // yield call(OdataServiceProvider.update,'payrollRuns',{id:payload.payrollRunId,data:{emailSent:true}})

                // yield call(OdataServiceProvider.updateMany,'payrolls',{ids:successResults.map(r=>r.response.ref),data:{emailSent:true,sentSlipType:status}})
                // yield call(OdataServiceProvider.updateMany,'payrolls',{ids:successResultsT.map(r=>r.ref),data:{emailSent:true,sentSlipType:status}})

                //yield put(showNotification('Emails sent successfully ', "info"))
                yield put({type:'EMAILS_SEND_FINISH',payload:{errors: _.flatMapDeep(res, x => x.error).filter(x=>x), results:_.flatMapDeep(res, x => x.response).filter(x=>x)}})
                yield put({type:'TELEGRAM_SEND_FINISH',payload:{errors: resT.filter(m=>m.error), results:resT.filter(m=>m.message_id||m.id)}})
                yield put(fetchEnd());
                yield put(refreshView())
                // yield put(push(payload.basePath))
            }
            catch (e) {
               // console.log(e)
                yield put(showNotification(`Sorry, email sending failed `, "error"))
                yield put(fetchEnd());
            }


        }
    )

    yield takeLatest('SEND_TELEGRAM', function* (action) {
            const {payload} = action
        // console.log({payload})
            const status= payload.isDraft?'Draft':'Final';
            yield put(fetchStart());
            try {
                yield  put({type:'START_SENDING_EMAIL',payload:payload.messages})

                // preprocessing an email

                //  const messages = []
                // const messages = yield  all (
                //
                //     payload.messages.map(message => {
                //         //   console.log('PROCESSING_EMAIL',message.id)
                //         // return all([
                //         //     put(showNotification(`Sorry, email sending failed `, "error")),
                //         //     call(renderMJML,message)
                //         // ])
                //         // put({type:'PROCESSING_EMAIL',payload:{id:message.id}})
                //         return call(renderMJML,message)
                //     })
                //
                // )


                // syncronus way too much performance cost
                // for (const message of payload.messages)
                // {
                //     const m = yield call(renderMJML,message)
                //     console.log(m)
                //     yield  put(showNotification(`Sorry, email sending failed `, "error"))
                // }

                // yield  put({type:'DONE_PROCESSING_PAYROLL',payload:{errors: messages.filter(m=>m.error),results: messages.filter(m=>m.response)}})

                // const renderErrors = messages.filter(m=>m.error);
                //
                // if(renderErrors.length>0) {
                //     yield  put({type:'ERROR_PROCESSING_EMAIL',payload:{errors:renderErrors}})
                // }
                // console.log(renderErrors)


                const res = yield all(

                    payload.messages.map(message => {
                        return call(sendCatchel,message)
                    })
                )

                // const sendErrors = res.filter(m=>m.error);
                //console.log(res)
                const successResults = res.filter(m=>m.message_id||m.id);
               // console.log(successResults)

                // yield call(OdataServiceProvider.update,'payrollRuns',{id:payload.payrollRunId,data:{emailSent:true}})

                yield call(OdataServiceProvider.updateMany,'payrolls',{ids:successResults.map(r=>r.ref),data:{emailSent:true,sentSlipType:status}})

                //yield put(showNotification('Emails sent successfully ', "info"))
                yield put({type:'EMAILS_SEND_FINISH',payload:{errors: res.filter(m=>m.error), results:res.filter(m=>m.message_id||m.id)}})
                yield put(fetchEnd());
                yield put(refreshView())
                // yield put(push(payload.basePath))
            }
            catch (e) {
                console.log(e)
                yield put(showNotification(`Sorry, telegram sending failed `, "error"))
                yield put(fetchEnd());
            }


        }
    )
}
