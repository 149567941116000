import React, {useEffect, useRef, useState} from 'react';
import {Loading, TextField, useMutation, useNotify, useQueryWithStore} from 'react-admin'
import TablePagination from "@material-ui/core/TablePagination";
import ReactToPrint from "react-to-print";
import Button from "@material-ui/core/Button";
import PrintIcon from "@material-ui/icons/Print"

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import MultiCurrencyField from "../../UIComponents/multiCurrencyField";
import ReportHeader from "./reportHeader";
import MyDatagrid from "../accountsGrid";
import BankListExcelExport from "./bankListExcelExport";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import {FormControlLabel, Switch} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
// import IconButton from "@material-ui/core/";
import {bindPopover, bindTrigger, usePopupState} from "material-ui-popup-state/hooks";
import SettingIcon from "@material-ui/icons/SettingsOutlined";
import ListIcon from "@material-ui/icons/List";
import Popover from "@material-ui/core/Popover";
import {Typography} from "../../UIComponents/Wrappers";
import PayrollFooterItem from "./payrollSheetGrid/payrollFooterItem";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import SaveIcon from "@material-ui/icons/SaveOutlined";
import {blue} from "@material-ui/core/colors";
//const banks = [{id:1,name:'CBE'},{id:2,name:'UNITED'}]
const PayrollBankList = props => {
    const defaultFooterElements = [{field:"General Manager", value:""},{field:"HR Head", value:""}]
    const  defaultColumns = [{width:30,field:"sn", title:"SN",show:true},{width:50,field:"orgID", title:"Work Id",show:true},{width:220,field:"bankAccountHolderName",title:"Name",show:true},{width:100,field:"bank",show:true},{width:100,field:"swiftCode",show:false},{width:100,field:"bankBranch",show:true},{width:160,field:"bankAccountNumber",show:true},{width:150,field:"cash",show:true, title:"Net Transfer Amount", type:"Number"},{width:150,field:"netAnnualBonus",show:false, title:"Net Annual Bonus", type:"Number"}]
    const [columnsId,setColumnsId] = useState();
    const [showFooter,setShowFooter] = useState(props.showFooter);
    const [footerItems,setFooterItems] = useState(defaultFooterElements)
    const [columns,setColumns] = useState(defaultColumns)
    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'footerPopover',
    })

    const popupStateCols = usePopupState({
        variant: 'popover',
        popupId: 'colsPopover',
    })

    const { loaded, error, data: companyBanks} = useQueryWithStore({
        type: 'getList',
        resource: 'companyBanks',
        payload: {filter:{expand:"bank"},pagination:{},sort:{}}
    });

    const { loadedSetting} = useQueryWithStore({
            type: 'getList',
            resource: 'payrollReportSettings',
        },{
            onSuccess: (res) =>{

                //  console.log(res)
                if(res.data[0])
                {

                    setShowFooter(!!res.data[0].showFooter)
                    if(res.data[0].bankFooters && res.data[0].bankFooters.length) setFooterItems(res.data[0].bankFooters)
                    setColumnsId(res.data[0].id)
                    // setColumnsId(res.data[0].id)
                    if(res.data[0].bankColumns&&res.data[0].bankColumns.length) {
                        setColumns(res.data[0].bankColumns)
                    }
                }
            }
        }
    );
    const [mutate, { loading }] = useMutation();
    const notify = useNotify();
    const saveColumns = ()=> mutate({
            type: columnsId?'update':'create',
            resource: `payrollReportSettings`,
            payload:{id:columnsId,data: {bankColumns:columns,bankFooters:footerItems}}
        },
        {
            onSuccess: ({ data }) => {
                //console.log(data)


                notify('Bank List View Saved for next time');
                //setOpen(false)
                //  refresh()
            },
            onFailure: (error) => notify(`Unable to save this view`, 'warning'),
        });
    // const [sort,setSort] = useState({ field: 'fullName', order: 'DESC' });
  const {sort, handelSort} = props;
    const componentRef = useRef();
    const [filteredPayrolls,setFilteredPayrolls] = useState(props.filteredPayrolls)

    const [onlyShowWithBank, setOnlyShowWithBank] = useState(false)
    const [bank,setBank] = useState(0)
    const [roundTo,setRoundTo] = useState(2)
    // const {sort} = props
    // const handelSort = field=> {
    //     let order = 'DESC';
    //     if(field===sort.field)
    //     {
    //         order = sort.order==='DESC'?'ASC':'DESC';
    //        // setSort({ field, order: sort.order==='DESC'?'ASC':'DESC' })
    //     }
    //
    //         setSort({ field, order})
    //
    //
    //     setFilteredPayrolls(_.orderBy(filteredPayrolls, [field], [order.toLowerCase()]));
    //
    // }
    useEffect(() => {
        setFilteredPayrolls(props.filteredPayrolls.filter(p=>!onlyShowWithBank||(p.bankAccountNumber&&p.bankAccountNumber.replace(/\s+/g, ''!==''))).map((p,index)=>{

           // console.log(p)
           return  {


                    ...p, cash: +p.cash.toFixed(roundTo),SN:index+1


            }
                // console.log(x)
            // return x
        }

        ));
        if(props.costCenter===-1) {
            props.onSeparateByCostCenter(0)
        }
      //  handelSort(sort.field)
    }, [props.filteredPayrolls,onlyShowWithBank])
    const [page, setPage] = React.useState(0);

    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    if (!loaded) { return <Loading />; }
    let ids = filteredPayrolls.map((p,index) => index)
    ids=rowsPerPage > 0
        ? ids.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : ids

    const handleFooterItemChange = index => ot => {
        const newRoles = footerItems.slice(0); // Create a shallow copy of the roles
        newRoles[index] = ot; // Set the new value
        setFooterItems(newRoles)
    }

    const handleRemoveFooterItem = index => () => {
        const newItems = footerItems.slice(0); // Create a shallow copy of the roles
        newItems.splice(index,1)
        setFooterItems(newItems)
    }


    const handleColumnChange = (event) => {

       const index = columns.findIndex(c=>c.field===event.target.name)
       const newCols = columns.slice(0)
        newCols[index].show= event.target.checked
         setColumns(newCols)
    };

    return (
        <div>
<Grid container spacing={3}>
    <Grid item>
    <Select
        value={bank}
        onChange={ev=>{
            setBank(ev.target.value)
            setFilteredPayrolls(props.filteredPayrolls.filter(p=>(p.bankAccountNumber&&p.bankAccountNumber.replace(/\s+/g, ''!==''))&&(ev.target.value===0||p.bank===ev.target.value)).map(p=>{


                    return ({...p,cash:+p.cash.toFixed(roundTo)})

            }))
            // payrollRun.filteredPayrolls=filteredPayrolls
            //   props.onChange({type:ev.target.value,hrs})
        }}
    >
        <MenuItem key={0} value={0}>All Banks</MenuItem>
        {companyBanks.map(ot=><MenuItem key={ot.id} value={ot.bank.name}>{ot.bank.name}</MenuItem>)}
    </Select>
    </Grid>
    <Grid item>
    <ReactToPrint
        trigger={() => <Button variant="outlined"  color="primary"  startIcon={<PrintIcon/>}  >Print/PDF</Button>}
        content={() => componentRef.current}
        pageStyle={""}
    />
    </Grid>
    <Grid item>

    <FormControl >
        <InputLabel>Round to</InputLabel>
        <Select

            value={roundTo}
            onChange={ev=>{
                setRoundTo(+ev.target.value)
                setFilteredPayrolls(props.filteredPayrolls.filter(p=>p.bankAccountNumber&&p.bankAccountNumber.replace(/\s+/g, ''!=='')).map(p=>{
                    if(bank===0||p.bank===bank)
                    {
                        return ({...p,cash:+p.cash.toFixed(+ev.target.value)})
                    }
                }))                // payrollRun.filteredPayrolls=filteredPayrolls
                //   props.onChange({type:ev.target.value,hrs})
            }}
        >
            <MenuItem key={0} value={0}>0 Decimal Place</MenuItem>
            <MenuItem key={0} value={1}>1 Decimal Place</MenuItem>
            <MenuItem key={0} value={2}>2 Decimal Place</MenuItem>
            <MenuItem key={0} value={3}>3 Decimal Place</MenuItem>
        </Select>
    </FormControl>



    </Grid>
    <Grid item>

    <BankListExcelExport columns={columns} footerItems={footerItems} showFooter={showFooter} payrollDate={props.payrollRun.payrollDate} decimalPlace={roundTo} logo={props.logo} data={filteredPayrolls} fileName={`${bank||''} Bank Transfer - ${props.site||''} - ${moment(props.payrollRun.payrollDate).format('MMMM YYYY')} - ${localStorage.getItem('companyName')} .xlsx`}/>




    </Grid>
    <Grid item>
        <div >

            <IconButton color="primary" {...bindTrigger(popupStateCols)}>

                <ListIcon/>
            </IconButton>
            <Popover
                {...bindPopover(popupStateCols)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div>

                    <FormControl component="fieldset" >
                        <FormLabel component="legend">Select Columns</FormLabel>
                        <FormGroup>

                            {
                                columns.map(c=> <FormControlLabel
                                    control={<Checkbox color='primary' checked={c.show} onChange={handleColumnChange} name={c.field} />}
                                    label={c.title||c.field}
                                />)
                            }

                        </FormGroup>
                        <FormHelperText>Make sure you save the view to keep the changes for next time</FormHelperText>
                    </FormControl>
                    <Button onClick={()=>setColumns(defaultColumns)} >Reset</Button>
                </div>

            </Popover>
        </div>
    </Grid>
    <Grid item><Button variant="outlined" color="primary" startIcon={<SaveIcon style={{color: blue[500]}}/>}
                       onClick={()=>{
                           //  updateColumnsWidth();
                           saveColumns();
                       }}>  Save View</Button></Grid>
    <Grid item>
        <FormControlLabel
            labelPlacement='bottom'
            control={<Switch
                checked={onlyShowWithBank}
                onChange={ev=>setOnlyShowWithBank(ev.target.checked)}
                value="onlyShowWithBank"
                color="primary"
                inputProps={{'aria-label': 'secondary checkbox'}}
            />
            }
            label="With BankAccount"
        />
    </Grid>

</Grid>


            <div ref={componentRef} className="all-boarder">
                <ReportHeader logo={props.logo} payrollRun={props.payrollRun} title="Bank Transfer List"/>
            <MyDatagrid perPage={1000} fullHeight decimalPlace={roundTo}   checkField={false} summeryFields={['cash']}  ids={ids} currentSort={sort} setSort={handelSort} data={filteredPayrolls}>
                {/*<TextField label="WorkId" source="orgID" />*/}
                {/*<TextField source="fullName" />*/}
                {/*<TextField source="bank" />*/}
                {/*<TextField source="bankBranch" />*/}
                {/*<TextField source="bankAccountNumber" />*/}
                {/*<MultiCurrencyField  decimalPlace={roundTo} label='Net Transfer Amount' source="cash" />*/}
                {
                    columns.map(c=>c.show&&(c.type==="Number"?<MultiCurrencyField  decimalPlace={roundTo} label={c.title} source={c.field}/>:<TextField label={c.title} source={c.field} />))
                }

            </MyDatagrid>
            </div>
            <TablePagination
                rowsPerPageOptions={[5, 10, 15, filteredPayrolls.length]}
                component="div"
                count={filteredPayrolls.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />

            {!!showFooter&&<footer style={{marginTop: 10}}>
                <div className="no-print"  >

                    <IconButton color="primary" {...bindTrigger(popupState)}>

                        <SettingIcon/>
                    </IconButton>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Typography >
                            {footerItems.map((f,index)=>{
                                return <PayrollFooterItem value={f.value} field={f.field}  onRemove={handleRemoveFooterItem(index)}  onChange={handleFooterItemChange(index)} />
                            })}
                            <Button onClick={()=>setFooterItems(defaultFooterElements)} color="primary">
                                Reset
                            </Button>

                            <Button onClick={()=>setFooterItems([...footerItems,{}])} color="primary">
                                Add
                            </Button>



                        </Typography>
                    </Popover>
                </div>
                <Grid justify="center" container spacing={10}>

                    {
                        footerItems.map(fe => <Grid item>
                                <strong>{fe.field}</strong> : <span
                                style={{textDecoration: 'underline'}}>{fe.value || '________'}__________</span>
                            </Grid>
                        )
                    }

                </Grid>



            </footer>}

        </div>
    );
};

export default PayrollBankList;
