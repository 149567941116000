import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import ReadyIcon from "@material-ui/icons/Check";
import WaitingIcon from "@material-ui/icons/MinimizeOutlined";
import {useMutation, useNotify, useRefresh} from "react-admin";

const SetReadyPayrollButton = ({record, permissions}) => {
    const [mutate, { loading }] = useMutation();
    const notify = useNotify();
    const refresh = useRefresh();
    const onReadyToggle= ()=> mutate({
            type: 'update',
            resource: `payrollRuns`,
            payload:{id:record.id,data: {locked:record.status!=='Ready',status:record.status==='Ready'?'Waiting':'Ready'}}
        },
        {
            onSuccess: ({ data, permissions }) => {
                notify(`Payroll ${record.status!=='Ready'?"Ready":"Waiting"}`);
                //setOpen(false)
                refresh()
            },
            onFailure: (error) => notify(`Error Setting Payroll Ready`, 'warning'),
        });
    return (
        <IconButton disabled={loading||(record.status==='Approved')||!permissions.includes('Manager')} onClick={onReadyToggle} color="primary" >
            {record.status==='Ready'||record.status==='Approved'?<ReadyIcon/>:<WaitingIcon/>}
        </IconButton>
    );
};

export default SetReadyPayrollButton;
