import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

export const ExcelIcon = props => {
    return (

            <SvgIcon {...props}>
                <path d="M 12 3 L 2 5 L 2 19 L 12 21 L 12 3 z M 14 5 L 14 7 L 16 7 L 16 9 L 14 9 L 14 11 L 16 11 L 16 13 L 14 13 L 14 15 L 16 15 L 16 17 L 14 17 L 14 19 L 21 19 C 21.552 19 22 18.552 22 18 L 22 6 C 22 5.448 21.552 5 21 5 L 14 5 z M 18 7 L 20 7 L 20 9 L 18 9 L 18 7 z M 4.1757812 8.296875 L 5.953125 8.296875 L 6.8769531 10.511719 C 6.9519531 10.692719 7.0084063 10.902625 7.0664062 11.140625 L 7.0917969 11.140625 C 7.1247969 10.997625 7.1919688 10.779141 7.2929688 10.494141 L 8.3222656 8.296875 L 9.9433594 8.296875 L 8.0078125 11.966797 L 10 15.703125 L 8.2714844 15.703125 L 7.1582031 13.289062 C 7.1162031 13.204062 7.0663906 13.032922 7.0253906 12.794922 L 7.0097656 12.794922 C 6.9847656 12.908922 6.934375 13.079594 6.859375 13.308594 L 5.7363281 15.703125 L 4 15.703125 L 6.0605469 11.996094 L 4.1757812 8.296875 z M 18 11 L 20 11 L 20 13 L 18 13 L 18 11 z M 18 15 L 20 15 L 20 17 L 18 17 L 18 15 z"/>
             </SvgIcon>

    );
};

export const CsvIcon =  props => {
    return (

        <SvgIcon viewBox={"0 0 585.918 585.918"} fontSize={'small'} {...props}>
            <g>
                <path d="M357.396,535.325c0.776,0.052,1.542,0.114,2.329,0.114h177.39c20.75,0,37.627-16.883,37.627-37.627V86.597
		c0-20.743-16.877-37.628-37.627-37.628h-177.39c-0.781,0-1.553,0.07-2.329,0.118V0L11.176,46.206v492.311l346.22,47.401V535.325z
		 M357.396,361.212h57.407v21.511h-57.407V361.212z M357.396,358.524v-21.905h57.407v21.905H357.396z M357.396,333.931v-25.13
		h57.407v25.13H357.396z M357.396,306.113v-28.131h57.407v28.131H357.396z M357.396,275.688V248.89h57.407v26.798H357.396z
		 M357.396,246.591v-26.798h56.262h1.146v26.798H357.396z M359.726,70.476h177.39c8.893,0,16.125,7.236,16.125,16.126v411.22
		c0,8.888-7.232,16.127-16.125,16.127h-177.39c-0.792,0-1.563-0.121-2.329-0.243V385.018h59.706v-0.005h130.669V214.811h-0.117
		v-26.409h-65.848h-2.299h-65.85v0.005h-56.262V70.711C358.162,70.594,358.929,70.476,359.726,70.476z M545.083,219.788v162.532
		h-25.092V227.635h-17.228v154.684H488.41V243.525h-17.228v138.794h-12.642V259.223h-17.229v123.096h-24.21V277.977v-2.294v-26.798
		v-2.299v-26.798h62.4h2.304H545.083z M481.807,217.499v-26.796h63.55v24.108h-0.273v2.688H481.807z M479.508,217.499h-62.405
		h-1.144v-26.796h63.544v26.796H479.508z M98.663,321.536c7.126,0.127,15.097-1.407,19.812-3.327l3.614,19.927
		c-4.399,2.226-14.268,4.462-26.974,4.121c-35.293-0.94-53.019-24.741-53.019-55.626c0-36.998,24.302-58.227,55.329-59.043
		c12.263-0.329,21.67,2.097,25.933,4.328l-4.882,20.282c-4.869-2.092-11.588-3.969-19.969-3.827
		c-18.528,0.309-32.715,12.422-32.715,36.824C65.791,307.152,77.843,321.186,98.663,321.536z M164.763,344.119
		c-12.567-0.341-24.829-4.03-30.909-7.711l4.956-21.087c6.595,3.634,16.809,7.36,27.425,7.537
		c11.556,0.2,17.722-4.683,17.722-12.225c0-7.219-5.321-11.366-18.707-16.394c-18.228-6.667-29.961-17.096-29.961-33.555
		c0-19.32,15.455-34.518,41.512-35.2c12.683-0.339,22.111,2.179,28.903,5.134l-5.771,21.399c-4.569-2.176-12.633-5.315-23.625-5.134
		c-10.884,0.178-16.125,5.364-16.125,11.31c0,7.307,6.212,10.482,20.609,16.042c19.99,7.52,29.523,18.271,29.523,34.784
		C210.307,328.654,195.525,344.938,164.763,344.119z M288.053,345.61l-32.086-0.829l-37.772-118.466l29.103-0.748l14.551,50.26
		c4.128,14.224,7.895,27.957,10.772,42.97l0.548,0.01c3.063-14.436,6.874-28.725,11.058-42.528l15.772-52.055l30.277-0.769
		L288.053,345.61z"/>
            </g>
        </SvgIcon>

    );
};


export const PDFIcon = props =>{
    return (
    <SvgIcon {...props}>
        <g>
            <path d="M39.57,25.259c0.149-0.209,0.207-0.343,0.23-0.415c-0.123-0.065-0.286-0.197-1.175-0.197c-0.505,0-1.139,0.022-1.811,0.108
		c0.815,0.627,1.014,0.944,1.547,0.944C38.594,25.7,39.262,25.69,39.57,25.259z"/>
            <path d="M20.536,32.634c0.149-0.047,1.019-0.444,2.64-3.436c-2.138,1.201-3.008,2.188-3.07,2.744
		C20.095,32.034,20.068,32.276,20.536,32.634z"/>
            <path d="M51.5,39V13.978c0-0.766-0.092-1.333-0.55-1.792L39.313,0.55C38.964,0.201,38.48,0,37.985,0H8.963
		C7.777,0,6.5,0.916,6.5,2.926V39H51.5z M37.5,3.391c0-0.458,0.553-0.687,0.877-0.363l10.095,10.095
		C48.796,13.447,48.567,14,48.109,14H37.5V3.391z M18.432,31.755c0.182-1.628,2.195-3.332,5.985-5.068
		c1.504-3.296,2.935-7.357,3.788-10.75c-0.998-2.172-1.968-4.99-1.261-6.643c0.248-0.579,0.557-1.023,1.134-1.215
		c0.228-0.076,0.804-0.172,1.016-0.172c0.504,0,0.947,0.649,1.261,1.049c0.295,0.376,0.964,1.173-0.373,6.802
		c1.348,2.784,3.258,5.62,5.088,7.562c1.311-0.237,2.439-0.358,3.358-0.358c1.566,0,2.515,0.365,2.902,1.117
		c0.32,0.622,0.189,1.349-0.39,2.16c-0.557,0.779-1.325,1.191-2.22,1.191c-1.216,0-2.632-0.768-4.211-2.285
		c-2.837,0.593-6.151,1.651-8.828,2.822c-0.836,1.774-1.637,3.203-2.383,4.251c-1.023,1.438-1.907,2.107-2.782,2.107h0
		c-0.348,0-0.682-0.113-0.967-0.326C18.506,33.216,18.366,32.347,18.432,31.755z"/>
            <path d="M21.222,45.858c-0.142-0.196-0.34-0.36-0.595-0.492c-0.255-0.132-0.593-0.198-1.012-0.198h-1.23v3.992h1.504
		c0.2,0,0.398-0.034,0.595-0.103c0.196-0.068,0.376-0.18,0.54-0.335c0.164-0.155,0.296-0.371,0.396-0.649
		c0.1-0.278,0.15-0.622,0.15-1.032c0-0.164-0.023-0.354-0.068-0.567C21.456,46.26,21.363,46.055,21.222,45.858z"/>
            <path d="M29.343,18.174c-0.716,2.474-1.659,5.145-2.674,7.564c2.09-0.811,4.362-1.519,6.496-2.02
		C31.815,22.15,30.466,20.192,29.343,18.174z"/>
            <path d="M6.5,41v15c0,1.009,1.22,2,2.463,2h40.074c1.243,0,2.463-0.991,2.463-2V41H6.5z M22.896,48.429
		c-0.173,0.415-0.415,0.764-0.725,1.046c-0.31,0.282-0.684,0.501-1.121,0.656s-0.921,0.232-1.449,0.232h-1.217V54h-1.641V43.924
		h2.898c0.428,0,0.852,0.068,1.271,0.205c0.419,0.137,0.795,0.342,1.128,0.615c0.333,0.273,0.602,0.604,0.807,0.991
		s0.308,0.822,0.308,1.306C23.156,47.552,23.069,48.014,22.896,48.429z M32.952,50.808c-0.178,0.588-0.403,1.08-0.677,1.477
		s-0.581,0.709-0.923,0.937s-0.672,0.398-0.991,0.513c-0.319,0.114-0.611,0.187-0.875,0.219C29.222,53.984,29.026,54,28.898,54
		h-3.814V43.924h3.035c0.848,0,1.593,0.135,2.235,0.403s1.176,0.627,1.6,1.073s0.74,0.955,0.95,1.524
		c0.209,0.569,0.314,1.155,0.314,1.757C33.219,49.511,33.13,50.22,32.952,50.808z M41.9,45.168h-4.635v3.172h4.211v1.121h-4.211V54
		h-1.668V43.924H41.9V45.168z"/>
            <path d="M30.765,46.282c-0.287-0.333-0.677-0.602-1.169-0.807s-1.13-0.308-1.914-0.308h-0.957v7.629h1.627
		c1.112,0,1.914-0.355,2.406-1.066s0.738-1.741,0.738-3.09c0-0.419-0.05-0.834-0.15-1.244
		C31.245,46.986,31.052,46.615,30.765,46.282z"/>
            <path d="M28.736,9.712c-0.098,0.033-1.33,1.757,0.096,3.216C29.781,10.813,28.779,9.698,28.736,9.712z"/>
        </g>

    </SvgIcon>
    )
}
