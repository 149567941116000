import React, {useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import allColumns from "../payrollSheetGrid/columns";
import {DialogActions, Divider, FormControlLabel} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useQueryWithStore} from "react-admin";
import {Typography} from "../../../UIComponents/Wrappers";


const AddOrEditJournal = ({editField,accounts,journalEntries,dialogOpen,handleClose, templates,setJournalEntries}) => {
    const record =editField.accountCode && journalEntries[editField.journalIndex][editField.journalType].find(j=>j.accountCode===editField.accountCode)
    const [filterPlusField,setFilterPlusField] = useState()
    const [filterMinusField,setFilterMinusField] = useState()
    const [account,setAccount] = useState({})
    const [customValue,setCustomValue] = useState({description:"",amount:0})
    // const { data: accounts} = useQueryWithStore({
    //     type: 'getList',
    //     resource: 'accounts',
    //     payload: {filter:{expand:'parentAccount'},pagination:{},sort:{}}
    // });

   // console.log(accounts)


  const [plusColumns,setPlusColumns] = useState([])
    const [minusColumns,setMinusColumns] = useState([])
    useEffect(()=>{
       record ? setPlusColumns(record.plusColumns||[]) : setPlusColumns([])
        record ? setMinusColumns(record.minusColumns||[]) : setMinusColumns([])
        record ? setCustomValue(record.customValues[0]) :  setCustomValue({description:"",amount:0})
        record ? setAccount(accounts.find(a=>a.code===record.accountCode)) : setAccount({})
    }, [record,accounts])
    const [columns,setColumns] = useState(allColumns(templates));
  //  console.log('reocrd',plusColumns)
    const handleColumnChange = (event) => {

        // const index = plusColumns.indexOf(event.target.name)
        // const newCols = plusColumns.slice(0)
       // newCols[index].show= event.target.checked
        if(event.target.checked)
        setPlusColumns([...plusColumns,event.target.name])
        else {
            const x = plusColumns.slice()
            const index = plusColumns.indexOf(event.target.name);
            if (index > -1) {
                x.splice(index, 1);
            }
            setPlusColumns(x)
        }
    };

    const handleMinusColumnChange = (event) => {

        // const index = plusColumns.indexOf(event.target.name)
        // const newCols = plusColumns.slice(0)
        // newCols[index].show= event.target.checked
        if(event.target.checked)
            setMinusColumns([...minusColumns,event.target.name])
        else {
            const x = minusColumns.slice()
            const index = minusColumns.indexOf(event.target.name);
            if (index > -1) {
                x.splice(index, 1);
            }
            setMinusColumns(x)
        }
    };
    const close = () => {
        setFilterPlusField(null);
        setFilterMinusField(null)
        setAccount({})
        setPlusColumns([])
        setMinusColumns([])
        setCustomValue({description:"",amount:0})
        handleClose();
    }
    const setJournals = () => {
        const x = journalEntries.slice(0)

        const y =  x[editField.journalIndex][editField.journalType]
        const index = y.findIndex(j=>j.accountCode===editField.accountCode)
       // console.log(account)
        if(index>-1)
        y[index] = {...y[index],plusColumns,accountName:account.name,minusColumns, accountCode:account.code,customValues:[{...customValue}]}
       else {
           y.push({plusColumns,accountName:account.name, accountCode:account.code,minusColumns,customValues:[{...customValue}]})
        }
        // console.log('x',x)


         setJournalEntries(x)
       // setFilterPlusField(null)
       //  setAccount({})
       //  setPlusColumns([])
       //  setMinusColumns([])
       //  setCustomValue({description:"",amount:0})
       //  handleClose()
        close()
      //  x[editField.journalIndex] =
        // const x = journalEntries.map(j=>{
        //     const index =
        //    const newRoles =   j[editField.journalType].slice(0)
        //     newRoles[index] = {... newRoles[index],value:+ot.target.value,fullValue:+ot.target.value}; // Set the new value
        //     setValues(newRoles)
        //     j[editField.journalType] =
        // })
    }
    return (
        <div>
            <Dialog open={dialogOpen} onClose={close} aria-labelledby="form-dialog-title">

                <DialogTitle id="form-dialog-title">Edit Journal Record</DialogTitle>
                <DialogContent>

                    <Autocomplete
                        id="combo-box-demo"
                        value={account}
                        onChange={(ev,val)=>setAccount(val)}
                        options={accounts}
                        getOptionLabel={(option) => option.name}
                        style={{ width: 200 }}
                        renderInput={(params) => <TextField  style={{ width: 200 }} {...params} label="Select Account"  />}
                    />
                    <Divider/>
                    <Grid container>
                        <Grid item xs={6}>
                            <TextField value={filterPlusField} onChange={ev=>setFilterPlusField(ev.target.value)}/>
                            <FormControl component="fieldset" >
                                <FormLabel component="legend">Add These Fields</FormLabel>
                                <FormGroup>

                                    {
                                        columns.filter(c=>c.format==='{0:n2}'&&(!filterPlusField||filterPlusField.toLowerCase()===c.field.toLowerCase()||c.title.toLowerCase().includes(filterPlusField.toLowerCase()))).map(c=> <FormControlLabel
                                            control={<Checkbox color='primary' checked={plusColumns.includes(c.field)} onChange={handleColumnChange} name={c.field} />}
                                            label={c.title||c.field}
                                        />)
                                    }

                                </FormGroup>
                                <FormHelperText>The Selected fields will be added to the value</FormHelperText>
                            </FormControl>

                        </Grid>
                        <Grid item xs={6}>
                            <TextField value={filterMinusField} onChange={ev=>setFilterMinusField(ev.target.value)}/>
                            <FormControl component="fieldset" >
                                <FormLabel component="legend">Deduct These Fields</FormLabel>
                                <FormGroup>

                                    {
                                        columns.filter(c=>c.format==='{0:n2}'&&(!filterMinusField||filterMinusField.toLowerCase()===c.field.toLowerCase()||c.title.toLowerCase().includes(filterMinusField.toLowerCase()))).map(c=> <FormControlLabel
                                            control={<Checkbox color='primary' checked={minusColumns.includes(c.field)} onChange={handleMinusColumnChange} name={c.field} />}
                                            label={c.title||c.field}
                                        />)
                                    }

                                </FormGroup>
                                <FormHelperText>The selected fields will be deducted from the value</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider/>
                    <div style={{marginTop:30}}>
                        <Typography>
                            You can add  <strong>Custom Values </strong> here
                        </Typography>
                        <Typography size="sm" >
                            (Use - sign to deduct)
                        </Typography>
                        <Grid container>
                            <Grid item xs={6}>
                                <TextField label="Description" value={customValue.description} onChange={ev=>setCustomValue({description: ev.target.value,amount:customValue.amount})} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField type="number" label="Amount" value={customValue.amount} onChange={ev=>setCustomValue({amount: +ev.target.value,description:customValue.description})} />
                            </Grid>
                        </Grid>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={close} color="primary">
                        Cancel
                    </Button>
                    <Button  onClick={setJournals} color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AddOrEditJournal;
