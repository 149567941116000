import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import EditIcon from "@material-ui/icons/Edit";

const options = ['Final', 'Draft'];

export default function EmailSendButton(props) {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleClick = () => {
        // console.info(`You clicked ${options[selectedIndex]}`);
        // eslint-disable-next-line default-case
        props.onSendEmail()
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        switch (options[index]) {
            case "Draft":
                props.onSetDraft(true)
                break
            case "Final":
                props.onSetDraft(false)
                break
        }
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
      <div>
                <ButtonGroup variant="contained" color={selectedIndex===0?"primary":"warning"}  ref={anchorRef} aria-label="split button">

                    <Button disabled={(props.status!=='Approved'&&selectedIndex===0)||(!props.locked&&selectedIndex===1)} onClick={()=> props.onPublish()}>Publish {options[selectedIndex]}</Button>
                    <Button disabled={(props.status!=='Approved'&&selectedIndex===0)||(!props.locked&&selectedIndex===1)} onClick={()=> props.onPublish(true)}>Publish {options[selectedIndex]} Don't Notify</Button>
                    <Button disabled={(props.status!=='Approved'&&selectedIndex===0)||(!props.locked&&selectedIndex===1)} onClick={handleClick}>Direct Send {options[selectedIndex]}</Button>
                    <Button
                        color="primary"
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                    <Button
                        color="primary"
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                         onClick={props.showTemplateEdit}
                    >
                        <EditIcon />
                    </Button>
                </ButtonGroup>


          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        {options.map((option, index) => (
                                            <MenuItem
                                                key={option}
                                                disabled={index === 2}
                                                selected={index === selectedIndex}
                                                onClick={(event) => handleMenuItemClick(event, index)}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
</div>
    );
}
