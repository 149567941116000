import React from 'react';
import {Create, Datagrid, Edit, List, SimpleForm, TextField, TextInput} from 'react-admin';
import exporterCommon from "../import/exporterCommon";
import DefaultListActions, {Empty} from "./common";


export const CostCenterList= props => (
    <List  empty={<Empty />}  exporter={exporterCommon(props.resource)} actions={<DefaultListActions/>} {...props}>
        <Datagrid rowClick="edit">
            {/*<TextField source="id" />*/}
            <TextField source="code" />
            <TextField source="name" />

            <TextField source="description" />
        </Datagrid>
    </List>
);

export const  CostCenterEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="code" />
            <TextInput source="name" />

            <TextInput source="description" />
        </SimpleForm>
    </Edit>
);

export const  CostCenterCreate = props => (
    <Create {...props}  >
        <SimpleForm redirect='list' >
            <TextInput source="code" />
            <TextInput source="name" />

            <TextInput source="description" />
        </SimpleForm>
    </Create>
);
