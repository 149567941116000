import React, {Fragment, useState} from "react";
import {EditXButton} from "./positions";
import {
    BooleanField,
    Button as RAButton,
    Create,
    CreateButton,
    Datagrid,
    Edit,
    FormTab,
    List, NumberField,
    NumberInput,
    ReferenceField,
    ReferenceManyField,
    SaveButton,
    Show,
    SimpleForm,
    Tab,
    TabbedForm,
    TabbedShowLayout,
    TextField,
    TextInput,
    Toolbar,
    translate,
    useNotify,
    useRedirect,
    ImageInput,
    ImageField,
    BooleanInput, DateField, usePermissions, ReferenceInput, required, SelectInput, AutocompleteInput
} from 'react-admin';
import {Link, Route} from "react-router-dom";
import CardActions from "@material-ui/core/CardActions";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import {connect, useDispatch} from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import exporterCommon from "../import/exporterCommon";
import DialogActions from "@material-ui/core/DialogActions";
import {TextField as MUITextField} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add"
import {AddressTab, AddressTabShow} from "./addresses";
import PercentField from "../../UIComponents/PercentField";
import {AddItemButton, EditRedirectButton} from "./common";
import {CopyToClipboard} from "react-copy-to-clipboard";
import CopyIcon from "@material-ui/icons/FileCopy"
import RestoreIcon from "@material-ui/icons/Restore"
import Grid from "@material-ui/core/Grid";
import {red} from "@material-ui/core/colors";
import {scopeResourcesEnum} from "./helperFunctions";
const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});





const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);




export const CompanyList= props => {

    const redirect = useRedirect();

    const handelClose = () => redirect('/companies','/');

    return <React.Fragment>
        <List bulkActionButtons={false}  exporter={exporterCommon(props.resource)} {...props} actions={<CompanyListActions/>}>
            <Datagrid rowClick="edit">
                {/*<TextField source="id"/>*/}
                <TextField source="name"/>
                <BooleanField source="active"/>
            </Datagrid>
        </List>

        <Route
            path="/companies/create">
            {({ match }) => (
                <Drawer  anchor="right" open={!!match} onClose={handelClose}>
                    <CompanyCreate {...props} onCancel={handelClose} />
                </Drawer>
            )}
        </Route>

        <Route path="/companies/:id">
            {({ match }) => {
                const isMatch = match && match.params && match.params.id !== "create";

                return (
                    <Dialog fullWidth maxWidth="lg"  open={isMatch}  onClose={handelClose}>
                        <DialogTitle id="customized-dialog-title" onClose={handelClose}>
                           Edit Company
                        </DialogTitle>
                        <DialogContent dividers>
                        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                        {isMatch ? (
                            <CompanyEdit
                                id={match.params.id}
                                onCancel={handelClose}
                                {...props}
                            />
                        ) : null}
                        </DialogContent>
                    </Dialog>
                );
            }}
        </Route>
    </React.Fragment>
};

const CompanyListActions = ({ basePath }) => (
       <CardActions>
               <CreateButton basePath={basePath} />
          </CardActions>
    );
const CompanyToolbar = translate(({ onCancel, translate, ...props }) => (
       <Toolbar {...props}>

              <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
           <SaveButton />
           </Toolbar>
    ));
export const CompanyCreate = ({ onCancel, ...props }) => (
    <Create title=" " {...props}>
         <SimpleForm redirect='list'  toolbar={<CompanyToolbar onCancel={onCancel} />}>
            <TextInput source="name" />

            {/*<TextInput label="Email Address" type="email" source="address.email" />*/}
         </SimpleForm>
    </Create>
);


const CompanyAsideComp = props => {

    const [open,setOpen] = useState(false)
    const [code,setCode] = useState()
    const [copied,setCopied] = useState()

    const notify = useNotify();
    if(!props.record || props.record.id==='00000000-0000-0000-0000-000000000001')
        return null


    return <div>
        {props.record && props.record.active  &&
        <div>
            <p> The company is Active and ready to use</p>
            <Button size='small'  disabled={props.loading} onClick={()=>setOpen(true)}>Re Activate</Button>
        </div>
      }
        {  props.record && !props.record.active &&
            <div>
                The company is not Licenced. <br/> you have to activate to continue using it. <br/>
                <Button variant="contained" onClick={() => props.onActivate(props.record)} disabled={props.loading}>Activate</Button>
                <Button size='small'  disabled={props.loading} onClick={()=>setOpen(true)}>Manual Activation</Button>
            </div>
       }

       <Dialog open={open}>
           <DialogContent>

               <Typography>
                   Please use the following Request to get <strong>Activation Code</strong> <br/>
                   <p>

                       Request Code: {props.record.id+'$'+btoa(props.record.name)}
                       <CopyToClipboard text={props.record.id+'$'+btoa(props.record.name)}
                                        onCopy={() =>{
                                            notify("Request Code Copied to Clipboard","info")
                                            setCopied(true)}}>
                         <IconButton><CopyIcon/></IconButton>

                       </CopyToClipboard>
                   </p>


               </Typography>


               <MUITextField
                   onChange={ev =>{
                       setCode(ev.target.value)
                      // props.onChange({type: oTType,hrs:ev.target.value})
                   }}
                   value={code}
                   autoFocus
                   multiline
                   label="Activation Code"

               />

           </DialogContent>
           <DialogActions>
               <Button  onClick={()=>setOpen(false)}>Cancel</Button>
               <Button variant="contained" onClick={() => {
                   props.onManualActivate(props.record,code)
                   setOpen(false)

               }} disabled={props.loading}>Activate</Button>

           </DialogActions>
       </Dialog>

    </div>
}

export  function formatPic(value) {
    if (!value ||  typeof value === "string") { // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
        return { src: value };
    } else {  // Else a new image is selected which results in a value object already having a preview link under the url key
        return value;
    }
}

export const CompanyEdit = ({ onCancel, ...props }) => {
  //  console.log('editprops',props);
     const redirect = useRedirect();
    // const permissions= usePermissions()
     if (!props.permissions) return null;


   return <Edit  title=" "  aside={props.permissions.includes('Superuser')&&<CompanyAside {...props}/>} {...props}>
       <TabbedForm  redirect={onCancel?'list':'show'}    toolbar={<CompanyToolbar onCancel={onCancel?onCancel:()=>redirect(`${props.match.url}/show`)} />}>
           <FormTab   label="Basic">
            {/*<TextInput source="name"/>*/}
               <TextInput source="tinNo"/>
               {/*<BooleanInput source="isParentCompany"/>*/}
               {props.permissions.includes('Superuser')&&<ReferenceInput perPage={1000} source="parentCompanyId" reference="companies">
                   {/*<SelectInput  validate={[required()]} optionText="name"  />*/}
                   <SelectInput optionText="name"/>

               </ReferenceInput>}

               <Grid container style={{width:'100%'}}>
                   <TextInput source="enterpriseNumber"/>
                   <TextInput source="taxAccountNumber"/>
                   <TextInput source="pensionAccountNumber"/>

               </Grid>

               <TextInput autoComplete="off" label="Mate Id" source="mateId"/>
               <ImageInput format={formatPic} source="logo" label="Company Logo" accept="image/*" placeholder={<p>Drop your file here</p>}>
                   <ImageField source="src" title="title" />
               </ImageInput>

           </FormTab>
           <AddressTab/>
           {props.permissions.includes('Superuser')&&<FormTab label="Email Settings">

               <Grid container style={{width: "100%"}}>
                   <TextInput label="SMTPServer" source="emailSetting.smtpServer"/>
                   <NumberInput label="Delay Between Emails (in Seconds)" source="emailSetting.delaySeconds"/>
               </Grid>

               <Grid container style={{width: "100%"}}>
                   <NumberInput label="SMTPPort" source="emailSetting.smtpPort"/>
                   <BooleanInput label="Require SSL" source="emailSetting.requireSSL"/>

               </Grid>
               <TextInput label="Name" source="emailSetting.sendingName"/>
               <TextInput label="Email Address" source="emailSetting.sendingEmail"/>
               <TextInput type="password" label="Password" autoComplete="new-password"
                          source="emailSetting.sendingEmailPass"/>
               <TextInput label="Email User" source="emailSetting.sendingUser"/>


           </FormTab>}
       </TabbedForm>
    </Edit>
};

 const CompanyAside = connect(state=>({loading:state.admin.loading}),dispatch=>({
    onActivate: (company)=>dispatch({type:'ACTIVATE_COMPANY', payload:company}),
     onManualActivate: (company,code)=>dispatch({type:'ACTIVATE_COMPANY_MANUAL', payload:{id:company.id,code}})
}))(CompanyAsideComp);

// export const AddNewCompanyBankButton = ({ record, scope }) => (
//     <RAButton
//         component={Link}
//         to={{
//             pathname: "/companyBanks/create",
//             search:`?companyId=${record.id}&&source=1`,
//         }}
//         label="Add Company Bank"
//     >
//         <AddIcon />
//     </RAButton>
// );


export const AddNewResourceButton = ({ record, scope, type, label }) => (
    <RAButton
        component={Link}
        to={{
            pathname: `/${type}/create`,
            search:`?companyId=${record.id}&&source=1`,
        }}
        label={label}
    >
        <AddIcon />
    </RAButton>
);


// export const AddNewCompanySettingButton = ({ record, scope }) => (
//     <RAButton
//         component={Link}
//         to={{
//             pathname: "/companySettings/create",
//             search:`?companyId=${record.id}&&source=1`,
//         }}
//         label="Add Company Setting"
//     >
//         <AddIcon />
//     </RAButton>
// );

export const RefreshCompanySettingButton = ({ record, scope }) => {
    const dispatch = useDispatch();
  return  <RAButton
        onClick={()=>dispatch({type:'COMPANY_SETTINGS_REFRESH',payload:record})}
        label="Get New Payslip Settings"
    >
        <RestoreIcon/>
    </RAButton>
};

export const RefreshCompanyGeneralSettingButton = ({ record, scope }) => {
    const dispatch = useDispatch();
    return  <RAButton
        onClick={()=>dispatch({type:'COMPANY_GENERAL_SETTINGS_REFRESH',payload:record})}
        label="Reset General Settings"
    >
        <RestoreIcon/>
    </RAButton>
};



export const CompanyShow = props => (
    <Show title="Company Settings" {...props}>
        <TabbedShowLayout>
            <Tab label="Basic">
                <ImageField source="logo"  />
                <TextField source="name" />
                <TextField source="tinNo" />
                <Grid container style={{width:'100%'}}>
                    <TextField source="enterpriseNumber"/>
                    <TextField source="taxAccountNumber"/>
                    <TextField source="pensionAccountNumber"/>

                </Grid>

                <BooleanField source="active"/>

                {/*<EditButton/>*/}

            </Tab>
            <AddressTabShow/>
            <Tab label="Additional Earnings" path="earnings">
                <ReferenceManyField
                    addLabel={false}
                    reference="earnings"
                    target="companies"
                    filter={{oFilter:`Scope eq 'Company' or Scope eq 'Template'`}}
                >
                    <Datagrid >
                        <TextField source="name" />
                        <TextField source="scope" />
                        <TextField source="valueType" />
                        <PercentField source="value"/>
                        <EditXButton type="earnings" id={props.id}/>
                    </Datagrid>
                </ReferenceManyField>
                <AddItemButton label={"Add Company wide Earning"} path="/earnings/create" scope={0}/>

                <AddItemButton label={"Add Earning Template"} path="/earnings/create" scope={3}/>

            </Tab>

            <Tab label="Deductions" path="deductions">
                <ReferenceManyField
                    addLabel={false}
                    reference="deductions"
                    target="companies"
                    filter={{oFilter:`Scope eq 'Company' or Scope eq 'Template'`}}

                >
                    <Datagrid >
                        <TextField source="name" />
                        <TextField source="scope" />
                        <TextField source="valueType" />
                        <PercentField source="value"/>
                        <EditXButton type="deductions" scope={0} id={props.id}/>
                    </Datagrid>
                </ReferenceManyField>
                {/*<AddNewDeductionButton scope={0}/>*/}
                <AddItemButton label={"Add Company wide Deduction"} path="/deductions/create" scope={0}/>
                <AddItemButton label={"Add Deduction Template"} path="/deductions/create" scope={3}/>

            </Tab>

            <Tab label="Expenses" path="expenses">
                <ReferenceManyField
                    addLabel={false}
                    reference="expenses"
                    target="companies"
                    filter={{oFilter:`Scope eq 'Company' or Scope eq 'Template'`}}
                >
                    <Datagrid >
                        <TextField source="name" />
                        <TextField source="scope" />
                        <TextField source="valueType" />
                        <PercentField source="value"/>
                        <EditXButton type="expenses" scope={0} id={props.id}/>
                    </Datagrid>
                </ReferenceManyField>
                <AddItemButton label={"Add Company wide Expense"} path="/expenses/create" scope={0}/>
                <AddItemButton label={"Add Expense Template"} path="/expenses/create" scope={3}/>

            </Tab>

            <Tab label="Banks" path="banks">
                <ReferenceManyField
                    addLabel={false}
                    reference="companyBanks"
                    target="companies"
                   >
                    <Datagrid >
                        {/*<TextField source="id" />*/}
                        <ReferenceField source="bankId" reference="banks">
                            <TextField source="name" />
                        </ReferenceField>
                        <TextField source="branch" />
                       <EditRedirectButton id={props.id} type='companyBanks'/>
                    </Datagrid>
                </ReferenceManyField>
                <AddNewResourceButton label="Add Company Bank" type="companyBanks"/>

            </Tab>

            <Tab label="Other Settings" path="settings">
                <ReferenceManyField
                    addLabel={false}
                    reference="companySettings"
                    target="companies"
                     filter={{oFilter:`SettingType ne 'Payslip'`}}

                >
                    <Datagrid >
                        <TextField source="name" />
                        <TextField source="description" />
                        <TextField source="value" />
                        <EditRedirectButton id={props.id} type='companySettings'/>
                    </Datagrid>

                </ReferenceManyField>
                <AddNewResourceButton label="Add new Company Setting" type="companySettings"/>
                <RefreshCompanyGeneralSettingButton/>
            </Tab>

            <Tab label="Payslip Settings" path="reportSettings">
                <ReferenceManyField
                    addLabel={false}
                    reference="companySettings"
                    target="companies"
                    filter={{oFilter:`SettingType eq 'Payslip'`}}

                >
                    <Datagrid >
                        <TextField source="name" />
                        <TextField source="description" />
                        <TextField source="value" />
                        <EditRedirectButton id={props.id} view='reportSettings' type='companySettings'/>
                    </Datagrid>

                </ReferenceManyField>

                <RefreshCompanySettingButton/>

            </Tab>


            <Tab label="Perdiem Rates" path="perdiemRates">
                {/*<Grid container direction='column' style={{width:"100%"}} >*/}
                <ReferenceManyField
                    addLabel={false}
                    reference="perdiemRates"
                    target="companies"
                >
                    <Datagrid>
                    <ReferenceField source="siteId" reference="sites">
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField source="jobLevelId" reference="jobLevels">
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField source="type" />
                    <NumberField source="rate" />
                        <EditRedirectButton id={props.id} type='perdiemRates'/>
                    </Datagrid>
                </ReferenceManyField>
                <AddNewResourceButton label="Add Perdiem Rate" type="perdiemRates"/>
                {/*</Grid>*/}
            </Tab>

            <Tab label="Job Levels" path="jobLevels">
                <ReferenceManyField
                    addLabel={false}
                    reference="jobLevels"
                    target="companies"
                >
                    <Datagrid>


                        <TextField source="name" />

                        <EditRedirectButton id={props.id} type='jobLevels'/>
                    </Datagrid>
                </ReferenceManyField>
                <AddNewResourceButton label="Add Job Level" type="jobLevels"/>
            </Tab>
            <Tab label="Currency Rates" path="currencyRates">
                <ReferenceManyField
                    sort={{field:"Date", order:'desc'}}
                    addLabel={false}
                    reference="currencyRates"
                    target="companies"
                >
                    <Datagrid>


                        <DateField source="date" />
                        <NumberField source="usd" />
                        <NumberField source="eur" />
                        <NumberField source="gbp" />
                        <TextField source="source" />


                        <EditRedirectButton id={props.id} type='currencyRates'/>
                    </Datagrid>
                </ReferenceManyField>
                <AddNewResourceButton label="Add Updated Rate (Company Specific)" type="currencyRates"/>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
