import React, {cloneElement} from "react";
import {
    Button,
    CreateButton,
    DeleteButton,
    ExportButton,
    sanitizeListRestProps,
    SaveButton,
    Toolbar,
    TopToolbar,
} from 'react-admin';
import ImportButton from "../import/importButton";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/AddToQueue"
import {GenerateLeaveButton} from "./leaves";
import {Link} from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit"
import BackButton from "../../UIComponents/backButton";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";


export const Empty = ({ basePath, resource }) => (
    <Box textAlign="center" m={1}>
        <AddIcon color="secondary" style={{ fontSize: 200 }}/>
        <Typography color="textSecondary" variant="h4" paragraph>
            No {resource} yet
        </Typography>
        <Typography color="textSecondary" variant="subtitle1">
            Create one or import from a file
        </Typography>
        <CreateButton basePath={basePath} />
        <ImportButton resource={resource} basePath={basePath} />
    </Box>
);



export const EmptyLeave = ({ basePath, resource }) => (
    <Box textAlign="center" m={1}>
        <AddIcon color="secondary" style={{ fontSize: 200 }}/>
        <Typography color="textSecondary" variant="h4" paragraph>
            No {resource} yet
        </Typography>
        <Typography color="textSecondary" variant="subtitle1">
           Generate Annual leave for employees or create custom leave
        </Typography>
        <GenerateLeaveButton/>
        <CreateButton basePath={basePath} />

    </Box>
);

// const DefaultListActions = ({ basePath,exporter,total,resource,currentSort,filterValues }) => (
//     <CardActions>
//         <CreateButton basePath={basePath} />
//         <ExportButton
//             disabled={total === 0}
//             resource={resource}
//             sort={currentSort}
//             filter={filterValues}
//             exporter={exporter}
//         />
//         <ImportButton resource={resource} basePath={basePath} />
//     </CardActions>
// );







const ListActions = ({
                         currentSort,
                         className,
                         resource,
                         filters,
                         displayedFilters,
                         exporter, // you can hide ExportButton if exporter = (null || false)
                         filterValues,
                         permanentFilter,
                         hasCreate, // you can hide CreateButton if hasCreate = false
                         basePath,
                         selectedIds,
                         onUnselectItems,
                         showFilter,
                         maxResults,
                         total,
                         componentRef,
                         extra,
                         ...rest

                     }) => (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {extra}

        <CreateButton basePath={basePath} />
        <ExportButton
            label="Download"
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />

        {componentRef&&<ReactToPrint
            trigger={() => <Button startIcon={<PrintIcon/>} label="Print/PDF"/>}
            content={() => componentRef.current}
            pageStyle={""}
        />}
        <ImportButton resource={resource} basePath={basePath} />
    </TopToolbar>
);

ListActions.defaultProps = {
    selectedIds: [],
    onUnselectItems: () => null,
};


export const AddItemButton = ({ record, scope, label, path }) => (
    <Button
        component={Link}
        to={{
            pathname: path,
            search:`?scopeId=${record.id}&&source=1&&scope=${scope}`,
            //search: `?source=${JSON.stringify({ scopeId: record.id,scope})}`,
        }}
        label={label}
    >
        <AddIcon />
    </Button>
);

export const RedirectToolbar = props => (
    <Toolbar {...props} >
        <BackButton />
        <SaveButton />
        <DeleteButton redirect={props.redirect}/>

    </Toolbar>
);

export const NoDeleteToolbar = props => (
    <Toolbar {...props} >

        <SaveButton />


    </Toolbar>
);

export const EditRedirectButton = ({type, record, id,view }) => (
    <Button
        component={Link}
        to={{
            pathname: `/${type}/${record.id}`,
            search:`?id=${id}&&source=1${view?`&&view=${view}`:''}`,
            //search: `?source=${JSON.stringify({ scopeId: record.id,scope})}`,
        }}
        label="Edit"
    >
        <EditIcon/>
    </Button>
);

export default ListActions
