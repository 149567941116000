import React, {useEffect, useMemo, useRef,} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Card, createMuiTheme, makeStyles,} from '@material-ui/core';
import {defaultTheme, LoginForm, Notification} from 'ra-ui-materialui';
import {ThemeProvider} from '@material-ui/styles';
import {useHistory} from 'react-router-dom';
import {useCheckAuth} from 'react-admin';
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
// import backImage from "./images/login_back.jpg"
// import headerImage from "./images/login_header.jpg"
import SwitchServerMenu from "./switchServerMenu";

import {connect, useStore} from "react-redux";
import {Typography} from "./Wrappers";

const useStyles = makeStyles(
    (theme) => ({
        main: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            height: '1px',
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
        card: {
            backgroundColor:'#e9e9e9',
            minWidth: 300,
            marginTop: '6em',
        },
        avatar: {
            margin: '1em',
            display: 'flex',
            justifyContent: 'center',
        },
        icon: {
            backgroundColor: theme.palette.secondary[500],
        },
        media: {
            height: 140,
        },
    }),
    { name: 'RaLogin' }
);

/**
 * A standalone login page, to serve as authentication gate to the admin
 *
 * Expects the user to enter a login and a password, which will be checked
 * by the `authProvider.login()` method. Redirects to the root page (/)
 * upon success, otherwse displays an authentication error message.
 *
 * Copy and adapt this component to implement your own login logic
 * (e.g. to authenticate via email or facebook or anything else).
 *
 * @example
 *     import MyLoginPage from './MyLoginPage';
 *     const App = () => (
 *         <Admin loginPage={MyLoginPage} authProvider={authProvider}>
 *             ...
 *        </Admin>
 *     );
 */
const Login =({
            loading,
             theme,
             classes: classesOverride,
             className,
             children,
             staticContext,
             backgroundImage,
             client,
             currentVersion,
             ...rest
         }) => {
    const containerRef = useRef();
    const classes = useStyles({ classes: classesOverride });
    const muiTheme = useMemo(() => createMuiTheme(theme), [theme]);
    let backgroundImageLoaded = false;
    const checkAuth = useCheckAuth();
    const history = useHistory();
    const store = useStore();
    useEffect(() => {
        if (!backgroundImageLoaded) {
            lazyLoadBackgroundImage();
        }
        checkAuth({}, false)
            .then(() => {
                //console.log('authed')
                // already authenticated, redirect to the home page
                history.push('/');
            })
            .catch(() => {

                // not authenticated, stay on the login page
            });
    }, [checkAuth, history]);

    const updateBackgroundImage = () => {
        if (!backgroundImageLoaded && containerRef.current) {
            containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
            backgroundImageLoaded = true;
        }
    };

    // Load background image asynchronously to speed up time to interactive
    const lazyLoadBackgroundImage = () => {
        if (backgroundImage) {
            const img = new Image();
            img.onload = updateBackgroundImage;
            img.src = backgroundImage;
        }
    };

    // useEffect(() => {
    //     if (!backgroundImageLoaded) {
    //         lazyLoadBackgroundImage();
    //     }
    // });
    const clientName = {xork:'truwrk', hst:'HST'}
    const title =`PayDay ${currentVersion} Dama - ${client&&clientName[client]}`;

    return (
        <ThemeProvider theme={muiTheme}>
            <div
                className={classnames(classes.main, className)}
                title='Dama Digital'

                ref={containerRef}
            >
                <Card className={classes.card}>


                    <CardMedia
                        className={classes.media}
                        image={require(`./images/login_header_${process.env.REACT_APP_CLIENT?process.env.REACT_APP_CLIENT:''}.jpg`)}
                        title={title}
                    >
                        {/*<ChangeServerButton/>*/}

                        <SwitchServerMenu client={client}/>



                    </CardMedia>
                    <Typography size='sm'>
                        {`Version ${currentVersion} ${client&&clientName[client]}`}
                    </Typography>
                        <CardContent>

                            {children}

                        </CardContent>



                    {/*<div className={classes.avatar}>*/}
                        {/*<Avatar className={classes.icon}>*/}
                            {/*<LockIcon />*/}
                        {/*</Avatar>*/}
                    {/*</div>*/}

                </Card>
                <Notification />
            </div>
         </ThemeProvider>
    );
};

Login.propTypes = {
    backgroundImage: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    theme: PropTypes.object,
    staticContext: PropTypes.object,
};

Login.defaultProps = {
    backgroundImage: require(`./images/login_back_${process.env.REACT_APP_CLIENT?process.env.REACT_APP_CLIENT:''}.jpg`),
    theme: defaultTheme,
    children: <LoginForm />,
};

export default connect(state=>({loading:state.admin.loading,currentVersion:state.ui.currentVersion,client:state.ui.client}))(Login);
