import React, {useEffect, useState} from "react";
import {Layout} from 'react-admin';
import Menu from './menu';
import Sidebar from './sideBar'
import MyAppBar from "./myAppBar";


// const MyLayout = props => <Layout {...props} sidebar={MySidebar} />
// const MyMenu = (props) => <Menu {...props} />

import { Notification } from 'react-admin';
import {Dialog} from "@material-ui/core";
import ModalProvider, {useModal} from "mui-modal-provider";
import SyncTik from "../components/sync/syncTik";
import SyncTikDialog from "../components/dialogs/syncTikDialog";
import {connect} from "react-redux";
import {useDispatch} from "react-redux";
import SyncTikMatesDialog from "../components/dialogs/syncTikMatesDialog";
const MyNotification = props => {
// const dispatch = useDispatch()

    // useEffect( () =>  {
    //     dispatch({type:'LOAD_TIK_CHANGES'})
    //    // console.log('loading')
    // },[])

    // useEffect( () =>  {
    //     switch (openedDialog)
    //     {
    //         case 'tikDialog':
    //             showModal(SyncTikDialog, { title: 'Simple Dialog' })
    //             break
    //         default:
    //             break
    //     }
    //
    //
    // }, [openedDialog])


    return    <Notification {...props} autoHideDuration={5000} />


};



const MyLayout = ({openedDialog,terminations,newHires, teams, notFoundTerminations, newEmps, inactiveEmps, updatedEmps,...props}) => {

    const { showModal, hideModal } = useModal();
    const [currentModal, setCurrentModal] = useState()
    const dispatch = useDispatch();
    useEffect( () =>  {
       dispatch({type:'LOAD_TIK_MATES_CHANGES'})
        // console.log('loading')
    },[])

    function onAccept (data)  {
        dispatch({type:'ACCEPT_TIK_CHANGES',payload:data})
    }
    function onIgnore (data)  {
        dispatch({type:'IGNORE_TIK_CHANGES',payload:data})
    }
    function onDeactivate (data)  {
        dispatch({type:'ACCEPT_TIK_CHANGES',payload: {...data, setInactive:true}})
    }
    useEffect( () =>  {
        if(currentModal){
            currentModal.hide()
        }
        let modal;

        switch (openedDialog)
        {

            case 'tikDialog':
                modal = showModal(SyncTikDialog, { title: 'Sync Changes with Tik Time Keeper', terminations,newHires, teams, notFoundTerminations,onClose:()=>{dispatch({type:'SET_OPENED_DIALOG', payload: ''})}, onAccept, onIgnore, onDeactivate })
               setCurrentModal(modal)
                break
            case 'tikMatesDialog':
                modal = showModal(SyncTikMatesDialog, { title: 'Sync Changes with Mates HCM and Tik Time Keeper', terminations,newHires, teams, newEmps, inactiveEmps, updatedEmps, notFoundTerminations,onClose:()=>{dispatch({type:'SET_OPENED_DIALOG', payload: ''})}, onAccept:changes=> dispatch({type:'ACCEPT_TIK_MATES_CHANGES',payload:changes}), onIgnore:changes=>dispatch({type:'IGNORE_TIK_MATES_CHANGES', payload:changes}), onDeactivate })
                setCurrentModal(modal)
                break
            default:
              break;
        }


    }, [openedDialog, notFoundTerminations])

    return <Layout {...props} menu={Menu} appBar={MyAppBar} sidebar={Sidebar} notification={connect(state => ({
        openedDialog: state.ui.openedDialog,
    }))(MyNotification)}/>
};

export default connect(state=>({
    openedDialog: state.ui.openedDialog,
    terminations: state.tik.terminations,
    newHires: state.tik.newHires,
    teams: state.tik.teams,
    notFoundTerminations:  state.tik.notFoundTerminations,
    newEmps: state.mates.newEmps,
    inactiveEmps: state.mates.inactiveEmps,
    updatedEmps: state.mates.updatedEmps,
}))(MyLayout);
