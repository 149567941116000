import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from "@material-ui/core/TablePagination";
import Payslip from "./payslip";
import ReactToPrint from "react-to-print";
import Button from "@material-ui/core/Button";
import PrintIcon from "@material-ui/icons/Print"
import RefreshIcon from "@material-ui/icons/Refresh"
import {connect, useSelector} from "react-redux";
import {useQueryWithStore} from 'react-admin';
import EmailSendDialog from "./emailSendDialog";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Divider} from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
});





 function PayslipList(props) {
     const loanTemplates =  useSelector((state) => state.payrollStatus.templateLoans)
    const componentRef = useRef();
    const rows = props.filteredPayrolls
     const refresh = props.refresh
    const [page, setPage] = React.useState(0);
     const [forceSend, setForceSend] = React.useState(false);
    const [dense, setDense] = React.useState(false);
    const [columns, setColumns] = React.useState({})
    const [rowsPerPage, setRowsPerPage] = React.useState(400);
     const [showFooter,setShowFooter] = useState(false);
     useEffect(() => {
         if(props.costCenter===-1) {
             props.onSeparateByCostCenter(0)
         }
     },[props.filteredPayrolls])

     const { data: payslipSettings } = useQueryWithStore({
         type: 'getList',
         resource: 'companySettings',
         payload: {filter:{filter:`SettingType eq 'Payslip'`},pagination:{},sort:{}}
     });

     const { loaded, error} = useQueryWithStore({
             type: 'getList',
             resource: 'payrollReportSettings',
         },{
             onSuccess: (res) =>{

                 //  console.log(res)
                 if(res.data[0])
                 {

                     setShowFooter(!!res.data[0].showFooter)
                     setColumns( Object.fromEntries(res.data[0].columns.map((s) => [s.field, s])))

                     // setColumnsId(res.data[0].id)
                     // if(res.data[0].columns&&res.data[0].columns.length) {
                     //     setColumns(res.data[0].columns)
                     // }
                 }
             }
         }
     );




    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

  //const store = useStore();
  //   const sendEmail = ()=> {
  //      const emailMessages = rows.map(row=>{
  //           // const html = Inky.doctype + renderToString((
  //           //     <Inky>
  //           //         <Inky.Head>
  //           //             <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/foundation-emails/2.2.1/foundation-emails.css" />
  //           //         </Inky.Head>
  //           //         <Inky.Body preview="Preview text">
  //           //             <PayslipEmail companyName={localStorage.getItem('companyName')} payroll={row} payrollDate={props.payrollRun.payrollDate}/>
  //           //         </Inky.Body>
  //           //     </Inky>
  //           // ));
  //          const mjml = payslipToMJML({companyName:localStorage.getItem('companyName'),payroll:row,payrollDate:props.payrollRun.payrollDate,
  //              welcomeTemplate, payslipSettings:payslipSetting, user: {firstName:localStorage.getItem('firstName'),email:localStorage.getItem('userEmail')}, templateData:{name:row.name,fullName:row.fullName,netPay:row.netPay}})
  //        //  console.log(mjml)
  //  if(row.employee.address && row.employee.address.email)
  //          return {
  //              to: [{name:row.fullName,email:row.employee.address.email}],
  //              body: mjml,
  //              subject: `Your Payslip for ${moment(row.payrollDate).format('MMMM, YYYY')}`,
  //              messageType: 'Payslip',
  //              ref: row.id
  //          }
  //
  //       }).filter(e=>e!=null)
  //
  //       dispatch({
  //           type: "SEND_EMAILS",
  //           payload: {messages:emailMessages, payrollRunId:props.payrollRun.id}
  //       })
  //
  //
  //   }

    const handleChangeRowsPerPage = event => {
        if(event.target.value==="All") {
            setRowsPerPage(-1);
        }
        else {
            setRowsPerPage(parseInt(event.target.value, 10));
        }

        setPage(0);
    };

    const handleChangeDense = event => {
        setDense(event.target.checked);
    };

     if(!payslipSettings){
         return null;
     }
     const payslipSetting = payslipSettings && payslipSettings.reduce(function(acc, cur) {
         acc[cur.name] = cur.value==='true';
         return acc;
     }, {});



    return (
        <div >
            <ReactToPrint
                trigger={() => <Button variant="outlined"  color="primary"  startIcon={<PrintIcon/>}  >Print/PDF</Button>}
                content={() => componentRef.current}
                pageStyle={""}
            />


                <EmailSendDialog rowsPerPage={rowsPerPage} page={page} logo={props.logo} forceSend={forceSend} onClose={refresh} payrolls={rows} payslipSetting={payslipSetting} payrollRun={props.payrollRun} />
                {/*<Button disabled={props.payrollRun.status!=='Approved'||props.emailsSent.includes(props.payrollRun.id)} onClick={sendEmail}>Send Email</Button>*/}
            <Button startIcon={<RefreshIcon/>}  onClick={() => window.location.reload(false)}>Refresh</Button>
            <FormControlLabel
                control={
                    <Switch
                        checked={showFooter}
                        onChange={ev=>setShowFooter(ev.target.checked)}
                        name="checkedB"
                        color="primary"
                    />
                }
                label="Show Footer"
            />

            <FormControlLabel
                control={
                    <Switch
                        checked={forceSend}
                        onChange={ev=>setForceSend(ev.target.checked)}
                        name="checkedV"
                        color="primary"
                    />
                }
                label="Send Forcefully"
            />



            {/*<Button onClick={showTemplateEdit}>Edit Template</Button>*/}
            <Table ref={componentRef}  >

                <TableBody>
                    <Table size="small">
                    </Table>
                    {(rowsPerPage > 0
                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rows
                    ).map(row => (
                        <TableRow key={row.id}>
                           <div className="break">
                            <Payslip columns={columns} loanTemplates={loanTemplates} payrollRun={props.payrollRun} showFooter={showFooter} logo={props.logo} payslipSettings={payslipSetting} companyName={localStorage.getItem('companyName')} payroll={row} payrollDate={props.payrollRun.payrollDate}/>
                            <Divider style={{marginBottom:30}}/>
                           </div>
                            {/*<footer></footer>*/}

                        </TableRow>

                    ))}


                </TableBody>
            </Table>

            <TablePagination
                rowsPerPageOptions={[2, 10, 50, 100,400, 600, "All"]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />




            {/*<RawHTML>{html2}</RawHTML>*/}

        </div>
    );
}
const RawHTML = ({children, className = ""}) =>
    <div className={className}
         dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '<br />')}} />

export default connect(state=>({emailsSent:state.payrollStatus.emailsSent, currentUser:state.user.currentUser}))(PayslipList)
