import React, {useState} from 'react';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {useDataProvider, useQueryWithStore} from "react-admin";
import CardHeader from "@material-ui/core/CardHeader";
import MultiDate from "../../UIComponents/multiDate";
import MultiSelectAll from "../../UIComponents/multiSelectAll";
import moment from "moment";
import _ from 'lodash'
const BonusMonths = ({payroll, onChange, extraData}) => {
    // const [month, setMonth] = useState()
    const dataProvider = useDataProvider();
    const {data: approvedRuns } = useQueryWithStore({
        type: 'getList',
        resource: 'approvedPayrollRuns',
        payload: {filter:{expand:'payrollRun'}}
    });
    if(!approvedRuns) {
        return null
    }

    // const payrollRuns = approvedRuns ? approvedRuns.map(a => a.payrollRun): [{}]
    const getMonthData = async (payrollRunIds)=>{
        try {
            const {data} = await dataProvider.getList('payrolls', { filter:{filter:`payrollRunId in (${payrollRunIds.map(x => "'" + x + "'").toString()}) and employeeId eq ${payroll.employeeId}`} })
            // console.log({data})
            return data
            // if(data.length) {
            //     onChange({taxableIncome:data[0].taxableIncome, backpayPayroll:payrollRunId, incomeTax:data[0].incomeTax})
            // }
        } catch (e) {

            console.log("bonusError",e)

        }

    }
    const handleMonthSelected = async (allSelected, lastSelected) => {
           const data =  await getMonthData(allSelected)
           if(data.length) {
               onChange({collection:data.map(d=>({taxableIncome:d.taxableIncome, backpayPayroll:d.payrollRunId, incomeTax:d.incomeTax}))})
           }
    }

    return (
        <FormControl margin='dense' fullWidth  >
            <InputLabel>Back Pay Months</InputLabel>
            {/*<Select*/}
            {/*    value={extraData.backpayPayroll}*/}
            {/*    onChange={ev=> getMonthData(ev.target.value)}*/}
            {/*>*/}
            {/*    {approvedRuns.map(r=><MenuItem key={r.id} value={r.payrollRun.id}><MultiDate gcDate={r.payrollRun.payrollDate} format={'MMMM YYYY'}/></MenuItem>)}*/}
            {/*</Select>*/}
            <MultiSelectAll value={extraData.collection?extraData.collection.map(p=>p.backpayPayroll):[]} onChange={handleMonthSelected} options=       {_.orderBy(approvedRuns,['payrollRun.payrollDate'],['desc']).map(r=>({key:r.id, value:r.payrollRun.id, textLabel:moment(r.payrollRun.payrollDate).format('MMM'), label:<MultiDate gcDate={r.payrollRun.payrollDate} format={'MMMM YYYY'}/>}))} />

        </FormControl>
    );
};

export default BonusMonths;
