import * as React from 'react';
import {
    GridColumnMenuSort,
    GridColumnMenuFilter, GridColumnMenuItemGroup, GridColumnMenuItem, GridColumnMenuItemContent
} from '@progress/kendo-react-grid';
import {filter} from "@progress/kendo-data-query/dist/npm/transducers";
import {Button, IconButton, TextField} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
export class CustomColumnMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: this.props.columns,
            columnsExpanded: false,
            filterExpanded: false,
            showAll: false,
            searchText: '',
            columnOnEdit:null
        };
    }

    onToggleColumn = (field) => {
        this.setState({
            columns: this.state.columns.map(column => {
                return column.field === field ? { ...column, show: !column.show } : column;
            })
        });
    }

    setOnEdit = id => {
        this.setState({
            columnOnEdit: id
        })
    }

    onReset = (event) => {
        event.preventDefault();
       // const allColumns =
       // console.log(this.props.initColumns)

        this.setState({ columns: [...this.props.initColumns] }, () => this.onSubmit());
    }

    onShowAllToggle = () => {
     //   event.preventDefault();
        const allColumns = this.props.columns.map(col => {
            return {
                ...col,
                show: !this.state.showAll
            };
        });

        this.setState({ columns: allColumns,showAll:!this.state.showAll });
    }

    onSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }
        this.props.onColumnsSubmit(this.state.columns);
        if (this.props.onCloseMenu) {
            this.props.onCloseMenu();
        }
    }
    setColumnTitle(field,title) {
        this.setState({
            columns: this.state.columns.map((column) => {
                return column.field === field ? { ...column, title: title } : column;
            })
        });
    }

    onMenuItemClick = () => {
        const value = !this.state.columnsExpanded;
        this.setState({
            columnsExpanded: value,
            filterExpanded: value ? false : this.state.filterExpanded
        });
    }

    onFilterExpandChange = (value) => {
        this.setState({
            filterExpanded: value,
            columnsExpanded: value ? false : this.state.columnsExpanded
        });
    }

    onSearch = e => {
        this.setState({
            searchText: e.target.value
        })
    }

    render() {
        const oneVisibleColumn = this.state.columns.filter(c => c.show).length === 1;

        return (
            <div  >
                <GridColumnMenuSort {...this.props} />
                {this.props.filterOn&&<GridColumnMenuFilter
                    {...this.props}
                    onExpandChange={this.onFilterExpandChange}
                    expanded={this.state.filterExpanded}
                />}
                <GridColumnMenuItemGroup >
                    <GridColumnMenuItem
                        title={'Columns'}
                        iconClass={'k-i-columns'}
                        onClick={this.onMenuItemClick}
                    />
                    <GridColumnMenuItemContent  show={this.state.columnsExpanded}>
                        <div  className={'k-column-list-wrapper'}>
                            <form onSubmit={this.onSubmit} onReset={this.onReset}>
                                <TextField   placeholder="Search Columns" value={this.state.searchText} onChange={this.onSearch}/>
                                <div className={'k-column-list'}>
                                    <div key={-1} className={'k-column-list-item'}>
                                           <span>
                                                    <input
                                                        id={`column-visiblity-show--1`}
                                                        className="k-checkbox"
                                                        type="checkbox"
                                                        // readOnly={true}
                                                        // disabled={column.show && oneVisibleColumn}
                                                         checked={this.state.showAll}
                                                        onClick={() => { this.onShowAllToggle(); }}
                                                    />
                                                    <label
                                                        htmlFor={`column-visiblity-show--1`}
                                                        className="k-checkbox-label"
                                                        style={{ userSelect: 'none' }}
                                                    >
                                                        Show All
                                                    </label>
                                                </span>
                                    </div>

                                    {this.state.columns.filter(c=>!this.state.searchText||c.title.toLowerCase().includes(this.state.searchText.toLowerCase())).map((column) =>
                                        (
                                            <div key={column.field} className={'k-column-list-item'}>
                                                <span>
                                                    <input
                                                        id={`column-visiblity-show-${column.field}`}
                                                        className="k-checkbox"
                                                        type="checkbox"
                                                        readOnly={true}
                                                        disabled={column.show && oneVisibleColumn}
                                                        checked={column.show}
                                                        onClick={() => { this.onToggleColumn(column.field); }}
                                                    />
                                                    { this.state.columnOnEdit===column.field?
                                                        <TextField
                                                        value={column.title}
                                                        onChange={(e)=>this.setColumnTitle(column.field,e.target.value)}
                                                    />: <label
                                                        htmlFor={`column-visiblity-show-${column.field}`}
                                                        className="k-checkbox-label"
                                                        style={{ userSelect: 'none' }}
                                                    >
                                                        {column.title}
                                                    </label>

                                                    }



                                                    <IconButton onClick={()=>this.setOnEdit(column.field)} size="small"><EditIcon/></IconButton>
                                                </span>
                                            </div>
                                        )
                                    )}
                                </div>
                                <div className={'k-columnmenu-actions'}>
                                    <button type={'reset'} className={'k-button'}>Reset</button>
                                    <button className={'k-button k-primary'}>OK</button>
                                </div>
                            </form>
                        </div>
                    </GridColumnMenuItemContent>
                </GridColumnMenuItemGroup>
            </div>
        );
    }
}
