import React, {useEffect, useMemo, useRef, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CaptionedField from "../../../UIComponents/captionedField";
import ReportHeader from "../reportHeader";
import ReactToPrint from "react-to-print";
import Button from "@material-ui/core/Button";
import PrintIcon from "@material-ui/icons/Print";
import _ from 'lodash'
import moment from 'moment'
import {Divider} from "@material-ui/core";
import {TextField, useDataProvider, useQueryWithStore,DateField,NumberField,  FunctionField } from "react-admin";
import MyDatagrid from "../../accountsGrid";
import MultiCurrencyField from "../../../UIComponents/multiCurrencyField";
import MultiCurrency from "../../multiCurrency";
import {FormattedNumber} from "react-intl";
import RowNumberField from "../../../UIComponents/RowNumberField";

const PayrollSummeryReport = ({payrollRun,logo,site,employmentType,department, filteredPayrolls, emps, sort,handelSort}) => {
    const componentRef = useRef();
    const dataProvider = useDataProvider();
    const [payroll1, setPayroll1] = useState();
    // const [variance, setVariance] = useState();
    const variance = useMemo(() => getVariance(filteredPayrolls,payroll1&&payroll1.payrolls), [filteredPayrolls,payroll1]);
    const absentDeductions = useMemo(() =>variance&&_.orderBy(variance.salaryChange.filter(e=>e.diffBasicAbsentDeduction!==0||e.basicAbsentDeduction!==0),['basicAbsentDeduction'],['desc']).map((e,index)=>({...e,sn:index+1})),[variance])
    const overtimes = useMemo(() =>variance&&_.orderBy(variance.salaryChange.filter(e=>e.diffOT!==0||e.overtimeTotal!==0),['overtimeTotal'],['desc']).map((e,index)=>({...e,sn:index+1})),[variance])
    const newHires = useMemo(() =>variance&&_.orderBy(variance.newEmps,['fullBasicSalary'],['desc']).map((e,index)=>({...e,sn:index+1})),[variance])

    const currentOvertimes = useMemo(() => _.orderBy(filteredPayrolls.filter(p=>p.overtimeTotal),['overtimeTotal'],['desc']).map((p,index) => {

            return {
                ...p,
                sn: index+1,
            }

    }), [filteredPayrolls]);

    function getTotals(payrolls) {
       return payrolls&&payrolls.length&&{
           basicSalary:_.sumBy(payrolls,'basicSalary'),
           additionalFixedEarnings:_.sumBy(payrolls,'additionalFixedEarnings'),
           bonusesTotal:_.sumBy(payrolls,'bonusesTotal'),
           overtimeTotal:_.sumBy(payrolls,'overtimeTotal'),

       }
   }

    const totalPayroll = useMemo(() => getTotals(filteredPayrolls), [filteredPayrolls]);
    const totalPrevPayroll = useMemo(() => getTotals(payroll1&&payroll1.payrolls), [payroll1]);

    function getVariance(p1,p2) {
       // console.log('getting v', p1,p2)
        if(p1&&p1.length&&p2&&p2.length) {
            const p1EmpIds = p1.map(p=>p.employeeId)
            const p2EmpIds = p2.map(p=>p.employeeId)
            const newEmps = p1.filter(p=>p.employeeId&&!p2EmpIds.includes(p.employeeId))
            const endEmps = p2.filter(p=>p.employeeId&&!p1EmpIds.includes(p.employeeId))
            const thisMonthEndEmps = p1.filter(p=>p.employee&&p.employee.contractEndDate&&moment(p.employee.contractEndDate)>=moment(payrollRun.periodStart)&&moment(p.employee.contractEndDate)<=moment(payrollRun.periodEnd))
            const p2LookUp = {}
            for(let p of p2) {
                p2LookUp[p.employeeId] =p
            }
            // console.log({p2LookUp})
            const   salaryChange = p1.map(p=>{
                const px = p2LookUp[p.employeeId]

                if(px && px.cash!==p.cash) {
                    p.prevPayroll = px
                    p.diffFullBasic = p.fullBasicSalary-px.fullBasicSalary
                    p.diffOT = p.overtimeTotal - px.overtimeTotal
                    p.diffAdditionalEarnings = p.additionalFixedEarnings - px.additionalFixedEarnings
                    p.diffBasic = p.basicSalary-px.basicSalary -(p.fullBasicSalary-px.fullBasicSalary)
                    p.diffBasicAbsentDeduction = p.basicAbsentDeduction - px.basicAbsentDeduction
                }
                return p;
            }).filter(p=>p.prevPayroll)




            return {
                newEmps,
                endEmps,
                thisMonthEndEmps,
                salaryChange
            }
        }
        return null
    }

    const loadPayrollRun = (id)=> {
        // const { data } = useQueryWithStore({
        //     type: 'getOne',
        //     resource: 'payrollRuns',
        //     payload: {id,expand:'payrolls'}
        // });
        dataProvider.getOne('payrollRuns', { id,expand:'payrolls' })
            .then(({ data }) => {

                    data.payrolls.map(p=>{
                        p.employee = (emps && emps.find(e=>e.id === p.employeeId)) || {}
                    })

                    setPayroll1(data)
                    // setVariance(getVariance(filteredPayrolls,data.payrolls))


            })

    };
    // const getNewHires = (payrolls) => {
    //     return  payrolls.filter(p=>p.employee.startDate)
    // }
    const { loaded, error, data: payrollRuns } = useQueryWithStore({
        type: 'getList',
        resource: 'payrollRuns',
        payload: {filter:{status:"Approved"},pagination:{},sort:{field: 'payrollDate', order: 'DESC'}}
    });

    useEffect(() => {
        // const filteredRuns = payrollRuns? payrollRuns.filter(p=>!statusFilter||p.status===statusFilter) : []
        if(payrollRuns&&payrollRuns.length) {
            loadPayrollRun(payrollRuns[0].id)
        }

    }, [payrollRuns]);



    return (
        <div>
            <ReactToPrint
                //onBeforePrint={()=>setScrollable("none")}

                trigger={() => <Button  variant="outlined"  color="primary"  startIcon={<PrintIcon/>}  >Print</Button>}
                content={() => componentRef.current}
                pageStyle={""}
            />
            <div ref={componentRef}>
            <ReportHeader logo={logo} site={site} employmentType={employmentType} department={department} payrollRun={payrollRun} title="Payroll Summery Report"/>
                <CaptionedField value={payrollRun.cash} label='Net Pay' />
                <Divider/>
            <Grid container spacing={10}>
                <Grid item>
                    <Grid container direction="column">

                            <Grid item>
                                Earning
                            </Grid>

                        <Grid item>
                            <CaptionedField value={totalPayroll.basicSalary} label='Basic Salary'/>
                            {variance&&<ul>
                                <li>{payroll1&&moment(payroll1.payrollDate).format('MMMM YYYY')} Basic Salary  <strong><FormattedNumber maximumFractionDigits={2}   value= {totalPrevPayroll.basicSalary}/></strong></li>
                                <li><strong>{variance.newEmps.length} New </strong> Hires <span style={{fontSize:'smaller'}}>*Full Salary <FormattedNumber maximumFractionDigits={2}   value= {_.sumBy(variance.newEmps,'fullBasicSalary')}/></span>  This month salary  +<FormattedNumber maximumFractionDigits={2}   value= {_.sumBy(variance.newEmps,'basicSalary')}/>    </li>
                                <li><strong>{variance.endEmps.length} End </strong> Employments -<FormattedNumber maximumFractionDigits={2}   value= {_.sumBy(variance.endEmps,'basicSalary')}/> </li>
                                <li>{variance.salaryChange.filter(e=>e.diffFullBasic!==0).length} Salary Adjustment +<FormattedNumber maximumFractionDigits={2}   value= {_.sumBy(variance.salaryChange,'diffFullBasic')}/> </li>
                                <li>{variance.salaryChange.filter(e=>e.diffBasic!==0).length} Salary Prorate Change +<FormattedNumber maximumFractionDigits={2}   value= {_.sumBy(variance.salaryChange,'diffBasic')}/> </li>
                            </ul>}
                        </Grid>
                        <Grid item>
                            <CaptionedField value={totalPayroll.additionalFixedEarnings} label='Allowances' />
                        </Grid>
                        <Grid item>
                            <CaptionedField value={totalPayroll.bonusesTotal} label='This Month Extra Earnings' />
                        </Grid>
                        <Grid item>
                            <CaptionedField value={totalPayroll.overtimeTotal} label='Total Overtime' />
                        </Grid>
                          <Divider/>



                    </Grid>
               </Grid>
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                            Deduction
                        </Grid>
                        <Grid item>
                            <CaptionedField value={_.sumBy(filteredPayrolls,'empPension')} label='Pension Employee' />
                        </Grid>
                        <Grid item>
                            <CaptionedField value={_.sumBy(filteredPayrolls,'costSharing')} label='Cost Sharing' />
                        </Grid>

                        <Grid item>
                            <CaptionedField value={_.sumBy(filteredPayrolls,'fixedDeductionsTotals')-_.sumBy(filteredPayrolls,'empPension')-_.sumBy(filteredPayrolls,'costSharing')} label='Other Fixed Payroll Deductions' />
                        </Grid>
                        <Grid item>
                        <CaptionedField value={_.sumBy(filteredPayrolls,'absenceDeduction')} label='Absence Deductions' />
                        </Grid>
                        <Grid item>
                            <CaptionedField value={_.sumBy(filteredPayrolls,'payrollDeductionTotal')} label='This Month Payroll Deductions' />
                        </Grid>
                        <Grid item>
                            <CaptionedField value={_.sumBy(filteredPayrolls,'incomeTax')} label='Income Tax' />
                        </Grid>

                        {/*<Grid item>*/}
                        {/*    <CaptionedField value={_.sumBy(filteredPayrolls,'companyPension')} label='Pension Company' />*/}
                        {/*</Grid>*/}
                        {/*<Grid item>*/}
                        {/*    <CaptionedField value={_.sumBy(filteredPayrolls,'totalPension')} label='Total Pension' />*/}
                        {/*</Grid>*/}
                    </Grid>
                </Grid>


            </Grid>
                <Grid container spacing={10}>
                    <Grid item>
                        <CaptionedField value={_.sumBy(filteredPayrolls,'totalIncome')} label='Total Employment Income' />
                    </Grid>
                    <Grid item>
                        <CaptionedField value={_.sumBy(filteredPayrolls,'totalDeduction')} label='Total Deduction' />
                    </Grid>
                    <Grid item>
                        <CaptionedField value={_.sumBy(filteredPayrolls,'netPay')} label='Net' />
                    </Grid>
                    <Grid item>
                        <CaptionedField value={_.sumBy(filteredPayrolls,'payrollDeductionTotalCash')} label='Settlements' />
                    </Grid>
                    <Grid item>
                        <CaptionedField value={_.sumBy(filteredPayrolls,'cash')} label='Net Pay' />
                    </Grid>
                    <Grid item></Grid>
                </Grid>
                {payroll1&&payroll1.id&&<div>
                    {moment(payroll1.payrollDate).format('MMMM YYYY')}
                    <Grid container spacing={10}>
                        <Grid item>
                            <CaptionedField value={_.sumBy(payroll1.payrolls,'totalIncome')} label='Total Employment Income' />
                        </Grid>
                        <Grid item>
                            <CaptionedField value={_.sumBy(payroll1.payrolls,'totalDeduction')} label='Total Deduction' />
                        </Grid>
                        <Grid item>
                            <CaptionedField value={_.sumBy(payroll1.payrolls,'netPay')} label='Net' />
                        </Grid>
                        <Grid item>
                            <CaptionedField value={_.sumBy(payroll1.payrolls,'payrollDeductionTotalCash')} label='Settlements' />
                        </Grid>
                        <Grid item>
                            <CaptionedField value={_.sumBy(payroll1.payrolls,'cash')} label='Net Pay' />
                        </Grid>
                        <Grid item></Grid>
                    </Grid>
                    {variance&& <div>
                        {/*{variance.newEmps.map(p=><div>*/}
                        {/*    {p.fullName} {p.employee&&p.employee.startDate}*/}
                        {/*</div>)}*/}
                        <div className="page-break"></div>
                        <Grid container spacing={10}>
                            <Grid item>

                                <Typography variant="h5">New Hires</Typography>
                                <MyDatagrid fullHeight checkField={false}
                                            summeryFields={['fullBasicSalary', 'basicSalary', 'cash']} data={newHires}
                                            ids={newHires.map((e, index) => index)}
                                            currentSort={{field: 'fullBasicSalary', order: 'DESC'}}
                                            setSort={handelSort}>
                                    <NumberField source="sn"/>
                                    <TextField source="fullName"/>
                                    <MultiCurrencyField source={"fullBasicSalary"}/>
                                    <DateField label={"Starting From"} source={"employee.startDate"}/>
                                    <MultiCurrencyField label={"This Month Salary"} source={"basicSalary"}/>
                                    <MultiCurrencyField label={"Net Pay"} source={"cash"}/>
                                </MyDatagrid>


                            </Grid>

                        </Grid>
                        <div className="page-break"></div>
                        <Grid container spacing={10}>

                            <Grid item>
                                <Typography variant="h5">Ended
                                    Employments {moment(payroll1.payrollDate).format('MMMM YYYY')}</Typography>
                                <MyDatagrid fullHeight checkField={false}
                                            summeryFields={['fullBasicSalary', 'basicSalary', 'cash']}
                                            data={variance.endEmps.map((e, index) => ({...e, sn: index + 1}))}
                                            ids={variance.endEmps.map((e, index) => index)} currentSort={sort}
                                            setSort={handelSort}>
                                    <NumberField source="sn"/>
                                    <TextField source="fullName"/>
                                    <DateField label={"Ended On"} source={"employee.contractEndDate"}/>
                                    <MultiCurrencyField source={"basicSalary"}/>
                                    <MultiCurrencyField label={"Net Pay"} source={"cash"}/>
                                </MyDatagrid>
                            </Grid>
                            <Grid item>
                                <Typography variant="h5">Ended
                                    Employments {moment(payrollRun.payrollDate).format('MMMM YYYY')}</Typography>
                                <MyDatagrid fullHeight checkField={false}
                                            summeryFields={['fullBasicSalary', 'basicSalary', 'cash']}
                                            data={variance.thisMonthEndEmps.map((e, index) => ({...e, sn: index + 1}))}
                                            ids={variance.thisMonthEndEmps.map((e, index) => index)} currentSort={sort}
                                            setSort={handelSort}>
                                    <NumberField source="sn"/>
                                    <TextField source="fullName"/>
                                    <DateField label={"Ended On"} source={"employee.contractEndDate"}/>
                                    <MultiCurrencyField label={"Net Pay"} source={"cash"}/>
                                </MyDatagrid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={10}>
                            <div className="page-break"></div>
                            <Grid item>
                                <Typography variant="h5">Salary Adjustment</Typography>

                                <MyDatagrid fullHeight checkField={false}
                                            summeryFields={['fullBasicSalary', 'diffFullBasic']}
                                            data={variance.salaryChange.filter(e => e.diffFullBasic !== 0).map((e, index) => ({
                                                ...e,
                                                sn: index + 1
                                            }))}
                                            ids={variance.salaryChange.filter(e => e.diffFullBasic !== 0).map((e, index) => index)}
                                            currentSort={sort} setSort={handelSort}>
                                    <NumberField source="sn"/>
                                    <TextField source="fullName"/>
                                    <MultiCurrencyField label={"Prev Salary"} source={"prevPayroll.fullBasicSalary"}/>
                                    <MultiCurrencyField label={"fullBasicSalary"} source={"fullBasicSalary"}/>
                                    <MultiCurrencyField label={"Basic Salary Change"} source={"diffFullBasic"}/>
                                </MyDatagrid>
                            </Grid>
                        </Grid>
                        <div className="page-break"></div>
                        <Grid container spacing={10}>

                            <Grid item>
                                <Typography variant="h5">Overtime</Typography>
                                <MyDatagrid fullHeight checkField={false} summeryFields={['overtimeTotal']}
                                            data={currentOvertimes} ids={currentOvertimes.map((e, index) => index)}
                                            currentSort={{field: 'overtimeTotal', order: 'DESC'}} setSort={handelSort}>

                                    <NumberField source="sn"/>
                                    <TextField source="fullName"/>
                                    <MultiCurrencyField source={"basicSalary"}/>
                                    <NumberField source="otNormal"/>
                                    <NumberField source="otNight"/>
                                    <NumberField source="otRest"/>
                                    <NumberField source="otHollyDay"/>
                                    <NumberField source="otOther"/>
                                    <MultiCurrencyField label={"Overtime"} source={"overtimeTotal"}/>
                                </MyDatagrid>
                            </Grid>
                        </Grid>

                            <div className="page-break"></div>
                            <Grid container spacing={10}>


                                <Grid item>
                                    <Typography variant="h5">Overtime Change</Typography>
                                    <MyDatagrid fullHeight checkField={false}
                                                summeryFields={['overtimeTotal', 'diffOT', 'prevPayroll.overtimeTotal']}
                                                data={overtimes} ids={overtimes.map((e, index) => index)}
                                                currentSort={{field: 'overtimeTotal', order: 'DESC'}}
                                                setSort={handelSort}>
                                        <NumberField source="sn"/>
                                        <TextField source="fullName"/>
                                        <MultiCurrencyField label={"Prev Overtime"}
                                                            source={"prevPayroll.overtimeTotal"}/>
                                        <MultiCurrencyField label={"Overtime"} source={"overtimeTotal"}/>
                                        <MultiCurrencyField label={"Overtime Change"} source={"diffOT"}/>
                                    </MyDatagrid>
                                </Grid>

                                <Grid item>
                                    <Typography variant="h5">Allowance Change</Typography>
                                    <MyDatagrid fullHeight checkField={false}
                                                summeryFields={['overtimeTotal', 'diffAdditionalEarnings', 'prevPayroll.overtimeTotal']}
                                                data={variance.salaryChange.filter(e => e.diffAdditionalEarnings !== 0).map((e, index) => ({
                                                    ...e,
                                                    sn: index + 1
                                                }))}
                                                ids={variance.salaryChange.filter(e => e.diffAdditionalEarnings !== 0).map((e, index) => index)}
                                                currentSort={sort} setSort={handelSort}>
                                        <NumberField source="sn"/>
                                        <TextField source="fullName"/>
                                        <MultiCurrencyField label={"Prev Allowance"}
                                                            source={"prevPayroll.additionalFixedEarnings"}/>
                                        <MultiCurrencyField label={"Allowance"} source={"additionalFixedEarnings"}/>
                                        <MultiCurrencyField label={"Allowance Change"}
                                                            source={"diffAdditionalEarnings"}/>
                                    </MyDatagrid>
                                </Grid>
                            </Grid>
                            <div className="page-break"></div>
                            <Grid container spacing={10}>

                                <Grid item>
                                    <Typography variant="h5">Absent Deduction</Typography>
                                    <MyDatagrid fullHeight checkField={false} summeryFields={['basicAbsentDeduction']}
                                                data={absentDeductions} ids={absentDeductions.map((i, index) => index)}
                                                currentSort={{field: 'basicAbsentDeduction', order: 'DESC'}}
                                                setSort={handelSort}>
                                        <NumberField source="sn"/>
                                        <TextField source="fullName"/>
                                        <MultiCurrencyField label={"Full Basic Salary"} source={"fullBasicSalary"}/>
                                        <NumberField source="absentDays"/>
                                        <FunctionField style={{width: '100px'}} label="Penalty Hours/Expected Hours"
                                                       render={record => <span><FormattedNumber
                                                           maximumFractionDigits={1}
                                                           value={record.monthlyHours - record.hoursWorked}/>/<FormattedNumber
                                                           maximumFractionDigits={1}
                                                           value={record.monthlyHours}/></span>}/>

                                        <MultiCurrencyField label={"Prev Absent Deduction"}
                                                            source={"prevPayroll.basicAbsentDeduction"}/>
                                        <MultiCurrencyField label={"Absent Deduction"} source={"basicAbsentDeduction"}/>
                                        {/*<MultiCurrencyField label={"Absent Deduction"}  source={"diffBasic"}/>*/}
                                    </MyDatagrid>
                                </Grid>
                            </Grid>
                            <div className="page-break"></div>
                            <Grid container spacing={10}>
                                <Grid item>
                                    <Typography variant="h5">Prorated Salary Change</Typography>
                                    <MyDatagrid fullHeight checkField={false}
                                                summeryFields={['basicSalary', 'diffBasic']}
                                                data={variance.salaryChange.filter(e => e.diffBasic !== 0).map((e, index) => ({
                                                    ...e,
                                                    sn: index + 1
                                                }))}
                                                ids={variance.salaryChange.filter(e => e.diffBasic !== 0).map((e, index) => index)}
                                                currentSort={sort} setSort={handelSort}>
                                        <NumberField source="sn"/>
                                        <TextField source="fullName"/>
                                        <MultiCurrencyField label={"Prev Salary"} source={"prevPayroll.basicSalary"}/>
                                        <MultiCurrencyField label={"basicSalary"} source={"basicSalary"}/>
                                        <MultiCurrencyField label={"Basic Salary Change"} source={"diffBasic"}/>
                                    </MyDatagrid>
                                </Grid>

                            </Grid>


                    </div>}
                </div>}
            </div>
        </div>
);
};

export default PayrollSummeryReport;
