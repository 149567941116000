import React from 'react';
import {
    Create,
    Datagrid,
    Edit,
    List, NumberField,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    NumberInput
} from 'react-admin';
import exporterCommon from "../import/exporterCommon";
import {parse} from "query-string";
import {RedirectToolbar} from "./common";

export const PerdiemRateList= props => (
    <List exporter={exporterCommon(props.resource)}  {...props}>
        <Datagrid rowClick="edit">
            <ReferenceField source="siteId" reference="sites">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="jobLevelId" reference="JobLevels">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="type" />
            <NumberField source="rate" />
        </Datagrid>
    </List>
);

export const PerdiemRateEdit = props => {
    const { id } =parse(props.location.search);
    const redirect = id ? `/companies/${id}/show/perdiemRates` : "list";
    return <Edit  {...props}>
        <SimpleForm redirect={redirect} toolbar={<RedirectToolbar/>}>
            {/*<ReferenceInput label="JobLevel" source="jobLevelId" reference="jobLevels" >*/}
            {/*    <SelectInput optionText="name"/>*/}
            {/*</ReferenceInput>*/}
            {/*<ReferenceInput label="Site" source="siteId" reference="sites" >*/}
            {/*    <SelectInput optionText="name"/>*/}
            {/*</ReferenceInput>*/}
            {/*<TextInput source="type"/>*/}
            <NumberInput source="rate"/>
        </SimpleForm>
    </Edit>
};

export const PerdiemRateCreate = props => {
    const { companyId} =parse(props.location.search);
    const redirect = companyId ? `/companies/${companyId}/show/perdiemRates` : "show";
    return <Create {...props}  >
        <SimpleForm redirect={redirect}>
            <ReferenceInput label="JobLevel" source="jobLevelId" reference="jobLevels" >
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <ReferenceInput label="Site" source="siteId" reference="sites" >
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <TextInput source="type"/>
            <NumberInput source="rate"/>
        </SimpleForm>
    </Create>
};

export const PerdiemRateShow = props => (
    <Create {...props}  >
        <SimpleForm redirect='list' >
            <ReferenceField source="siteId" reference="sites">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="jobLevelId" reference="JobLevels">
            <TextField source="name" />
            </ReferenceField>
            <TextField source="type" />
            <NumberField source="rate" />
        </SimpleForm>

    </Create>
);
