import React, {useEffect, useRef, useState} from 'react';
import {DateField, NumberField, TextField} from 'react-admin'
import Button from "@material-ui/core/Button";
import moment from "moment";
import PrintIcon from "@material-ui/icons/Print"
import ReactToPrint from "react-to-print";
import Grid from "@material-ui/core/Grid";
import FdreLogo from "../../UIComponents/images/fdre_logo.png"
import ErcaLogo from "../../UIComponents/images/erca_logo.jpg"
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core";
import Gridb from "../../UIComponents/gridb";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {FormattedNumber} from "react-intl";

import MyDatagrid from "../accountsGrid";
import ExportCSVButton from "./exportCSVButton";
import MultiDate from "../../UIComponents/multiDate";
import TableFooter from "@material-ui/core/TableFooter";
import IncomeTaxExcelExport from "./incometaxExcelExport";
import BankListExcelExport from "./bankListExcelExport";
import CsvDownloader from "react-csv-downloader";
import {CsvIcon} from "../../UIComponents/icons";
import {green} from "@material-ui/core/colors";
import Tooltip from "@material-ui/core/Tooltip";
import allColumns from "./payrollSheetGrid/columns";

const useStyles = makeStyles( theme=> ({
    box: {
        border:true,
        borderColor:"#000",
        borderWidth:2,
        borderStyle:"solid",
        fontSize: "large"
        // backgroundColor: theme.palette.primary.main,
        //height: '100%'
    },
    icon:{
        color:'#FFF'
    },
    root: {
        color:'#FFF'
    },


}));

const columns = [
    {
        title: 'ሀ) ተ.ቁ',
        field: 'sn',
        show: true,
        width:80
    }, {
        title: 'ለ) የሠራተኛው ግብር ከፋይ ቁጥር (TIN)',
        field: 'tinNo',
        show: true,
        width:100
    },
    {
        title: 'ሐ) የሠራተኛው ስም የአባት ስምና የአያት ስም',
        field: 'fullName',
        show: true,
        width:120
    },
    {
        title: 'መ) የተቀጠሩበት ቀን',
        field: 'formatedStartDate',
        show: true,
        type: "Date",
        width:100
    },
    {
        title: 'ሠ) ደምወዝ',
        field: 'earnedSalary',
        show: true,
        width:80,
        type:"Number"
    },

    {
        title: 'ረ) ጠቅላላ የትራንስፖርት አበል',
        field: 'declarationTransportAllowance',
        show: true,
        width:80,
        type:"Number"
    },


    {
        title: 'ሰ) የስራ ንግድ ግብር የሚከፈልበት የትራንስፖርት አበል',
        field: 'taxableTransportAllowance',
        show: true,
        width:80,
        type:"Number"
    },

    {
        title: 'ሸ) የትርፍ ሰዓት ክፍያ ',
        field: 'totalOvertimeAllowance',
        show: true,
        width:80,
        type:"Number"
    },


    {
        title: 'ቀ) ሌሎች ጥቅማ ጥቀሞች  ',
        field: 'otherEarningsThanTransport',
        show: true,
        width:80,
        type:"Number"
    },

    {
        title: 'በ) ጠቅላላ ግብር የሚከፈልበት ብር',
        field: 'taxableIncome',
        show: true,
        width:80,
        type:"Number"
    },

    {
        title: 'ተ የስራ ግብር ',
        field: 'incomeTax',
        show: true,
        width:80,
        type:"Number"
    },
    {
        title: 'ቸ) የትምህርት ወጪ መጋራት',
        field: 'costSharing',
        show: true,
        width:80,
        type:"Number"
    },

    {
        title: 'ገ) የተጣራ ክፍያ ',
        field: 'cash',
        show: true,
        width:80,
        type:"Number"
    },

    {
        title: 'የሠራተኛ ፊርማ ',
        field: 'sign',
        show: true,
        width:80
    },



]




const TaxDeclarationReport = props => {
    const classes = useStyles();
    const componentRef = useRef();
    const {payrollRun,sort,filteredPayrolls=[],department, company, groupBy} = props
    const exportExcel = useRef()
    const [page, setPage] = React.useState(0);

//console.log('company',company)
    useEffect(() => {
        if(props.costCenter===-1) {
            props.onSeparateByCostCenter(0)
        }
    },[props.filteredPayrolls])

    const [rowsPerPage, setRowsPerPage] = React.useState(1000);
    const exportToExcel = () => {
        exportExcel.current.save();
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    let ids = filteredPayrolls.map((p,index) => index)
    ids=rowsPerPage > 0
        ? ids.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : ids

    const [aggregates,setAggregates] = useState([{
        field: 'fullName',
        aggregate: "count",
    }].concat(columns.filter(c=>c.type==="Number").map(c=>( {
        field: c.field,
        aggregate: "sum",
    }))));



    return (
        <div>
            {/*<PayrollExcelExport ref={exportExcel} data={filteredPayrolls} fileName={`PayDay ${moment(payrollRun.payrollDate).format('MMMM YYYY')} ${department}.xlsx`}/>*/}
            {/*<Button variant="outlined"  color="primary"  startIcon={<ExcelIcon  style={{ color: green[500] }}/>} onClick={exportToExcel}>Export to Excel</Button>*/}
            <Grid container spacing={3}>
                <Grid item>
                    <ReactToPrint
                        trigger={() => <Button variant="outlined"  color="primary"  startIcon={<PrintIcon/>}  >Print/PDF</Button>}
                        content={() => componentRef.current}
                        pageStyle="@page { size: A4 landscape;} @media print { body { -webkit-print-color-adjust: exact;} }"
                    />

                </Grid>
                <Grid item>
                    <IncomeTaxExcelExport group={groupBy&&  [
                        { field: groupBy,aggregates }
                    ]} columns={columns} company={company}  payrollDate={props.payrollRun.payrollDate}  logoLeft={FdreLogo} logoRight={ErcaLogo} data={filteredPayrolls} fileName={`Income Tax Declaration - ${props.site||''} - ${moment(props.payrollRun.payrollDate).format('MMMM YYYY')} - ${localStorage.getItem('companyName')} .xlsx`}/>

                </Grid>
                <Grid item>
                    <Tooltip placement="top-end" title={payrollRun.status!=='Approved'?"Payroll need to be Approved first !":"Download CSV File"}>
                    <CsvDownloader
                        disabled={payrollRun.status!=="Approved"}

                        filename={`${localStorage.getItem('companyName')} PayDayE-TAXIncomeTax${moment(payrollRun.payrollDate).format("MMMM-YYYY")}`}
                        extension=".csv"
                        separator=","
                        bom={false}
                        // noHeader={true}
                        columns={["tinNumber", "fullName", "startDate", "endDate", "earnedSalary", "declarationTransportAllowance", "taxableTransportAllowance", "totalOvertimeAllowance", "otherEarningsThanTransport", "taxableIncome", "incomeTax", "costSharing"]}
                        datas={filteredPayrolls.map(pr=>{
                            const  p = {...pr}
                            // p.taxAccountNumber=company.taxAccountNumber
                            // p.enterpriseNumber=company.enterpriseNumber
                            const tinNumber = p.employee&&p.employee.tinNo
                            p.tinNumber = tinNumber&&tinNumber.length<=10&&tinNumber.length>=7?tinNumber:''
                            p.fullName = p.tinNumber?'':p.fullName.replace(/[^\w\s]/gi, '')
                            p.startDate = moment((p.employee&&p.employee.startDate)||'2015-01-01').format('DD/MM/YYYY')
                            p.endDate = (p.employee&&p.employee.contractEndDate && moment(payrollRun.periodEnd)<=moment(p.employee.contractEndDate)&& moment(payrollRun.periodStart)>=moment(p.employee.contractEndDate))?moment(p.employee.contractEndDate).format('DD/MM/YYYY'):''
                            // console.log("dafda")
                                for(let i of  ["earnedSalary", "declarationTransportAllowance", "taxableTransportAllowance", "totalOvertimeAllowance", "otherEarningsThanTransport", "taxableIncome", "incomeTax", "costSharing"] ){
                                                                 p[i]= p[i]&&+p[i].toFixed(2)
                                }



                            return p
                        })}
                        text="DOWNLOAD CSV" >
                        <Button  disabled={payrollRun.status!=="Approved"}  startIcon={<CsvIcon  style={{ color: green[500] }}/>} variant="outlined"  color="primary" >Download E-TAX File</Button>
                    </CsvDownloader>
                    </Tooltip>
                    {/*<ExportCSVButton fields={["fullName"]} data={filteredPayrolls} fileName={`${localStorage.getItem('companyName')} Income Tax ${moment(payrollRun.payrollDate).format("MMMM-YYYY")}`} />*/}
                </Grid>
            </Grid>

            <div ref={componentRef} className="fit-all all-boarder">
                <Box className={classes.box} >
                <Grid container xs={12}>
                    <Grid item xs={2}>
                        <img alt='erca_logo' width={110} height={110} src={FdreLogo} />

                    </Grid>
                    <Grid item xs={3}>
                        <Box  style={{height:'100%',backgroundColor:"#2b3c3c", color:"#FFF", textAlign:"center", textJustify:"center"}}>
                        ኢትዮጲያ ፌደራላዊ ዲሞከራሲያዊ ሪፐብሊክ <br/>
                        የኢትዮጲያ ገቢዎችና ጉሙሩክ ባለስልጣን
                        </Box>
                    </Grid>
                    <Grid item xs={5} container justify='center'>
                        ሠንጠረዥ "ሀ" የስራ ግብር ክፍያ ማስታወቂያ ቅጽ (ለተቀጣሪዎች) <br/>
                        (የገቢ ግብር አዋጅ ቁጥር 976/2016 ና ገቢ ግብር ደንብ ቁጥር 410/2009)

                    </Grid>
                    <Grid item container xs={2} justify='flex-end'>
                        <img alt='erca_logo' width={110} height={110} src={ErcaLogo} />

                    </Grid>
                </Grid>
                </Box>

                <Grid item xs={12} container justify="center" >
                    ክፍል 1 የግብር ከፋይ ዝርዝር መረጃ
                </Grid>

                <Box className={classes.box} >
                <Grid container >

                    <Gridb item xs={3}>
                        1. የግብር ከፋይ ስም:  {company.name}
                    </Gridb>
                    <Gridb item xs={3}>
                        3. የግብር ከፋይ መለያ ቁጥር: {company.tinNo}
                    </Gridb>
                    <Gridb item xs={3}>
                        4. የግብር ከፋይ ሂሳብ ቁጥር
                    </Gridb>
                    <Grid container item xs={3}>
                        <Gridb  item xs={7}>
                            5. የክፍያ ጊዜ
                            <Gridb container>
                                <Gridb xs item>ወር: <MultiDate gcDate={payrollRun.payrollDate} format={'MMMM'} /> </Gridb>
                                <Gridb xs item>ዓ.ም.  <MultiDate gcDate={payrollRun.payrollDate} format={'YYYY'} /></Gridb>
                            </Gridb>
                        </Gridb>
                        <Gridb item xs={5}>
                            Page 1 of _____
                        </Gridb>
                    </Grid>
                </Grid>
                    <Grid container>
                        <Grid item xs={9} container direction='column'>
                        <Gridb container item>
                            <Gridb container item xs={6}>
                                <Gridb item xs>
                                    2a. ከልል {company.address && company.address.city}
                                </Gridb>
                                <Gridb item xs>
                                    2b. ዞን/ክፍለ ከተማ {company.address && company.address.subcity}
                                </Gridb>
                            </Gridb>
                            <Grid item xs={6}>
                                5. የግብር ሰብሳቢ መ/ቤት ስም
                            </Grid>
                        </Gridb>
                        <Gridb item container >

                                    <Gridb item xs>
                                        2c ወረዳ {company.address && company.address.woreda}
                                    </Gridb>
                                    <Gridb item xs>
                                        2d ቀበሌ/ገበሬ ማህበር {company.address && company.address.kebele}
                                    </Gridb>

                                <Gridb item xs>
                                    2e. የቤት ቁጥር {company.address && company.address.houseNumber}
                                 </Gridb>
                                <Gridb item xs>
                                    6. የስልክ ቁጥር {company.address && company.address.phoneNumber }
                                </Gridb>
                                <Gridb item xs>
                                    7. ፋክስ ቁጥር {company.address && company.address.fax}
                                </Gridb>
                            </Gridb>
                        </Grid>
                        <Gridb item xs={3}>
                            የሰነድ ቁጥር  (ለቢሮ አገልግሎት ብቻ)
                        </Gridb>

                    </Grid>
                </Box>
                <Grid item xs={12} container justify="center" >
                    ሠንጠረዥ 2. ማስታወቂያ ዝርዝር መረጃ
                </Grid>
                <Box className={classes.box}  >
                <MyDatagrid optimized decimalPlace={2}   checkField={false} summeryFields={['earnedSalary','declarationTransportAllowance','taxableTransportAllowance','totalOvertimeAllowance','otherEarningsThanTransport','taxableIncome','incomeTax','costSharing','cash']}   ids={ids} currentSort={{}}  setSort={sort} data={filteredPayrolls} reportType="incomeTax">
                    <TextField label="ሀ) ተ.ቁ" source="sn"  />
                    <TextField cellClassName="width5" label="ለ) የሠራተኛው ግብር ከፋይ ቁጥር (TIN)"  source="tinNo"   />
                    <TextField cellClassName="width17" label="ሐ) የሠራተኛው ስም የአባት ስምና የአያት ስም" source="fullName"/>
                    <DateField label="መ) የተቀጠሩበት ቀን " source="employee.startDate"  />
                    <NumberField label="ሠ) ደምወዝ " source="earnedSalary" options={{ maximumFractionDigits: 2 }}/>

                    <NumberField label="ረ) ጠቅላላ የትራንስፖርት አበል " source="declarationTransportAllowance" options={{ maximumFractionDigits: 2 }}/>
                    <NumberField cellClassName="width5"   label="ሰ) የስራ ንግድ ግብር የሚከፈልበት የትራንስፖርት አበል " source="taxableTransportAllowance" options={{ maximumFractionDigits: 2 }} />
                    <NumberField label="ሽ) የትርፍ ሰዓት ክፍያ " source="totalOvertimeAllowance" options={{ maximumFractionDigits: 2 }} />
                    <NumberField label="ቀ) ሌሎች ጥቅማ ጥቀሞች " source="otherEarningsThanTransport" options={{ maximumFractionDigits: 2 }} />
                    <NumberField label="በ) ጠቅላላ ግብር የሚከፈልበት ብር " source="taxableIncome" options={{ maximumFractionDigits: 2 }} />
                    <NumberField label="ተ የስራ ግብር " source="incomeTax" options={{ maximumFractionDigits: 2 }} />
                    <NumberField label="ቸ) የትምህርት ወጪ መጋራት " source="costSharing" options={{ maximumFractionDigits: 2 }} />
                     <NumberField label='ገ) የተጣራ ክፍያ ' source="cash" options={{ maximumFractionDigits: 2 }} />
                    <TextField label="የሠራተኛ ፊርማ"   />
                </MyDatagrid>
                </Box>

                {/*<Grid container spacing={1} >*/}
                {/*    <Grid item>*/}
                {/*        -*/}
                {/*    </Grid>*/}


                {/*</Grid>*/}


            </div>
            {/*<TablePagination*/}
                {/*rowsPerPageOptions={[5, 10, 15, filteredPayrolls.length]}*/}
                {/*component="div"*/}
                {/*count={filteredPayrolls.length}*/}
                {/*rowsPerPage={rowsPerPage}*/}
                {/*page={page}*/}
                {/*onChangePage={handleChangePage}*/}
                {/*onChangeRowsPerPage={handleChangeRowsPerPage}*/}
            {/*/>*/}


        </div>
    );
};

export default TaxDeclarationReport;
