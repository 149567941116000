import {call, put, takeLatest} from "redux-saga/effects";
import {fetchStart,fetchEnd} from "react-admin";
import OdataServiceProvider from "../Helpers/odataServiceProvider";
import moment from "moment";
import {select} from "@redux-saga/core/effects";

export default function* consolidatedPayrollSaga() {
    yield takeLatest('LOAD_PAYROLL_RUNS', function* (action) {
        //console.log('activation')
        const {payload} = action
        yield put(fetchStart());
        yield put({type:'CONSOLIDATED_PAYROLL_DATE_CHANGED',payload:payload.payrollDate})
        try {
            // const {data: templates} = yield  call(OdataServiceProvider.getList, 'payrollRuns', {
            //     pagination: {},
            //     sort: {},
            //     // filter: {filter: `status eq 'Approved' and companyId eq a78d55a7-e4ac-4192-81ec-8e5095070315`}
            //     filter: {
            //         filter: `status eq 'Approved' and companyId in (${payload.companyIds.map(x => "'" + x + "'").toString()}) and payrollDate ge ${moment(payload.payrollDate).startOf('month').format()} and payrollDate le ${moment(payload.payrollDate).endOf('month').format()}`,
            //         expand:`payrolls($orderby=fullName; $expand=payrollFixedEarnings ,payrollFixedExpenses, payrollDeductions,payrollFixedDeductions,bonuses,overtimes($expand=type))`
            //     },
            //
            //
            // })


            const {data: payrollRuns} = yield  call(OdataServiceProvider.getList, 'payrollRuns', {
                pagination: {},
                sort: {},
                // filter: {filter: `status eq 'Approved' and companyId eq a78d55a7-e4ac-4192-81ec-8e5095070315`}
                filter: {
                    filter: `status eq 'Approved' and companyId in (${payload.companyIds.map(x => "'" + x + "'").toString()}) and payrollDate ge ${moment(payload.payrollDate).startOf('month').format()} and payrollDate le ${moment(payload.payrollDate).endOf('month').format()}`,
                    expand:`payrolls($orderby=fullName; $expand=payrollFixedEarnings ,payrollFixedExpenses, payrollDeductions,payrollFixedDeductions,bonuses,overtimes($expand=type))`
                },


            })

                // console.log({payrollRuns})
            yield put(fetchEnd());
            const templates = yield select(state=>state.payrollStatus);
            console.log({templates})
            yield put({type:'SET_CONSOLIDATED_PAYROLL_RUNS',payload: {runs:payrollRuns,templates}})
        } catch (e) {
            yield put(fetchEnd());
        }
    })
}
