import React, {useEffect, useRef, useState} from 'react';
import {DateField, NumberField, TextField} from 'react-admin'
import Button from "@material-ui/core/Button";
import moment from "moment";
import Tooltip from '@material-ui/core/Tooltip';
import PrintIcon from "@material-ui/icons/Print"
import ReactToPrint from "react-to-print";
import Grid from "@material-ui/core/Grid";
import FdreLogo from "../../UIComponents/images/fdre_logo.png"
import ErcaLogo from "../../UIComponents/images/erca_logo.jpg"
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core";
import Gridb from "../../UIComponents/gridb";
import MyDatagrid from "../accountsGrid";
import MultiDate from "../../UIComponents/multiDate";
import PensionExcelExport from "./pensionExcelExport";
import CsvDownloader from 'react-csv-downloader';
import {CsvIcon} from "../../UIComponents/icons";
import {green} from "@material-ui/core/colors";

const useStyles = makeStyles( theme=> ({
    box: {
        border:true,
        borderColor:"#000",
        borderWidth:2,
        borderStyle:"solid"
        // backgroundColor: theme.palette.primary.main,
        //height: '100%'
    },
    icon:{
        color:'#FFF'
    },
    root: {
        color:'#FFF'
    },
    container: {
        maxHeight: "none",
    },
}));



const columns = [
    {
        title: 'ሀ) ተ.ቁ',
        field: 'sn',
        show: true,
        width:100
    }, {
        title: 'ለ) የቋሚ ሠራተኛው የግብር ከፋይ መለያ ቁጥር (TIN)',
        field: 'tinNo',
        show: true,
        width:150
    },
    {
        title: 'ሐ) የሠራተኛው ስም የአባት ስምና የአያት ስም',
        field: 'fullName',
        show: true,
        width:150
    },
    {
        title: 'መ) የተቀጠሩበት ቀን /ቀን/ወር/ዓም/',
        field: 'formatedStartDate',
        show: true,
        type: "Date",
        width:150
    },
    {
        title: 'ሠ) የወር ደመወዝ /ብር/',
        field: 'basicSalary',
        show: true,
        width:150,
        type:"Number"
    },

    {
        title: 'ረ) የሰራተኛው መዋጮ መጠን 7%/ብር/',
        field: 'empPension',
        show: true,
        width:150,
        type:"Number"
    },


    {
        title: 'ሰ) የአሰሪው መዋጮ መጠን 11%/ብር/',
        field: 'companyPension',
        show: true,
        width:100,
        type:"Number"
    },


    {
        title: 'ሸ) በአሰሪው የሚገባ ጥቅል መዋጮ 18%/ብር/ (ረ + ሰ) ',
        field: 'totalPension',
        show: true,
        width:80,
        type:"Number"
    },

    {
        title: 'ፊርማ ',
        field: 'sign',
        show: true,
        width:100
    },



]


const PensionDeclarationReport = props => {
    const classes = useStyles();
    const componentRef = useRef();
    const {payrollRun,sort,filteredPayrolls,department, company, groupBy} = props
    const exportExcel = useRef()
    const [page, setPage] = React.useState(0);

    useEffect(() => {
        if(props.costCenter===-1) {
            props.onSeparateByCostCenter(0)
        }
    },[props.filteredPayrolls])


    const [rowsPerPage, setRowsPerPage] = React.useState(1000);
    const exportToExcel = () => {
        exportExcel.current.save();
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const pensionPayrolls = props.filteredPayrolls.filter(p=>p.empPension>0).map((p,index)=>{
        p.sn = index +1
       // p.totalPension = p.companyPension + p.empPension;
        return p
    })
    let ids = pensionPayrolls.map((p,index) => {
      //  p.totalPension = p.companyPension + p.empPension;
        return index;
    })
    ids=rowsPerPage > 0
        ? ids.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : ids

    const [aggregates,setAggregates] = useState([{
        field: 'fullName',
        aggregate: "count",
    }].concat(columns.filter(c=>c.type==="Number").map(c=>( {
        field: c.field,
        aggregate: "sum",
    }))));
    if( !props.filteredPayrolls) {
        return <div>Loading...</div>
    }
    return (
        <div >

            {/*<PayrollExcelExport ref={exportExcel} data={filteredPayrolls} fileName={`PayDay ${moment(payrollRun.payrollDate).format('MMMM YYYY')} ${department}.xlsx`}/>*/}
            {/*<Button variant="outlined"  color="primary"  startIcon={<ExcelIcon  style={{ color: green[500] }}/>} onClick={exportToExcel}>Export to Excel</Button>*/}
            <Grid container spacing={3}>
                <Grid item>
            <ReactToPrint
                trigger={() => <Button variant="outlined"  color="primary"  startIcon={<PrintIcon/>}  >Print/PDF</Button>}
                content={() => componentRef.current}
                pageStyle="@page { size: A4 landscape;} @media print { body { -webkit-print-color-adjust: exact;} }"
            />
                </Grid>
                <Grid item>
                    <PensionExcelExport group={groupBy&&  [
                        { field: groupBy,aggregates }
                    ]} columns={columns} company={company}  payrollDate={props.payrollRun.payrollDate}  logoLeft={FdreLogo} logoRight={ErcaLogo} data={pensionPayrolls} fileName={`Pension Declaration - ${props.site||''} - ${moment(props.payrollRun.payrollDate).format('MMMM YYYY')} - ${localStorage.getItem('companyName')} .xlsx`}/>
                </Grid>

                <Grid item>
                    <Tooltip placement="top-end" title={payrollRun.status!=='Approved'?"Payroll need to be Approved first !":"Download CSV File"}>
                    <CsvDownloader
                        disabled={payrollRun.status!=="Approved"}
                        filename= {`${localStorage.getItem('companyName')} Payday E-TAX Pension ${moment(payrollRun.payrollDate).format("MMMM-YYYY")}`}
                        extension=".csv"
                        separator=","
                        bom={false}
                       // noHeader={true}
                        columns={["tinNumber", "fullName", "pensionNo", "startDate", "endDate", "basicSalary"]}
                        datas={pensionPayrolls.map(p=>{
                           const tinNumber = p.employee&&p.employee.tinNo

                            return {
                                tinNumber: tinNumber && tinNumber.length <= 10 && tinNumber.length >= 7 ? tinNumber : '',
                                pensionNo: p.pensionNo ? p.pensionNo : 0,
                                fullName: p.tinNumber ? '' : p.fullName.replace(/[^\w\s]/gi, ''),
                                startDate: moment((p.employee && p.employee.startDate) || '2015-01-01').format('DD/MM/YYYY'),
                                endDate: (p.employee && p.employee.contractEndDate && moment(payrollRun.periodEnd) <= moment(p.employee.contractEndDate) && moment(payrollRun.periodStart) >= moment(p.employee.contractEndDate)) ? moment(p.employee.contractEndDate).format('DD/MM/YYYY') : '',
                                basicSalary: +p.basicSalary.toFixed(2),

                            }
                        })}
                        text="DOWNLOAD CSV" >
                        <Button         disabled={payrollRun.status!=="Approved"} startIcon={<CsvIcon  style={{ color: green[500] }}/>} variant="outlined"  color="primary" >Download E-TAX File</Button>
                    </CsvDownloader>
                    </Tooltip>
                    {/*<ExportCSVButton fields={["fullName"]} data={filteredPayrolls} fileName={`${localStorage.getItem('companyName')} Pension ${moment(payrollRun.payrollDate).format("MMMM-YYYY")}`} />*/}
                </Grid>
            </Grid>
            <div ref={componentRef} className="all-boarder">
                <Box className={classes.box} >
                <Grid container xs={12}>
                    <Grid item xs={2}>
                        <img alt='erca_logo' width={110} height={110} src={FdreLogo} />

                    </Grid>
                    <Grid item xs={3}>
                        <Box  style={{height:'100%',backgroundColor:"#2b3c3c", color:"#FFF", textAlign:"center", textJustify:"center"}}>
                        ኢትዮጲያ ፌደራላዊ ዲሞከራሲያዊ ሪፐብሊክ <br/>
                        የኢትዮጲያ ገቢዎችና ጉሙሩክ ባለስልጣን
                        </Box>
                    </Grid>
                    <Grid item xs={5} container justify='center'>
                        የግል ድርጅት ሠራተኞች የጡረታ መዋጮ ማሳወቂያ  ቅፅ <br/>
                        (በግል ድርጅቶች ሠራተኞች ጡረታ አዋጅ ቁጥር 715/2003)

                    </Grid>
                    <Grid item container xs={2} justify='flex-end'>
                        <img alt='erca_logo' width={110} height={110} src={ErcaLogo} />

                    </Grid>
                </Grid>
                </Box>

                <Grid item xs={12} container justify="center" >
                    ክፍል 1 የጡረታ መዋጮውን የሚከፍለው ድርጅት ዝርዝር መረጃ
                </Grid>

                <Box className={classes.box} >
                <Grid container >

                    <Gridb item xs={3}>
                        1. የድርጅቱ ስም:  {localStorage.getItem('companyName')}
                    </Gridb>
                    <Gridb item xs={3}>
                        3. የድርጅቱ የግብር ከፋይ መለያ ቁጥር {company.tinNo}
                    </Gridb>
                    <Gridb item xs={3}>
                        4. የግብር ሂሳብ ቁጥር
                    </Gridb>
                    <Grid container item xs={3}>
                        <Gridb  item xs={7}>
                            5. የክፍያ ጊዜ
                            <Gridb container>
                                <Gridb xs item>ወር: <MultiDate gcDate={payrollRun.payrollDate} format={'MMMM'} /> </Gridb>
                                <Gridb xs item>ዓ.ም.  <MultiDate gcDate={payrollRun.payrollDate} format={'YYYY'} /></Gridb>
                            </Gridb>
                        </Gridb>
                        <Gridb item xs={5}>
                            Page 1 of _____
                        </Gridb>
                    </Grid>
                </Grid>
                    <Grid container>
                        <Grid item xs={9} container direction='column'>
                            <Gridb container item>
                                <Gridb container item xs={6}>
                                    <Gridb item xs>
                                        2a. ከልል {company.address && company.address.city}
                                    </Gridb>
                                    <Gridb item xs>
                                        2b. ዞን/ክፍለ ከተማ {company.address && company.address.subcity}
                                    </Gridb>
                                </Gridb>
                                <Grid item xs={6}>
                                    5. የግብር ሰብሳቢ መ/ቤት ስም
                                </Grid>
                            </Gridb>
                            <Gridb item container >

                                <Gridb item xs>
                                    2c ወረዳ {company.address && company.address.woreda}
                                </Gridb>
                                <Gridb item xs>
                                    2d ቀበሌ/ገበሬ ማህበር {company.address && company.address.kebele}
                                </Gridb>

                                <Gridb item xs>
                                    2e. የቤት ቁጥር {company.address && company.address.houseNumber}
                                </Gridb>
                                <Gridb item xs>
                                    6. የስልክ ቁጥር {company.address && company.address.phoneNumber }
                                </Gridb>
                                <Gridb item xs>
                                    7. ፋክስ ቁጥር {company.address && company.address.fax}
                                </Gridb>
                            </Gridb>
                        </Grid>
                        <Gridb item xs={3}>
                            የሰነድ ቁጥር  (ለቢሮ አገልግሎት ብቻ)
                        </Gridb>

                    </Grid>
                </Box>
                <Grid item xs={12} container justify="center" >
                    ሠንጠረዥ 2. ማስታወቂያ ዝርዝር መረጃ
                </Grid>

                <Box className={classes.box} >
                <MyDatagrid decimalPlace={2} classes={classes}  checkField={false} summeryFields={['basicSalary','empPension','companyPension','totalPension']}   ids={ids} currentSort={{}}  setSort={sort} data={pensionPayrolls} reportType="pension">
                    <TextField label="ሀ) ተ.ቁ" source="sn" />
                    <TextField label="ለ) የቋሚ ሠራተኛው የግብር ከፋይ መለያ ቁጥር (TIN)"  source="tinNo" />
                    <TextField label="ሐ) የሠራተኛው ስም፣ የአባት ስም እና የአያት ስም" source="fullName" />
                    <DateField label="መ) የተቀጠሩበት ቀን /ቀን/ወር/ዓም/" source="employee.startDate" />
                    <NumberField label="ሠ) የወር ደመወዝ /ብር/" source="basicSalary" />

                    <NumberField label="ረ) የሰራተኛው መዋጮ መጠን 7%/ብር/" source="empPension" />
                    <NumberField label="ሰ) የአሰሪው መዋጮ መጠን 11%/ብር/" source="companyPension" />
                    <NumberField label="ሸ) በአሰሪው የሚገባ ጥቅል መዋጮ 18%/ብር/ (ረ + ሰ) " source="totalPension" />
                    <TextField label="ፊርማ"   />
                </MyDatagrid>
                </Box>
                {/*<Grid container spacing={1} >*/}
                {/*    <Grid item>*/}
                {/*        -*/}
                {/*    </Grid>*/}


                {/*</Grid>*/}

            </div>
            {/*<TablePagination*/}
                {/*rowsPerPageOptions={[5, 10, 15, filteredPayrolls.length]}*/}
                {/*component="div"*/}
                {/*count={filteredPayrolls.length}*/}
                {/*rowsPerPage={rowsPerPage}*/}
                {/*page={page}*/}
                {/*onChangePage={handleChangePage}*/}
                {/*onChangeRowsPerPage={handleChangeRowsPerPage}*/}
            {/*/>*/}


        </div>
    );
};

export default PensionDeclarationReport;
