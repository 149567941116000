import React from 'react';
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Typography} from "../../UIComponents/Wrappers";
import {Chip} from "@material-ui/core";
import moment from "moment";
import MultiCurrency from "../multiCurrency";
const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
}));
const PopoverData = ({data,anchor, handleClose}) => {
    const classes = useStyles();
    // const [anchorEl, setAnchorEl] = React.useState(null);

    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    //
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    const open = Boolean(anchor);
    const id = open ? 'simple-popover' : undefined;
    return (
        <div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchor}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {
                    data&&<div style={{padding: 20}}>
                        <Typography weight="bold">{data.fullName}</Typography>
                        {!data.updateType&&<div>
                            {data.leftType && <div>

                                <Chip label={data.reasonType}/>
                            </div>}
                            {!data.leftType && <div>
                                <Chip label={data.joinType}/> <Chip label={data.joinTeam}/>
                                <div>
                                    Employment Type: <Chip label={data.employmentType}/> <br/>
                                    Contract Type: <Chip
                                    label={data.contractType}/> {data.contractType === 'FixedTerm' &&
                                <span><strong>{data.duration}</strong> {data.period}</span>}<br/>
                                    From <strong>{moment(data.startDate).format("MMMM D, YYYY")}</strong> to <strong>{moment(data.endDate).format("MMMM D, YYYY")}</strong><br/>
                                    {data.employmentType === 'Casual' ? 'Wadge' : 'Salary'}: <strong><MultiCurrency
                                    value={data.earning.rate}/></strong> / {data.earning.period}
                                    {data.earning.additionalEarnings.map(er => <div>
                                        {er.name}: <strong><MultiCurrency value={er.value}/></strong>
                                    </div>)}
                                </div>
                            </div>}
                        </div>}
                        {
                            data.updateType&&<div>
                                {data.updateTypes.map(t=><Chip label={t} />)}
                                {data.updateTypes.map(t=><div>

                                    {t==='salary'&&<div>Salary from {data.member.earning.rate} to {data.salary}</div>}
                                    {t==='contactDate'&&<div>Contract End Date from {moment(data.member.endDate).format('mmmm dd, YYYY')} to {moment(data.contractEndDate).format('mmmm dd, YYYY')}</div>}

                                    </div>
                                )}
                            </div>
                        }

                    </div>
                }

            </Popover>
        </div>
    );
};

export default PopoverData;
