import React from 'react';
import PropTypes from 'prop-types';
import get from "lodash/get";
import MultiDate from "./multiDate";

const MultiDateField =  ({ source, record = {}, format  })  => {
    if (!record) {
        return null;
    }
    const value = get(record, source);
    if (value == null) {
        return null;
    }
    return (
        <MultiDate gcDate={value} format={format}/>
    );
};

export default MultiDateField;
