import React from 'react';
import {FormattedNumber} from 'react-intl'
import MultiCurrency from "../components/multiCurrency";
import {Typography} from "./Wrappers";

const CaptionedField = (props) => {
    return (
        <div style={{marginBlockEnd:10,marginBlockStart:10}}>
        <Typography style={{lineHeight:1}} display='block' variant="caption">
            {props.label}  {props.labelValue&&<MultiCurrency
            defaultCurrency={props.defaultCurrency}
            value= {props.labelValue}/>}
        </Typography>

        <strong>
            {props.style==='text'? <Typography size="mmd" weight="bold">
                {props.value}
            </Typography>:props.style==='number'?
                <FormattedNumber  value= {props.value}/>:
                <MultiCurrency
                    defaultCurrency={props.defaultCurrency}
                    value= {props.value}

                />
            }

           </strong>
        </div>
    );
};

export default CaptionedField;
