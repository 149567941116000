import React from 'react';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

function getRemaining (ref,loans,deduct=0) {
    if(ref >=0 && loans) {
        const loan = loans.find(l=>l.id===ref)
        if(loan) {
            return loan.disburesedAmount  - loan.repayedAmount  - deduct
        }
    }
    return 0;
}

const PayrollDeductionItem = props => {
    const [type, setType] = React.useState(props.item.type||props.DeductionTypes[0].type);
    const [value, setValue] = React.useState(props.item.value||0);
    const [cash, setCash] = React.useState(props.item.cash);
    const [name, setName] = React.useState(props.item.name);
    const [ref, setRef] = React.useState(props.item.ref);
    const [remaining, setRemaining] = React.useState(props.item.remaining);
    const [estimatedMonth, setEstimatedMonth] = React.useState(props.item.estimatedMonth);

    // useEffect(() => {
    //    // console.log(name,remaining)
    //
    //     props.onChange({cash,value,name,type,ref,remaining})
    //
    // }, [remaining])




    return (
        <Grid container style={{minWidth:900}}>
            <Grid item xs={1}>
                <FormControlLabel
                    control={ <Checkbox
                    checked={cash}
                    onChange={ev=>{
                        setCash(ev.target.checked)
                        props.onChange({cash:ev.target.checked,value,name,type,ref,remaining,estimatedMonth})
                    }}
                    value="cash"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                /> }
                    label="Cash"
                />
            </Grid>
            <Grid item xs={2}>


                <FormControl margin='normal' fullWidth  >
                    <InputLabel>Deduction Type</InputLabel>

                    <Select
                        value={type}
                        onChange={ev=>{
                            const type  = props.DeductionTypes.find(t=>t.type===ev.target.value)
                            setType(ev.target.value)
                            type&&setName(type.name)
                             props.onChange({type:ev.target.value,value,name:type?type.name:name,cash,ref,remaining,estimatedMonth})
                        }}
                    >
                        {props.DeductionTypes.map(ot=><MenuItem key={ot.type} value={ot.type}>{ot.name}</MenuItem>)}
                    </Select>
                </FormControl>

            </Grid>
            <Grid item xs={2}>
                <TextField
                    onChange={ev =>{
                        setName(ev.target.value)
                        props.onChange({type,cash,value,name:ev.target.value,ref,remaining,estimatedMonth})
                    }}
                    value={name}
                    autoFocus
                    label="Name"
                    margin='normal'

                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    onChange={ev =>{
                        setValue(+ev.target.value)
                         setRemaining(getRemaining(ref,props.loans,+ev.target.value))
                        setEstimatedMonth(Math.ceil(+(getRemaining(ref,props.loans,+ev.target.value)/+ev.target.value).toFixed(5)))
                        props.onChange({type,cash,name,value:+ev.target.value,ref,remaining:getRemaining(ref,props.loans,+ev.target.value,estimatedMonth),estimatedMonth:Math.ceil(getRemaining(ev.target.value,props.loans,value)/value)})
                    }}
                    value={value}
                    autoFocus
                    type="number"
                    label="Value"
                    margin='normal'

                />
            </Grid>
            <Grid item xs={2}>
                <FormControl margin='normal' fullWidth  >
                    <InputLabel>Reference</InputLabel>

                    <Select
                        value={ref}
                        onChange={ev=>{
                            setRef(ev.target.value)
                            setRemaining(getRemaining(ev.target.value,props.loans,value))
                            setEstimatedMonth(Math.ceil(+(getRemaining(ev.target.value,props.loans,value)/value).toFixed(5)))
                            setName(props.loans.find(l=>l.id===ev.target.value).name)
                            props.onChange({ref:ev.target.value,value,name:props.loans.find(l=>l.id===ev.target.value).name,cash,type,remaining:getRemaining(ev.target.value,props.loans,value),estimatedMonth:Math.ceil(getRemaining(ev.target.value,props.loans,value)/value)})
                        }}
                    >
                        {props.loans&& props.loans.map(ot=><MenuItem key={ot.id} value={ot.id}>{ot.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
            {ref > 0 &&<> <Grid item xs={1}>
                <TextField
                    onChange={ev => {
                        setRemaining(+ev.target.value)
                        setEstimatedMonth(Math.ceil(+(+ev.target.value/value).toFixed(5)))
                        props.onChange({type, cash, value, name, remaining:+ev.target.value, ref,estimatedMonth:Math.ceil(+(+ev.target.value/value).toFixed(5))})
                    }}
                    value={remaining}
                    type="number"
                    label="Remaining"
                    margin='normal'


                />
            </Grid>

                <Grid item xs={1}>
                    <TextField
                        onChange={ev => {
                            setEstimatedMonth(+ev.target.value)
                            props.onChange({type, cash, value, name, remaining, ref,estimatedMonth:+ev.target.value})
                        }}
                        value={estimatedMonth}
                        type="number"
                        label="Estimated Month"
                        margin='normal'


                    />
                </Grid>
            </>
            }

            <Grid item xs={1}>
                <IconButton onClick={props.onRemove} aria-label="delete" >
                    <DeleteIcon fontSize="large" />
                </IconButton>



            </Grid>

        </Grid>
    );
};

export default PayrollDeductionItem;
