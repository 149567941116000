import React, {Children, cloneElement, isValidElement, useCallback,} from 'react';
import PropTypes from 'prop-types';
import {sanitizeListRestProps} from 'ra-core';
import {makeStyles} from '@material-ui/core/styles';
 // import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import classnames from 'classnames';


import {DatagridBody, DatagridHeaderCell, DatagridLoading, PureDatagridBody} from 'react-admin';
import TableFooter from "@material-ui/core/TableFooter";
import MultiCurrency from "./multiCurrency";
import get from "lodash/get";
import TableContainer from "../UIComponents/tableContainer";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Gridb from "../UIComponents/gridb";
import TableBody from "@material-ui/core/TableBody";
import {FormattedNumber} from "react-intl";



const CustomDatagridRow = ({ record, classes, hasBulkActions, resource, id, onToggleItem, children, selected, basePath }) => (
    <TableRow key={id}>
        {hasBulkActions &&<TableCell style={{width: "10%"}} padding="none">
            {record.selectable && <Checkbox
                checked={selected}
                onClick={() => onToggleItem(id)}
            />}
        </TableCell>}
        {React.Children.map(children, field => (
            <TableCell style={{width: field.props.width}} className={classnames(
                `column-${field.props.source}`,
                classes.rowCell
            )}  key={`${id}-${field.props.source}`}>
                {React.cloneElement(field, {
                    record,
                    basePath,
                    resource,
                })}
            </TableCell>
        ))}
    </TableRow>
);

const CustomDatagridBody = props => <DatagridBody {...props} row={<CustomDatagridRow hasBulkActions={props.hasBulkActions} />} />;





const useStyles = makeStyles(theme => ({
    table: {
        tableLayout: 'auto',
    },

    container: {
        '@media print' : {
            maxHeight: "none",
            overflow: "hidden"
        },

        maxHeight: "100vh",

    },
    thead: {},
    tbody: {

    },
    headerRow: {},
    headerCell: {
        fontSize: "medium",
        verticalAlign:"top"
    },
    checkbox: {},
    row: {},
    clickableRow: {
        cursor: 'pointer',
    },
    rowEven: {},
    rowOdd: {},
    rowCell: {
        fontSize:"large"
    },
    expandHeader: {
        padding: 0,
        width: theme.spacing(6),
    },
    expandIconCell: {
        width: theme.spacing(6),
    },
    expandIcon: {
        padding: theme.spacing(1),
        transform: 'rotate(-90deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expanded: {
        transform: 'rotate(0deg)',
    },
}));

/**
 * The MyDatagrid component renders a list of records as a table.
 * It is usually used as a child of the <List> and <ReferenceManyField> components.
 *
 * Props:
 *  - rowStyle
 *
 * @example Display all posts as a datagrid
 * const postRowStyle = (record, index) => ({
 *     backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
 * });
 * export const PostList = (props) => (
 *     <List {...props}>
 *         <MyDatagrid rowStyle={postRowStyle}>
 *             <TextField source="id" />
 *             <TextField source="title" />
 *             <TextField source="body" />
 *             <EditButton />
 *         </MyDatagrid>
 *     </List>
 * );
 *
 * @example Display all the comments of the current post as a datagrid
 * <ReferenceManyField reference="comments" target="post_id">
 *     <MyDatagrid>
 *         <TextField source="id" />
 *         <TextField source="body" />
 *         <DateField source="created_at" />
 *         <EditButton />
 *     </MyDatagrid>
 * </ReferenceManyField>
 */
function MyDatagrid({ classes: classesOverride, ...props }) {
    const classes = useStyles({ classes: classesOverride });
    const {
        basePath,
        optimized = false,
        body = optimized ? <PureDatagridBody/> :<DatagridBody/>,
        children,
        className,
        currentSort,
        data,
        SN,
        expand,
        hasBulkActions,
        hover,
        ids,
        loading,
        loaded,
        onSelect,
        onToggleItem,
        resource,
        rowClick,
        rowStyle,
        selectedIds,
        setSort,
        size = 'small',
        total,
        version,
        summeryFields,
        reportType,
        checkField,
        fullHeight,
        decimalPlace,
        ...rest
    } = props;

    const getTotalForField = field => {


        // something like
        return <MultiCurrency decimalPlace={decimalPlace} value={ids.map(id => get(data[id],field)).reduce((acc, curr) => acc + curr,0)}/>
    }

    const updateSort = useCallback(
        event => {
            event.stopPropagation();
            setSort(event.currentTarget.dataset.sort);
        },
        [setSort]
    );

    const handleSelectAll = useCallback(
        event => {
            if (event.target.checked) {
                onSelect(
                    ids.concat(selectedIds.filter(id => !ids.includes(id)))
                );
            } else {
                onSelect([]);
            }
        },
        [ids, onSelect, selectedIds]
    );

    /**
     * if loaded is false, the list displays for the first time, and the dataProvider hasn't answered yet
     * if loaded is true, the data for the list has at least been returned once by the dataProvider
     * if loaded is undefined, the MyDatagrid parent doesn't track loading state (e.g. ReferenceArrayField)
     */
    if (loaded === false) {
        return (
            <DatagridLoading
                classes={classes}
                className={className}
                expand={expand}
                hasBulkActions={hasBulkActions}
                nbChildren={React.Children.count(children)}
                size={size}
            />
        );
    }

    /**
     * Once loaded, the data for the list may be empty. Instead of
     * displaying the table header with zero data rows,
     * the datagrid displays nothing in this case.
     */
    if (loaded && (ids.length === 0 || total === 0)) {
        return null;
    }

if(SN) {
    console.log({data})
}
    /**
     * After the initial load, if the data for the list isn't empty,
     * and even if the data is refreshing (e.g. after a filter change),
     * the datagrid displays the current data.
     */
    return (
        <TableContainer className={!fullHeight?classes.container:''}>
        <Table
            className={classnames(classes.table, className)}
            size={size}

            stickyHeader
            {...sanitizeListRestProps(rest)}
        >
            <TableHead  className={classes.thead}>
                <TableRow
                    className={classnames(classes.row, classes.headerRow)}
                >
                    {expand && (
                        <TableCell
                            padding="none"
                            className={classes.expandHeader}
                        />
                    )}

                    {hasBulkActions && (
                        <TableCell   padding="checkbox">
                            <Checkbox
                                className="select-all"
                                color="primary"
                                checked={
                                    selectedIds.length > 0 &&
                                    ids.length > 0 &&
                                    ids.every(id => selectedIds.includes(id))
                                }
                                onChange={handleSelectAll}
                            />
                        </TableCell>
                    )}
                    {SN&& (
                        <TableCell

                        >
                            SN
                        </TableCell>
                    )}
                    {Children.map(children, (field, index) =>
                        isValidElement(field) ? (
                            <DatagridHeaderCell
                                className={classes.headerCell}
                                currentSort={currentSort}
                                field={field}
                                isSorting={
                                    currentSort.field ===
                                    (field.props.sortBy || field.props.source)
                                }
                                key={field.props.source || index}
                                resource={resource}
                                updateSort={updateSort}
                            />
                        ) : null
                    )}
                </TableRow>
            </TableHead>
            {cloneElement(
                body,
                {
                    basePath,
                    className,
                    classes,
                    expand,
                    rowClick,
                    data,
                    hasBulkActions,
                    hover,
                    ids,
                    onToggleItem,
                    resource,
                    rowStyle,
                    selectedIds,
                    version,
                },
                children
            )}
            <TableFooter>

            <TableRow   className={classnames(classes.row, classes.headerRow)}>
                {checkField && <TableCell key={-1}> </TableCell>}
                {Children.map(children, (field, index) =>
                    {
                        if(!!field && field.props)
                        {
                           // console.log("tableF",field)

                            // if(!field.props || !field.props.source) {
                            //     // return <TableCell style={{textAlign: 'end'}} className={classes.headerCell}
                            //     //                   key={index}> -</TableCell>
                            // }
                            if (summeryFields.includes(field.props.source))
                                return <TableCell
                                    style={{textAlign: 'end', borderLeft: '1px solid rgba(224, 224, 224, .5)'}}
                                    className={classes.headerCell}
                                    key={index}>{getTotalForField(field.props.source)}</TableCell>
                            else {
                                return <TableCell style={{textAlign: 'end'}} className={classes.headerCell}
                                                  key={index}> -</TableCell>
                            }
                        }
                    }

                )}
            </TableRow>
                {reportType==="incomeTax"&& <TableRow>
                    <TableCell colSpan={14}>
                        <div className={'break'}>
                            <Grid container spacing={1}>
                                <Grid item xs={4} container direction="column">
                                    <Grid item container justify="center">
                                        ክፍል 3. የወሩ የተጠቃለለ ሂሳብ
                                    </Grid>
                                    <Grid item>
                                        <Box className={classes.box}>
                                            <Table size="small">

                                                <TableBody>


                                                    <TableRow>
                                                        <TableCell>10</TableCell>
                                                        <TableCell>በዚህ ወር ደምውዝ ሚከፈላቸው ሰራተኞች ብዛት </TableCell>
                                                        <TableCell>{data.length}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>20</TableCell>
                                                        <TableCell>የውሩ ጠቅላላ የስራ ገብር የሚከፈልበት ገቢ (ከላይ ካለው ሠንጠረዥ
                                                            (በ)) </TableCell>
                                                        <TableCell><FormattedNumber maximumFractionDigits={decimalPlace}
                                                            value={data.reduce((sum, cv) => sum + (+cv.taxableIncome.toFixed(decimalPlace)), 0)}/></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>30</TableCell>
                                                        <TableCell>የወሩ ጠቅላላ መከፈል ያለበት የሥራ ገበር (ከላይ ካለው ሠንጠረዥ (ተ)
                                                            ) </TableCell>
                                                        <TableCell><FormattedNumber maximumFractionDigits={decimalPlace}
                                                            value={data.reduce((sum, cv) => sum + (+cv.incomeTax.toFixed(decimalPlace)), 0)}/></TableCell>
                                                    </TableRow>

                                                </TableBody>

                                            </Table>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} container direction="column">
                                    <Grid item container justify="center">
                                        ክፍል 4- በዚህ ወር ስራ የለቀቁ ሠራተኞች ዝርዝር መረጃ
                                    </Grid>
                                    <Grid item>
                                        <Box className={classes.box}>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableCell>ተ.ቁ.</TableCell>
                                                    <TableCell>የሠራተኛው የግብር ከፋይ ቁጥር </TableCell>
                                                    <TableCell>የሠራተኛው ስም /የአባት ሰምና የአያት ስም </TableCell>
                                                </TableHead>

                                                <TableBody>


                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>

                                                </TableBody>

                                            </Table>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} container direction="column">
                                    <Grid item container justify="center">
                                        ለቢሮ አገልግሎት ብቻ
                                    </Grid>
                                    <Grid item>
                                        <Box className={classes.box}>
                                            <Table size="small">

                                                <TableBody>


                                                    <TableRow>

                                                        <TableCell>የተከፈለብት ቀን</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                    <TableRow>

                                                        <TableCell>የደረሰኝ ቁጥር </TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                    <TableRow>

                                                        <TableCell>የገንዘብ ልክ </TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                    <TableRow>

                                                        <TableCell>የቼክ ቁጥር </TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                    <TableRow>

                                                        <TableCell>የገንዘብ ተቀባይ ፊርማ</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>

                                                </TableBody>

                                            </Table>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container justify="center">
                                ክፍል 5 የትክክለኛነት ማረጋገጫ
                            </Grid>
                            <Box className={classes.box}>
                                <Grid container>
                                    <Gridb item xs={4}>
                                        ከላይ የተገለጸው ማስታወቂያና የተሰጠው መረጃ ሙሉ በሙሉ የተሟላና ትክክለኛ መሆኑን አረጋገጣለሁ። ትክክለኛ ያልሆነ መረጃ
                                        ማቅረብ በግብር ሀጎችም ሆ በወንጀለኛ ሕግ የሚያስቀጣ መሆኑን እገነዘባለሁ
                                    </Gridb>
                                    <Gridb item xs={3}>
                                        የግብር ከፋይ ሀጋዊ ወኪሉ <br/>
                                        ስም__________________________<br/>
                                        ፊርማ___________ ቀን____________

                                    </Gridb>
                                    <Gridb item xs={2}>
                                        ማሕተም
                                    </Gridb>
                                    <Gridb item xs={3}>
                                        የግብር ባለስልጣን ስም <br/>
                                        ፊርማ___________ ቀን____________

                                    </Gridb>
                                </Grid>
                            </Box>
                        </div>
                    </TableCell>
                </TableRow>
                }

                {reportType==="pension"&& <TableRow>
                    <TableCell colSpan={14}>
                        <div className={'break'}>
                            <Grid container spacing={1} >
                                <Grid item xs={4} container direction="column">
                                    <Grid item  container justify="center" >
                                        ክፍል  3. የወሩ የተጠቃለለ ሂሳብ
                                    </Grid>
                                    <Grid item >
                                        <Box className={classes.box} >
                                            <Table size="small"  >

                                                <TableBody>


                                                    <TableRow>
                                                        <TableCell >10</TableCell>
                                                        <TableCell >በዚህ ወር ደምውዝ ሚከፈላቸው ሰራተኞች ብዛት </TableCell>
                                                        <TableCell >{data.length}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>20</TableCell>
                                                        <TableCell >የወሩ ጠቅላላ የሠራተኞች ደመወዝ</TableCell>
                                                        <TableCell ><FormattedNumber maximumFractionDigits={decimalPlace} value={data.reduce((sum,cv)=>sum+(+cv.basicSalary.toFixed(decimalPlace)),0)}/></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell >30</TableCell>
                                                        <TableCell >የወሩ ጠቅላላ የሰራተኞች መዋጮ መጠን</TableCell>
                                                        <TableCell ><FormattedNumber maximumFractionDigits={decimalPlace} value={data.reduce((sum,cv)=>sum+(+cv.empPension.toFixed(decimalPlace)),0)}/></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell >40</TableCell>
                                                        <TableCell >የወሩ ጠቅላላ የአሰሪው መዋጮ መጠን	</TableCell>
                                                        <TableCell ><FormattedNumber maximumFractionDigits={decimalPlace} value={data.reduce((sum,cv)=>sum+(+cv.companyPension.toFixed(decimalPlace)),0)}/></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell >50</TableCell>
                                                        <TableCell >የወሩ ጠቅላላ የመዋጮ መዋጮ መጠን		</TableCell>
                                                        <TableCell ><FormattedNumber  maximumFractionDigits={decimalPlace} FractionDigits={decimalPlace} value={data.reduce((sum,cv)=>sum+(+cv.totalPension.toFixed(decimalPlace)),0)}/></TableCell>
                                                    </TableRow>

                                                </TableBody>

                                            </Table>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} container direction="column">
                                    <Grid item  container justify="center" >
                                        ክፍል 4- በዚህ ወር ስራ የለቀቁ ሠራተኞች ዝርዝር መረጃ
                                    </Grid>
                                    <Grid item >
                                        <Box className={classes.box} >
                                            <Table size="small">
                                                <TableHead>
                                                    <TableCell>ተ.ቁ.</TableCell>
                                                    <TableCell>የቋሚ ሠራተኛው የግብር ከፋይ ቁጥር</TableCell>
                                                    <TableCell>የሠራተኛው ስም /የአባት ሰምና የአያት ስም </TableCell>
                                                </TableHead>

                                                <TableBody>


                                                    <TableRow>
                                                        <TableCell ></TableCell>
                                                        <TableCell ></TableCell>
                                                        <TableCell ></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell ></TableCell>
                                                        <TableCell ></TableCell>
                                                        <TableCell ></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell ></TableCell>
                                                        <TableCell ></TableCell>
                                                        <TableCell ></TableCell>
                                                    </TableRow>

                                                </TableBody>

                                            </Table>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} container direction="column">
                                    <Grid item  container justify="center" >
                                        ለቢሮ አገልግሎት ብቻ
                                    </Grid>
                                    <Grid item >
                                        <Box className={classes.box} >
                                            <Table size="small">

                                                <TableBody>


                                                    <TableRow>

                                                        <TableCell >የተከፈለብት  ቀን</TableCell>
                                                        <TableCell ></TableCell>
                                                    </TableRow>
                                                    <TableRow>

                                                        <TableCell >የደረሰኝ ቁጥር </TableCell>
                                                        <TableCell ></TableCell>
                                                    </TableRow>
                                                    <TableRow>

                                                        <TableCell >የገንዘብ ልክ  </TableCell>
                                                        <TableCell ></TableCell>
                                                    </TableRow>
                                                    <TableRow>

                                                        <TableCell >የቼክ ቁጥር  </TableCell>
                                                        <TableCell ></TableCell>
                                                    </TableRow>
                                                    <TableRow>

                                                        <TableCell >የገንዘብ ተቀባይ ፊርማ</TableCell>
                                                        <TableCell ></TableCell>
                                                    </TableRow>

                                                </TableBody>

                                            </Table>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container justify="center" >
                                ክፍል 5 የትክክለኛነት ማረጋገጫ
                            </Grid>
                            <Box className={classes.box}>
                                <Grid container>
                                    <Gridb item xs={4}>
                                        ከላይ የተገለጸው ማስታወቂያና የተሰጠው መረጃ ሙሉ በሙሉ የተሟላና ትክክለኛ መሆኑን አረጋገጣለሁ። ትክክለኛ ያልሆነ መረጃ ማቅረብ በግብር ሀጎችም ሆነ በወንጀለኛ ሕግ የሚያስቀጣ መሆኑን እገነዘባለሁ
                                    </Gridb>
                                    <Gridb item xs={3}>
                                        የግብር ከፋይ ሀጋዊ ወኪሉ <br/>                ስም__________________________<br/>
                                        ፊርማ___________ ቀን____________

                                    </Gridb>
                                    <Gridb item xs={2}>
                                        ማሕተም
                                    </Gridb>
                                    <Gridb item xs={3}>
                                        የግብር ባለስልጣን ስም <br/>
                                        ፊርማ___________ ቀን____________

                                    </Gridb>
                                </Grid>
                            </Box></div>
                    </TableCell>
                </TableRow>
                }
            </TableFooter>
        </Table>
        </TableContainer>
    );
}

MyDatagrid.propTypes = {
    basePath: PropTypes.string,
    body: PropTypes.element,
    children: PropTypes.node.isRequired,
    classes: PropTypes.object,
    className: PropTypes.string,
    currentSort: PropTypes.shape({
        field: PropTypes.string,
        order: PropTypes.string,
    }),
    data: PropTypes.object.isRequired,
    expand: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
    hasBulkActions: PropTypes.bool.isRequired,
    hover: PropTypes.bool,
    ids: PropTypes.arrayOf(PropTypes.any).isRequired,
    loading: PropTypes.bool,
    onSelect: PropTypes.func,
    onToggleItem: PropTypes.func,
    resource: PropTypes.string,
    rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    rowStyle: PropTypes.func,
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    setSort: PropTypes.func,
    total: PropTypes.number,
    version: PropTypes.number,
    summaryFields: PropTypes.arrayOf(PropTypes.string),
    checkField: true
};

MyDatagrid.defaultProps = {
    data: {},
    hasBulkActions: false,
    ids: [],
    selectedIds: [],
    summaryFields: []
};

export default MyDatagrid;
