import moment from "moment";
import renderTemplate from 'react-text-templates'
export function payslipToTelegraphPage ({user,companyName,payroll,payrollDate,welcomeTemplate,templateData,payslipSettings,logo, isDraft}) {
    return {
        "title": `💸 Payslip For ${moment(payrollDate).format('MMMM, YYYY')}`,
        "author_name": `${companyName}`,
        "content": [
            {
                "tag": "h3",
                "children": [
                    `👋 ${renderTemplate(welcomeTemplate, templateData)}`
                ]
            },
            {
                "tag": "h4",
                "children": [
                   `How was your month?`
                ]
            },
            {
                "tag": "p",
                "children": [
                    `Your NetPay for ${moment(payrollDate).format('MMMM, YYYY')} is `,
                    {
                        "tag": "strong",
                        "children": [
                           `${payroll.cash.toFixed(1).toNumber().toLocaleString()}`
                        ]
                    }
                ]
            },
            {
                "tag": "h4",
                "children": [
                    "Earnings"
                ]
            },
            {
                "tag": "ul",
                // "children": [
                //     {
                //         "tag": "li",
                //         "children": [
                //             "Basic Salary:                         100,000"
                //         ]
                //     },
                //     {
                //         "tag": "li",
                //         "children": [
                //             "Responsibility Allowance:             100"
                //         ]
                //     },
                //     {
                //         "tag": "li",
                //         "children": [
                //             "Housing Allowance:                 100"
                //         ]
                //     },
                //     {
                //         "tag": "li",
                //         "children": [
                //             {
                //                 "tag": "strong",
                //                 "children": [
                //                     "➕Total Earnings                    40,736"
                //                 ]
                //             }
                //         ]
                //     }
                // ]
                "children":  payroll.earnings.filter(i=>i.value>0).map(item =>{
                    return {
                                "tag": "li",
                                "children": [
                                    `${item.name}:                     ${item.value.toFixed(1).toNumber().toLocaleString()}`
                                ]

                    }

                } ).concat(
                        {
                        "tag": "li",
                        "children": [
                            {
                                "tag": "strong",
                                "children": [
                                    `➕Total Earnings                    ${payroll.earnings.reduce((sum, cv) => sum + cv.value, 0).toFixed(1).toNumber().toLocaleString()}`
                                ]
                            }
                        ]
                    }
                    )
            },
            {
                "tag": "h4",
                "children": [
                    "Deductions"
                ]
            },
            {
                "tag": "ul",
                "children":  payroll.deductions.filter(i=>i.value>0).map(item =>{
                    return {
                        "tag": "li",
                        "children": [
                            `${item.name}:                     ${item.value.toFixed(1).toNumber().toLocaleString()}`
                        ]

                    }

                } ).concat(
                    {
                        "tag": "li",
                        "children": [
                            {
                                "tag": "strong",
                                "children": [
                                    `➖Total Deductions                    ${payroll.deductions.reduce((sum, cv) => sum + cv.value, 0).toFixed(1).toNumber().toLocaleString()}`
                                ]
                            }
                        ]
                    }
                )
            },
            {
                "tag": "h3",
                "children": [
                    `Net  ${payroll.cash.toFixed(1).toNumber().toLocaleString()}`
                ]
            }
        ]
    }
}
