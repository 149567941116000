import React from 'react';
import get from "lodash/get";
import Chip from "@material-ui/core/Chip";
import FaceIcon from '@material-ui/icons/Face';

const ChipFieldDeletable = ({ source, record = {} ,onDelete }) => {
    if (!record) {
        return null;
    }
    const value = get(record, source);
    if (value == null) {
        return null;
    }
    return <Chip
        icon={<FaceIcon />}
        label={value}
        onDelete={()=>onDelete(value)}
    />
}

export default ChipFieldDeletable;
