import jwtDecode from 'jwt-decode'

const initialState = {
    user: {} ,
    currentCompany: {name:localStorage.getItem('companyName'),isParentCompany:localStorage.getItem('isParentCompany')==='True', id:localStorage.getItem('companyId'),mateId:localStorage.getItem('companyMateId'), token:localStorage.getItem('companyName'), logo:localStorage.getItem('companyLogo'), childCompanies:JSON.parse(localStorage.getItem('childCompanies'))}
};

export default (previousState = initialState, { type, payload }) => {
    if (type === 'COMPANY_SELECTED') {
        //console.log('company',payload)
        localStorage.setItem('company',payload.token);
        // localStorage.setItem('isParentCompany',payload.isParentCompany);
        localStorage.setItem('companyId',payload.id);
        localStorage.setItem('companyMateId',payload.mateId);
        localStorage.setItem('companyLogo',payload.logo);
        localStorage.setItem('childCompanies',payload.childCompanies?JSON.stringify(payload.childCompanies):'[]');
        const decoded = jwtDecode(payload.token);
        localStorage.setItem('companyName',decoded['RegisteredName']);
        localStorage.setItem('isParentCompany',decoded['isParentCompany']);
        payload.name = decoded['RegisteredName'];
        payload.isParentCompany = decoded['isParentCompany']==='True';
         return {...previousState, currentCompany: payload};
    }
    if (type === 'USER_LOADED') {
       //console.log('user',payload)
       // console.log(payload)
        localStorage.setItem('user',payload.username)
        localStorage.setItem('userId',payload.id)
        localStorage.setItem('firstName',payload.firstName)
        localStorage.setItem('userEmail',payload.email)
        payload.email2&&localStorage.setItem('userEmail2',payload.email2)
        return {...previousState, currentUser: payload};
    }
    return previousState;
}
