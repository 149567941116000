// in src/App.js
import React, {useEffect} from 'react';
import '@progress/kendo-theme-material/dist/all.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import {Admin, Resource} from 'react-admin';
import {UserCreate, UserList, UserShow} from "./components/entities/users";
import Dashboard from "./components/dashboard";
import authProvider from "./Helpers/authProvider";
import PeopleIcon from "@material-ui/icons/PeopleOutline"
import MoneyIcon from "@material-ui/icons/LocalAtmOutlined"
import PlaceIcon from "@material-ui/icons/PlaceOutlined"
import AccountsIcon from "@material-ui/icons/AccountBalanceOutlined"
import TravelIcon from "@material-ui/icons/ExitToAppOutlined"
import TemplateIcon from "@material-ui/icons/ExtensionOutlined"
import {EmployeeCreate, EmployeeEdit, EmployeeList, EmployeeShow} from "./components/entities/employees";
import MyLayout from "./UIComponents/layout";
import {PositionCreate, PositionEdit, PositionList, PositionShow} from "./components/entities/positions";
import {EarningCreate, EarningEdit} from "./components/entities/earnings";
import {DepartmentCreate, DepartmentEdit, DepartmentList} from "./components/entities/departments";
import {DeductionCreate, DeductionEdit} from "./components/entities/deductions";
import dataproviders from "./Helpers/dataproviders";
import runPayrollReducer from "./reducers/runPayrollReducer";
import runPayrollSaga from "./sagas/runPayrollSaga";
import uiReducer from "./reducers/uiReducer";
import customRoutes from './routes'
import {IntlProvider} from 'react-intl'
import {CompanyEdit, CompanyList, CompanyShow} from "./components/entities/companies";
import {ExpenseCreate, ExpenseEdit} from "./components/entities/expenses";
import {AccountCreate, AccountList, AccountShow, AccountEdit} from "./components/entities/accounts";
import MomentUtils from '@date-io/moment';
import Themes from "./themes";
import {LoanCreate, LoanEdit, LoanList, LoanShow} from "./components/entities/loans";
import loanSaga from "./sagas/loanSaga";
import userReducer from "./reducers/userReducer";
import {Page404} from "./UIComponents/404Page";
import MyLoginPage from "./UIComponents/loginPage";
import importSaga from "./sagas/importSaga";
import {LeaveCreate, LeaveEdit, LeaveList} from "./components/entities/leaves";
import leaveSaga from "./sagas/leaveSaga";
import companySaga from "./sagas/companySaga";
import MyLogoutButton from "./UIComponents/logoutButton";
import {BankCreate, BankEdit, BankList, BankShow} from "./components/entities/banks";
import {CompanyBankCreate, CompanyBankEdit, CompanyBankShow} from "./components/entities/companyBanks";
import {CompanySettingCreate, CompanySettingEdit} from "./components/entities/companySettings";
import emailSaga from "./sagas/emailSaga";
import currencyReducer from "./reducers/currencyReducer";
import payrollStatusReducer from "./reducers/payrollStatusReducer";
import {IncomeTaxRuleEdit, IncomeTaxRuleList} from "./components/entities/incomeTaxRules";
import {OverTimeTypeCreate, OverTimeTypeEdit, OverTimeTypeList} from "./components/entities/overtimeTypes";
import appSaga from "./sagas/appSaga";
import {CurrencyRateCreate, CurrencyRateEdit, CurrencyRateList} from "./components/entities/currencyRates";
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {PayrollRunList} from "./components/entities/payrollRuns";
import HistoryIcon from "@material-ui/icons/History"
import DepIcon from "@material-ui/icons/Business"
import CostCenterIcon from "@material-ui/icons/StorageOutlined"
import PosIcon from "@material-ui/icons/WorkOutline"
import EmpExIcon from "@material-ui/icons/TransferWithinAStationOutlined"
import {LoanTemplateCreate, LoanTemplateEdit, LoanTemplateList} from "./components/entities/loanTemplates";
import {EmployeeExpenseCreate, EmployeeExpenseEdit, EmployeeExpenseList, EmployeeExpenseShow} from "./components/entities/employeeExpenses";
import {SiteCreate, SiteEdit, SiteList, SiteShow} from "./components/entities/sites";
import {PerdiemRateCreate, PerdiemRateEdit, PerdiemRateShow} from "./components/entities/perdiemRates";
import {JobLevelCreate, JobLevelEdit, JobLevelShow} from "./components/entities/jobLevels";
import matesSaga from "./sagas/matesSaga";
import matesReducer from "./reducers/matesReducer";
import settingReducer from "./reducers/settingReducer";
import tikReducer from "./reducers/tikReducer";
import publishSaga from "./sagas/publishSaga";
import {CostCenterCreate, CostCenterEdit, CostCenterList} from "./components/entities/costCenters";
import {useStore} from "react-redux";
import consolidatedPayrollReducer from "./reducers/consolidatedPayrollReducer";
import consolidatedPayrollSaga from "./sagas/consolidatedPayrollSaga";
import ModalProvider from "mui-modal-provider";
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import reportSaga from "./sagas/reportSaga";
import reportReducer from "./reducers/reportReducer";

//
// const theme = createMuiTheme({
//     palette: {
//         secondary:{
//             light: blueGrey["100"],
//             main: blueGrey["100"],
//             dark: blueGrey.A700,
//             contrastText: '#5b5b5b',
//         },
//         primary: blue
//
//     },
//     slider: {
//            // size: 3000
//     },
//     overrides: {
//         MenuItemLink: { // override the styles of all instances of this component
//             icon: { // Name of the rule
//                // color: '#FFF', // Some CSS
//             },
//             root: { // Name of the rule
//                 //color: '#FFF', // Some CSS
//             },
//         },
//     },
// });



const client = localStorage.getItem('client')?localStorage.getItem('client').toLowerCase():process.env.REACT_APP_CLIENT;

const resourses = permissions=>  [
    ( permissions.includes('Admin')||permissions.includes('Manager')) &&
    <Resource name='payrollRuns'  options={{  label:'History'  }} icon={HistoryIcon} list={ PayrollRunList}/>,


    <Resource icon={DepIcon} name="departments" create={DepartmentCreate} list={DepartmentList} edit={DepartmentEdit}/>,
    <Resource icon={CostCenterIcon} options={{  label:'Cost Centers'  }}  name="costCenters" create={CostCenterCreate} list={CostCenterList} edit={CostCenterEdit}/>,

    <Resource icon={PosIcon} name="positions" show={PositionShow} create={ ( permissions.includes('Admin')||permissions.includes('Manager')) && PositionCreate} list={ (permissions.includes('Admin')||permissions.includes('Manager')) && PositionList}
              edit={ (permissions.includes('Admin')||permissions.includes('Manager')) && PositionEdit}/>,
    <Resource name="earnings" edit={EarningEdit} create={EarningCreate}/>,
    <Resource name="deductions" edit={DeductionEdit} create={DeductionCreate}/>,
    <Resource name="expenses" edit={ExpenseEdit} create={ExpenseCreate}/>,
    <Resource name="accounts" icon={AccountsIcon} create={AccountCreate} list={AccountList} show={AccountShow} edit={AccountEdit}/>,
    <Resource name="loans" icon={MoneyIcon} create={LoanCreate} list={LoanList} edit={LoanEdit} show={LoanShow}/>,
    <Resource name="leaves" icon={TravelIcon} edit={LeaveEdit} list={LeaveList} create={LeaveCreate}/>,
    <Resource  options={{  label:'Perdiem/Expenses'  }} icon={EmpExIcon}   name="employeeExpenses" edit={EmployeeExpenseEdit} list={client==='xork'&&EmployeeExpenseList} create={EmployeeExpenseCreate} show={EmployeeExpenseShow}/>,
    <Resource name="accountTypes"/>,
    <Resource name="leaveTypes"/>,
    <Resource name="transactionRecords"/>,
    <Resource name="overtimeTypes"/>,
    <Resource name="companySettings" edit={CompanySettingEdit} create={CompanySettingCreate}/>,
    <Resource  name='companyBanks' edit={CompanyBankEdit} create={CompanyBankCreate} show={CompanyBankShow}/>,
    <Resource name="employees" icon={PeopleIcon} show={EmployeeShow} create={ ( permissions.includes('Admin')||permissions.includes('Manager')) &&EmployeeCreate} list={EmployeeList}
              edit={( permissions.includes('Admin')||permissions.includes('Manager')) &&EmployeeEdit}/>,
    <Resource name="loanTemplates" options={{  label:'Loan Templates'  }} icon={TemplateIcon} list={LoanTemplateList} edit={LoanTemplateEdit} create={LoanTemplateCreate}/>,
    <Resource  name="jobLevels" edit={JobLevelEdit} create={JobLevelCreate} show={JobLevelShow}/>,
    <Resource  icon={PlaceIcon}  name="sites" edit={SiteEdit} list={client==='xork'&&SiteList} create={SiteCreate} show={SiteShow}/>,
    <Resource  name="perdiemRates" edit={PerdiemRateEdit} create={PerdiemRateCreate} show={PerdiemRateShow}/>,

]

const dataProvider = dataproviders //jsonServerProvider('http://jsonplaceholder.typicode.com');
const App = () => {

    // const store= useStore();
    // //
    // useEffect(() => store.dispatch({type:'APP_STARTUP'}), [store]);
   return <IntlProvider locale='en'>
       <ThemeProvider theme={Themes.default}>
       <ModalProvider legacy>
       <MuiPickersUtilsProvider utils={MomentUtils}>
        <Admin logoutButton={MyLogoutButton} loginPage={MyLoginPage}   catchAll={Page404} customRoutes={customRoutes} customSagas={[appSaga, runPayrollSaga, companySaga, loanSaga, importSaga, leaveSaga, emailSaga, matesSaga, publishSaga, consolidatedPayrollSaga, reportSaga]}
               customReducers={{runPayroll: runPayrollReducer, ui: uiReducer, user: userReducer, currency:currencyReducer, payrollStatus:payrollStatusReducer, mates:matesReducer, setting: settingReducer, consolidatedPayroll:consolidatedPayrollReducer, tik:tikReducer, report:reportReducer}} layout={MyLayout}
               theme={Themes.default} authProvider={authProvider} dashboard={Dashboard} dataProvider={dataProvider}>
            {/*<Resource filter={{id:'x'}} create={UserCreate}  name="users" list={ListGuesser} edit={EditGuesser} />*/}
            {/*<Resource name='posts'/>*/}
            {/*<div>hihi</div>*/}
            {permissions => [

                <Resource name='companies' show={CompanyShow} edit={permissions.includes('Manager')&&CompanyEdit} list={ permissions.includes('Superuser')?CompanyList:null}  />,
                 <Resource name='users' create={ permissions.includes('Superuser') && UserCreate} list={ permissions.includes('Superuser') && UserList} show={ permissions.includes('Superuser') && UserShow}  />,
                <Resource name='banks' create={ permissions.includes('Superuser') && BankCreate} list={ permissions.includes('Superuser') && BankList} show={BankShow} edit={BankEdit}  />,
                permissions.includes('Superuser') && <Resource  options={{  label:'Income Tax Rates'  }} name='incomeTaxRules'  list={IncomeTaxRuleList} edit={IncomeTaxRuleEdit}  />,
                permissions.includes('Superuser') && <Resource options={{  label:'Overtime Rates'  }} name='overtimeTypes'  list={OverTimeTypeList} edit={OverTimeTypeEdit} create={OverTimeTypeCreate}  />,
                <Resource options={{  label:'Currency Rates'  }} name='currencyRates'  list={ permissions.includes('Superuser') && CurrencyRateList} create={CurrencyRateCreate} edit={CurrencyRateEdit}  />,
            ].concat(!permissions.includes('Superuser')?resourses(permissions):null)}
        </Admin>
       </MuiPickersUtilsProvider>
       </ModalProvider>
       </ThemeProvider>
    </IntlProvider>

};

export default App;
