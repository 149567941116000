import React, {useState} from "react";
import {Grid, Input, MenuItem, Select} from "@material-ui/core";
import {ArrowForward as ArrowForwardIcon} from "@material-ui/icons";
import {useTheme} from "@material-ui/styles";
import {Bar, BarChart} from "recharts";
import classnames from "classnames";
// styles
import useStyles from "./styles";
import Widget from "../../UIComponents/Widget";
import {Typography} from "../../UIComponents/Wrappers";
import MultiCurrency from "../multiCurrency";


// components


export default function PayrollStat(props) {
  var { product, total, color, bottomRows, simple, runs,dataKey} = props;
  var classes = useStyles();
  var theme = useTheme();
  //console.log(runs,dataKey)

  // local
  var [value, setValue] = useState("current");

  return (
    <Widget
      header={
        <div className={classes.title}>
          <Typography variant="h5">{product}</Typography>

          <Select
            value={value}
            onChange={e => setValue(e.target.value)}
            input={
              <Input
                disableUnderline
                classes={{ input: classes.selectInput }}
              />
            }
            className={classes.select}
          >
            <MenuItem value="current">Latest Payroll</MenuItem>
            <MenuItem value="year">Year</MenuItem>
            <MenuItem value="overall">Overall</MenuItem>
          </Select>
        </div>
      }
      upperTitle
    >
      {!!total[value].value&&<><div className={classes.totalValueContainer}>
        <div className={classes.totalValue}>
          <Typography size="md" weight="medium">

            <MultiCurrency
                value={Math.abs(total[value].value)}

            />

          </Typography>
          <Typography color={total.percent.profit ? "success" : "secondary"}>
            &nbsp;{total.percent.profit ? "+" : "-"}
            {total.percent.value}%
          </Typography>
        </div>
        <BarChart width={150} height={70} data={runs}>
          <Bar
              dataKey={dataKey}
              fill={theme.palette[color].main}
              radius={10}
              barSize={10}
          />
        </BarChart>
      </div>
      {!simple &&   <div className={classes.bottomStatsContainer}>

        {bottomRows.map(r => <div className={classnames(classes.statCell, classes.borderRight)}>
              <Grid container alignItems="center">
                <Typography variant="h6">
                  <MultiCurrency
                      value={Math.abs(r[value].value)}

                  />

                </Typography>
                <ArrowForwardIcon
                    className={classnames(classes.profitArrow, {
                      [!r[value].profit]: classes.profitArrowDanger,
                    })}
                />
              </Grid>
              <Typography size="sm" color="text" colorBrightness="secondary">
                {r.title}
              </Typography>
            </div>
        )}


        {/*<div className={classes.statCell}>*/}
        {/*<Grid container alignItems="center">*/}
        {/*<Typography variant="h6">*/}
        {/*<FormattedNumber*/}
        {/*value=  {payable[value].value}*/}

        {/*/>*/}

        {/*</Typography>*/}
        {/*<ArrowForwardIcon*/}
        {/*className={classnames(classes.profitArrow, {*/}
        {/*[!expense[value].profit]: classes.profitArrowDanger,*/}
        {/*})}*/}
        {/*/>*/}
        {/*</Grid>*/}
        {/*<Typography size="sm" color="text" colorBrightness="secondary">*/}
        {/*Payable withholding*/}
        {/*</Typography>*/}
        {/*</div>*/}
        {/*<div className={classnames(classes.statCell, classes.borderRight)}>*/}
        {/*<Grid container alignItems="center">*/}
        {/*<Typography variant="h6">*/}
        {/*<FormattedNumber*/}
        {/*value= {paid[value].value}*/}

        {/*/>*/}
        {/*</Typography>*/}
        {/*<ArrowForwardIcon*/}
        {/*className={classnames(classes.profitArrow, {*/}
        {/*[classes.profitArrowDanger]: !paid[value].profit,*/}
        {/*})}*/}
        {/*/>*/}
        {/*</Grid>*/}
        {/*<Typography size="sm" color="text" colorBrightness="secondary">*/}
        {/*Cash (Paid)*/}
        {/*</Typography>*/}
        {/*</div>*/}
        {/**/}

        </div>}
        </>}

    </Widget>
  );
}

// #######################################################################

function getRandomData() {
 return [{netPay:13},{netPay:13}]
}


