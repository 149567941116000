

// in src/authProvider.js
 //import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';
//https://lenovo-ypro-ii:44443
// in src/authProvider.js  const request = new Request('https://addis09.hst-et.com:44333/api/users/authenticate', {
const authProvider = {
    login: ({ username, password }) =>  {
        const request = new Request(`${localStorage.getItem('domain')}/api/users/authenticate`, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json','X-COMPANY': 'ROOT','Bypass-Tunnel-Reminder':"true" }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token, roles }) => {

                localStorage.setItem('token', token);
                localStorage.setItem('roles', JSON.stringify(roles));
                if(roles.includes('Superuser'))
                  localStorage.setItem('company', "ROOT");
                else
                  localStorage.removeItem('company')
            });
    },
    logout: () => {
       // console.log('loginout',x)
        localStorage.removeItem('token');
        localStorage.removeItem('roles');
        localStorage.removeItem('company');
        localStorage.removeItem('companyName');
        localStorage.removeItem('firstName');
        localStorage.removeItem('companyId');
        localStorage.removeItem('companyMateId');
        localStorage.removeItem('isParentCompany');
        localStorage.removeItem('userEmail2');
        localStorage.removeItem('userEmail');
        localStorage.removeItem('childCompanies');
        localStorage.removeItem('companyLogo');
        localStorage.removeItem('user');
        localStorage.removeItem('userId');
        sessionStorage.clear();
        return Promise.resolve();
    },
    checkAuth: () => {
        //console.log('checking',localStorage.getItem('company'))
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    },
    checkError: (error) => {
        const status = error.status;


        if (status === 401 || status === 403) {
             localStorage.removeItem('token');
             localStorage.removeItem('company');
            return Promise.reject();
        }
        // if(status === 412) {
        //    // localStorage.removeItem('company');
        //    // localStorage.setItem('company',"ERROR")
        //    //  if( localStorage.getItem('company') && localStorage.getItem('company')!=="ROOT")
        //    //  window.location.replace("/#/companyError")
        //    //  else {
        //    //      window.location.replace("/#/selectCompanies")
        //    //  }
        //     return Promise.resolve()
        // }
        return Promise.resolve();
    },
    getPermissions: params =>{
        const roles = JSON.parse(localStorage.getItem('roles'));
        return roles ? Promise.resolve(roles) : Promise.reject();
    },
};

export default authProvider;




// export default (type, params) => {
//     // called when the user attempts to log in
//     if (type === AUTH_LOGIN) {
//         const { username } = params;
//         localStorage.setItem('username', username);
//         // accept all username/password combinations
//         return Promise.resolve();
//     }
//     // called when the user clicks on the logout button
//     if (type === AUTH_LOGOUT) {
//         localStorage.removeItem('username');
//         return Promise.resolve();
//     }
//     // called when the API returns an error
//     if (type === AUTH_ERROR) {
//         const { status } = params;
//         if (status === 401 || status === 403) {
//             localStorage.removeItem('username');
//             return Promise.reject();
//         }
//         return Promise.resolve();
//     }
//     // called when the user navigates to a new location
//     if (type === AUTH_CHECK) {
//         return localStorage.getItem('username')
//             ? Promise.resolve()
//             : Promise.reject();
//     }
//     return Promise.reject('Unknown method');
// };
