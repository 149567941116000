import React, {useEffect} from 'react';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import SyncMates from "../sync/syncMates";
import {Button} from "@progress/kendo-react-buttons"
import MergePayroll from "../MergePayroll";
const MergePayrollDialog = ({payrollRun,id}) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);



    return (
        <>
            <Button className="k-info-colored" onClick={()=>setDialogOpen(true)}>Merge</Button>
            <Dialog disableBackdropClick maxWidth='md'  fullWidth open={dialogOpen} onClose={()=> setDialogOpen(false)}>
                <DialogContent dividers>
                  <MergePayroll payrollRun={payrollRun} id={id} onMerge={()=>setDialogOpen(false)}/>
                </DialogContent>

            </Dialog>
        </>
    );
};

export default  MergePayrollDialog;
