import React from 'react';
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {connect, useDispatch} from "react-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloudIcon from "@material-ui/icons/CloudOutlined"
import ServerIcon from "@material-ui/icons/ApartmentOutlined"
import WebIcon from "@material-ui/icons/LanguageOutlined"
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import Loader from 'react-loader-spinner'

const LoadingIcon = ({show,serverType}) => {
     if(show)
      return <div>

    <Loader
        visible={show}
        type="Bars"
        color="#FFF"
        height={40}
        width={40}
    />
          <div style={{color:'white',fontSize:'small'}}>Connecting...</div>
    </div>
    else return <ServerDisplayIcon serverType={serverType}/>
}

const ServerDisplayIcon = ({serverType}) => {
   // console.log("type",serverType)
    if(serverType==='cloud')
        return <CloudIcon color="secondary"/>
    else if(serverType==='local')
        return <ServerIcon color="secondary"/>
    else return <WebIcon color="secondary"/>

};




const SwitchServerMenu = ({serverType,loading,client,server}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [domain, setDomain] = React.useState(serverType==='cloud'||serverType==='local'?'':serverType);
  const dispatch = useDispatch();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function handelChoice(choice) {
        if(choice==='custom')
        {
            setOpen(true);
        }
        else {
            dispatch({type:'SWITCH_PAYDAY_SERVER',payload:choice})
            handleClose()
        }


    }



    const handleDialogClose = () => {
        setOpen(false);
    };

    const handleOk = ()=> {
        dispatch({type:'SWITCH_PAYDAY_SERVER',payload:domain})
        setOpen(false);
        handleClose()
    };


    return (
        <div>
            <IconButton onClick={handleClick} aria-label="delete">
              <LoadingIcon show={loading>0} serverType={serverType}/>
            </IconButton>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >

                <MenuItem onClick={()=>handelChoice('local')}>
                        <ListItemIcon>
                            <ServerIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit">Local Office</Typography>
                </MenuItem>
                {server!=='xork'&&<MenuItem onClick={() => handelChoice('paydserver')}>
                    <ListItemIcon>
                        <CloudIcon fontSize="small"/>
                    </ListItemIcon>
                    <Typography variant="inherit">Through Internet - {server}</Typography>
                </MenuItem>}
                {client==='xork'&&<MenuItem onClick={() => handelChoice('paydaycloud')}>
                    <ListItemIcon>
                        <CloudIcon fontSize="small"/>
                    </ListItemIcon>
                    <Typography variant="inherit">Payday Cloud</Typography>
                </MenuItem>}
                <MenuItem onClick={()=>handelChoice('custom')}>
                    <ListItemIcon>
                        <WebIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Custom</Typography>

                </MenuItem>
            </Menu>

            <Dialog
                open={open}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Change PayDay Server"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Type in the payday server name with port number
                    </DialogContentText>
                    <TextField id="standard-name" label="PayDay Server" value={domain} onChange={e=>setDomain(e.target.value)} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleOk} color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};

export default connect(state=>({loading:state.admin.loading,server:state.ui.server,serverType:state.ui.serverType}))(SwitchServerMenu);
