import React, {useEffect, useState} from 'react';
import {Error, Loading, useAuthenticated, useLogout, usePermissions, useQueryWithStore, useRedirect} from 'react-admin'
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import {ReactComponent as PayDayText} from "../../UIComponents/icons/paydayText.svg";
import {ReactComponent as PayDayLogo} from "../../UIComponents/icons/paydayLogo.svg";
// import Typography from "@material-ui/core/Typography";
import pLogo from "../../UIComponents/images/pLogo.png"
import paydayColorLogoText from "../../UIComponents/images/paydayColorLogoText.png"
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {getGreetingTime} from "../../Helpers/timeHelpers";
import CardActionArea from "@material-ui/core/CardActionArea";
// import {Typography} from "../../UIComponents/Wrappers";
const useStyles = makeStyles(theme=>({
    root:{
        backgroundColor: "#F7F7F7",
        minHeight:"100vh",
       // paddingTop:30
    },
    content: {
        padding:30
       // minHeight: "calc(100vh - 100px)"
},
    footer: {
        minHeight: "100px",
        backgroundColor:" #045de9",
        padding:15,
        backgroundImage: "linear-gradient(315deg, #045de9 0%, #09c6f9 74%)"
    },
        card: {
            //margin:'0 70px 70px 70px',
            padding: '50px',
            backgroundColor: "#eaeaea",

},
    cardHeader: {
            //color:"#ffffff",
      //  backgroundColor: "#098EF6"
    },
    searchBox: {
       // align: 'right',
    },
    intro: {
        padding:'20px'
    }

}));

const CompanySelect = (props) => {
    useAuthenticated()
    const permissions= usePermissions()
    const logout = useLogout()
    const redirect =  useRedirect()
    // const [loading, setLoading] = useState(true);
    // console.log(props.location.flush)

    useEffect(() => {
        if (props.location.flush) {
            window.location.reload();

        } else {
            console.log('page already loaded');
        }
    }, [props.location.flush]);

    if(permissions.loaded && permissions.permissions.includes('Superuser'))
    {
        redirect('/admin')
    }
    // if(localStorage.getItem('company')==='ROOT') {
    //     redirect('/admin')
    // }


    const classes = useStyles();
       const [user,setUser] = React.useState();
        const { loaded, error} = useQueryWithStore({
        type: 'getMany',
        resource: 'users/me',
        payload: {filter:{},pagination:{},sort:{}}
    },{
        onSuccess: (res) =>{
            setUser(res.data)
            props.dispatch({type:'USER_LOADED',payload:res.data})
            return res.data;
        }
        }
        );


    if (!loaded) { return <Loading />; }
    if (error) { return <Error />; }
    if (!user) return null;

   const  setCurrentCompany = company => {
       // console.log({company})
       props.dispatch({type:'COMPANY_SELECT',payload:company})
       if(company&&company.childCompanies&&company.childCompanies.length>0) {
           redirect('/consolidatedReport/tax')
       }


    }


    return (
        <Grid container direction="column" justify='space-between' className={classes.root} >
            <Grid item container direction="column" className={classes.content}>

                <Grid container justify="space-between">
                    <Grid item>
                      <img height={75} src={pLogo} />
                    </Grid>
                    <Grid item>

                        <Button borderRadius="50%"  variant="contained" style={{backgroundColor:"#FFBB00", color:"#FFF"}} onClick={logout} >Logout</Button>
                    </Grid>
                </Grid>

                <Grid container justify="center">
                    <Grid item xs={6}>
                        <Grid container direction="column">
                            <Typography variant="h3"  >
                                {getGreetingTime()} {user.username.charAt(0).toUpperCase() + user.username.slice(1)}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Select a Company to Begin
                            </Typography>
                        </Grid>

                    </Grid>
                    <Divider light flexItem orientation="vertical" />
                    <Grid item xs={4}>
                        <Grid container direction="column">

                        <Typography variant="h4" color="textSecondary">
                            Welcome to PayDay
                        </Typography>
                            <Typography variant="body2" color="textSecondary">
                                a Payroll management system that lets you run your payroll right, clean and easy
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                                <Grid style={{marginTop:50}} container spacing={3}  >
                            {user.userCompanies.map(uc=>
                                                    <Grid  item xs={3}>
                                    <Card>
                                        <CardActionArea onClick={()=>setCurrentCompany(uc.company)} disabled={!uc.company.active}>
                                        <CardHeader
                                            title={uc.company.name}
                                            subheader={uc.company.mateId?`@${uc.company.mateId}`:''}
                                            titleTypographyProps={{ align: 'left',color:"textSecondary" }}
                                            subheaderTypographyProps={{ align: 'center' }}
                                            className={classes.cardHeader}
                                        />
                                <CardContent>
                                    {!uc.company.active&&
                                    <Typography variant={"caption"}>The Company is not Activated Contact the local payday administrator or payday support team (support@payday.truwrk.com)</Typography>
                                    }
                                    <img height={50} src={uc.company.logo} />

                                </CardContent>
                                        {/*<CardActions>*/}

                                        {/*    <Button color='primary' variant='outlined' onClick={()=>setCurrentCompany(uc.company)} disabled={!uc.company.active}>Select</Button>*/}
                                        {/*</CardActions>*/}
                                        </CardActionArea>
                            </Card>

                                                    </Grid>
                                        )}
                                            </Grid>



            </Grid>
            <Grid item className={classes.footer} container justify="space-between" alignItems="flex-end">

                    <Grid item>
                       <span style={{color:"white"}}>www.payday.truwrk.com</span>
                    </Grid>
                    <Grid item>
                <img height={50}  src={paydayColorLogoText}/>
                    </Grid>

            </Grid>


{/*        <Card >*/}

{/*<Grid container>*/}
{/*<Grid item>*/}
{/*            <PayDayText width={300} height={100}/>*/}
{/*</Grid>*/}
{/*    <Grid item xs>*/}
{/*    </Grid>*/}
{/*    <Grid item  >*/}
{/*    <PayDayLogo  width="100" height="100"/>*/}
{/*    </Grid>*/}
{/*</Grid>*/}
{/*            <Typography color='textSecondary' variant='subtitle1'>Easy,  Smart Payroll System</Typography>*/}
{/*<div className={classes.intro}>*/}

{/*    <Button color="primary" variant="outlined" onClick={logout}>logout</Button>*/}
{/*            <Typography><strong>Welcome {user.username}</strong></Typography>*/}

{/*            <Typography>You are granted access to these companies, select one to run your day</Typography>*/}
{/*</div>*/}
{/*    <Box display="flex" flexDirection="row-reverse">*/}
{/*                <TextField className={classes.searchBox} id="outlined-basic" label="Search" variant="outlined" />*/}
{/*            </Box>*/}
{/*            <Card className={classes.card}>*/}

{/*                <Grid container spacing={3}  >*/}
{/*            {user.userCompanies.map(uc=>*/}
{/*                                    <Grid  item xs={3}>*/}
{/*                    <Card>*/}
{/*                        <CardHeader*/}
{/*                            title={uc.company.name}*/}
{/*                            subheader={uc.company.mateId?`@${uc.company.mateId}`:''}*/}
{/*                            titleTypographyProps={{ align: 'center' }}*/}
{/*                            subheaderTypographyProps={{ align: 'center' }}*/}
{/*                            className={classes.cardHeader}*/}
{/*                        />*/}
{/*                <CardContent>*/}

{/*                </CardContent>*/}
{/*                        <CardActions>*/}

{/*                            <Button color='primary' variant='outlined' onClick={()=>setCurrentCompany(uc.company)} disabled={!uc.company.active}>Select</Button>*/}
{/*                        </CardActions>*/}

{/*            </Card>*/}

{/*                                    </Grid>*/}
{/*            )}*/}
{/*                </Grid>*/}
{/*            </Card>*/}
{/*            </Card>*/}
        </Grid>

    );
};

export default connect()(CompanySelect);
