import React from 'react';
import Iframe from 'react-iframe'
import {parse} from "query-string";

const PaydayDocs = props => {
  const {doc="/kb",path} =parse(props.location.search);

    return (
        <div>

            <Iframe url={`${process.env.REACT_APP_DOCUMENTATION||'https://payday.truwrk.com/docs/'}${doc}${path}`}
                    display="initial"
                    frameBorder="0"
                    width="110%"
                    height={700}
                    position="relative"/>
        </div>
    );
};

export default PaydayDocs;
