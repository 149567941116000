import React from 'react';
import {useDataProvider} from "react-admin";
import {ImportButton} from "../import/importButton";
import {useDispatch} from "react-redux";
import {deductionTypes, earningTypes} from "../../Helpers/typeList";

const ImportEarningsDeductionsFromFile = ({onPayrollDeductionEarnings}) =>{
    const dataProvider = useDataProvider();


    const dispatch = useDispatch();

    const onImport = (res,values) => {
        // console.log("imporintg extra")
        dataProvider
            .getList('employees')
            .then(response => {
                if(response.data.length)
                {
                     console.log({values})
                    const tss = values.map(ts=>{


                        const e = response.data.find(e=>
                            ((e.orgID&&ts.orgID)&&(e.orgID.replace(/\s+/g, '').toLowerCase() === ts.orgID.replace(/\s+/g, '').toLowerCase()))
                            ||
                            ((e.fullname&&ts.fullName)&&(e.fullname.replace(/\s+/g, '').toLowerCase() === ts.fullName.replace(/\s+/g, '').toLowerCase())));
                        if(e&&ts.value&&+ts.value!==0)
                        {
                            const extraData = {}
                            Object.keys(ts).forEach(key=>{
                                if(key.startsWith("extra")&&key!=='extra') {
                                   // ts.address.push(key);
                                    const name = key.split('.')[1]?key.split('.')[1]:""
                                    // console.log(name,"earnings")

                                    extraData[name]= (+ts[key])

                                }
                            })

                            return {employeeId:e.id, orgID:e.orgID,category:ts.category||ts.kind||'deduction',cash:ts.cash, type:ts.type, name:ts.name, value:ts.value, taxOption:ts.taxOption, extraData }
                        }
                    }).filter(t=>t)

                    // console.log(tss)

                    // console.log(response.data[0].payrolls); // { id: 123, title: "hello, world" }
                    // onPayrollDeductionEarnings( tss );
                    dispatch({type:"PAYROLL_EARNINGS_DEDUCTIONS_UPDATED",payload: {items:tss, idField:"employeeId", deductionTypes, earningTypes}})

                }

            });
        //return onTimeSheet(ts, "mateId")
    }

    return <ImportButton key={"deductionImport"} label='Import Earnings/Deductions' onImport={onImport}/>
}

export default ImportEarningsDeductionsFromFile
