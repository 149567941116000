import React from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

const PayrollFooterItem = props => {
    const [field, setField] = React.useState(props.field);
    const [value, setValue] = React.useState(props.value);
    return (

            <Grid container>
                <Grid item xs={5}>
                    <TextField
                        onChange={ev =>{
                            setField(ev.target.value)
                            props.onChange({value,field:ev.target.value})
                        }}
                        value={field}
                        autoFocus
                        label="Field Name"
                        margin='normal'

                    />
                </Grid>

                <Grid item xs={5}>
                    <TextField
                        onChange={ev =>{
                            setValue(ev.target.value)
                            props.onChange({field,value:ev.target.value})
                        }}
                        value={value}
                        autoFocus
                        label="Value"
                        margin='normal'

                    />
                </Grid>
                <Grid item xs={2}>
                    <IconButton onClick={props.onRemove} aria-label="delete" >
                        <DeleteIcon fontSize="large" />
                    </IconButton>



                </Grid>
            </Grid>


    );
};

export default PayrollFooterItem;
