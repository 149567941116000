import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {Title} from 'react-admin';
import React from "react";

export const Page404= () => (
    <Card>
        <Title title="Not Found" />
        <CardContent>
            <h1>404: Page not found</h1>
        </CardContent>
    </Card>
);
