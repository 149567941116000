// import MyUrlField from "../MyUrlField";
import React, {cloneElement, useState} from 'react';
import {
    ArrayField,
    Create,
    Datagrid,
    DateField,
    EmailField,
    List,
    PasswordInput,
    Show,
    SimpleForm,
    SingleFieldList,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput,
    useMutation,
    useNotify,
    useQueryWithStore,
    useRefresh
} from 'react-admin';
import Chip from "@material-ui/core/Chip";
import {Button} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";
import InputLabel from "@material-ui/core/InputLabel";
import exporterCommon from "../import/exporterCommon";
import UnassignButton from "@material-ui/icons/RemoveCircleOutline"
import IconButton from "@material-ui/core/IconButton";
import ChipFieldDeletable from "../../UIComponents/chipFieldDeletable";
import {passwordValidator} from "./helperFunctions";
import PasswordVisibilityInput from "../../UIComponents/passwordInput";
import ResetIcon from "@material-ui/icons/LockOpen"
export const StringToLabelObject = ({ record, children, ...rest }) =>
   {
      // console.log('eee',record)
       return cloneElement(children, {
        record: { label: record },
        ...rest,
    })}

const Roles = ({ record }) => {
  //  console.log(record)
   return <div>
        {record.roles.map(item => (
            <span key={item} className="chip">
        {item}
      </span>
        ))}
    </div>
};
const TextArrayField = ({ record, source }) => {
    const array = record[source]
    if (typeof array === 'undefined' || array === null || array.length === 0) {
        return <div/>
    } else {
        return (
            <>
                {array.map(item => <Chip label={item} key={item}/>)}
            </>
        )
    }
}
TextArrayField.defaultProps = { addLabel: true }

export const UserList = props => (
    <List  exporter={exporterCommon(props.resource)} {...props} >
        <Datagrid rowClick="show" >
            <TextField source="firstName" />
            <TextField source="lastName" />
            <TextField source="username" />
            <EmailField source="email" />
        </Datagrid>
    </List>
);

export const UserCreate = props => (
    <Create {...props}>
        <SimpleForm redirect='list'>
            <TextInput source="username" />
            <PasswordInput  source="password" validate={passwordValidator} />
            <TextInput source="firstName" />
            <TextInput source="lastName" />
            <TextInput type="email" source="email" />
        </SimpleForm>
    </Create>
);

export const UserShow = props => {
    console.log(props)
    const notify = useNotify();
    const refresh = useRefresh();
    const [mutate, { loading }] = useMutation();
    const removeRole = role => mutate({
            type: 'delete',
            resource: `users/${props.id}/roles`,
            payload:{id:role}
        },
        {
            onSuccess: ({ data }) => {
                notify('User UnAssigned from '+role+' Role');
                //setOpen(false)
                refresh()
            },
            onFailure: (error) => notify(`Role UnAssigment Error`, 'warning'),
        });

    const resetPassword = newPassword => mutate({
            type: 'update',
            resource: `users/${props.id}/password`,
            payload:{id:'reset',data: {newPassword}}
        },
        {
            onSuccess: ({ data }) => {
                notify('User Password Rested');
                //setOpen(false)
                refresh()
            },
            onFailure: (error) => notify(`Password Reset Error`, 'warning'),
        });



    return <Show {...props}>
        <TabbedShowLayout>
            <Tab label="Basic Info">
                <TextField source="firstName"/>
                <TextField source="lastName"/>
                <TextField source="username"/>
                <EmailField source="email"/>
                <RestPasswordButton onReset={resetPassword} />

            </Tab>
            <Tab label="Roles" path="roles">

                {/*<Roles/>*/}
                <ArrayField source="roles">
                    <SingleFieldList linkType={false}>
                        <StringToLabelObject>
                            <ChipFieldDeletable source="label" onDelete={removeRole}/>
                            {/*<Button>XX</Button>*/}
                        </StringToLabelObject>
                    </SingleFieldList>
                </ArrayField>


                <AssignRoleButton/>

            </Tab>
            <Tab label="Companies" path="companies">
                <ArrayField label="Assigned Companies" source='userCompanies'>
                    <Datagrid>
                        <TextField label='Company' source="company.name"/>
                        <DateField source="dateAssinged"/>
                        <UnAssignButton/>

                    </Datagrid>
                </ArrayField>

                <AssignToCompanyButton/>
            </Tab>

        </TabbedShowLayout>
    </Show>
};

const UnAssignButton =  ({record,...props}) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const [unAssign] = useMutation(
        {
            type: 'delete',
            resource: `users/${record.userId}/companies`,
            payload:{id:record.company.id}
        },
        {
            onSuccess: ({ data }) => {
                notify('User UnAssigned');
                //setOpen(false)
                refresh()
            },
            onFailure: (error) => notify(`User UnAssigment Error`, 'warning'),
        }
    );

   return <IconButton onClick={unAssign} aria-label="Unassign" >
        <UnassignButton/>
    </IconButton>
}

const RestPasswordButton = ({onReset}) => {
    const [open,setOpen] = useState(false);
    const [newPassword,setNewPassword] = useState();
    const [error,setError] = useState();
    return <div>

        <IconButton onClick={()=>setOpen(true)} aria-label="resetpassword"  color="primary">
            <ResetIcon />
        </IconButton>

        {/*<Button onClick={()=>setOpen(true)} variant="contained">*/}
        {/*   Rest Password*/}
        {/*</Button>*/}
        <Dialog open={open}>
            <DialogContent>
                <PasswordVisibilityInput

                    label='New Password'
                    error={newPassword&&error}
                    value={newPassword}
                    onChange={e=> {
                        setError(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(newPassword))
                        setNewPassword(e.target.value)
                    }}
                    hintText="At least 1 Capital letter, a digit and a special character"
                    floatingLabelText="Enter new password"
                    errorText="Password must be at least 8 characters and  have at least 1 capital 1 number and 1 special character'"


                />

                    {/*<PasswordField*/}
                    {/*    label='New Password'*/}
                    {/*    error={!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(newPassword)}*/}
                    {/*    value={newPassword}*/}
                    {/*    onChange={e=>setNewPassword(e.target.value)}*/}
                    {/*    hintText="At least 1 Capital letter, a digit and a special character"*/}
                    {/*    floatingLabelText="Enter new password"*/}
                    {/*    errorText="Password must be at least 8 characters and  have at least 1 capital 1 number and 1 special character'"*/}
                    {/*/>*/}


            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setOpen(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={()=> {
                    if(newPassword&&!error){
                        setOpen(false)
                        onReset(newPassword)
                    }
                }} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    </div>
}


const AssignRoleButton = ({record,...props}) => {
    const [open,setOpen] = useState(false);
    const [role,setRole] = useState({});
    const notify = useNotify();
    const refresh = useRefresh();
    const { loaded, error, data: roles } = useQueryWithStore({
        type: 'getList',
        resource: 'roles',
        payload: {filter:{},pagination:{},sort:{}}
    });

    const [assign, { loading }] = useMutation(
        {
            type: 'create',
            resource: `users/${record.id}/roles`,
            payload: { data: role },
        },
        {
            onSuccess: ({ data }) => {
                notify(`User now have ${role} role`);
                setOpen(false)
                refresh()
            },
            onFailure: (error) => notify(`User Assigment Error: ${error.message}`, 'warning'),
        }
    );
 //   if(!record) return null;
    if(!roles) return null;

   // const ids = record.roles.map(uc=>uc.company.id) || [];
    // console.log(ids)
    return <div>
        <Button onClick={()=>setOpen(true)} variant="contained">
            Assign Role
        </Button>
        <Dialog open={open}>
            <DialogContent>
                <FormControl margin='normal' fullWidth  >
                    <InputLabel>Role</InputLabel>

                    <Select
                        value={role}
                        onChange={ev=>
                            setRole(ev.target.value)
                        }
                    >
                        {roles.filter(r=>!record.roles.includes(r.name)).map(ot=><MenuItem key={ot.id} value={ot.name}>{ot.name}</MenuItem>)}
                    </Select>
                </FormControl>


            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setOpen(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={assign} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    </div>
}


const AssignToCompanyButton = ({record,...props}) => {

   const [open,setOpen] = useState(false);
   const [company,setCompany] = useState({});
    const notify = useNotify();
    const refresh = useRefresh();
    const { loaded, error, data: companies } = useQueryWithStore({
        type: 'getList',
        resource: 'companies',
        payload: {filter:{filter:'Abstract eq false'},pagination:{},sort:{}}
    });

    const [assign, { loading }] = useMutation(
        {
            type: 'create',
            resource: `users/${record.id}/companies`,
            payload: { data: {id:company.id} },
        },
        {
            onSuccess: ({ data }) => {
                notify('User Assigned');
                setOpen(false)
                refresh()
            },
            onFailure: (error) => notify(`User Assigment Error: ${error.message}`, 'warning'),
        }
    );




    if(!record) return null;
    if(!companies) return null;

    const ids = record.userCompanies.map(uc=>uc.company.id) || [];
   // console.log(ids)
    return <div>
            <Button onClick={()=>setOpen(true)} variant="contained">
                Assign To Company
            </Button>
        <Dialog open={open}>
            <DialogContent>
                <FormControl margin='normal' fullWidth  >
                    <InputLabel>Company</InputLabel>

                    <Select
                        value={company}
                        onChange={ev=>
                            setCompany(ev.target.value)
                          }
                    >
                        {companies.filter(c => !ids.includes(c.id)).map(ot=><MenuItem key={ot.id} value={ot}>{ot.name}</MenuItem>)}
                    </Select>
                </FormControl>


            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setOpen(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={assign} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    </div>
}



// const AssignToCompanyButton =connect(null,dispatch=>({
//     assignCompany: (userId,companyId)=> dispatch({type:'ASSIGN_COMPANY', payload:{userId,companyId} })
// }))(AssignToCompanyButtonComp)
