import React from 'react';
import Card from "@material-ui/core/Card";

import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import ErrorIcon from "@material-ui/icons/Error"
import Box from "@material-ui/core/Box";

const CompanyAuthError = () => {
    return (
       <Card>
           <CardContent>
               <Box textAlign="center" m={1}>
                   <ErrorIcon color="primary" style={{ fontSize: 200 }}/>
                   <Typography color="textSecondary" variant="subtitle1">
                       This Company Has an authentication error. Please contact the support team.
                   </Typography>

                   <Button component={Link} to={`/selectCompanies`}  >Select Another Company</Button>

               </Box>
           </CardContent>
       </Card>
    );
};

export default CompanyAuthError;

