import React from 'react';
import {NullableBooleanInput, NumberInput, TextInput} from 'react-admin'
import get from 'lodash/get';

export  const DynamicInput = ({ source, record = {}, ...rest  }) => {
    if (!record) {
        return null;
    }
    const value = get(record, source);
    // if (value == null) {
    //     return null;
    // }

    const valueType = get(record, "valueType");
    if (valueType === 'boolean') {
     return  <NullableBooleanInput format={val=>val==='true'} parse={val=>val?"true":"false"} {...rest} source={source}/>

    }
    if (valueType === 'number') {
        return  <NumberInput {...rest} parse={val=>val.toString()} source={source}/>

    }

    return <TextInput source={source} {...rest}/>

}
