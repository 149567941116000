import React, {useRef} from 'react';
import {TextField,NumberField} from 'react-admin'
import TablePagination from "@material-ui/core/TablePagination";
import PayrollExcelExport from "../payroll/excelExport";
import Button from "@material-ui/core/Button";
import moment from "moment";
import {ExcelIcon} from "../../UIComponents/icons";
import PrintIcon from "@material-ui/icons/Print"
import {green} from '@material-ui/core/colors';
import ReactToPrint from "react-to-print";
import MultiCurrencyField from "../../UIComponents/multiCurrencyField";
import MyDatagrid from "../accountsGrid";
import ReportHeader from "./reportHeader";
import PayrollSheetGrid from "./payrollSheetGrid/payrollSheetGrid";
import {makeStyles} from "@material-ui/core/styles";
import clsx from  'clsx';
import {multiDateFun} from "../../Helpers/multiDateFun";
const useStyles = makeStyles({
    container: {
        position: 'relative'
    },
    view: {
        position: 'absolute',
        top: 0,
        left: 0,
       // minWidth: 650,
    },
    overlay: {
        zIndex: 9,
        background: '#009938'
    },
});

const PayrollSheetReport = props => {
    const classes = useStyles();
    const componentRef = useRef();
    const {payrollRun,department,site, sort, handelSort, filteredPayrolls, employmentType, logo} = props
    const exportExcel = useRef()
    const [page, setPage] = React.useState(0);
     //const [filteredPayrolls,setFilteredPayrolls] = useState(props.filteredPayrolls)
    const [rowsPerPage, setRowsPerPage] = React.useState(100);

   const[scroll,setScrollable] = React.useState("scrollable");
    // useEffect(() => {
    //     setFilteredPayrolls(props.filteredPayrolls);
    //    // handelSort(sort.field)
    // }, [props.filteredPayrolls])



    const exportToExcel = () => {

        exportExcel.current.save();
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    let ids = filteredPayrolls.map((p,index) => index)
    ids=rowsPerPage > 0
        ? ids.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : ids
    return (
        <div>
            {/*<PayrollExcelExport templates={props.templates} ref={exportExcel} data={filteredPayrolls} fileName={`PayDay ${props.companyName} Report ${moment(payrollRun.payrollDate).format('MMMM YYYY')} ${department||''} ${site||''}.xlsx`}/>*/}
            {/*<Button variant="outlined"  color="primary"  startIcon={<ExcelIcon  style={{ color: green[500] }}/>} onClick={exportToExcel}>Export to Excel</Button>*/}

            <ReactToPrint
                //onBeforePrint={()=>setScrollable("none")}
                onBeforeGetContent={()=>new Promise(resolve => {
                    setScrollable("none")
                    setTimeout(resolve, 500)

                })}
                trigger={() => <Button  variant="outlined"  color="primary"  startIcon={<PrintIcon/>}  >Print</Button>}
                content={() => componentRef.current}
                onAfterPrint={()=>setScrollable("scrollable")}
                pageStyle={""}
            />
<div ref={componentRef}>
    <ReportHeader logo={logo} site={site} employmentType={employmentType} department={department} payrollRun={payrollRun} title="Payroll Sheet"/>
        {/*<div className={classes.container}>*/}
        {/*    <div className={classes.view}>*/}
                <PayrollSheetGrid companySettings={props.companySettings} onSeparateByCostCenter={props.onSeparateByCostCenter} scroll={scroll} calendar={props.calendar} logo={logo} employmentType={employmentType} payrollRun={payrollRun} site={site} department={department}  templates={props.templates} fileName={`PayDay ${multiDateFun(props.calendar,payrollRun.payrollDate,'MMMM YYYY')} ${department||''} ${site||''} ${props.companyName} `} data={filteredPayrolls} />

        {/*    </div>*/}
        {/*    <div style={{height:400,opacity:0.2}} className={clsx(classes.view, classes.overlay)} >*/}

        {/*    </div>*/}
        {/*</div>*/}

            {/*<MyDatagrid checkField={false} summeryFields={['basicSalary','overtimeTotal','additionalEarningsTotal','taxableIncome', 'incomeTax','empPension', 'companyPension','pf','absenceDeduction','totalDeduction', 'netPay','payrollDeductionTotalCash', 'cash']}   ids={ids} currentSort={sort} setSort={handelSort} data={filteredPayrolls}>*/}
            {/*    <TextField label="WorkId" source="employee.orgID" />*/}
            {/*    <TextField source="fullName" />*/}
            {/*    <TextField label="Position" source="positionName" />*/}
            {/*    <TextField  label="Department" source="departmentName" />*/}
            {/*    <MultiCurrencyField currencyRate={payrollRun.currencyRate}  source="basicSalary" />*/}
            {/*    <NumberField source="absentDays" />*/}
            {/*    <MultiCurrencyField source="absenceDeduction" />*/}
            {/*    <MultiCurrencyField source="overtimeTotal" />*/}
            {/*    <MultiCurrencyField source="additionalFixedEarnings" />*/}
            {/*    <MultiCurrencyField source="bonusesTotal" />*/}
            {/*    <MultiCurrencyField source="additionalEarningsTotal" />*/}
            {/*    <NumberField source="leaveDays" />*/}
            {/*    <MultiCurrencyField source="additionalEarningsAbsentDeduction" />*/}
            {/*    <MultiCurrencyField source="grossIncome" />*/}
            {/*    <MultiCurrencyField source="taxableIncome" />*/}
            {/*    <MultiCurrencyField source="incomeTax" />*/}
            {/*    <MultiCurrencyField source="empPension" />*/}
            {/*    <MultiCurrencyField source="companyPension" />*/}
            {/*    <MultiCurrencyField source="pf" />*/}
            {/*    <MultiCurrencyField source="companyPf" />*/}
            {/*    <MultiCurrencyField source="fixedDeductionsTotalsAndIncomeTax" label='Total Deductions from Gross' />*/}
            {/*    <MultiCurrencyField label='Net' source="netPay" />*/}
            {/*    <MultiCurrencyField label='Advance/Loan Settlement' source="payrollDeductionTotalCash" />*/}
            {/*    <MultiCurrencyField label='Net Pay' source="cash" />*/}
            {/*    <TextField label='Sign' source="sign" />*/}
            {/*</MyDatagrid>*/}
</div>
            {/*<TablePagination*/}
            {/*    rowsPerPageOptions={[5, 10, 15, filteredPayrolls.length]}*/}
            {/*    component="div"*/}
            {/*    count={filteredPayrolls.length}*/}
            {/*    rowsPerPage={rowsPerPage}*/}
            {/*    page={page}*/}
            {/*    onChangePage={handleChangePage}*/}
            {/*    onChangeRowsPerPage={handleChangeRowsPerPage}*/}
            {/*/>*/}

        </div>
    );
};

export default PayrollSheetReport;
