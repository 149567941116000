import React, {useState} from 'react';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";

const PasswordVisibilityInput = props => {

    const[showPassword,setShowPassword]= useState();

    return (
        <FormControl>
            <InputLabel htmlFor="outlined-adornment-password">{props.label}</InputLabel>
            <Input {...props}
                id="standard-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={()=>setShowPassword(!showPassword)}
                            disabled={props.disabled}
                            onMouseDown={e=> e.preventDefault()}
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>


    );
};

export default PasswordVisibilityInput;
