import React, {useRef, useState} from 'react';
import Card from "@material-ui/core/Card";
import {DateField, NumberField, TextField, Title, useQueryWithStore} from "react-admin";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MultiSelectAll from "../../UIComponents/multiSelectAll";
import _ from "lodash";
import moment from "moment";
import MultiDate from "../../UIComponents/multiDate";
import Grid from "@material-ui/core/Grid";
import {connect, useDispatch} from "react-redux";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import MyDatagrid from "../accountsGrid";
import MultiCurrencyField from "../../UIComponents/multiCurrencyField";
import ReactToPrint from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import VarianceReportExcelExport from "./varianceReportExcelExport";
import EmployeeReportExcelExport from "./employeeReportExcelExport";



export const MonthSelection = ({handleOk}) => {

    const[payrollRuns, setPayrollRuns] = useState([]);

    const {data: approvedRuns } = useQueryWithStore({
        type: 'getList',
        resource: 'approvedPayrollRuns',
        payload: {filter:{expand:'payrollRun'}}
    });
    if(!approvedRuns) {
        return null
    }
    return <Grid container spacing={2}>
        <Grid item xs={8}>
            <FormControl margin='dense' fullWidth  >
                <InputLabel>Months</InputLabel>
                {/*<Select*/}
                {/*    value={extraData.backpayPayroll}*/}
                {/*    onChange={ev=> getMonthData(ev.target.value)}*/}
                {/*>*/}
                {/*    {approvedRuns.map(r=><MenuItem key={r.id} value={r.payrollRun.id}><MultiDate gcDate={r.payrollRun.payrollDate} format={'MMMM YYYY'}/></MenuItem>)}*/}
                {/*</Select>*/}
                <MultiSelectAll
                    value={payrollRuns}
                    onChange={setPayrollRuns}
                    options={_.orderBy(approvedRuns,['payrollRun.payrollDate'],['desc']).map(r=>({key:r.id, value:r.payrollRun, textLabel:moment(r.payrollRun.payrollDate).format('MMM'), label:<MultiDate gcDate={r.payrollRun.payrollDate} format={'MMMM YYYY'}/>}))} />


            </FormControl>
        </Grid>
       <Grid item>
           <Button onClick={()=>handleOk(payrollRuns)} color="primary">
               OK
           </Button>
       </Grid>

    </Grid>
}

const EmployeeReport = ({newHires,endEmployments}) => {
    const componentRef = useRef();
    const dispatch = useDispatch();
    const handleOk = (payrollRuns)=>{
        dispatch({
            type: "GET_EMPLOYEE_VARIANCE",
            payload: {
                payrollRuns,
            }
        })
    }

    const initColumns = [
        {field:"sn", width:80, show:true, title:"SN", type:"Text"},
        {show:true,field:"fullName", width:230, title:"Full Name", type:"Text"},
        {show:true,field:`fullBasicSalary`,  title:'Full Basic Salary', type:"Number"},
        {show:true,field:`startDate`, title:'Starting From', type:"Date"},
        {show:true,field:`payrollDate`,  title:"First Payroll", type:"Date"},
        {show:true,field:`basicSalary`,  title:"Basic Salary",  type:"Number"},
        {show:true,field:`totalIncome`,  title:"Total Income",  type:"Number"},
        {show:true,field:`cash`,  title:"Net Pay",  type:"Number"},
    ]

    const initColumnsEnd = [
        {field:"sn", width:80, show:true, title:"SN", type:"Text"},
        {show:true,field:"fullName", width:230, title:"Full Name", type:"Text"},
        {show:true,field:`fullBasicSalary`,  title:'Full Basic Salary', type:"Number"},
        {show:true,field:`endDate`, title:'Ends On', type:"Date"},
        {show:true,field:`payrollDate`,  title:"Last Payroll", type:"Date"},
        {show:true,field:`basicSalary`,  title:"Basic Salary",  type:"Number"},
        {show:true,field:`totalIncome`,  title:"Total Income",  type:"Number"},
        {show:true,field:`cash`,  title:"Net Pay",  type:"Number"},
    ]



    return (
        <Card>
            <Title title="Employee Report"/>

            <Card>
                <CardContent>
               <Grid container>
                   <MonthSelection handleOk={handleOk}/>
               </Grid>
                    <Grid container>
                        <ReactToPrint
                            trigger={() => <Button variant="outlined"  color="primary"  startIcon={<PrintIcon/>}  >Print/PDF</Button>}
                            content={() => componentRef.current}
                            pageStyle={""}
                        />
                        <EmployeeReportExcelExport label="Export New Hires" footerItems={[]} showFooter={false}  data={newHires.map((s,i)=>({...s,sn:i+1, payrollDate: moment(s.payrollRun.payrollDate).format('MMMM, YYYY'), startDate: moment(s.employee.startDate).format('MM-DD-YYYY')}))}  columns={initColumns} fileName={'NewHiresReport.xlsx'}  title={`New Hires`}/>
                        <EmployeeReportExcelExport label="Export End Employments" footerItems={[]} showFooter={false}  data={endEmployments.map((s,i)=>({...s,sn:i+1,payrollDate: moment(s.payrollRun.payrollDate).format('MMMM, YYYY'), endDate: moment(s.employee.contractEndDate).format('MM-DD-YYYY')}))} columns={initColumnsEnd} fileName={'EndEmploymentReport.xlsx'}  title={`End Employments`}/>

                    </Grid>

                    <Grid  ref={componentRef} direction="column" container spacing={10}>
                        <Grid item >

                            <Typography variant="h5">New Hires</Typography>
                            <MyDatagrid fullHeight checkField={false}  summeryFields={['fullBasicSalary','basicSalary','totalIncome','cash']} data={newHires.map((s,i)=>({...s,sn:i+1, payrollDate: moment(s.payrollRun.payrollDate).format('MMMM, YYYY'), startDate: moment(s.employee.startDate).format('MM-DD-YYYY')}))} ids={newHires.map((e,index)=>index)} currentSort={{ field: 'payrollRun.payrollDate', order: 'desc' }}  setSort={()=>{}}>
                                <NumberField  source="sn" />
                                <TextField  source="fullName" />
                                <MultiCurrencyField  source={"fullBasicSalary"}/>
                                <DateField label={"Starting From"} source={"employee.startDate"}/>
                                <TextField label={"First Payroll"} source={"payrollDate"}/>
                                <MultiCurrencyField label={"This Month Salary"}  source={"basicSalary"}/>
                                <MultiCurrencyField label={"Gross Income"}  source={"totalIncome"}/>
                                <MultiCurrencyField  label={"Net Pay"} source={"cash"}/>
                            </MyDatagrid>


                        </Grid>
                        <Grid item >

                            <Typography variant="h5">End Employments</Typography>
                            <MyDatagrid fullHeight checkField={false}  summeryFields={['fullBasicSalary','basicSalary','totalIncome','cash']} data={endEmployments.map((s,i)=>({...s,sn:i+1,payrollDate: moment(s.payrollRun.payrollDate).format('MMMM, YYYY'), endDate: moment(s.employee.contractEndDate).format('MM-DD-YYYY')}))}ids={endEmployments.map((e,index)=>index)} currentSort={{ field: 'payrollRun.payrollDate', order: 'desc' }} setSort={()=>{}}>
                                <NumberField  source="sn" />
                                <TextField  source="fullName" />
                                <MultiCurrencyField  source={"fullBasicSalary"}/>
                                <DateField label={"Ends on"} source={"employee.contractEndDate"}/>
                                <TextField label={"Last Payroll"} source={"payrollDate"}/>
                                <MultiCurrencyField label={"This Month Salary"}  source={"basicSalary"}/>
                                <MultiCurrencyField label={"Gross Income"}  source={"totalIncome"}/>
                                <MultiCurrencyField  label={"Net Pay"} source={"cash"}/>
                            </MyDatagrid>


                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
        </Card>

    );
};

export default connect(state=>({
    newHires: state.report.newHires,
    endEmployments:state.report.endEmployments,
}))(EmployeeReport);
