import React from 'react';
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import PayrollDeductionItem from "./payrollDeductionItem";
import {useDataProvider, useQueryWithStore} from "react-admin";
import {ImportButton} from "../import/importButton";

const DeductionTypes= [{type:'other',name:'Other',cash:false},{type:'damage',name:'Damage',cash:true},{type:'tax',name:'Tax Paid on behalf',cash:true}]

const useStyles = makeStyles(theme => ({
    typography: {
        padding: theme.spacing(2),
    },
}));
const AddPayrollDeductionPopover = props => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [payrollDeductions, setPayrollDeductions] = React.useState(props.payrollDeductions);

    const { data: loanTemplates } = useQueryWithStore({
        type: 'getList',
        resource: 'loanTemplates',
        payload: {filter:{},pagination:{},sort:{}}
    });

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleAdd = () => {
        props.onAdd(payrollDeductions)
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const handleItemsChange = index => ot => {
        // console.log(ot)
        const newRoles = payrollDeductions.slice(0); // Create a shallow copy of the roles
        newRoles[index] = ot; // Set the new value
        setPayrollDeductions(newRoles)
        // console.log(payrollDeductions)
    }

    const handleRemove = index => () => {
        const newRoles = payrollDeductions.slice(0); // Create a shallow copy of the roles
        newRoles.splice(index,1)
        setPayrollDeductions(newRoles)

    }


    return (
        <div style={{marginTop:-20}}>
            <Button aria-describedby={id} variant="text" color="primary" onClick={handleClick}>
                + Loan/Other Deductions {payrollDeductions.length>0?`(${payrollDeductions.length})`:''}
            </Button>
            <Popover
                id={id}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 200, left: 400 }}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Card style={{minWidth:900}}>
                    <CardContent>

                        {payrollDeductions.map((ot,index) =>
                            <PayrollDeductionItem loans={props.loans} DeductionTypes={(loanTemplates?loanTemplates.map(l=>({type:l.name,name:l.name})):[]).concat(DeductionTypes)} key={index} item={ot} onRemove={handleRemove(index)}  onChange={handleItemsChange(index)} />
                        )}

                        <Button onClick={()=>setPayrollDeductions([...payrollDeductions,{type:DeductionTypes[0].type,name:"Advance",value:0,cash:true}])} color="primary">
                            Add
                        </Button>





                    </CardContent>
                    <CardActions>
                        <Button style={{ marginLeft: 'auto'}} onClick={handleAdd} variant="text" color="primary">
                            Ok
                        </Button>
                    </CardActions>
                </Card>

            </Popover>
        </div>
    );
};

export default AddPayrollDeductionPopover;
