import React from 'react';
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Popover from "@material-ui/core/Popover";
import CardContent from "@material-ui/core/CardContent";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {DatePicker} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";

const DistEarningsAppliesToPopover = ({payrollRun, groups, appliesTo, setGroups, setEndDate}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <div>
            <Button aria-describedby={id} variant="text" color="primary" onClick={handleClick}>
               Edit
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Card>
                    <CardContent>

                        <Grid container direction="column">
                            <Grid item fullWidth>
                                <FormControl fullWidth  margin='dense' >
                                    <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                        Current Site
                                    </InputLabel>

                                    <Select
                                        value={appliesTo.groups}
                                        multiple
                                        onChange={ev=>{
                                            setGroups(ev.target.value)

                                        }}
                                    >
                                        <MenuItem key={"all"} value={null}>All Sites</MenuItem>
                                        {groups&&groups.map(ot=><MenuItem key={ot.id} value={ot.name}>{ot.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>

                            <DatePicker
                                views={["month"]}
                                label="Hired Before"
                                margin="dense"
                                clearable

                                // minDate={new Date("2018-03-01")}
                                // maxDate={new Date("2018-06-01")}

                                value={appliesTo.endDate}
                                onChange={ setEndDate}
                            />
                            </Grid>
                        </Grid>



                    </CardContent>
                </Card>
            </Popover>
        </div>
    );
};

export default DistEarningsAppliesToPopover;
