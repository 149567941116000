import React, {useEffect, useMemo, useState} from 'react';
import {Button} from "@progress/kendo-react-buttons";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import SyncTik from "../sync/syncTik";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import MultiSelectAll from "../../UIComponents/multiSelectAll";
import _ from "lodash";
import moment from "moment";
import MultiDate from "../../UIComponents/multiDate";
import FormControl from "@material-ui/core/FormControl";
import Divider from "@material-ui/core/Divider";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close"
import {useDispatch} from "react-redux";
import {CustomDialogTitle} from "../../UIComponents/CustomDialogTitle";
import SyncTikMates from "../sync/syncTikMates";
import {stringify} from "query-string";
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const SyncTikMatesDialog = ({ teams, title,autoOpen,newHires, terminations,notFoundTerminations, newEmps, inactiveEmps, updatedEmps, onAccept, onIgnore, onDeactivate, onClose, ...props}) => {

const [selectedSites, setSelectedSites] = useState([]) //teams.map(t=>t.code.toLowerCase().replace(/\s+/g, '')));

const [type, setType] = useState('')

const [source, setSource] = useState('');

    // const hires = useMemo(() => {
    //    switch(source) {
    //         case 'tik':
    //             return newHires
    //        case 'mates':
    //             return newEmps
    //        default:
    //            return newHires.concat(newEmps)
    //
    //     }
    // }, [source, newEmps, newHires]);



    return (
            <Dialog disableBackdropClick maxWidth='md'  fullWidth {...props} onClose={onClose}>
                <CustomDialogTitle onClose={onClose} id="form-dialog-title">{title}</CustomDialogTitle>

                <Grid style={{padding:10}} container>
                    <Grid item xs={4} >
                        <FormControl margin='dense' fullWidth  >
                            <InputLabel>Sites</InputLabel>

                            <MultiSelectAll
                                value={selectedSites}

                                onChange={setSelectedSites}
                                options={teams.map(r=>({key:r.code, value:r.code.toLowerCase().replace(/\s+/g, ''), textLabel:r.name}))} />

                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl margin='dense' fullWidth>
                            <InputLabel>Source</InputLabel>
                            <Select value={source} onChange={e=>setSource(e.target.value)}>
                                <MenuItem value=''>
                                    All
                                </MenuItem>
                                <MenuItem value='tik'>
                                    Tik Time keeper
                                </MenuItem>
                                <MenuItem value='mates'>
                                    Mates HCM
                                </MenuItem>

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl margin='dense' fullWidth>
                            <InputLabel>Type</InputLabel>
                            <Select value={type} onChange={e=>setType(e.target.value)}>
                                <MenuItem value=''>
                                    All
                                </MenuItem>
                                <MenuItem value='newHire'>
                                    New Hire
                                </MenuItem>
                                <MenuItem value='termiation'>
                                    End Employment
                                </MenuItem>
                                <MenuItem value='update'>
                                    Update Employment
                                </MenuItem>

                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>



                <DialogContent dividers>
                    <SyncTikMates type={type}
                                  newHires={newHires.concat(newEmps).filter(n=>(!selectedSites.length||selectedSites.includes(n.currentTeam||''.toLowerCase().replace(/\s+/g, '')))&&(!source||n.source===source))}
                                  terminations={terminations.concat(inactiveEmps).filter(n=>(!selectedSites.length||selectedSites.includes(n.currentTeam||''.toLowerCase().replace(/\s+/g, '')))&&(!source||n.source===source))}
                                  updates={updatedEmps.filter(n=>(!selectedSites.length||selectedSites.includes(n.currentTeam||''.toLowerCase().replace(/\s+/g, '')))&&(!source||n.source===source))}
                                  notFoundTerminations={notFoundTerminations} onAccept={onAccept} onDeactivate={onDeactivate} onIgnore={onIgnore}/>
                </DialogContent>
            </Dialog>
    );
};

export default SyncTikMatesDialog;
