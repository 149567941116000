export const WEEK_NAMES = ["እሑድ", "ሰኞ", "ማክሰኞ", "ረቡዕ", "ሓሙስ", "ዓርብ", "ቅዳሜ"];

export const MONTHS_NAMES = [
  "መስከረም",
  "ጥቅምት",
  "ኅዳር",
  "ታኅሣሥ",
  "ጥር",
  "የካቲት",
  "መጋቢት",
  "ሚያዝያ",
  "ግንቦት",
  "ሰኔ",
  "ሐምሌ",
  "ነሐሴ",
  "ጳጉሜን"
];

export const SHORT_MONTHS_NAMES = [
  "መስከ",
  "ጥቅም",
  "ኅዳር",
  "ታኅሣ",
  "ጥር",
  "የካቲ",
  "መጋቢ",
  "ሚያዝ",
  "ግንቦ",
  "ሰኔ",
  "ሐምሌ",
  "ነሐሴ",
  "ጳጉሜ"
];

export default {WEEK_NAMES,MONTHS_NAMES,SHORT_MONTHS_NAMES}
