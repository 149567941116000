import React, {cloneElement, useState} from 'react';
import {
    Button,
    Datagrid,
    DateField,
    DeleteButton,
    List,
    sanitizeListRestProps,
    TextField,
    TopToolbar,
    usePermissions
} from 'react-admin';
import DeleteRunField from "../../UIComponents/deleteRunField";

import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import CaptionedField from "../../UIComponents/captionedField";
import CardActions from "@material-ui/core/CardActions";
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewGridIcon from "@material-ui/icons/ViewModule"
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/LibraryBooksOutlined"
import {Link} from "react-router-dom";
import ApproveButton from "../payroll/approveButton";
import {Typography} from "../../UIComponents/Wrappers";
import MultiCurrencyField from "../../UIComponents/multiCurrencyField";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import UnlockPayrollButton from "../payroll/unlockButton";
import UnApproveButton from "../payroll/unApproveButton";
import SetReadyPayrollButton from "../payroll/setReadyButton";
import {MultiCurrencyContext} from "../../contexts";
import MultiDate from "../../UIComponents/multiDate";
import MultiDateField from "../../UIComponents/multiDateField";

const cardStyle = {
    width: 400,
    margin: '0.5em',
    display: 'inline-block',
    verticalAlign: 'top'
};
const PayrunGrid = ({ ids, data, basePath,permissions, onUnlock }) => (

    <div style={{ margin: '1em' }}>
        {ids.map(id =>
            <MultiCurrencyContext.Provider value={data[id].usedCurrencyRates}>
            <Card key={id} style={cardStyle}>
                <CardHeader
                    // title={ <DateField record={data[id]} source="payrollDate" options={{year: 'numeric', month: 'long'}}/>}
                    title={ <MultiDateField record={data[id]} source="payrollDate" format={'MMMM YYYY'} />}
                    // subheader={<DateField record={data[id]} source="runDate"    showTime={true}  />}
                    subheader={<MultiDateField record={data[id]} source="runDate" format={'MM/DD/YYYY HH:mm'} />}
                    action={
                        <>
                            <SetReadyPayrollButton permissions={permissions} record={data[id]}/>
                       <UnlockPayrollButton permissions={permissions} record={data[id]}/>
                  </>
                    }
                />
                <CardContent>
                    {/*<MultiDate gcDate={data[id].payrollDate} />*/}
                    <Grid container justify="space-between"
                          alignItems="center">
                        <CaptionedField value={data[id].totalIncome} label='Total Income' />
                        <CaptionedField value={data[id].netPay} label='Net (before settlement)' />


                    </Grid>
                    <Grid container>
                        <Grid xs={8} item>
                            <Typography>
                                Run By {data[id].createdBy}
                            </Typography>
                            <Typography>
                                {data[id].status}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <CaptionedField value={data[id].cash} label='Net Pay' />
                        </Grid>
                    </Grid>

                    {data[id].description||''}


                </CardContent>
                {/*<CardContent>*/}
                    {/*about&nbsp;*/}
                    {/*<ReferenceField label="Post" resource="comments" record={data[id]} source="post_id" reference="posts" basePath={basePath}>*/}
                        {/*<TextField source="title" />*/}
                    {/*</ReferenceField>*/}
                {/*</CardContent>*/}
                <CardActions style={{ textAlign: 'right' }}>
                    <Button component={Link} to={`/reports/payroll/${id}/sheet`}  startIcon={<ViewIcon/>}  label='reports'/>
                    <Button component={Link} to={`/runPayroll/${id}`}  startIcon={<EditIcon/>}  label='edit'/>
                    <DeleteButton disabled={data[id].locked||(data[id].status==='Approved')||(!(permissions.includes("Manager")&&permissions.includes("Manager"))&&data[id].createdBy.toLowerCase()!==localStorage.getItem('user').toLowerCase())} resource="payrollRuns" basePath={basePath} record={data[id]}/>
                    {/*<EditButton resource="posts" basePath={basePath} record={data[id]} />*/}
                    {
                        permissions.includes('Manager') &&   data[id].status !== 'Approved' &&    <ApproveButton record={data[id]}/>
                    }
                    {
                        !data[id].locked && permissions.includes('Manager') && permissions.includes('Admin') &&  data[id].status === 'Approved' &&    <UnApproveButton record={data[id]}/>
                    }

                </CardActions>
            </Card>
            </MultiCurrencyContext.Provider>
        )}
    </div>

);
PayrunGrid.defaultProps = {
    data: {},
    ids: [],
};






const PayrollrunActions =({
                              onViewChange,
                              currentSort,
                              className,
                              resource,
                              filters,
                              displayedFilters,
                              exporter, // you can hide ExportButton if exporter = (null || false)
                              filterValues,
                              permanentFilter,
                              hasCreate, // you can hide CreateButton if hasCreate = false
                              basePath,
                              selectedIds,
                              onUnselectItems,
                              showFilter,
                              maxResults,
                              total,
                              ...rest
                          }) => (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <IconButton color='primary'  aria-label="Grid View" onClick={()=>onViewChange('grid')}>
            <ViewGridIcon fontSize="large" />
        </IconButton>
        <IconButton color='primary' aria-label="List View" onClick={()=>onViewChange('list')}>
            <ViewListIcon fontSize="large" />
        </IconButton>


    </TopToolbar>
);


const ViewSelector = props => {

    return <>{props.view === 'list' &&
    <Datagrid {...props}
                                           isRowSelectable={record => record.status !== "Approved" && ((props.permissions.includes("Admin")&&props.permissions.includes("Manager")) || record.createdBy.toLowerCase() === localStorage.getItem('user').toLowerCase())}>
        {/*<TextField source="id" />*/}
        <DateField source="runDate"/>
        <DateField source="payrollDate" options={{year: 'numeric', month: 'long'}}/>
        <TextField source="createdBy"/>
        <TextField source="status"/>
        <MultiCurrencyField source="netPay"/>
        <DeleteRunField permissions={props.permissions}/>
    </Datagrid>}
        {props.view === 'grid' && <PayrunGrid {...props}/>}
    </>
}
export const PayrollRunList= props => {
    const [view,setView] = useState('grid');
    const permissions= usePermissions()
    if (!permissions.permissions) return null;


   return   <List actions={<PayrollrunActions onViewChange={setView}/>} exporter={false}  {...props} sort={{field: 'runDate', order: 'DESC'}}>
     <ViewSelector  permissions={permissions.permissions} view={view}/>
    </List>
};


