import React from 'react';
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit"
import {Link} from 'react-router-dom';
import {
    Button,
    Create,
    Datagrid,
    Edit, EditButton,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    ReferenceManyField,
    required,
    SelectInput,
    Show,
    ShowButton,
    SimpleForm,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput
} from 'react-admin';
import exporterCommon from "../import/exporterCommon";
import DefaultListActions, {AddItemButton, Empty} from "./common";
import PercentField from "../../UIComponents/PercentField";
import Grid from "@material-ui/core/Grid";
import {scopeEnum} from "./helperFunctions";


const PositionFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Department" source="departmentID" reference="departments" >
            <SelectInput optionText="nam" />
        </ReferenceInput>
    </Filter>
)


export const PositionList= props => (
    <List perPage={1000} empty={<Empty />} filters={<PositionFilter/>}  exporter={exporterCommon(props.resource)} actions={<DefaultListActions/>} {...props}>
        <Datagrid rowClick="show">
            {/*<TextField source="id" />*/}
            <TextField source="name" />
            <ReferenceField label="Department" source="departmentID" reference="departments">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Level" source="jobLevelId" reference="jobLevels">
                <TextField source="name" />
            </ReferenceField>

            <EditButton />
        </Datagrid>
    </List>
);

export const PositionEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <ReferenceInput label="Department" source="departmentID" reference="departments" validate={[required()]}>
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput  label="Level" source="jobLevelId" reference="jobLevels">
                {/*<SelectInput  validate={[required()]} optionText="name"  />*/}
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput label='Position Name' source="name" />
        </SimpleForm>
    </Edit>
);

export const PositionCreate = props => (
    <Create {...props}>
        <SimpleForm redirect='list' >
            <ReferenceInput   label="Department" source="departmentID" reference="departments" validate={[required()]}>
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput  label="Level" source="jobLevelId" reference="jobLevels">
                {/*<SelectInput  validate={[required()]} optionText="name"  />*/}
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="name" />


        </SimpleForm>
    </Create>
);

// export const AddNewEarningButton = ({ record, scope }) => (
//     <Button
//         component={Link}
//         to={{
//             pathname: "/earnings/create",
//             search:`?scopeId=${record.id}&&source=1&&scope=${scope}`,
//             //search: `?source=${JSON.stringify({ scopeId: record.id,scope})}`,
//         }}
//         label="Add an Earning"
//     >
//         <AddIcon />
//     </Button>
// );



export const AddNewDeductionButton = ({label, record, scope }) => (
    <Button
        component={Link}
        to={{
            pathname: "/deductions/create",
            search:`?scopeId=${record.id}&&source=1&&scope=${scope}`,
           // search: `?source=${JSON.stringify({ scopeId: record.id,scope})}`,
        }}
        label="Add a Deduction"
    >
        <AddIcon />
    </Button>
);




export const AddNewExpenseButton = ({ record, scope }) => (
    <Button
        component={Link}
        to={{
            pathname: "/expenses/create",
            search:`?scopeId=${record.id}&&source=1&&scope=${scope}`,
            // search: `?source=${JSON.stringify({ scopeId: record.id,scope})}`,
        }}
        label="Add a Expense"
    >
        <AddIcon />
    </Button>
);



export const EditXButton = ({type, record, scope, id }) => (
    <Button
        component={Link}
        to={{
            pathname: `/${type}/${record.id}`,
            search:`?scopeId=${id}&&source=1&&scope=${scope||scopeEnum.indexOf(record.scope)}`,
            //search: `?source=${JSON.stringify({ scopeId: record.id,scope})}`,
        }}
        label="Edit"
    >
        <EditIcon/>
    </Button>
);

export const PositionShow = props => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="Basic">
                <TextField source="name" />
                <TextField source="basicSalary" />
            </Tab>
            <Tab label="Additional Earnings" path="earnings">
                <ReferenceManyField
                    addLabel={false}
                    reference="earnings"
                    target="positions"
                     >
                    <Datagrid >
                        <TextField source="name" />
                        <TextField source="valueType" />
                        <PercentField source="value"/>
                        <EditXButton type="earnings" scope={1} id={props.id}/>
                     </Datagrid>
                </ReferenceManyField>
                <AddItemButton label={"Add an Earning"} path="/earnings/create" scope={1}/>

            </Tab>

            <Tab label="Deductions" path="deductions">
                <ReferenceManyField
                    addLabel={false}
                    reference="deductions"
                    target="positions"
                >
                    <Datagrid >
                        <TextField source="name" />
                        <TextField source="valueType" />
                        <PercentField source="value"/>
                        <EditXButton type="deductions" scope={1} id={props.id}/>
                    </Datagrid>
                </ReferenceManyField>
                <AddNewDeductionButton scope={1}/>

            </Tab>

            <Tab label="Expenses" path="expenses">
                <ReferenceManyField
                    addLabel={false}
                    reference="expenses"
                    target="positions"
                >
                    <Datagrid >
                        <TextField source="name" />
                        <TextField source="valueType" />
                        <PercentField source="value"/>
                        <EditXButton type="expenses" scope={1} id={props.id}/>
                    </Datagrid>
                </ReferenceManyField>
                <AddNewExpenseButton scope={1}/>

            </Tab>

        </TabbedShowLayout>
    </Show>
);


