import {fetchUtils} from 'react-admin';


const emailApi = (url, options = {}) => {
    const api = localStorage.getItem('domain')
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const company = localStorage.getItem('company');
    // add your own headers here
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    options.headers.set('X-COMPANY', company);
    return fetchUtils.fetchJson(api+url, options);
}
const mjmlApi = (url, options = {}) => {
   const api = 'https://api.mjml.io/v1'
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    // add your own headers here
   // const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Basic NDVmZWU3ZGItZmM3MS00MzJkLWI2MmItYzhjYWVlYTQ5NTRjOmEyZGM2M2UzLTQ0YzgtNDg3Mi1hNDU2LWM2M2E1MmNhMGZiZg==`);
    return fetchUtils.fetchJson(api+url, options);
}


export function renderMJML(message) {
    // console.log(message.body)
   return  mjmlApi("/render",{
        method: 'POST',
        body: JSON.stringify({mjml:message.body}),
    }).then(res=>({response:{...message,body:res.json.html}})).catch(error=>({error:{e:error,message}}))
}

export function sendEmailNow(emails) {
    return emailApi('/api/emails', {
        method: 'POST',
        body: JSON.stringify(emails),
    }).then(function (_a) {
        const json = _a.json;
        return ({
            data: json
        });
    })
}
export const sendEmails  = emails => sendEmailNow(emails).then(response => ({ response:response.data }))
    .catch(error => {
        // console.log('send failed', error)
        //put(showNotification('Emails sent successfully ', "info"))
        return {error}
    })

