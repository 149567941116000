import React from 'react';
import {useQueryWithStore} from 'react-admin'
import {A, Box, Email, Item, renderEmail, Span} from 'react-html-email'
import Inky, {Column, Container, Row} from 'react-inky';
// import payslipStyle from  './emailTemplate/payslipStyle.css'
import {renderToString} from 'react-dom/server';
import PayslipEmail from "../../UIComponents/emailTemplate/payslipEmail";

const RawHTML = ({children, className = ""}) =>
    <div className={className}
         dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '<br />')}} />
const css = `
@media only screen and (max-device-width: 480px) {
  font-size: 20px !important;
}`.trim()






function EmailTemplate({ name }) {
    return (
        <Container >
            <row>
            </row>


                <Row  >
                    <Column>
                        hiih
                    </Column>
                    <Column>
                        hiih
                    </Column>

                </Row>


        </Container>
    );
}





const sourceURL = 'https://raw.githubusercontent.com/chromakode/react-html-email/master/examples/kitchenSink.js'
const PayslipEmailTemplate = props => {
    const id  = +props.match.params.id;
     const { loaded, error, data: payrollRun } = useQueryWithStore({
        type: 'getOne',
        resource: 'payrollRuns',
        payload: {id, expand:'payrolls'}
    });
    if(!payrollRun || !payrollRun.payrolls) return null;
    // renderToStaticMarkup((
    //     <Inky>
    //         <Inky.Head>
    //             <link rel="stylesheet" href="style.css" />
    //         </Inky.Head>
    //         <Inky.Body preview="Preview text">
    //             <EmailTemplate name="Inky" />
    //         </Inky.Body>
    //     </Inky>
    // ));



    const html2 = Inky.doctype + renderToString((
        <Inky>
            <Inky.Head>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/foundation-emails/2.2.1/foundation-emails.css" />
            </Inky.Head>
            <Inky.Body preview="Preview text">
                <PayslipEmail companyName={localStorage.getItem('companyName')} payroll={payrollRun.payrolls[0]} payrollDate={payrollRun.payrollDate}/>
            </Inky.Body>
        </Inky>
    ));


    const html= renderEmail(
        <Email title="Test Email" headCSS={css}>
            <Item>
                Hello Abebe How are you doing this month,
                Here is your monthly payslip
            </Item>
            <Item style={{ paddingTop:"40px"}}>
                <Span fontSize={20}>Helvica PLC</Span>
                <Item>
                Payroll Slip for <Span fontSize={20}>January 2020</Span>
                </Item>
            </Item>
            <Item>
                <Box cellSpacing={20} width="100%" style={{ borderTop: '3px solid black' }}>


<Item>
    <Box>
        <Item>hh</Item>
    </Box>
    <Box>
        <Item>hh</Item>
    </Box>
</Item>



                    <Item>
                        <Span color="gray" lineHeight={20}>Powered by <A href="https://payday.et"><span fontSize={15}>PayDay</span> One Click Payroll </A></Span>
                      </Item>
                </Box>
            </Item>
        </Email>
    )



    return (
        <div>
           {/*<RawHTML>{html}</RawHTML>*/}
            <RawHTML>{html2}</RawHTML>
        </div>
    );
};

export default PayslipEmailTemplate;
