import React, {useContext} from 'react';
import {connect} from "react-redux";
import {FormattedNumber} from "react-intl";
import {MultiCurrencyContext} from "../contexts";

const MultiCurrency = ({value,conversionRates,currentCurrency,decimalPlace=2,hideSymbol,defaultCurrency}) => {
 //console.log(conversionRates[currentCurrency])
    const rates = useContext(MultiCurrencyContext);
  //  console.log(currentCurrency)

    const amount = value * ((rates||conversionRates)[defaultCurrency.toLowerCase()]) / ((rates||conversionRates)[currentCurrency]);
    return (

            <FormattedNumber maximumFractionDigits={decimalPlace} minimumFractionDigits={decimalPlace} value={amount} style={(hideSymbol&&currentCurrency.toLowerCase()==='etb')?undefined:"currency"}  currency={currentCurrency}/>

    );
};

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => {
    return {
        currentCurrency: (ownProps.currentCurrency || propsFromState.currentCurrency || 'etb').toLowerCase(),
        conversionRates:ownProps.conversionRates || propsFromState.conversionRates,
        value: ownProps.value,
        decimalPlace: ownProps.decimalPlace,
        defaultCurrency: ownProps.defaultCurrency || 'etb',
        hideSymbol:ownProps.hideSymbol || propsFromState.hideSymbol

    };
};

export default connect(state=>({
    currentCurrency : state.currency.currentCurrency,
    hideSymbol : state.currency.hideSymbol,
    conversionRates: state.currency.conversionRates,
}),null,mergeProps)(MultiCurrency);
