import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {connect, useSelector} from 'react-redux'
import Card from "@material-ui/core/Card";
import {CardHeader} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import moment from "moment";
import {makeStyles} from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import * as classnames from "classnames";
const useStyles = makeStyles({
    selected: {
        backgroundColor: '#bbdefb',
    },
    notFound: {
        backgroundColor: '#fcca86',
    },

    notFoundButSelected: {
        backgroundColor: '#ffa726',
    },

});


const SyncTik = ({terminations,newHires, notFoundTerminations, onAccept, onDeactivate, onIgnore}) => {


    useEffect( () =>  {
        setSelectedTerminations(selectedTerminations.filter(st=>!notFoundTerminations.includes(st)))
        },
        [notFoundTerminations])

    const [selectedTerminations, setSelectedTerminations] = useState(terminations.filter(t=>moment(t.endDate)>moment().startOf('month').add(-10,'days')))

    const classes = useStyles();

    const selectCard = (card, type) => {
        card.type= type;
        if(selectedTerminations.includes(card))
        {
            setSelectedTerminations(selectedTerminations.filter(s=>s!==card))
        }else {
            setSelectedTerminations([...selectedTerminations, card])
        }
    }

    return (
            <div>
                {!!terminations.length&&<>
                    {terminations.length} Approved Terminations
                    <Grid container spacing={2}>
                        {terminations.map(t =>
                            <Grid item>
                                <Card onClick={() => selectCard(t,'termination')} className={classnames({
                                    [classes.selected]: selectedTerminations.includes(t),
                                    [classes.notFound]: notFoundTerminations.includes(t),
                                    [classes.notFoundButSelected]: selectedTerminations.includes(t) && notFoundTerminations.includes(t),
                                })}>


                                    <CardHeader title={`${t.member.firstName} ${t.member.fatherName}`}
                                                subheader={`from ${t.currentTeam} on ${t.endDate} `}/>
                                    <CardContent>
                                        <Grid container direction="column">
                                            <Grid item>
                                                Employment End
                                                Date <strong>{moment(t.endDate).format('MMMM DD, YYYY')}</strong>

                                            </Grid>
                                            <Grid item>
                                                Reason <strong>{t.reason}</strong>
                                                {!!t.remark && <div>{t.remark}</div>}
                                            </Grid>
                                            <Grid item>

                                                Approved By <strong>{t.approvedBy}</strong>
                                            </Grid>
                                            <Grid item>
                                                mateId: {t.member.mateId}
                                            </Grid>
                                        </Grid>
                                    </CardContent>

                                </Card>

                            </Grid>)}


                    </Grid>

                </>}

                {!!newHires.length&&<>
                    {newHires.length} Approved Hires
                    <Grid container  spacing={2}>
                        {newHires.map(t =>
                            <Grid item sm={4} xs={6}>
                                <Card fullWidth style={{cursor: 'pointer'}} onClick={() => selectCard(t,'newHire')} className={classnames({
                                    [classes.selected]: selectedTerminations.includes(t),
                                    [classes.notFound]: notFoundTerminations.includes(t),
                                    [classes.notFoundButSelected]: selectedTerminations.includes(t) && notFoundTerminations.includes(t),
                                })}>


                                    <CardHeader title={`${t.firstName} ${t.fatherName}`}
                                                subheader={`in ${t.currentTeam}`}/>
                                    <CardContent>
                                        <Grid container direction="column">
                                            <Grid item>
                                                 Starting from <strong>{moment(t.startDate).format('MMMM DD, YYYY')}</strong>
                                            </Grid>
                                            <Grid item>
                                                 {!!t.remark && <div>{t.remark}</div>}
                                            </Grid>
                                            <Grid item>

                                                Approved By <strong>{t.approvedBy}</strong>
                                            </Grid>
                                            <Grid item>
                                                mateId: {t.mateId}
                                            </Grid>
                                        </Grid>
                                    </CardContent>

                                </Card>

                            </Grid>)}


                    </Grid>

                </>}

                <Grid container justify="flex-end" spacing={2}>


                    <Grid item>
                        {!!selectedTerminations.length && <Button style={{

                            backgroundColor: "#d95e52",

                        }} color="primary" variant="contained"
                                                                  onClick={() => onIgnore({terminations: selectedTerminations})}>Skip & Ignore Selected ({selectedTerminations.length})</Button>}

                    </Grid>

                    <Grid item>
                        {!!selectedTerminations.length  && selectedTerminations.every(i=>i.type==='termination') && <Button color="primary"
                                                                  style={{

                                                                      backgroundColor: "#ffbf46",

                                                                  }}
                                                                  variant="contained"
                                                                  onClick={() => onDeactivate({terminations: selectedTerminations})}>Deactivate {selectedTerminations.length} Employees</Button>}

                    </Grid>

                    <Grid item>
                        {!!selectedTerminations.length && <Button color="primary" variant="contained"
                                                                  onClick={() => onAccept({terminations: selectedTerminations})}>Accept {selectedTerminations.length}  Changes</Button>}
                    </Grid>
                </Grid>

            </div>


    );
};

export default SyncTik
