import React from 'react';
import {Typography} from "../../UIComponents/Wrappers";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import FdreLogo from "../../UIComponents/images/fdre_logo.png";
import MultiDate from "../../UIComponents/multiDate";


const ReportHeader = (props) => {
    const {site,department,employmentType,logo} = props;
    return (
        <Grid container>
            <Grid item xs={1}>
                {logo&&<img alt='company_logo' height={50} src={logo}/>}
            </Grid>
            <Grid item xs={11}>
                <Grid container justify='center' alignItems='center' direction='column'>

                    <Grid item >


                        <Typography size="xl">
                            {localStorage.getItem('companyName')}
                        </Typography>



                    </Grid>
                    <Grid item >
                        <Typography size="xl">
                            {props.title} {employmentType?`- ${employmentType} Employees`:''} {site?`- ${site} Site`:''} {department?`- ${department} Department`:''}

                        </Typography>

                    </Grid>
                    <Grid item >

                        <Typography size='md' align='center'  >

                            {/*{props.runDate}*/}

                            <MultiDate gcDate={props.payrollRun.payrollDate} format={'MMMM, YYYY'} />
                            {/*{moment(props.payrollRun.payrollDate).format('MMMM, YYYY')} Payroll*/}
                        </Typography>
                        <Typography size="sm">
                            Report Generated on:  <MultiDate gcDate={new Date()} format={'MMM DD,YYYY'} />
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    );
};

export default ReportHeader;
