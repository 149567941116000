import React from 'react';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';

const OverTimeItem = props => {
   // console.log('ott',props)
    const [oTType, setOTType] = React.useState(props.Overtimes.find(o=>o.id===props.value.typeId)||props.Overtimes[0]);
    const [hrs, setHrs] = React.useState(props.value.hrs||0);
    return (
        <Grid container>
            <Grid item xs={5}>
            <FormControl margin='normal' fullWidth  >
                <InputLabel>Overtime Type</InputLabel>

                <Select
                    value={oTType}
                    onChange={ev=>{
                        setOTType(ev.target.value)
                        props.onChange({typeId:ev.target.value.id,rate:ev.target.value.rate,hrs})
                    }}
                >
                    {props.Overtimes.map(ot=><MenuItem key={ot.id} value={ot}>{ot.name}</MenuItem>)}
                </Select>
            </FormControl>
            </Grid>
            <Grid item xs={5}>
                <TextField
                    onChange={ev =>{
                        setHrs(ev.target.value)
                        props.onChange({typeId: oTType.id,rate:oTType.rate,hrs:+ev.target.value})
                    }}
                    value={hrs}
                    autoFocus
                    type="number"
                    label="Hours Worked"
                    margin='normal'

                />
            </Grid>
            <Grid item xs={2}>
                <IconButton onClick={props.onRemove} aria-label="delete" >
                    <DeleteIcon fontSize="large" />
                </IconButton>



            </Grid>

        </Grid>
    );
};

export default OverTimeItem;
