// in src/dashboard.js
import React, {useEffect} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import {Button, Loading, Title, useAuthState, usePermissions, useQueryWithStore, useRedirect} from 'react-admin'
import Grid from "@material-ui/core/Grid";
import PayrollStat from "./PayrollStat/payrollStat";
import moment from "moment";
import {ReactComponent as PaydayLogo} from '../UIComponents/icons/paydaylogoText.svg';
//import PaydayLogo from '../UIComponents/images/paydaylogoblack.png'
import {connect, useDispatch} from "react-redux";
import CaptionedField from "../UIComponents/captionedField";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import {CardActionArea} from "@material-ui/core";
import MultiDate from "../UIComponents/multiDate";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
const PayDayLogoText = () => <Grid container justify="flex-end">
    <Grid item>
        {/*<img src={PaydayLogo} height={75}/>*/}
        <PaydayLogo width={130} height={75}/>
    </Grid>
    <Grid item style={{marginTop:-10}}>
        V 1.1
    </Grid>
</Grid>
const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
};

const monthColors = ["#ff9e00","#2ab0ff"]

const useStyles = makeStyles({
    mainCard: {
        backgroundColor: '#f7f7f7'
    },
    main: {
        flex: '1',
        marginLeft: '1em',
        marginTop: 20,
    },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right',
    },
    name: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
    gridList: {
        flexWrap: 'nowrap',
        // // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
        width:'90vw'
    },
});




const CheckDashboard = () => {
    const { loading, authenticated } = useAuthState();
    if (loading) {
        return <Loading />;
    }
    if (authenticated) {
        return <Dashboard />;
    }
    return null;
};




const DashboardComp = props =>
   {
      // useAuthenticated();

       const classes = useStyles();

       const permissions= usePermissions()
       const dispatch = useDispatch();
      //console.log(permissions)
       const redirect =  useRedirect()


           useEffect(() => {

               if(!localStorage.getItem('company')&&(localStorage.getItem('company')!=='ROOT'))
               {
                      redirect('/selectCompanies')

               }

               if(permissions.loaded && permissions.permissions.includes('Superuser'))
               {
                   redirect('/admin')
               }




               }, [permissions]);




       const { loaded, error, data: approvedRuns} = useQueryWithStore({
           type: 'getList',
           resource: 'approvedPayrollRuns',
           payload: {filter:{expand:'payrollRun'}}
       });

       const { data: accounts} = useQueryWithStore({
           type: 'getList',
           resource: 'accounts',
           payload: {filter:{expand:'openingEntry'}}
       });

       // const { data:rates} = useQuery({
       //     type: 'getList',
       //     resource: 'currencyRates',
       //     payload: {filter:{queryString:'$orderby=Date desc &$top=1'}}
       // },{
       //     onSuccess: ({ data }) => {
       //         dispatch({type:'CURRENCY_RATES_LOADED',payload:data})
       //     },
       // });


      //  const company = localStorage.getItem('company')


       // if (!loaded) { return <Loading />; }

       // if (!payrollRun) return null;

let payrollStat = []
       if(accounts && approvedRuns)
       {
           const payrollRuns = approvedRuns ? approvedRuns.map(a => a.payrollRun): [{}]
           const payrollRun = payrollRuns[0] || {}
           const accountsSummery = {
               salariesExpense:{openingEntry:{}},
               salariesPayable:{openingEntry:{}}
           }

           accounts.map(a=>a.openingEntry = a.openingEntry || {credit:0,debit:0, balance:0})
           accountsSummery.salariesExpense= accounts.find(a=>a.name==='Salaries Expense')||{balance:0,openingEntry:{credit:0,debit:0, balance:0}};
           accountsSummery.salariesPayable= accounts.find(a=>a.name==='Salaries Payable')||{balance:0,openingEntry:{credit:0,debit:0, balance:0}};
           accountsSummery.pensionPayable= accounts.find(a=>a.name==='Pension Payable(Employee)')||{balance:0,openingEntry:{credit:0,debit:0, balance:0}};
           accountsSummery.pensionPayableCompany= accounts.find(a=>a.name==='Pension Payable(Company)')||{balance:0,openingEntry:{credit:0,debit:0, balance:0}};
           accountsSummery.pensionCompanyExpense= accounts.find(a=>a.name==='Pension Expense(Company)')||{balance:0,openingEntry:{credit:0,debit:0, balance:0}};
           accountsSummery.pfCompanyPayable= accounts.find(a=>a.name==='PF Payable(Company)')||{balance:0,openingEntry:{credit:0,debit:0, balance:0}};
           accountsSummery.pfEmpPayable= accounts.find(a=>a.name==='PF Payable(Employee)')||{balance:0,openingEntry:{credit:0,debit:0, balance:0}};
           accountsSummery.pfCompanyExpense= accounts.find(a=>a.name==='PF Expense(Company)')||{balance:0,openingEntry:{credit:0,debit:0, balance:0}};
           accountsSummery.payrollAdvanceRecivable= accounts.find(a=>a.name==='Payroll Advance')||{balance:0,openingEntry:{credit:0,debit:0, balance:0}};
           accountsSummery.payrollLoanRecivable= accounts.find(a=>a.name==='Payroll Loan Receivable')||{balance:0,openingEntry:{credit:0,debit:0, balance:0}};

           payrollStat= [
               {
                   product: "Payroll Net",
                   dataKey: "netPay",
                   total: {
                       current: {value:payrollRun.netPay,profit: false},
                       year: {value:accountsSummery.salariesPayable.balance,profit:false},
                       overall: {value:accountsSummery.salariesPayable.balance+accountsSummery.salariesPayable.openingEntry.debit,profit:false},
                       percent: { value: 0, profit: false }
                   },
                   color: "primary",
                   bottomRows: [
                       {
                           title: 'Net Pay (Cash)',
                           current: { value: payrollRun.cash , profit: false },
                           year: { value: accountsSummery.salariesExpense.balance, profit: true },
                           overall: { value: accountsSummery.salariesExpense.balance+accountsSummery.salariesExpense.openingEntry.credit, profit: true },
                           percent: { value: 0, profit: false }
                       } ,
                       {
                        title: 'Salary Expense',
                       current: { value: payrollRun.netPay + payrollRun.pensionEmployee + payrollRun.pf + payrollRun.incomeTax, profit: false },
                       year: { value: accountsSummery.salariesExpense.balance, profit: true },
                       overall: { value: accountsSummery.salariesExpense.balance+accountsSummery.salariesExpense.openingEntry.credit, profit: true },
                       percent: { value: 0, profit: false }
                   } ,
                       {
                           title: 'Total Withholding',
                           current: { value: payrollRun.pensionEmployee + payrollRun.pf + payrollRun.incomeTax, profit: false },
                           year: { value:accountsSummery.salariesExpense.balance +accountsSummery.salariesPayable.balance, profit: true },
                           overall: { value: accountsSummery.salariesExpense.balance + accountsSummery.salariesExpense.openingEntry.credit  +accountsSummery.salariesPayable.balance+ accountsSummery.salariesPayable.openingEntry.debit, profit: true },
                           percent: { value: 0, profit: false }
                       },
                       // {
                       //     title:'Paid',
                       //     current: { value: 0, profit: false },
                       //     year: { value: accountsSummery.salariesExpense.balance + accountsSummery.salariesPayable.balance , profit: true },
                       //     overall: { value: accountsSummery.salariesExpense.balance + accountsSummery.salariesPayable.balance+accountsSummery.salariesExpense.openingEntry.credit-accountsSummery.salariesPayable.openingEntry.debit, profit: true },
                       //     percent: { value: 3.7, profit: false }
                       // }
                   ]

               },
               {
                   product: "Pension",
                   dataKey: "pensionEmployee",
                   simple: false,
                   total: {
                       current: {value:payrollRun.pensionEmployee+payrollRun.pensionCompany,profit: false},
                       year: {value:accountsSummery.pensionCompanyExpense.balance-accountsSummery.pensionPayable.balance,profit:false},
                       overall: {value:accountsSummery.pensionCompanyExpense.balance+accountsSummery.pensionCompanyExpense.openingEntry.credit-(accountsSummery.pensionPayable.balance+accountsSummery.pensionPayable.openingEntry.debit),profit:false},
                       percent: { value: 0, profit: false }
                   },
                   color: "secondary",
                   bottomRows: [{
                       title:'Pension Employee',
                       current: {value:payrollRun.pensionEmployee,profit: false},
                       year: {value:accountsSummery.pensionPayable.balance,profit:false},
                       overall: {value:accountsSummery.pensionPayable.balance+accountsSummery.pensionPayable.openingEntry.debit,profit:false},
                       percent: { value: 0, profit: false }
                   },

                       {
                           title:'Pension Company',
                           current: {value:payrollRun.pensionCompany,profit: false},
                           year: { value: accountsSummery.pensionPayableCompany.balance , profit: true },
                           overall: { value: accountsSummery.pensionPayableCompany.balance+accountsSummery.pensionPayableCompany.openingEntry.debit, profit: true },
                           percent: { value: 0, profit: false }
                       },
                       // {
                       //     title:'Paid',
                       //     current: { value: 0, profit: false },
                       //     year: { value: accountsSummery.pensionCompanyExpense.balance+accountsSummery.pensionPayableCompany.balance , profit: true },
                       //     overall: { value: accountsSummery.pensionCompanyExpense.balance+accountsSummery.pensionCompanyExpense.openingEntry.credit+accountsSummery.pensionPayableCompany.balance+accountsSummery.pensionPayableCompany.openingEntry.debit, profit: true },
                       //     percent: { value: 3.7, profit: false }
                       // }
                   ]


               },
               {
                   product: "Provident Fund",
                   dataKey:'pf',
                   simple: false,
                   total: {
                       current: {value:payrollRun.pf+payrollRun.pfCompany,profit: false},
                       year: {value:accountsSummery.pfCompanyExpense.balance-accountsSummery.pfEmpPayable.balance,profit:false},
                       overall: {value:accountsSummery.pfCompanyExpense.balance+accountsSummery.pfCompanyExpense.openingEntry.credit-(accountsSummery.pfEmpPayable.balance+accountsSummery.pfEmpPayable.openingEntry.debit),profit:false},
                       percent: { value: 0, profit: false }
                   },
                   color: "secondary",
                   bottomRows: [{
                       title:'PF Employee',
                       current: {value:payrollRun.pf,profit: false},
                       year: {value:accountsSummery.pfEmpPayable.balance,profit:false},
                       overall: {value:accountsSummery.pfEmpPayable.balance+accountsSummery.pfEmpPayable.openingEntry.debit,profit:false},
                       percent: { value: 0, profit: false }
                   },

                       {
                           title:'PF Company',
                           current: {value:payrollRun.pfCompany,profit: false},
                           year: { value: accountsSummery.pfCompanyPayable.balance , profit: true },
                           overall: { value: accountsSummery.pfCompanyPayable.balance+accountsSummery.pfCompanyPayable.openingEntry.debit, profit: true },
                           percent: { value: 0, profit: false }
                       },
                       // {
                       //     title:'Paid',
                       //     current: { value: 0, profit: false },
                       //     year: { value: accountsSummery.pfCompanyExpense.balance+accountsSummery.pfCompanyPayable.balance , profit: true },
                       //     overall: { value: accountsSummery.pfCompanyExpense.balance+accountsSummery.pfCompanyExpense.openingEntry.credit+ accountsSummery.pfCompanyPayable.balance+accountsSummery.pfCompanyPayable.openingEntry.debit, profit: true },
                       //     percent: { value: 3.7, profit: false }
                       // }
                   ]


               },
               {
                   product: "Payroll Loan or Advance",
                   dataKey:'loanSettlement',
                   simple: false,
                   total: {
                       current: {value:payrollRun.loanSettlement+payrollRun.advanceSettlement,profit: false},
                       year: {value:accountsSummery.payrollAdvanceRecivable.balance+accountsSummery.payrollLoanRecivable.balance,profit:false},
                       overall: {value:accountsSummery.payrollAdvanceRecivable.balance+accountsSummery.payrollAdvanceRecivable.openingEntry.credit+accountsSummery.payrollLoanRecivable.balance+accountsSummery.payrollLoanRecivable.openingEntry.credit,profit:false},
                       percent: { value: 0, profit: false }
                   },
                   color: "secondary",
                   bottomRows: [{
                       title:'Payroll Advance',
                       current: {value:payrollRun.advanceSettlement,profit: false},
                       year: { value: accountsSummery.payrollAdvanceRecivable.balance , profit: true },
                       overall: { value: accountsSummery.payrollAdvanceRecivable.balance+accountsSummery.payrollAdvanceRecivable.openingEntry.credit, profit: true },
                       percent: { value: 0, profit: false }
                   },

                       {
                           title:'Payroll Loan',
                           current: {value:payrollRun.loanSettlement,profit: false},
                           year: { value: accountsSummery.payrollLoanRecivable.balance , profit: true },
                           overall: { value: accountsSummery.payrollLoanRecivable.balance+accountsSummery.payrollLoanRecivable.openingEntry.credit, profit: true },
                           percent: { value: 0, profit: false }
                       },
                       // {
                       //     title:'Paid',
                       //     current: { value: 0, profit: false },
                       //     year: { value: accountsSummery.pfCompanyExpense.balance+accountsSummery.pfCompanyPayable.balance , profit: true },
                       //     overall: { value: accountsSummery.pfCompanyExpense.balance+accountsSummery.pfCompanyExpense.openingEntry.credit+ accountsSummery.pfCompanyPayable.balance+accountsSummery.pfCompanyPayable.openingEntry.debit, profit: true },
                       //     percent: { value: 3.7, profit: false }
                       // }
                   ]


               },
               // {
               //     product: "Provident Fund",
               //     simple: false,
               //     total: {
               //         current: {value:payrollRun.pf+payrollRun.pfCompany,profit: false},
               //         year: {value:accountsSummery.pfCompanyExpense.balance,profit:false},
               //         overall: {value:accountsSummery.pensionPayable.balance+accountsSummery.pensionPayableCompany.balance+accountsSummery.pensionPayable.openingEntry.debit+accountsSummery.pensionPayableCompany.openingEntry.debit,profit:false},
               //         percent: { value: 3.7, profit: false }
               //     },
               //     color: "secondary",
               //     bottomRows: [{
               //         title:'PF Employee',
               //         current: {value:payrollRun.pf,profit: false},
               //         year: {value:accountsSummery.pensionPayable.balance,profit:false},
               //         overall: {value:accountsSummery.pensionPayable.balance+accountsSummery.pensionPayable.debit,profit:false},
               //         percent: { value: 3.7, profit: false }
               //     },
               //
               //         {
               //             title:'Pension Company',
               //             current: {value:payrollRun.pensionCompany,profit: false},
               //             year: {value:accountsSummery.pensionPayableCompany.balance,profit:false},
               //             overall: {value:accountsSummery.pensionPayableCompany.balance+accountsSummery.pensionPayableCompany.openingEntry.debit,profit:false},
               //             percent: { value: 3.7, profit: false }
               //         },
               //         {
               //             title:'Paid',
               //             current: { value: 0, profit: false },
               //             year: { value: accountsSummery.pensionCompanyExpense.balance+accountsSummery.pensionPayableCompany.balance , profit: true },
               //             overall: { value: accountsSummery.salariesPayable.debit, profit: true },
               //             percent: { value: 3.7, profit: false }
               //         }
               //     ]
               //
               //
               // },
               // {
               //     product: "Provident Fund",
               //     simple: false,
               //     total: {
               //         current: {value:payrollRun.pf+payrollRun.pfCompany,profit: false},
               //         year: {value:(accountsSummery.pfEmpPayable.credit-accountsSummery.pfEmpPayable.openingEntry.debit)+(accountsSummery.pfCompanyPayable.credit-accountsSummery.pfCompanyPayable.openingEntry.debit),profit:false},
               //         overall: {value:accountsSummery.pfEmpPayable.credit+accountsSummery.pfCompanyPayable.credit,profit:false},
               //         percent: { value: 3.7, profit: false }
               //     },
               //     color: "secondary",
               //     bottomRows: [{
               //         title:'Provident Fund Employee',
               //         current: {value:payrollRun.pf,profit: false},
               //         year: {value:accountsSummery.pfEmpPayable.credit-accountsSummery.pfEmpPayable.openingEntry.debit,profit:false},
               //         overall: {value:accountsSummery.pfEmpPayable.credit,profit:false},
               //         percent: { value: 3.7, profit: false }
               //     },
               //
               //         {
               //             title:'Pension Company',
               //             current: {value:payrollRun.pfCompany,profit: false},
               //             year: {value:accountsSummery.pfCompanyExpense,profit:false},
               //             overall: {value:accountsSummery.pfCompanyPayable.credit,profit:false},
               //             percent: { value: 3.7, profit: false }
               //         },
               //         {
               //             title:'Paid',
               //             current: { value: 0, profit: false },
               //             year: { value: accountsSummery.salariesPayable.debit , profit: true },
               //             overall: { value: accountsSummery.salariesPayable.debit, profit: true },
               //             percent: { value: 3.7, profit: false }
               //         }
               //     ]
               //
               //
               // },
               // {
               //     product: "Pension Expense (Company)",
               //     simple: true,
               //     total: {
               //         current: {value:payrollRun.pensionCompany,profit: false},
               //         year: {value:accountsSummery.pensionExpense.debit-accountsSummery.pensionExpense.openingEntry.credit,profit:false},
               //         overall: {value:accountsSummery.pensionExpense.credit,profit:false},
               //         percent: { value: 3.7, profit: false }
               //     },
               //     color: "warning",
               //     expense: {
               //         current: { value: payrollRun.netPay + payrollRun.pensionEmployee + payrollRun.pf + payrollRun.incomeTax, profit: false },
               //         year: { value: accountsSummery.salariesExpense.debit-accountsSummery.salariesExpense.credit, profit: true },
               //         overall: { value: accountsSummery.salariesExpense.debit, profit: true },
               //         percent: { value: 3.7, profit: false }
               //     },
               //     payable: {
               //         current: { value: payrollRun.pensionEmployee + payrollRun.pf + payrollRun.incomeTax, profit: false },
               //         year: { value: (accountsSummery.salariesExpense.debit -accountsSummery.salariesPayable.credit) - (accountsSummery.salariesExpense.credit -accountsSummery.salariesPayable.openingEntry.debit), profit: true },
               //         overall: { value: (accountsSummery.salariesExpense.debit -accountsSummery.salariesPayable.credit), profit: true },
               //         percent: { value: 3.7, profit: false }
               //     },
               //     paid: {
               //         current: { value: 0, profit: false },
               //         year: { value: accountsSummery.salariesPayable.debit , profit: true },
               //         overall: { value: accountsSummery.salariesPayable.debit, profit: true },
               //         percent: { value: 3.7, profit: false }
               //     }
               // }
           ]

       }








     //  if (error) { return <Error />; }
       const getGridListCols = () => {
           if (isWidthUp('xl', props.width)) {
               return 4;
           }

           if (isWidthUp('lg', props.width)) {
               return 3;
           }

           if (isWidthUp('md', props.width)) {
               return 2;
           }

           return 1;
       }

       return  <Card className={classes.mainCard}>
           <Title title={`PayDay 1.1     ${props.company && props.company.name}`}/>
            <CardHeader title={props.company && props.company.name} />
           {props.company.logo&&<img src={props.company.logo} height={50}/>}
            <CardContent>

                <Typography>Recent Payrolls</Typography>
                <Grid container spacing={4}>

                    {(approvedRuns && approvedRuns.length) ? <Grid item xs={10} container>
                        {/*<BarChartWidget/>*/}
                        <GridList  className={classes.gridList} cols={getGridListCols()}>
                        {approvedRuns.sort((a, b) => new Date(b.payrollRun.payrollDate) - new Date(a.payrollRun.payrollDate)).map(r =>
                            <GridListTile key={r.id} cols={1} style={{height:200}}>
                                <Card>
                                    <CardActionArea component={Link} to={`reports/payroll/${r.payrollRun.id}/sheet`} >
                                    <CardHeader
                                        style={{color:'#fff',backgroundColor:monthColors[moment(r.payrollRun.payrollDate).month()%2]}}
                                        title=<MultiDate gcDate={r.payrollRun.payrollDate} format={'MMMM YYYY'}/>

                                    />
                                    <CardContent style={{padding:"0px 5px 5px 0px "}}>
                                      <CaptionedField label='Payroll Net' value={r.payrollRun.netPay}/>
                                        <CaptionedField label='Settlements' value={r.payrollRun.cashSettlement}/>
                                      <CaptionedField label='Net Pay (Cash)' value={r.payrollRun.cash}/>
                                    </CardContent>
                                    </CardActionArea>
                                </Card>
                            </GridListTile>
                        )

                        }

                        </GridList>
                    </Grid>:<Grid item xs={10}>
                        <Typography variant="h4" color="textSecondary">
                            Approved Payrolls will be listed here
                        </Typography>
                    </Grid>
                    }
                    <Grid item xs={2}>
                        <PayDayLogoText />
                    </Grid>
                    {payrollStat.map(stat => (
                        <Grid item md={4} sm={6} xs={12} key={stat.product}>
                            <PayrollStat runs={approvedRuns.map(ar=>ar.payrollRun)} {...stat} />
                        </Grid>
                    ))}
                </Grid>


            </CardContent>
        </Card>








}
;
const Dashboard =  connect(state=>({company:state.user.currentCompany,
loading:state.admin.loading}))(withWidth()(DashboardComp))

export  default CheckDashboard
