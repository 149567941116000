import React from 'react';
import {Button, useNotify, useRefresh, useUnselectAll,} from 'react-admin';
import MoneyIcon from "@material-ui/icons/MoneyOutlined"
import {connect} from "react-redux";
import {loanCancelAction, loanDisburseAction} from "../../actions/loanActions";


const CancelLoansButton = ({ onDisburse,  selectedIds,loading,redirect,record }) => {
   // const {, record,redirect,id, } = props
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    // const [updateMany, { loading }] = useUpdateMany(
    //     'loans',
    //     selectedIds,
    //     { views: 0 },
    //     {
    //         onSuccess: () => {
    //             refresh();
    //             notify('Posts updated');
    //             unselectAll('loans');
    //         },
    //         onFailure: error => notify('Error: posts not updated', 'warning'),
    //     }
    // );



    return (
        <Button
            label="Cancel Loan"
            disabled={loading||(record&&record.loanStatus==='Pending')}
            onClick={()=> {
                onDisburse(selectedIds, redirect);
                unselectAll("loans");
            }}
        >
            <MoneyIcon />
        </Button>
    );
};

//export default DisburseLoansButton;
export default connect(state=>({loading:state.admin.loading}),dispatch=>({onDisburse:(ids,path)=>dispatch(loanCancelAction(ids,path))}))(CancelLoansButton)
