import moment from "moment";
import React from "react";
import renderTemplate from 'react-text-templates'
import {listOvertimes} from "../../components/report/common";
String.prototype.toNumber = function (s) {
    return +this

}

export const payslipToMJML = ({user,companyName,payroll,payrollDate,welcomeTemplate,templateData,payslipSettings,logo, isDraft})=> {
    payroll.earnings = [
        {
            name: `Basic Salary ${payroll.employmentType === "Casual"?`(${payroll.wadge} * ${payroll.daysWorked} days)`:''}`,
            value: payroll.basicSalary},
        {
        name: `Overtime ${listOvertimes(payroll.overtimes)}`,
        value: payroll.overtimeTotal
    }].concat(payroll.bonuses).concat(payroll.payrollFixedEarnings)
    payroll.deductions =  [{name:'Income Tax',value: payroll.incomeTax}].concat(payroll.payrollFixedDeductions).concat( payroll.payrollDeductions.filter(d=>!d.cash)).concat([{name:'Absence Deduction',value: payroll.absenceDeduction}])
    payroll.expenses = payroll.payrollFixedExpenses;

    payroll.cashDeductions = payroll.payrollDeductions.filter(d=>d.cash)

     const client = localStorage.getItem('client')||'xork'
    const bgColor = isDraft?'#ebb456':'#568feb'
    const bgLight = isDraft?'#ec9931':'#4b76cc'
    const bgDarker = isDraft?'#c87801':'#2974ea'
    const bgDark= isDraft?'#ec9931':'#1671e0'

   // console.log(welcomeTemplate)
   // console.log(renderTemplate(welcomeTemplate,templateData))

    return `
<mjml>
 <mj-head>
   <mj-breakpoint width="600px" />
    <mj-style inline="inline" >
    a:link {
  color: white;
}

/* visited link */
a:visited {
  color: green;
}

/* mouse over link */
a:hover {
  color: #4aa1ff;
}

/* selected link */
a:active {
  color: blue;
}
    </mj-style>
  </mj-head>
  <mj-body background-color="#ccd3e0" font-size="13px">
    <mj-section background-color="${bgDarker}" padding="10px 0">
      <mj-column width="33%">
        <mj-text align="left" color="#ffffff" font-size="30px" font-family="Lato, Helvetica, Arial, sans-serif" padding="18px 0px">${companyName}</mj-text>
      </mj-column>
      <mj-column width="66%">
        <mj-text align="right" color="#ffffff" font-size="20px" font-family="Lato, Helvetica, Arial, sans-serif" padding="18px 0px">${isDraft?'Draft':''} Payslip for  <strong>${moment(payrollDate).format('MMMM, YYYY')}</strong></mj-text>
      </mj-column>
    </mj-section>
    <mj-section background-color="#fff" padding-bottom="20px" padding-top="20px">
      <mj-column width="30%">
              <mj-image width="100px" src=${!!logo?`"${logo}"`:`"https://res.cloudinary.com/mates-xorks/image/upload/v1599389152/icons/payday-slip-${client}.png"`} ></mj-image>

      </mj-column>
     <mj-column width="70%">
       <mj-text align="left" font-size="18px" color="${bgDark}" font-family="Ubuntu, Helvetica, Arial, sans-serif" padding-left="25px" padding-right="25px" padding-bottom="5px" padding-top="28px">
  
 ${renderTemplate(welcomeTemplate, templateData)}
        </mj-text>
     </mj-column>
     
    </mj-section>
    
    <mj-section background-color="${bgDark}" padding-bottom="5px" padding-top="0">
      <mj-column width="100%">
        <mj-divider border-color="#ffffff" border-width="2px" border-style="solid" padding-left="20px" padding-right="20px" padding-bottom="0px" padding-top="0"></mj-divider>
      </mj-column>
    </mj-section>
        <mj-section background-color="${bgDark}" padding-bottom="0px" padding-top="0">
        <mj-column width="70%">
          <mj-text align="center" color="#FFF" font-size="13px" font-family="Helvetica" padding-left="25px" padding-right="25px" padding-bottom="28px" padding-top="28px"><span style="font-size:20px; font-weight:bold">How was your month?</span>
          <br/>
          <span style="font-size:15px">We have got you a detailed pay-slip for  this ${moment(payrollDate).format('MMMM')}. (see below)</span></mj-text>
          </mj-column>
         <mj-column width="30%">
              <mj-text align="center" color="#FFF" font-size="11px" font-family="Helvetica" padding-left="25px" padding-right="25px" padding-bottom="28px" padding-top="28px"><span style="font-size:15px;">Your NetPay</span>
          <br/>
          <span style="font-size:20px;font-weight:bold"><strong>${payroll.cash.toFixed(1).toNumber().toLocaleString()}</strong></span></mj-text>
          </mj-column>
  
 
    
    </mj-section>
    
        <mj-section background-color="${bgColor}" padding-bottom="15px">
      <mj-column>
        <mj-text align="left" color="#FFF" font-size="15px" font-family="Ubuntu, Helvetica, Arial, sans-serif" padding-left="25px" padding-right="25px" padding-bottom="0px"><strong>${payroll.fullName}</strong></mj-text>
        <mj-text align="left" color="#FFF" font-size="13px" font-family="Helvetica" padding-left="25px" padding-right="25px" padding-bottom="20px" padding-top="10px">
        
    <br/>
     ${payslipSettings.daysWorked ? `
     Days worked: <strong>${payroll.daysWorked}</strong> <br/>
    ` : ` `}
       ${payslipSettings.hoursWorked&&(payroll.hoursWorked!==payroll.monthlyHours)? `
     Payable Hours: <strong>${payroll.hoursWorked.toFixed(1)}</strong> of ${payroll.monthlyHours} <br/>
    ` : ` `}
     ${payslipSettings.absentDays ? `
     Absent days: <strong>${payroll.absentDays}</strong> <br/>
    ` : ` `}
      ${payslipSettings.monthLeaveDays ? `
     Leave days: <strong>${payroll.leaveDays}</strong> <br/>
    ` : ` `}
        </mj-text>
      </mj-column>
      <mj-column>
       
        <mj-text align="left" color="#FFF" font-size="15px" font-family="Helvetica" padding-left="25px" padding-right="25px" padding-bottom="20px" padding-top="10px">
        ${payslipSettings.tinNo && payroll.employee && payroll.employee.tinNo ? `
      TIN No: ${payroll.employee.tinNo}  <br/>
    ` : ` `}
        ${payslipSettings.pensionNo && payroll.employee && payroll.employee.pensionNo? `
      Pension No: ${payroll.employee.pensionNo}  <br/>
    ` : ` `}
         ${payslipSettings.department ? `
      Department: ${payroll.departmentName}  <br/>
    ` : ` `}
          ${payslipSettings.position? `
     Position: ${payroll.positionName} <br/>
    ` : ` `}
     ${payslipSettings.employmentType&&payroll.employmentType? `
    Employment Type:${payroll.employmentType} <br/>
    ` : ` `}
        </mj-text>
      </mj-column>
     
    </mj-section>
    
    
    
    
    <mj-section background-color="${bgColor}" padding-bottom="15px">
      <mj-column>
        <mj-text align="left" color="#FFF" font-size="15px" font-family="Ubuntu, Helvetica, Arial, sans-serif" padding-left="25px" padding-right="25px" padding-bottom="0px"><strong>Earnings</strong></mj-text>
         <mj-table color="#FFF">
         
         
             ${payroll.earnings.filter(i=>i.value>0).map(item =>
        `<tr>
        <td style="padding: 0 0px 0 0;">${item.name}</td>
        <td  align="end"  style="padding: 0 0px;">${item.value.toFixed(1).toNumber().toLocaleString()}</td>

    </tr>`
    ).join('')}
         
         
        
        </mj-table>
      </mj-column>
      <mj-column>
        <mj-text align="left" color="#FFF" font-size="15px" font-family="Ubuntu, Helvetica, Arial, sans-serif" padding-left="25px" padding-right="25px" padding-bottom="0px"><strong>Deductions</strong></mj-text>
       <mj-table color="#FFF">
         
         ${payroll.deductions.filter(i=>i.value>0).map(item => `<tr>
        <td style="padding: 0 0px 0 0;">${item.name}</td>
        <td  align="end"  style="padding: 0 0px;">${item.value.toFixed(1).toNumber().toLocaleString()}</td>

    </tr>`
    ).join('')}
     
         
        </mj-table>
      
      </mj-column>
        </mj-section>
        
           <mj-section background-color="${bgColor}" padding-bottom="15px">
      <mj-column>

 <mj-table color="#FFF">
 <tr>
        <td style="padding: 0 0px 0 0;">Total Earnings</td>
        <td  align="end"  style="padding: 0 0px;"><strong>${payroll.earnings.reduce((sum, cv) => sum + cv.value, 0).toFixed(1).toNumber().toLocaleString()}</strong></td>

    </tr>
 </mj-table>

        </mj-column>
         
              <mj-column>
              <mj-table color="#FFF">
 <tr>
        <td style="padding: 0 0px 0 0;">Total Deductions</td>
        <td  align="end"  style="padding: 0 0px;"><strong>${payroll.deductions.reduce((sum, cv) => sum + cv.value, 0).toFixed(1).toNumber().toLocaleString()}</strong></td>

    </tr>
 </mj-table>
        </mj-column>
   
      
    </mj-section>
        
        ${payslipSettings.expenses?`
         <mj-section background-color="${bgColor}" padding-bottom="15px">
      <mj-column>
        <mj-text align="left" color="#FFF" font-size="15px" font-family="Ubuntu, Helvetica, Arial, sans-serif" padding-left="25px" padding-right="25px" padding-bottom="0px"><strong>Company Expenses</strong></mj-text>
         <mj-table color="#FFF">
         
         
             ${payroll.expenses.filter(i=>i.value>0).map(item =>
        `<tr>
        <td style="padding: 0 0px 0 0;">${item.name}</td>
        <td  align="end"  style="padding: 0 0px;">${item.value.toFixed(1).toNumber().toLocaleString()}</td>

    </tr>`
    ).join('')}
         
         
        
        </mj-table>
      </mj-column>
      <mj-column>
      </mj-column>
      </mj-section>
        `:''}
        
        ${(payroll.cashDeductions&&payroll.cashDeductions.length>0)?`
        <mj-section background-color="${bgColor}" padding-bottom="15px">
         <mj-column>
         
<mj-text align="left" color="#FFF" font-size="15px" font-family="Ubuntu, Helvetica, Arial, sans-serif" padding-left="25px" padding-right="25px" padding-bottom="0px"><strong>Settlements</strong></mj-text>
<mj-table color="#FFF">
            <tr style="border-bottom:1px solid #ecedee;text-align:left;padding:15px 0;">
              <th class="first-col">Type</th>
              <th class="col">Settlement</th>
              <th class="col">Remaining</th>
              <th class="last-col">Estimated Months</th>
            </tr>
         
         ${payroll.cashDeductions.map(item => `<tr>
        <td style="padding: 0 0px 0 0;">${item.name}</td>
        <td  align="end"  style="padding: 0 0px;">${item.value.toFixed(1).toNumber().toLocaleString()}</td>
         <td  align="end"  style="padding: 0 0px;">${(item.remaining||0).toFixed(1).toNumber().toLocaleString()}</td>
          <td  align="end"  style="padding: 0 0px;">${(item.estimatedMonth||0).toLocaleString()}</td>

    </tr>`
    ).join('')}
     
         
        </mj-table>      
     
         </mj-column>
        </mj-section>
     
      `:` `}    
        
 
       <mj-section background-color="${bgLight}" padding-bottom="20px" padding-top="20px">
      <mj-column width="50%">
        <mj-table color="#FFF">
          ${payslipSettings.OutstandingLoan? `
     <tr>
            <td style="padding: 0 0px 0 0;">Outstanding Loan/Advance</td>
            <td  align="end"  style="padding: 0 0px;">${payroll.outstandingLoan.toFixed(1).toNumber().toLocaleString()} </td>
         
          </tr>
    ` : ` `}
           ${payslipSettings.annualLeave? `
     <tr>
            <td  style="padding: 0 0px 0 0;">Annual Leave</td>
            <td  align="end"  style="padding: 0 0px;">${payroll.leaveDaysEntitled>0?payroll.leaveDaysTaken + '/' + payroll.leaveDaysEntitled + 'Days':''}
</td>
     
          </tr>
    ` : ` `}
          
        </mj-table>
      </mj-column>
      <mj-column width="50%">
         <mj-table color="#FFF">
         
           ${payslipSettings.net? `
      <tr>
            <td style="padding: 0 0px 0 0;">Net</td>
            <td  align="end"  style="padding: 0 0px;">${payroll.netPay.toFixed(1).toNumber().toLocaleString()}</td>
         
          </tr>
    ` : ` `}
         
          ${payslipSettings.LoanSettlement? `
      <tr>
            <td style="padding: 0 0px 0 0;">Advance/Loan Settlement</td>
            <td  align="end"  style="padding: 0 0px;">${payroll.payrollDeductionTotalCash.toFixed(1).toNumber().toLocaleString()}</td>
     
          </tr>
    ` : ` `}
            ${payslipSettings.netPay? `
      <tr>
            <td style="padding: 0 0px 0 0;">Net Pay</td>
            <td  align="end"  style="padding: 0 0px; font-weight: bold;">${payroll.cash.toFixed(1).toNumber().toLocaleString()}</td>
     
          </tr>
    ` : ` `}
           
        </mj-table>
         </mj-column>

    </mj-section>
    
     <mj-section background-color="#ddd" padding-bottom="5px" padding-top="0">
      ${user.email? `<mj-column width="100%">
        <mj-divider border-color="#ffffff" border-width="2px" border-style="solid" padding-left="20px" padding-right="20px" padding-bottom="0px" padding-top="0"></mj-divider>
         <mj-text align="center" color="#000" font-size="15px" font-family="Helvetica" padding-left="25px" padding-right="25px" padding-bottom="20px" padding-top="20px">
         You can reply to <a href="mailto:${user.email}">${user.firstName || user.username || ' '} (${user.email}) </a> regarding this Payslip
</mj-text>
     </mj-column>`:''}
  </mj-section>
    
   
    <mj-section background-color="${bgDark}" padding-bottom="5px" padding-top="0">
      <mj-column width="100%">
        <mj-divider border-color="#ffffff" border-width="2px" border-style="solid" padding-left="20px" padding-right="20px" padding-bottom="0px" padding-top="0"></mj-divider>
        
        <mj-text align="center" color="#FFF" font-size="10px" font-family="Helvetica" padding-left="25px" padding-right="25px" padding-bottom="20px" padding-top="20px">⚡ 
         <a style="color=#FFF" href="https://www.payday.truwrk.com">PayDay</a></mj-text>
      </mj-column>
    </mj-section>
  </mj-body>
</mjml>
`
}
