import React from 'react';
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(theme => ({
    typography: {
        padding: theme.spacing(2),
    },
}));
const AddBonusPopover = props => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [name, setName] = React.useState('Bonus');
    const [value, setValue] = React.useState(0);
    const [taxOption, setTaxOption] = React.useState('NonTaxable');
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleAdd = () => {
        props.onAdd({name: name,value,taxOption})
        setName('Bonus')
        setValue(0)
        setTaxOption('NonTaxable')
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div style={{marginTop:-20}}>
            <Button aria-describedby={id} variant="text" color="primary" onClick={handleClick}>
                + Bonus/Benefit
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Card>
                   <CardContent>
                    <FormControl fullWidth  >
                        <TextField
                            onChange={ev => setName(ev.target.value)}
                            value={name}
                            margin="dense"
                            label="Title"
                            fullWidth
                        />
                        <Select
                            value={taxOption}
                            onChange={ev=>setTaxOption(ev.target.value)}
                        >
                            {[
                                { id: 'NonTaxable', name: 'NonTaxable' },
                                { id: 'Taxable', name: 'Taxable' },
                                { id: 'TaxableWithLimits', name: 'TaxableWithLimits' },
                                { id: 'TaxableWithPayback', name: 'Taxable But Payback' },
                                // { id: 'TaxCoveredGrossUp', name: 'Tax Covered By Employer (GrossUp)' },
                                { id: 'TaxCovered', name: 'Tax Covered By Employer (Add to Net)' },
                            ].map(to=><MenuItem key={to.id} value={to.id}>{to.name}</MenuItem>)}
                        </Select>




                        <TextField
                            onChange={ev => setValue(+ev.target.value)}
                            value={value}
                            type="number"
                            autoFocus
                            margin="dense"
                            label="Value"
                            fullWidth
                        />

                    </FormControl>
                   </CardContent>
                    <CardActions>
                        <Button style={{ marginLeft: 'auto'}} onClick={handleAdd} variant="text" color="primary">
                            Add
                        </Button>
                    </CardActions>
                </Card>

            </Popover>
        </div>
    );
};

export default AddBonusPopover;
