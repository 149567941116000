import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {FormattedNumber} from "react-intl";
import MultiCurrency from "../components/multiCurrency";

const PercentField = ({ source, record = {} }) => {
    if (!record) {
        return null;
    }
   // console.log(record, "parent")
    const value = get(record, source);
    if (value == null) {
        return null;
    }
    const currency = get(record, "parent.defaultCurrency");
    if(record.valueType==='Percentage')
    return <FormattedNumber value={value} style='percent' minimumFractionDigits={2} />
    else
        return <MultiCurrency defaultCurrency={currency}    value={value}/>
}

PercentField.defaultProps = {
    textAlign:"right"
}

PercentField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default PercentField;
