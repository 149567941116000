import React, {useEffect} from 'react';
import {Button as KButton} from "@progress/kendo-react-buttons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import BonusItem from "./bonusItem";
import Button from "@material-ui/core/Button";
import DistEarningItem from "./distEarningItem";
import DialogActions from "@material-ui/core/DialogActions";
import {useDispatch} from "react-redux";
import {CustomDialogTitle} from "../../UIComponents/CustomDialogTitle";

const DistEarnings = props => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [items, setItems] = React.useState(props.distEarnings);

    useEffect(() => {
        if(props.distEarnings&&props.distEarnings.length) {
            setItems(props.distEarnings || [])
        }
    },[props.distEarnings])

    const dispatch = useDispatch();
    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const handleClose = () => {
        dispatch({type:"DIST_EARNINGS_CHANGED", payload:{distEarnings: items, saveOnly:true}})
        setDialogOpen(false);
    };

    const handleAdd = () => {
        dispatch({type:"DIST_EARNINGS_CHANGED", payload:{distEarnings: items}})
        setDialogOpen(false);
    };

    const handleItemChange = index => ot => {
        // console.log({ot})
        const newItems = items.slice(0); // Create a shallow copy of the roles
        newItems[index] = ot; // Set the new value
        setItems(newItems)
    }

    const handleRemove = index => () => {
        dispatch({type:"DIST_EARNINGS_REMOVED", payload:{apply: {...items[index],baseAmount:0}}})
        const newItems = items.slice(0); // Create a shallow copy of the roles
        newItems.splice(index,1)
        setItems(newItems)

    }

    const handleApply = (index,i)  => {

       const item = i||items[index]
        console.log(item,i)
        dispatch({type:"DIST_EARNINGS_CHANGED", payload:{apply:item}})

    }

    const saveDefaults = () => {
       return  dispatch({type:'SAVE_DEFAULT_DIST_EARNINGS',payload:items})
    }
    return (
        <>
            <KButton icon="filter-add-expression" className="k-info-colored"  onClick={handleClickOpen}>
               Dist Earnings
            </KButton>
            <Dialog maxWidth='lg'   fullWidth open={dialogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
                <CustomDialogTitle onClose={handleClose} id="form-dialog-title">Distributed Earnings
                <Button color='primary' onClick={saveDefaults}>Save</Button>
                </CustomDialogTitle>
                <DialogContent dividers>
                    {items.map((ot,index) =>
                        <DistEarningItem groups={props.groups} payrollRun={props.payrollRun}  key={index} value={ot} onRemove={handleRemove(index)}  onChange={handleItemChange(index)} onApply={item=>handleApply(index,item)} />
                    )}

                    <Button onClick={()=>setItems([...items,{name:'*Service Charge'}])} color="primary">
                        Add
                    </Button>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                    {/*<Button onClick={handleAdd} color="primary">*/}
                    {/*    OK*/}
                    {/*</Button>*/}
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DistEarnings;
