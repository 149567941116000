import React from 'react';
import RunPayroll from "./runPayRoll";
import Dock from 'react-dock'
import {connect} from "react-redux";
import PayrollTotals from "./payrollTotals";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {Title} from 'react-admin';
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close"

const PayrollView = props => {
    const id  = +props.match.params.id;
    return (
        <Card>
            <Title title="Run PayRoll" />
            <CardContent>
                <RunPayroll id={id}/>
                <Dock  defaultSize={0.35}  zIndex={999} dockStyle={{width:'65%',left:'25%',paddingLeft:'30px'}} dimMode="none" position='bottom' isVisible={props.dockVisible}>
                    <Box display="flex" alignContent="flex-start" justifyContent="flex-end">
                        <PayrollTotals/>
                        <IconButton onClick={props.closeDock}>
                            <CloseIcon/>
                        </IconButton>
                    </Box>

                </Dock>
            </CardContent>
        </Card>

    );
};

export default connect(state=>({
    dockVisible:state.ui.payrollDockVisible
}),dispatch=>({
    closeDock:()=>dispatch({type:'SET_PAYROLL_DOCK_VISIBLE',payload:false})
}))(PayrollView);
