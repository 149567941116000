import React from 'react';
import {Title} from 'react-admin';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AssignmentIcon from '@material-ui/icons/Assignment';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {deepOrange, green} from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    cardHeader: {
        backgroundColor: theme.palette.primary.main,
        color:'#fff'
    },
    cardHeader2: {
        backgroundColor: theme.palette.warning.main,
        color:'#fff'
    },
    square: {
        color: '#fff',
        backgroundColor: deepOrange[500],
    },
    rounded: {
        color: '#fff',
        backgroundColor: green[500],
    },
}));
const Reports = () => {





    const classes = useStyles();
    return (
        <Card style={{backgroundColor:'#F5F5F5'}}>
            <Title title="Payroll" />
            <CardContent>

                <Grid container spacing={2}>
                    <Grid item>
                        <Card >
                            <CardHeader  className={classes.cardHeader}
                                         avatar={
                                             <Avatar aria-label="recipe" className={classes.square} >
                                                 <AssignmentIcon />
                                             </Avatar>
                                         }
                                 action={
                                    <IconButton aria-label="settings">
                                        <MoreVertIcon />
                                    </IconButton>
                                }
                                title="Payrolls"
                                //subheader="View Reports about Payroll"
                               subheaderTypographyProps={{ align: 'center', color:'#f3f3f3' }}
                            />
                            <CardContent>
                                <Typography  color="textSecondary" gutterBottom>
                                    View Reports about Payroll
                                </Typography>
                                {/*<Typography variant="h5" component="h2">*/}

                                {/*</Typography>*/}
                                {/*<Typography color="textSecondary">*/}

                                {/*</Typography>*/}
                                {/*<Typography variant="body2" component="p">*/}
                                    {/*hhh*/}
                                {/*</Typography>*/}
                            </CardContent>
                            <CardActions>

                                    <Button component={Link} to={`/reports/payroll`}  >View Payroll Report</Button>

                            </CardActions>
                        </Card>

                    </Grid>
                    <Grid item>
                        <Card >
                            <CardHeader className={classes.cardHeader2}
                                        avatar={
                                            <Avatar aria-label="recipe" className={classes.square} >
                                                <AssignmentIcon />
                                            </Avatar>
                                        }
                                        action={
                                            <IconButton aria-label="settings">
                                                <MoreVertIcon />
                                            </IconButton>
                                        }
                                        title="Variance Report"
                                // subheader="Payroll Advance and Employee Loan Report"
                                        subheaderTypographyProps={{ align: 'center', color:'#f3f3f3' }}
                            />
                            <CardContent>
                                <Typography  color="textSecondary" gutterBottom>
                                    Variance Report with Detail and summery views
                                </Typography>
                                {/*<Typography variant="h5" component="h2">*/}

                                {/*</Typography>*/}
                                {/*<Typography color="textSecondary">*/}

                                {/*</Typography>*/}
                                {/*<Typography variant="body2" component="p">*/}
                                {/*hhh*/}
                                {/*</Typography>*/}
                            </CardContent>
                            <CardActions>
                                <Button component={Link} to={`/reports/variance`} size="small">View Variance Report</Button>
                            </CardActions>
                        </Card>

                    </Grid>
                    <Grid item>
                        <Card >
                            <CardHeader className={classes.cardHeader}
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.square} >
                                        <AssignmentIcon />
                                    </Avatar>
                                }
                                action={
                                    <IconButton aria-label="settings">
                                        <MoreVertIcon />
                                    </IconButton>
                                }
                                title="Employee Report"
                                // subheader="Payroll Advance and Employee Loan Report"
                                        subheaderTypographyProps={{ align: 'center', color:'#f3f3f3' }}
                            />
                            <CardContent>
                                <Typography  color="textSecondary" gutterBottom>
                                    Employee Report
                                </Typography>
                                {/*<Typography variant="h5" component="h2">*/}

                                {/*</Typography>*/}
                                {/*<Typography color="textSecondary">*/}

                                {/*</Typography>*/}
                                {/*<Typography variant="body2" component="p">*/}
                                    {/*hhh*/}
                                {/*</Typography>*/}
                            </CardContent>
                            <CardActions>
                                <Button component={Link} to={`/reports/employee`} size="small">View Employee Reports</Button>
                            </CardActions>
                        </Card>

                    </Grid>
                    <Grid item>
                        <Card >
                            <CardHeader className={classes.cardHeader2}
                                        avatar={
                                            <Avatar aria-label="recipe" className={classes.square} >
                                                <AssignmentIcon />
                                            </Avatar>
                                        }
                                        action={
                                            <IconButton aria-label="settings">
                                                <MoreVertIcon />
                                            </IconButton>
                                        }
                                        title="Employee Payroll Statement"
                                // subheader="Payroll Advance and Employee Loan Report"
                                        subheaderTypographyProps={{ align: 'center', color:'#f3f3f3' }}
                            />
                            <CardContent>
                                <Typography  color="textSecondary" gutterBottom> Payroll Statement
                                </Typography>
                                {/*<Typography variant="h5" component="h2">*/}

                                {/*</Typography>*/}
                                {/*<Typography color="textSecondary">*/}

                                {/*</Typography>*/}
                                {/*<Typography variant="body2" component="p">*/}
                                {/*hhh*/}
                                {/*</Typography>*/}
                            </CardContent>
                            <CardActions>
                                <Button component={Link} to={`/reports/payrollStatement`} size="small">View Payroll Statement</Button>
                            </CardActions>
                        </Card>

                    </Grid>
                    {/*<Grid item>*/}
                    {/*    <Card >*/}
                    {/*        <CardHeader className={classes.cardHeader2}*/}
                    {/*                    avatar={*/}
                    {/*                        <Avatar aria-label="recipe" className={classes.square} >*/}
                    {/*                            <AssignmentIcon />*/}
                    {/*                        </Avatar>*/}
                    {/*                    }*/}
                    {/*                    action={*/}
                    {/*                        <IconButton aria-label="settings">*/}
                    {/*                            <MoreVertIcon />*/}
                    {/*                        </IconButton>*/}
                    {/*                    }*/}
                    {/*                    title="Loan/Advance"*/}
                    {/*            // subheader="Payroll Advance and Employee Loan Report"*/}
                    {/*                    subheaderTypographyProps={{ align: 'center', color:'#f3f3f3' }}*/}
                    {/*        />*/}
                    {/*        <CardContent>*/}
                    {/*            <Typography  color="textSecondary" gutterBottom>*/}
                    {/*                Payroll Advance and Employee Loan Report*/}
                    {/*            </Typography>*/}
                    {/*            /!*<Typography variant="h5" component="h2">*!/*/}

                    {/*            /!*</Typography>*!/*/}
                    {/*            /!*<Typography color="textSecondary">*!/*/}

                    {/*            /!*</Typography>*!/*/}
                    {/*            /!*<Typography variant="body2" component="p">*!/*/}
                    {/*            /!*hhh*!/*/}
                    {/*            /!*</Typography>*!/*/}
                    {/*        </CardContent>*/}
                    {/*        <CardActions>*/}
                    {/*            <Button size="small">View Loan Reports</Button>*/}
                    {/*        </CardActions>*/}
                    {/*    </Card>*/}

                    {/*</Grid>*/}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default Reports;
