import React from 'react';
import {MenuItemLink, usePermissions, useQueryWithStore, UserMenu} from 'react-admin'
import SettingsIcon from '@material-ui/icons/Settings'
import ListIcon from '@material-ui/icons/ListAltOutlined'

const MyUserMenu = props => {

    const { loaded, error, data: profile} = useQueryWithStore({
        type: 'getMany',
        resource: 'users/me',
        payload: {filter:{},pagination:{},sort:{}}
    });

    const p= usePermissions()
    const permissions = p.permissions


    return (
        <UserMenu label={profile ? profile.username : ''} {...props}>
            <MenuItemLink
                to="/my-profile"
                primaryText="Edit Profile"
                leftIcon={<SettingsIcon />}
            />
            {permissions && !permissions.includes('Superuser') && <MenuItemLink
                to={{ pathname: '/selectCompanies', flush:true }}
                primaryText="Select Another Company"
                leftIcon={<ListIcon/>}
            />
            }

        </UserMenu>
    );
};

export default MyUserMenu;
