const initialState = {
    payrollDockVisible: true,
    minVersion : "1.1.5",
    currentVersion : "1.1.6",
    client: 'xork',
    server:"xork",
    serverType: localStorage.getItem('serverType'),
   // confirmDialogOpen: false,
    confirmDialog: {
        isOpen: false,
        title: '',
        message: ''
      },
    openedDialog: ''

};

export default (previousState = initialState, { type, payload }) => {
    if (type === 'SET_PAYROLL_DOCK_VISIBLE') {
        return {...previousState, payrollDockVisible: payload};
    }
    if (type === 'SET_APP_CLIENT') {
        localStorage.setItem('client',payload)
        return {...previousState, client: payload};
    }

    if (type === 'SET_APP_SERVER') {
        localStorage.setItem('server',payload)
        return {...previousState, server: payload};
    }

    if (type === 'SET_SERVER_TYPE') {
        localStorage.setItem('serverType',payload)
        return {...previousState, serverType: payload};
    }

    if (type === 'SET_MIN_VERSION') {
        return {...previousState,  minVersion: payload};
    }
    if (type === 'SET_CURRENT_VERSION') {
        return {...previousState,   currentVersion: payload};
    }

    if (type === 'SET_SHOW_CONFIRM') {
        return {...previousState,   confirmDialogOpen: payload};
    }

    if (type === 'SET_OPENED_DIALOG') {
        return {...previousState,   openedDialog: payload};
    }

    return previousState;
}
