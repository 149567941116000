import React from 'react';
import {Error, Loading, Title, usePermissions, useQueryWithStore} from 'react-admin';

import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {deepOrange, green} from '@material-ui/core/colors';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Link} from "react-router-dom";
import ApproveButton from "./approveButton";
import moment from "moment";
import CaptionedField from "../../UIComponents/captionedField";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    square: {
        color: '#fff',
        backgroundColor: deepOrange[500],
    },
    rounded: {
        color: '#fff',
        backgroundColor: green[500],
    },
}));

const PayrollReportsView = props => {
    const id  = +props.match.params.id;
    const classes = useStyles();
    const permissions= usePermissions()
    const { loaded, error, data: payrollRun } = useQueryWithStore({
        type: 'getOne',
        resource: 'payrollRuns',
        payload: {id}
    });
    if (!loaded) { return <Loading />; }
    if (error) { return <Error />; }
    if (!payrollRun || !permissions.permissions) return null;


    return (
        <Card style={{backgroundColor:'#F5F5F5'}}>
            <Title title="Payroll" />
            <CardContent>
              Status: {payrollRun.status}
                <Grid container direction='column'>
                <Grid container spacing={2}>
                    <Grid item>
                        <Card >
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="recipe" >
                                        R
                                    </Avatar>
                                }
                                action={
                                    <IconButton aria-label="settings">
                                        <MoreVertIcon />
                                    </IconButton>
                                }
                                title={`${moment(payrollRun.payrollDate).format('MMMM')} Payroll`}
                                subheader={`${moment(payrollRun.runDate).format('MMMM D, YYYY')}`}
                            />
                            <CardContent>
                                <Typography  color="textSecondary" gutterBottom>

                                </Typography>
                                <Typography variant="h5" component="h2">

                                </Typography>
                                <Typography color="textSecondary">

                                </Typography>
                                <Typography variant="body2" component="p">
                                    <CaptionedField value={payrollRun.cash} label="NetPay"/>
                                    {/*<strong>Netpay</strong> <MultiCurrency value={payrollRun.netPay}/>*/}
                                </Typography>
                            </CardContent>
                            <CardActions>

                                    <Button component={Link} to={`/reports/payroll/${id}/sheet`}  size="small">View Payroll Sheet</Button>
                                <Button component={Link} to={`/reports/payroll/${id}/bank`}  size="small">View Bank List</Button>

                            </CardActions>
                        </Card>

                    </Grid>
                    {/*<Grid item>*/}
                        {/*<Card >*/}
                            {/*<CardHeader*/}
                                {/*avatar={*/}
                                    {/*<Avatar aria-label="recipe" className={classes.square} >*/}
                                        {/*<AssignmentIcon />*/}
                                    {/*</Avatar>*/}
                                {/*}*/}
                                {/*action={*/}
                                    {/*<IconButton aria-label="settings">*/}
                                        {/*<MoreVertIcon />*/}
                                    {/*</IconButton>*/}
                                {/*}*/}
                                {/*title="December Payroll"*/}
                                {/*subheader="December 6, 2019"*/}
                            {/*/>*/}
                            {/*<CardContent>*/}
                                {/*<Typography  color="textSecondary" gutterBottom>*/}

                                {/*</Typography>*/}
                                {/*<Typography variant="h5" component="h2">*/}

                                {/*</Typography>*/}
                                {/*<Typography color="textSecondary">*/}

                                {/*</Typography>*/}
                                {/*<Typography variant="body2" component="p">*/}
                                    {/*<strong>Netpay</strong> {payrollRun.netPay}*/}
                                {/*</Typography>*/}
                            {/*</CardContent>*/}
                            {/*<CardActions>*/}
                                {/*<Button size="small">View Bank List</Button>*/}
                            {/*</CardActions>*/}
                        {/*</Card>*/}

                    {/*</Grid>*/}

                </Grid>
                <Grid container>
                    {
                        permissions.permissions.includes('Manager') && payrollRun.status !== 'Approved' &&    <ApproveButton record={payrollRun}/>
                    }

                </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
};

export default PayrollReportsView
