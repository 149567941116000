import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from "@material-ui/core/IconButton";
import HelpIcon from "@material-ui/icons/Help";
import {Link} from "react-router-dom";
import {usePermissions} from 'react-admin'

export default function HelpMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const permissions= usePermissions()
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <HelpIcon />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {permissions.permissions &&permissions.permissions.includes('Superuser')&&<MenuItem onClick={handleClose} component={Link} to={'/help?doc=admin&page=getting-started'}>Super Admins Guide</MenuItem>}
                <MenuItem onClick={handleClose} component={Link} to={'/help?path=?top-category=getting-started&&doc='}>Getting Started</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to={'/help?path=?top-category=faqs&&doc='}>Frequently Asked Questions</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to={'/help?path=?top-category=how-to-guides&&doc='}>How to Guides</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to={'/help?path=/how-to-create-loans'}>Loans</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to={'/help?path=/how-to-create-annual-leaves'}>Leaves</MenuItem>
                {/*<MenuItem onClick={handleClose} component={Link} to={'/help?path=?top-category=faqs&&doc='}>Frequently Asked Questions</MenuItem>*/}

                {/*<MenuItem onClick={handleClose} component={Link} to={'/help?page=reports-and-payslips'}>Reports and Payslips</MenuItem>*/}

            </Menu>
        </div>
    );
};


