import React from 'react';
import {Callout, Center, Column, Container, Item, Menu, Row, Spacer, Wrapper} from 'react-inky';


import moment from "moment";


const PayslipEmail = ({payroll,payrollDate,companyName}) => {
   // console.log(payroll)
    payroll.earnings = [{name:'Basic Salary',value: payroll.basicSalary},{name:'Overtime',value: payroll.overtimeTotal}].concat(payroll.bonuses).concat(payroll.payrollFixedEarnings)
    payroll.deductions =  [{name:'Income Tax',value: payroll.incomeTax}].concat(payroll.payrollFixedDeductions).concat( payroll.payrollDeductions).concat([{name:'Absence Deduction',value: payroll.absenceDeduction}])

    return (
        <div>
            <Wrapper className="header">
                <Container>
                    <Row className="collapse">
                        <Column small="6">
                            <img src="http://placehold.it/200x50/663399" />
                        </Column>
                        <Column small="6">
                            <p className="text-right">{companyName}</p>
                        </Column>
                    </Row>
                </Container>
            </Wrapper>
            <Container>
                <Spacer size="16"></Spacer>
                <Row>
                    <Column>
                        <h3>Hello,  <strong> {payroll.name} </strong>, How was you month?</h3>
                        <p className="lead">We have got {moment(payrollDate).format('MMMM')}'s Payslip for you</p>
                        {/*<Center>*/}
                        {/*<img src="http://placehold.it/570x300" alt="" />*/}
                        {/*</Center>*/}
                        <Callout className="primary">
                            <p>Your net pay for this month is <strong>{payroll.cash.toLocaleString()}</strong> <a href="#">Scroll down to see full details!</a></p>
                        </Callout>
                    </Column>
                </Row>

                <Callout className="primary">
                    <Center>
                        {companyName}
                    </Center>
                    <Center>
                        Payslip for Period <strong>{moment(payrollDate).format('MMMM, YYYY')}</strong>
                    </Center>
                    <Row>
                        <Column>
                            {payroll.fullName}  <br/>
                            Days worked: <strong>{payroll.daysWorked}</strong> <br/>
                            Absentdays: <strong>{payroll.absentDays}</strong> <br/>
                        </Column>

                        <Column>
                            TIN No: {payroll.employee.tinNo}  <br/>
                            Pension No: {payroll.employee.pensionNo}  <br/>
                            Employment Type:{payroll.employee.employmentType}  <br/>
                            Department: {payroll.department}  <br/>
                            Position: {payroll.position} <br/>

                        </Column>

                    </Row>
                </Callout>

                <Callout className="primary">
                <Row>

                    <Column small="6">
                        <h6>Earnings</h6>

                        {payroll.earnings.map(item=><Row>
                            <Column>{item.name}</Column>
                            <Column>{item.value.toLocaleString()}

                            </Column>
                        </Row>)}



                    </Column>
                    <Column small="6" className="sidebar">
                        <h6>Deductions</h6>
                        {payroll.deductions.map(item=><Row>
                            <Column>{item.name}</Column>
                            <Column>
                            {item.value.toLocaleString()}

                            </Column>
                        </Row>)}

                    </Column>

                </Row>
                    <Row>
                        <Column>
                            <Row>
                                <Column>Total Earnings</Column>
                                <Column><strong>{payroll.earnings.reduce((sum,cv)=>sum+cv.value,0).toLocaleString()}</strong>

                                </Column>
                            </Row>
                        </Column>
                        <Column>
                            <Row>
                                <Column>Total Deductions</Column>
                                <Column><strong>{payroll.deductions.reduce((sum,cv)=>sum+cv.value,0).toLocaleString()}</strong>

                                </Column>
                            </Row>
                        </Column>
                    </Row>
                </Callout>
                <Spacer/>
                <Callout>
                    <Row>
                        <Column>
                            <strong>Outstanding Loan/Advance: </strong> {}
                            {payroll.employee.outstandingLoan.toLocaleString()} <br/>

                            <strong>Annual Leave:   </strong>

                            {payroll.employee.leaves[0]&&<>{payroll.employee.leaves[0].daysTaken}/{payroll.employee.leaves[0].entitledDays} Days</>}

                        </Column>
                        <Column>
                            <strong>Net: </strong>
                            {payroll.netPay.toLocaleString()}<br/>
                            <strong>Advance/Loan Settlement: </strong>
                            {payroll.payrollDeductionTotalCash.toLocaleString()} <br/>
                            <strong>Net Pay: </strong>
                            {payroll.cash.toLocaleString()}
                        </Column>
                    </Row>
                </Callout>
                <Center>
                    <Menu>
                        <Item href="#">Terms</Item>
                        <Item href="#">Privacy</Item>
                        <Item href="#">Unsubscribe</Item>
                    </Menu>
                    <a href="https://www.payday.et">⚡ by PayDay</a>
                </Center>
            </Container>
        </div>
    );
};

export default PayslipEmail;
