import React from 'react';
import PropTypes from 'prop-types';
import MultiCurrency from "../components/multiCurrency";
import get from 'lodash/get';

const MultiCurrencyField = ({ source, record = {},decimalPlace=2  }) => {
    if (!record) {
        return null;
    }
    const value = get(record, source);
    if (value == null) {
        return null;
    }

    const currency = get(record, "defaultCurrency");


return <MultiCurrency    defaultCurrency={currency} decimalPlace={decimalPlace}  value={value}/>
}

MultiCurrencyField.defaultProps = {
   textAlign:"right"
}

MultiCurrencyField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default MultiCurrencyField;
