import React, {useState} from 'react';
import {Button, useNotify, useRefresh, useUnselectAll, useUpdateMany} from "react-admin";
import Popover from "@material-ui/core/Popover";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import PayrollDeductionItem from "./payrollDeductionItem";
import CardActions from "@material-ui/core/CardActions";
import {useQueryWithStore} from "react-admin";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import ActiveIcon from "@material-ui/icons/AddLocationOutlined";

const ChangeEmploymentTypePopover = props => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [employmentType, setEmploymentType] = useState(0);
  const {selectedIds} = props;
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const [updateMany] = useUpdateMany(
        'employees',
        selectedIds,
        { employmentType:employmentType },
        {
            onSuccess: () => {
                refresh();
                notify(` ${selectedIds.length} Employees Changed to${data.find(s=>s.id===employmentType).name}`);
                unselectAll('employees');
            },
            onFailure: error => notify('Error: Some Employees cannot be moved', 'warning'),
        }
    );


    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMove= () => {
        updateMany()
        setAnchorEl(null);
    };
    // const { data: sites} = useQueryWithStore({
    //     type: 'getList',
    //     resource: 'sites',
    //     payload: {filter:{},pagination:{},sort:{}}
    // });
    const data = [
            { id: 'FullTime', name: 'FullTime' },
    { id: 'Contract', name: 'Contract' },
    { id: 'PartTime', name: 'PartTime' },
    { id: 'InternOrFellow', name: 'InternOrFellow' },
    { id: 'Casual', name: 'Casual' },
]

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <div>
            <Button label={props.label} aria-describedby={id} variant="text" color="primary" onClick={handleClick}>
                <ActiveIcon/>
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Card style={{minWidth:100}}>
                    <CardContent>
                        <FormControl margin='normal' >
                            <InputLabel shrink >
                               Employment Type
                            </InputLabel>
                        <Select
                            value={employmentType}
                            onChange={ev => {
                                setEmploymentType(ev.target.value)
                            }
                            }
                        >
                             {data&&data.map(ot=><MenuItem key={ot.id} value={ot.id}>{ot.name}</MenuItem>)}
                        </Select>
                        </FormControl>

                    </CardContent>
                    <CardActions>
                        <Button label='OK' disabled={!employmentType} style={{ marginLeft: 'auto'}} onClick={handleMove} variant="text" color="primary">

                        </Button>
                    </CardActions>
                </Card>

            </Popover>
        </div>
    );
};

export default ChangeEmploymentTypePopover;
