import React, {Fragment, useRef} from 'react';
import {
    AutocompleteInput, BooleanField,
    BulkDeleteButton,
    Create,
    Datagrid,
    DeleteButton,
    Edit, EditButton, Filter,
    FormDataConsumer,
    FunctionField,
    List,
    NumberField,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    required,
    SaveButton,
    SelectInput,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    Toolbar,
    DateField,
    useQueryWithStore,
} from 'react-admin';
import DisburseLoansButton from "../loan/disburseButton";
import exporterCommon from "../import/exporterCommon";
import MultiCurrencyField from "../../UIComponents/multiCurrencyField";
import MultiCurrency from "../multiCurrency";
import DefaultListActions, {Empty} from "./common";
import {useForm} from "react-final-form";
import queryString from "query-string";
import CancelLoansButton from "../loan/cancelLoanButton";
import Typography from "@material-ui/core/Typography";

const {parse} = queryString
const LoanBulkActionButtons = props => {

    // const canDisburse = props.data.some(d=>d.)
  //  console.log(props)

    return  <Fragment>
        {props.filterValues.loanStatus==="Pending"&&<DisburseLoansButton label="Disburse Loan" {...props} />}
        {/* default bulk delete action */}
        {props.filterValues.loanStatus==="Standing"&&<CancelLoansButton {...props} />}
        {(props.filterValues.loanStatus==="Pending"||props.filterValues.loanStatus==="Paid")&&<BulkDeleteButton {...props} />}
    </Fragment>

}


const LoanEditToolbar = props => (
    <Toolbar {...props} >

        <SaveButton />
        {props.record.loanStatus==='Pending'&&<DeleteButton redirect={props.redirect}/>}

    </Toolbar>
);

const LoanFilter = (props) => (
    <Filter {...props}>
        <SelectInput  source="type"  choices={[
            { id: 'Advance', name: 'Advance' },
            { id: 'Loan', name: 'Loan' },
        ]}  />

        <SelectInput alwaysOn source="loanStatus"   choices={[
            { id: 'Pending', name: 'Pending' },
            { id: 'Standing', name: 'Standing' },
            { id: 'Paid', name: 'Paid' },
        ]}  />

        <ReferenceInput label="Employee Name"  emptyText="All Employees" allowEmpty alwaysOn source="employeeId" reference="employees" perPage={1000} filter={{filter: "active eq true"}}>
            <AutocompleteInput  options={{ type: 'search' }} allowEmpty optionText={(record)=> record.fullname?record.fullname:"All Employees"} />
        </ReferenceInput>

        <ReferenceInput allowEmpty
                        allowNull
                        format={(v) => (!v ? null : v)} // final-form's default format makes null to "" :-(
                        label="Loan Name" source="name" reference="loanTemplates" perPage={1000}>
            <AutocompleteInput optionText="name" optionValue="name"   />
        </ReferenceInput>
    </Filter>
)

const Aside = ({ data, ids }) => {

    return (
        <div style={{ width: 400, margin: '1em' }}>
            <Typography variant="h6">Loan Stat</Typography>
            <Typography variant="body2">
                Total Portfolio (Disbursed): <strong><MultiCurrency value={ids.map(id => data[id]).reduce((sum, post) => sum + post.disburesedAmount, 0)} /> </strong> <br/>
               {/*Requested Amount (Pending): {ids.map(id => data[id]).filter(l=>l.loanStatus==="Pending").reduce((sum, post) => sum + post.requestedAmount, 0)}*/}
                Repaid Amount: <strong><MultiCurrency value={ids.map(id => data[id]).reduce((sum, post) => sum + post.repayedAmount, 0)} /></strong>  <br/>

                Outstanding Amount: <strong><MultiCurrency  value= {ids.map(id => data[id]).reduce((sum, post) => sum + post.disburesedAmount - post.repayedAmount, 0)}/> </strong> <br/>

            </Typography>
        </div>
    );
};

export const LoanList= props =>
    {
        const componentRef = useRef();
        return <List aside={<Aside />}   filterDefaultValues={{loanStatus: "Pending"}} filters={<LoanFilter/>} perPage={1000} empty={<Empty/>}
                     exporter={exporterCommon(props.resource)}  actions={<DefaultListActions componentRef={componentRef}  />} {...props}
                     bulkActionButtons={<LoanBulkActionButtons/>}>
            <PrintContainer comRef={componentRef}/>
        </List>
    }
;

const PrintContainer = props =>
    <div ref={props.comRef}>
        <Datagrid {...props}  rowClick="show">
            <ReferenceField label="Work ID" source="employeeId" reference="employees">
                <TextField  source="orgID" />
            </ReferenceField>
            <ReferenceField source="employeeId" reference="employees">
                <TextField source="fullname" />
            </ReferenceField>
            <TextField source="type" />
            <TextField source="name" />
            <MultiCurrencyField source="requestedAmount" />
            <TextField source="loanStatus" />
            <MultiCurrencyField  label='Disbursed Amount' source="disburesedAmount" />
            <MultiCurrencyField  label='Installment Amount' source="installmentAmount" />
            <MultiCurrencyField label='Repaid Amount' source="repayedAmount" />
            <NumberField source="estimateMonths"/>
            <FunctionField label="Outstanding" render={record => <MultiCurrency value={record.disburesedAmount-record.repayedAmount}/>} />
        </Datagrid>
    </div>



export const LoanEdit = props => {
    const { employeeId } =parse(props.location.search);
    const redirect = employeeId ? `/employees/${employeeId}/show/loans` : "list";

    return <Edit {...props}>
        <SimpleForm redirect={redirect} toolbar={<LoanEditToolbar/>}>
            <FormDataConsumer>
                {formDataProps => (
                    <LoanForm employeeId={employeeId}  {...formDataProps}  />
                )}
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
};


const LoanForm = ({create, formData, employeeId, ...rest }) => {

    const standing = formData&&formData.loanStatus === "Standing"
    // console.log("formloan:",formData)
    const form = useForm();
    const { data: loanTemplates } = useQueryWithStore({
        type: 'getList',
        resource: 'loanTemplates',
        payload: {filter:{},pagination:{},sort:{}}
    });

    const setEstimateMonthsReq = (val,installmentAmount=formData.installmentAmount) => {
        form.change('estimateMonths',Math.ceil(+((val-(formData.repayedAmount||0))/installmentAmount).toFixed(5)))
    }
    const setEstimateMonthsIns = (val) => {
        form.change('estimateMonths',Math.ceil(+((formData.requestedAmount-(formData.repayedAmount||0))/val).toFixed(5)))
    }
    const setEstimateMonthsRepaid = (val) => {
        form.change('estimateMonths',Math.ceil(+((formData.requestedAmount-val)/formData.installmentAmount).toFixed(5)))
    }
    return <Fragment>


        {/*{!create  && <SelectInput  {...rest} source="loanStatus" choices={[*/}
        {/*    {id: 'Pending', name: 'Pending'},*/}
        {/*    {id: 'Standing', name: 'Standing'},*/}
        {/*]}/>*/}
        {/*}*/}

        {!standing&&<>
            <ReferenceInput {...rest} perPage={1000} disabled={employeeId}   label="Employee" source="employeeId" reference="employees" validate={[required()]} filter={{filter: "active eq true"}}>
                <AutocompleteInput optionText="fullname" />
            </ReferenceInput>

            <SelectInput label='Template' source="loanTemplateId"  {...rest} choices={loanTemplates}
                         onChange={ev => {
                             ///  console.log(ev)
                             const template = loanTemplates.find(c => c.id === ev.target.value)
                             form.change('type', template.type)
                             form.change('name', template.name)
                             form.change('installmentAmount', template.installmentAmount)
                             form.change('installmentType', template.installmentType)
                             form.change('installmentPercent', template.installmentPercent)
                             form.change('description', template.description)
                         }
                         } optionText="name"/>

            <SelectInput onChange={e => form.change('name', e.target.value)} {...rest} source="type"  choices={[
                {id: 'Advance', name: 'Advance'},
                {id: 'Loan', name: 'Loan'},
            ]}  />


            <TextInput {...rest} source='name' validate={[required()]}/> <br/>

            <NumberInput onChange={ev => {
                if (formData.type === 'Advance') {
                    form.change('installmentAmount', ev.target.value)
                    form.change('estimateMonths', 1)
                } else {
                    if(formData.installmentType==="Percentage") {
                        form.change('installmentAmount', formData.installmentPercent * ev.target.value / 100)
                        setEstimateMonthsReq(ev.target.value,formData.installmentPercent * ev.target.value / 100)

                    }
                    else setEstimateMonthsReq(ev.target.value);
                }
            }} {...rest} source="requestedAmount"/>

            {!create&&<NumberInput  onChange={ev => setEstimateMonthsRepaid(ev.target.value)} label="Repaid Amount" source="repayedAmount" />}  <br/>
            <SelectInput defaultValue="Absolute" {...rest} source="installmentType"  choices={[
                { id: 'Absolute', name: 'Absolute' },
                { id: 'Percentage', name: 'Percentage' },
            ]}  />
            {formData.installmentType==="Percentage"&&<NumberInput onChange={ev => {
                form.change('installmentAmount', formData.requestedAmount * ev.target.value / 100)
                setEstimateMonthsIns(formData.requestedAmount * ev.target.value / 100)
            }} {...rest} source="installmentPercent"/>}
            <NumberInput  onChange={ev => setEstimateMonthsIns(ev.target.value)} {...rest} source="installmentAmount" />
            <NumberInput {...rest} source="estimateMonths" />  <br/>
        </> }
        {standing&&<TextInput {...rest} source='name' validate={[required()]}/>}
        <TextInput {...rest} source='description'/>

    </Fragment>
}

export const LoanCreate = props => {
    const { employeeId } =parse(props.location.search);
    const redirect = employeeId ? `/employees/${employeeId}/show/loans` : "list";
    return <Create {...props}  >

        <SimpleForm  redirect={redirect} initialValues={{employeeId:+employeeId}}  >
            <FormDataConsumer>
                {formDataProps => {
                    // console.log(formDataProps)
                    //  formDataProps.formData.estimateMonths = 3
                    return <LoanForm employeeId={employeeId} create {...formDataProps}  />
                }}
            </FormDataConsumer>
        </SimpleForm>

    </Create>
};
export const LoanShow = props => (
    <Show  {...props}>
        <SimpleShowLayout>
            <ReferenceField source="employeeId" reference="employees">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="type" />
            <TextField source="name" />
            <NumberField source="requestedAmount" />
            <NumberField source="installmentAmount" />
            <NumberField source="estimateMonths" />
            <TextField source="loanStatus" />
            <DateField source="createdDate" />
            <DisburseLoansButton selectedIds={[props.id]} label="Disburse Loan" {...props} />
        </SimpleShowLayout>
    </Show>
);
