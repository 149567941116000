import OdataServiceProvider from "./odataServiceProvider";
import PlainAPIProvider from "./plainAPIProvider";

const dataProviders = [
    { dataProvider: OdataServiceProvider, resources: ['foos'] },
    { dataProvider: PlainAPIProvider, resources: ['users/me/', 'users', 'roles', 'users/([^/])+/companies','users/([^/])+/roles'] },
];


function pickProvider(resource) {
    const dataProviderMapping = dataProviders.find(dp => dp.resources.some(rx=> new RegExp(rx).test(resource)))||{dataProvider:OdataServiceProvider};

    return dataProviderMapping.dataProvider
}


export default {
    getList: (resource, params) => pickProvider(resource).getList(resource, params),
    getOne: (resource, params) => pickProvider(resource).getOne(resource, params),
    getMany: (resource, params) => pickProvider(resource).getMany(resource, params),
    getManyReference: (resource, params) => pickProvider(resource).getManyReference(resource, params),
    update: (resource, params) => pickProvider(resource).update(resource, params),

    updateMany: (resource, params) => pickProvider(resource).updateMany(resource, params),

    create: (resource, params) => pickProvider(resource).create(resource, params),

    delete: (resource, params) =>pickProvider(resource).delete(resource, params),

    deleteMany: (resource, params) =>pickProvider(resource).deleteMany(resource, params),
};

