import simpleRestProvider from 'ra-data-simple-rest';
import {fetchUtils} from 'react-admin';

const fetchJson = (url, options = {}) => {
    const api = localStorage.getItem('domain')
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    // add your own headers here
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    options.headers.set('X-COMPANY', 'ROOT');
    options.headers.set('Bypass-Tunnel-Reminder', "true");
    return fetchUtils.fetchJson(api+url, options);
}


const dataProvider = simpleRestProvider(`/api`, fetchJson);
export default {
    ...dataProvider,
   }
