import React from 'react';
import {connect} from "react-redux";
import Zemen from "./ZemenCalendar/zemen";
import moment from "moment";
import {multiDateFun} from "../Helpers/multiDateFun";
const MultiDate = ({calendar, gcDate, format}) => {

   // const date = new Date(gcDate.getFullYear(),gcDate.getMonth(),gcDate.getDate()+8)



    return (
        <div style={{display:'inline'}}>
            {multiDateFun(calendar,gcDate,format)}
        </div>

    );


};

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => {
    return {
        calendar: ownProps.calendar||propsFromState.calendar,
        gcDate: ownProps.gcDate,
        format: ownProps.format || 'MMMM DD, YYYY'
       };
};


export default connect(state=>({
    calendar : state.setting.calendar,
}),null,mergeProps)(MultiDate);

// export default MultiDateField;
