import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {fetchEnd, fetchStart, refreshView, showNotification} from 'react-admin';
import {getFrontEnd, login, postPayroll, publishPayroll, updateCompany, getPayroll} from "../Helpers/publishApi";
import OdataServiceProvider from "../Helpers/odataServiceProvider";
import {renderMJML, sendEmailNow, sendEmails} from "../Helpers/emailHelper";
import {payslipLinkEmailMJML} from "../UIComponents/emailTemplate/payslipLinkEmailMJML";
import moment from "moment";
import {getIsoDate} from "../Helpers/timeHelpers";
import _ from "lodash";
import {publishAndSendPayslips} from "../Helpers/paydayApi";

const sendEmail  = email => sendEmailNow(email).then(response => ({ response:response.data }))
    .catch(error => {
        // console.log('send failed', error)
        //put(showNotification('Emails sent successfully ', "info"))
        return {error}
    })



export default function* publishSaga() {
    yield takeLatest('PUBLISH_PAYROLLS', function* (action) {
      // console.log('staring to publish')
        const {payrolls,isDraft,payrollRun,welcomeTemplate, logo, slipSetting, dontEmail, forceSend} = action.payload
const status= isDraft?'Draft':'Final';

        // prepare payslips
        const payslips =  payrolls.filter(p=>p.employee&&p.employee.sendPayslipVia.includes('Publish'));
       // console.log({payslips})
        const toBePublished = payslips.filter(p=>forceSend || !(p.publishId&&p.sentSlipType===status))

       //  const publishedButNotEmailed = payslips.filter(p=>(p.data.publishId&&(!p.data.emailSent||forceSend)&&p.email && p.sendPayslipVia.includes('EmailLink')))

        try {
            yield put(fetchStart());
            yield put({type:'CLEAR_EMAILS'})
            // publish payslips one by one and get the results
            yield  put({type:'START_PUBLISHING_PAYROLL',payload:toBePublished})


                try {
                    let publishedIds =[]
                    if(toBePublished.length) {
                        const {token} = yield call(login, ({email: localStorage.getItem('userEmail'), password: 'P@ssw0rd1'}))
                        yield call(updateCompany, {id: payrolls[0].companyId, name:localStorage.getItem('companyName'), slipSetting})
                        const published = yield  all(toBePublished.map(p => call(publishAndSendPayslips, {token,payrollId:p.id, payrollRunId:p.payrollRunId,email:(p.employee && p.employee.address && p.employee.address.email) || p.emailAddress,status, dontEmail })))
                        //  publishedIds = published.filter(p => p.data).map(p => ({...p.data}))
                        // // console.log(publishedIds)
                        //
                        // //update publishIds
                        // yield  all(publishedIds.map(p => call(OdataServiceProvider.update, 'payrolls', {
                        //     id: p.payrollId,
                        //     data: {publishId: p.id, sentSlipType: status}
                        // })))

                        yield  put({
                            type: 'DONE_PUBLISHING_PAYROLL',
                            payload: {
                                errors: published.filter(m => m.error),
                                results: published.filter(m => m.data),
                                sendingEmail: !dontEmail
                            }
                        })

                        yield  put({
                            type: 'EMAILS_SEND_FINISH',
                            payload: {
                                errors: published.filter(m => m.error),
                                results: published.filter(m => m.data),
                                sendingEmail: !dontEmail
                            }
                        })
                    }

                    //send Email
                    //render message
                    // const publishedEmails = publishedIds.filter(p => p.email && p.sendPayslipVia.includes('EmailLink'))
                    // if(!dontEmail&&(publishedEmails.length||publishedButNotEmailed.length)) {
                    //     // const publishedEmails = publishedIds.filter(p => p.email && p.data.sendPayslipVia.includes('EmailLink')).concat(publishedButNotEmailed)
                    //    if(publishedButNotEmailed.length) {
                    //        yield call(login, ({email: localStorage.getItem('userEmail'), password: 'P@ssw0rd1'}))
                    //        const toBeEmailed = yield  all(publishedButNotEmailed.map(p=>call(getPayroll,p.data.publishId,p.data.id,p.email)))
                    //
                    //         // console.log({toBeEmailed,publishedButNotEmailed})
                    //
                    //
                    //        publishedEmails.push(...toBeEmailed)
                    //    }


                        // yield  put({type: 'START_SENDING_EMAIL', payload: publishedEmails})
                        //
                        //
                        //
                        // const messages =
                        //     publishedEmails.map(p => {
                        //         //console.log(p)
                        //
                        //         const link = `${getFrontEnd()}/payslips/${p.id}?code=${p.user.verificationCode}` //p.user && p.user.verified ? `${getFrontEnd()}/payslips/${p.id}`:`${getFrontEnd()}/verify/${p.user.verificationCode}?redirect=payslips/${p.id}`;
                        //         //const
                        //         // const link = `${getFrontEnd()}/${p.id}?${params}`;
                        //         //  console.log(link)
                        //         const templateData = {
                        //             name: p.data.name,
                        //             fullName: p.fullName,
                        //             netPay: p.netPay, ...p.data
                        //         }
                        //         //   console.log('PROCESSING_EMAIL',message.id)
                        //         // return all([
                        //         //     put(showNotification(`Sorry, email sending failed `, "error")),
                        //         //     call(renderMJML,message)
                        //         // ])
                        //         // put({type:'PROCESSING_EMAIL',payload:{id:message.id}})
                        //         const mjml = payslipLinkEmailMJML(p, {
                        //             link,
                        //             welcomeTemplate,
                        //             templateData,
                        //             isDraft,
                        //             logo,
                        //             companyName: localStorage.getItem('companyName'),
                        //             user: {
                        //                 firstName: localStorage.getItem('firstName'),
                        //                 email: localStorage.getItem('userEmail')
                        //             }
                        //         })
                        //
                        //
                        //         const message = {
                        //             to: [{
                        //                 name: p.fullName,
                        //                 email: ((p.employee && p.employee.address && p.employee.address.email) || p.email)
                        //             }],
                        //             bcc: localStorage.getItem('userEmail2') ? [{
                        //                 name: localStorage.getItem('firstName'),
                        //                 email: localStorage.getItem('userEmail2')
                        //             }] : [],
                        //             from: localStorage.getItem('userEmail'),
                        //             replyTo: localStorage.getItem('userEmail') ? [{
                        //                 name: localStorage.getItem('firstName'),
                        //                 email: localStorage.getItem('userEmail')
                        //             }] : [],
                        //             MJML: mjml,
                        //             subject: `Your ${isDraft ? 'Draft' : ''} Payslip for ${moment(payrollRun.payrollDate).format('MMMM, YYYY')}`,
                        //             messageType: 'Payslip',
                        //             ref: p.payrollId
                        //         }
                        //         return message
                        //       //  return call(renderMJML, message)
                        //     }
                        // )
                        // yield  put({
                        //     type: 'DONE_PROCESSING_PAYROLL',
                        //     payload: {errors: [], results: messages}
                        // })
                        // // const res = yield all(
                        // //     messages.filter(m => m.response).map(message => {
                        // //         return call(sendEmail, message.response)
                        // //     })
                        // // )
                        //
                        // const res = yield all(
                        //
                        //     _.chunk(messages, 10).map(messages => {
                        //         return call(sendEmails,messages)
                        //     })
                        // )

                        // const successResults = res.filter(m => m.response);
                        // // console.log({successResults})
                        // yield call(OdataServiceProvider.updateMany, 'payrolls', {
                        //     ids: successResults.map(r => r.response.ref),
                        //     data: {emailSent: true}
                        // })

                        //yield put(showNotification('Emails sent successfully ', "info"))
                        // yield put({
                        //     type: 'EMAILS_SEND_FINISH',
                        //     payload: {errors: [], results:_.flatMapDeep(res, x => x.response).filter(x=>x)}
                        // })
                    //}
                    yield put(fetchEnd());
                    yield put(refreshView())


                } catch (e) {
                    console.log({e})
                    if(e.message==="Failed to fetch") {
                        yield put(showNotification(`Couldn't Connect to Publish Server`, "error"))
                        yield put({type: 'ADD_SEND_ERROR', payload: `Couldn't Connect to Publish Server`})
                    }
                    else if(e.message==="Unauthorized") {
                        yield put(showNotification(`You don't have privilege to publish `, "error"))
                        yield put({type: 'ADD_SEND_ERROR', payload: `You don't have privilege to publish`})

                    }
                    else {
                        yield put(showNotification(`Sorry, Something went wrong `, "error"))
                        yield put({type: 'ADD_SEND_ERROR', payload: `Sorry, Something went wrong`})

                    }

                    yield put(fetchEnd());
                }

            // else {  // if nothing to be published
                yield put(fetchEnd());





        }
        catch (e) {
           console.log(e)
            yield put(showNotification(`Sorry, email sending failed `, "error"))
            yield put(fetchEnd());
        }


        // const results = yield call(publishPayroll,{payrolls:payslips})

    })
    }
