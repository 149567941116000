import React from 'react';
import {Loading, Title, usePermissions, useQueryWithStore, useRedirect} from 'react-admin';
import _ from "lodash";
import moment from "moment";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import {Link} from "react-router-dom";
import ApproveButton from "./approveButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MultiCurrency from "../multiCurrency";
import {useDispatch} from "react-redux";


const PayrollHistory = props => {


    const { loaded, error, data: payrollRuns } = useQueryWithStore({
        type: 'getList',
        resource: 'payrollRuns',
        payload: {filter:{expand:"currencyRate"},pagination:{},sort:{}}
    });

    const dispatch = useDispatch();
    const redirect = useRedirect();
    const permissions= usePermissions()
    if (!loaded) { return <Loading />; }
    if (!payrollRuns || !permissions.permissions) return null;


    const sorted = _.orderBy(payrollRuns,['runDate'],['desc']).map(p=>{
        p.payYearMonth =moment(p.payrollDate).format('MMMM, YYYY ')
        p.year =moment(p.payrollDate).format('YYYY ')
        p.month =moment(p.payrollDate).format('MMMM')
        return p
    })

    return (
        <Card style={{backgroundColor:'#F5F5F5'}}>
            <Title title="Payroll" />
            <Grid spacing={10} container>
                {sorted.map(payrollRun=><Grid item xs={3}>
                    <Card>
                        <CardHeader
                          title={`${payrollRun.month},${payrollRun.year} `}
                          subheader={`Status: ${payrollRun.status}`}
                        />
                        <CardContent>
                            <Typography variant="body2">
                                On {moment(payrollRun.runDate).format('MMMM, DD, YYYY')}
                            </Typography>
                            <Typography variant="body2" component="p">

                                <strong>Netpay</strong> <MultiCurrency  conversionRates={payrollRun.currencyRate} value={payrollRun.netPay}/>
                            </Typography>

                        </CardContent>
                        <CardActions>
                            <Button component={Link} to={`/reports/payroll/${payrollRun.id}/sheet`}  size="small">View Payroll Sheet</Button>
                            {
                                permissions.permissions.includes('Manager') &&   payrollRun.status !== 'Approved' &&    <ApproveButton record={payrollRun}/>
                            }

                            {/*<Button onClick={()=> {*/}
                                {/*dispatch({type: 'LOAD_PAYROLLRUN', payload: {id: payrollRun.id}})*/}
                                {/*// redirect('/runPayroll')*/}
                            {/*}*/}

                            {/*}  size="small">Edit</Button>*/}
                            <Button component={Link} to={`/runPayroll/${payrollRun.id}`}  size="small">Edit</Button>

                        </CardActions>
                    </Card>
                </Grid>)}
            </Grid>

        </Card>
    );
};

PayrollHistory.propTypes = {

};

export default PayrollHistory;
