import React from 'react';
import {
    Button,
    CardActions,
    Create,
    CreateButton,
    Datagrid,
    Edit,
    ExportButton,
    List,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField
} from 'react-admin';
import exporterCommon from "../import/exporterCommon";
import {connect, useStore} from "react-redux";
import {useForm} from "react-final-form";
import MultiCurrencyField from "../../UIComponents/multiCurrencyField";
import {EmptyLeave} from "./common";


export const GenerateLeaveButton = connect(null,dispatch=>({
    onGenerateLeave: ()=>dispatch({type:'GENERATE_ANNUAL_LEAVE'})
}))(props=> {

    return (
        <Button onClick={props.onGenerateLeave} label="Generate Annual Leave"/>
    );
});




const LeaveListActions = ({ basePath,exporter,total,resource,currentSort,filterValues }) => (
    <CardActions>

        <GenerateLeaveButton/>

        <CreateButton basePath={basePath} />
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />

    </CardActions>
);

export const LeaveList= props => (
    <List empty={<EmptyLeave/>}  actions={<LeaveListActions/>} exporter={exporterCommon(props.resource)} {...props}>
        <Datagrid rowClick="edit">
            <ReferenceField source="employeeId" reference="employees">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="typeId" reference="leaveTypes">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="carriedOverDays"/>
            <TextField source="year"/>
            <TextField source="entitledDays"/>
            <TextField source="daysTaken"/>

            <MultiCurrencyField source="compensationPay"/>
        </Datagrid>
    </List>
);



const LeaveCreateForm = props => {
    const form = useForm();
    const store = useStore();
    const onChange=ev=>form.change('compensationPay',
        ((form.getFieldState('carriedOverDays').value +  form.getFieldState('entitledDays').value
            -form.getFieldState('daysTaken').value) *employee(form.getFieldState('employeeId').value).basicSalary/26)
    )


    const employee= id=> store.getState("admin").admin.resources['employees'] ? store.getState("admin").admin.resources["employees"].data[id] : {basicSalary: 0}
    return (
        <>
           <div>
            <ReferenceInput source="employeeId" reference="employees">
                <SelectInput disabled={!props.create} source="name" />
            </ReferenceInput>
            </div>
             <div>
            <NumberInput onChange={onChange} {...props} source="carriedOverDays"/>
             </div>
            <div>
            <NumberInput {...props}source="year"/>
            </div>
            <div>
            <NumberInput onChange={onChange} {...props}source="entitledDays"/>
            </div>
            <div>
            <NumberInput onChange={onChange} {...props}source="daysTaken" />
            </div>
            <div>
            <NumberInput format={v=>v&&v.toFixed(2)} {...props}source="compensationPay"/>
            </div>
        </>
    );
};

export default LeaveCreateForm;


export const LeaveEdit = props => {
    const store = useStore();

    const employee= id=> store.getState("admin").admin.resources['employees'] ? store.getState("admin").admin.resources["employees"].data[id] : {basicSalary: 0}

    return <Edit {...props}>
        <SimpleForm >

           <LeaveCreateForm/>
        </SimpleForm>
    </Edit>
};

export const LeaveCreate = props => (
    <Create {...props}>
        <SimpleForm redirect='list' >
           <LeaveCreateForm create/>
        </SimpleForm>
    </Create>
);
