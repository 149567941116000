import React from 'react';
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import {FormattedNumber} from "react-intl";
import MultiCurrency from "../components/multiCurrency";
import TableHead from "@material-ui/core/TableHead";


const ListValues = props => {
   // console.log(props)
    return (

        <Table size="small">
            {props.type === 'loan' && <TableHead>

                    <TableCell>
                      Type

                    </TableCell>
                <TableCell>
                    Settlement

                </TableCell>



                <TableCell>
                        Outstanding

                    </TableCell>


                    <TableCell>
                        Estimated Remaining Months
                    </TableCell>

            </TableHead>
            }
            <TableBody>
            {props.items.filter(i=>i.value>0).map(item=><TableRow>
                <TableCell>
                    {item.name} {item.originalValue && Math.abs(item.originalValue-item.value)>1?`(of ${item.originalValue.toFixed(2)})`:''}
                </TableCell>
                <TableCell style={{textAlign:"end"}}>
                    <MultiCurrency
                        value= {item.value}

                    />
                    {/*{item.remaining >0 && <div>*/}
                        {/*OutStanding <MultiCurrency*/}
                        {/*value= {item.remaining}*/}

                    {/*/>*/}
                    {/*</div>}*/}
                </TableCell>
                    {props.type==='loan' &&
                        <>
                <TableCell style={{textAlign:"end"}}>
                    <MultiCurrency
                        value= {item.remaining||0}

                    />
                </TableCell>

                <TableCell style={{textAlign:"center"}}>
                    <FormattedNumber value={item.estimatedMonth||0}/>

                </TableCell>
                        </>}
            </TableRow> )

            }
            </TableBody>
            {!props.hideTotal&&<TableFooter>
                <TableRow>
                    <TableCell>
                        Total {props.title}
                    </TableCell>
                    <TableCell style={{textAlign: "end"}}>
                        <MultiCurrency
                            value={props.total}

                        />
                    </TableCell>
                </TableRow>

            </TableFooter>}
        </Table>

    );
};

ListValues.propTypes = {

};

export default ListValues;
