import React from 'react';
import {Create, Datagrid, Edit, List, SimpleForm, TextField, TextInput} from 'react-admin';
import exporterCommon from "../import/exporterCommon";
import DefaultListActions, {Empty} from "./common";


export const DepartmentList= props => (
    <List  empty={<Empty />}  exporter={exporterCommon(props.resource)} actions={<DefaultListActions/>} {...props}>
        <Datagrid rowClick="edit">
            {/*<TextField source="id" />*/}
            <TextField source="name" />
        </Datagrid>
    </List>
);

export const DepartmentEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name" />
        </SimpleForm>
    </Edit>
);

export const DepartmentCreate = props => (
    <Create {...props}  >
        <SimpleForm redirect='list' >
            <TextInput source="name" />
        </SimpleForm>
    </Create>
);
