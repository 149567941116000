import React from 'react';
import {DatePicker} from "@material-ui/pickers";


const DateRangeSimple = ({range, onChange}) => {
    return (
        <div>
            <DatePicker value={range.start} onChange={e=>onChange(e,'start')} />
            <DatePicker value={range.end} onChange={e=>onChange(e,'end')} />
        </div>
    );
};

export default DateRangeSimple;
