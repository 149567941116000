import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import {useMutation, useNotify, useRefresh} from "react-admin";

const UnlockPayrollButton = ({record, permissions}) => {
    const [mutate, { loading }] = useMutation();
    const notify = useNotify();
    const refresh = useRefresh();
    const onLockToggle= ()=> {
        if(record.locked&&(!(permissions.includes('Manager')&& permissions.includes('Admin')  ) && record.createdBy.toLowerCase()!==localStorage.getItem('user').toLowerCase())) {
            return notify(`You dont have privilege to unlock this payroll`);
        }
        mutate({
                type: 'update',
                resource: `payrollRuns`,
                payload: {id: record.id, data: {locked: !record.locked}}
            },
            {
                onSuccess: ({data, permissions}) => {
                    notify(`Payroll ${!record.locked ? "Locked" : "Unlocked"}`);
                    //setOpen(false)
                    refresh()
                },
                onFailure: (error) => notify(`Error Locking/Unlocking Payroll`, 'warning'),
            });
    }
    return (
        <IconButton disabled={loading} onClick={onLockToggle} color="primary" >
            {record.locked?<LockIcon/>:<LockOpenIcon/>}
        </IconButton>
    );
};

export default UnlockPayrollButton;
