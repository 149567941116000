import React from 'react';
import {
    Create,
    Datagrid,
    Edit,
    FormDataConsumer,
    List,
    NumberInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    NumberField
} from 'react-admin';
import exporterCommon from "../import/exporterCommon";
import DefaultListActions, {Empty} from "./common";
import MultiCurrencyField from "../../UIComponents/multiCurrencyField";
import {useForm} from "react-final-form";


export const LoanTemplateList= props => (
    <List  empty={<Empty />}  exporter={exporterCommon(props.resource)} actions={<DefaultListActions/>} {...props}>
        <Datagrid rowClick="edit">
            {/*<TextField source="id" />*/}
            <TextField  source="type" />
            <TextField source="name" />

            <MultiCurrencyField source="installmentAmount" />
        </Datagrid>
    </List>
);

export const LoanTemplateEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <FormDataConsumer>
                {formDataProps => (
                    <LoanTemplateForm  {...formDataProps}  />
                )}
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
);

const LoanTemplateForm = ({formData, ...rest}) => {
    const form = useForm();
    return <>
        <SelectInput source="type"  choices={[
            { id: 'Advance', name: 'Advance' },
            { id: 'Loan', name: 'Loan' },
            { id: 'Deduction', name: 'Deduction' },
        ]}  />
        <TextInput {...rest} source="name" />
        <TextInput source="scope"/>

        <SelectInput defaultValue="Absolute" {...rest} source="installmentType"  choices={[
            { id: 'Absolute', name: 'Absolute' },
            { id: 'Percentage', name: 'Percentage' },
        ]}  />
        {formData.installmentType==="Percentage"&&<NumberInput {...rest} source="installmentPercent"/>}

        {formData.installmentType!=="Percentage"&&<NumberInput {...rest} source="installmentAmount" />}

        <TextInput {...rest} source="description" />
    </>
}


export const LoanTemplateCreate = props => (
    <Create {...props}  >
        <SimpleForm redirect='list' >
            <FormDataConsumer>
                {formDataProps => (
                    <LoanTemplateForm  {...formDataProps}  />
                )}
            </FormDataConsumer>
        </SimpleForm>
    </Create>
);
