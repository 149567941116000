const initialState = {
    approving: false,
    templateEarnings: [],
    templateLoans: [],
    templateDeductions: [],
    templateExpenses: [],
    emailProgress:0,
    emailsSent: [],
    telegramsSent:[],
    telegramsProgress:0,
    emailsQueued: [],
    telegramsQueued: [],
    emailsProcessed: [],
    emailsErrorProcessed: [],
    publishQueued: [],
    emailsPublished: [],
    emailsErrorPublished:[],
    emailsSentErrors: [],
    emailSendDone: false,
    errorMessage:''


};
export default (previousState = initialState, { type, payload }) => {
    if (type === 'PAYROLL_APPROVE') {
        return {...previousState,approving: true};
    }
    if (type === 'ADD_SEND_ERROR') {
        return {...previousState,errorMessage: previousState.errorMessage +','+ payload, emailProgress: 100, emailsErrorPublished: previousState.publishQueued};
    }
    if (type === 'START_PUBLISHING_PAYROLL') {
        // console.log('START_SENDING_EMAIL',payload)
        // const {payrollRunId} = payload
        return {...previousState,emailProgress:payload.length?10:100,publishQueued: payload};
    }


    if (type === 'START_SENDING_EMAIL') {
       // console.log('START_SENDING_EMAIL',payload)
        // const {payrollRunId} = payload
         return {...previousState,emailProgress:payload.length?10:100,emailsQueued: payload};
    }

    if (type === 'START_SENDING_TELEGRAM') {
        // console.log('START_SENDING_EMAIL',payload)
        // const {payrollRunId} = payload
        return {...previousState,telegramProgress:payload.length?10:100,telegramQueued: payload};
    }

    if (type === 'DONE_PROCESSING_PAYROLL') {
        // console.log('DONE_PROCESSING_PAYROLL',payload)
       // const {payrollRunId} = payload
        return {...previousState,emailProgress:55,emailsProcessed: payload.results.map(m=>m.response), emailsErrorProcessed: payload.errors.map(m=>m.error)};
    }
    if (type === 'DONE_PUBLISHING_PAYROLL') {
        // console.log('DONE_PROCESSING_PAYROLL',payload)
      const {sendingEmail} = payload
        return {...previousState,emailProgress:sendingEmail?25:100,emailsPublished: payload.results.map(m=>m.response), emailsErrorPublished: payload.errors.map(m=>m.error)};
    }

    if (type === 'EMAILS_SEND_FINISH') {
       // console.log(payload)
       // const {payrollRunId} = payload
        return {...previousState,emailProgress:100,emailsSent:payload.results.map(m=>m.response),emailsSentErrors:payload.errors.map(m=>m.error)};
    }
    if (type === 'TELEGRAM_SEND_FINISH') {
        // console.log(payload)
        // const {payrollRunId} = payload
        return {...previousState,emailProgress:100,telegramsSent:payload.results.map(m=>m.message_id),emailsSentErrors:payload.errors.map(m=>m.error)};
    }
    if (type === 'CLEAR_EMAILS') {
        // console.log(payload)
        // const {payrollRunId} = payload
        return {...previousState,
            emailProgress:0,
            emailsSent: [],
            telegramsSent:[],
            telegramsProgress:0,
            emailsQueued: [],
            telegramsQueued: [],
            emailsProcessed: [],
            emailsErrorProcessed: [],
            publishQueued: [],
            emailsPublished: [],
            emailsErrorPublished:[],
            emailsSentErrors: [],
            emailSendDone: false,
            errorMessage:''

        };

    }
    if (type === 'TEMPLATES_LOADED') {
      //  console.log(payload)
        const {templateEarnings,templateDeductions,templateExpenses,templateLoans} = payload
        return {...previousState,templateEarnings,templateDeductions,templateExpenses,templateLoans}
    }
    // if (type === 'TEMPLATE_LOANS_LOADED') {
    //     return {...previousState,templateLoans: payload}
    // }
    return previousState;
}
