import {fetchUtils} from 'react-admin';

const catchelApi = (url, options = {}) => {
    const api = 'https://api.catchel.truwrk.com'
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    // add your own headers here
    // const token = localStorage.getItem('token');
    // options.headers.set('Authorization', `Basic NDVmZWU3ZGItZmM3MS00MzJkLWI2MmItYzhjYWVlYTQ5NTRjOmEyZGM2M2UzLTQ0YzgtNDg3Mi1hNDU2LWM2M2E1MmNhMGZiZg==`);
    return fetchUtils.fetchJson(api+url, options);
}

export function sendCatchel(message) {
    // console.log(message.body)
    return  catchelApi("/message",{
        method: 'POST',
        body: JSON.stringify(message),
    }).then(res=>({...res.json[0], ref:message.ref})).catch(error=>({error:{e:error,message}}))
}
