import React from 'react';
import {
    Create,
    Datagrid,
    DateField, Edit,
    List,
    NumberField,
    NumberInput,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';
import exporterCommon from "../import/exporterCommon";
import {parse} from "query-string";
import DefaultListActions, {RedirectToolbar} from "./common";


export const CurrencyRateList= props => (
    <List sort={{field:"Date", order:'desc'}}   exporter={exporterCommon(props.resource)}  {...props} actions={<DefaultListActions/>}>
        <Datagrid >
            {/*<TextField source="id" />*/}
            <DateField source="date" />
            <NumberField source="usd" />
            <NumberField source="eur" />
            <NumberField source="gbp" />
            <TextField source="source" />

        </Datagrid>
    </List>
);

export const CurrencyRateEdit = props => {
    const { id} =parse(props.location.search);
    const redirect = id? `/companies/${id}/show/currencyRates` : "show";
    return <Edit {...props}>
        <SimpleForm redirect={redirect} toolbar={<RedirectToolbar/>}>
            <NumberInput source="usd"/>
            <NumberInput source="eur"/>
            <NumberInput source="gbp"/>
            <TextInput defaultValue={id ? 'Company' : ''} source="source"/>
        </SimpleForm>
    </Edit>
};

export const CurrencyRateCreate = props => {
    const { companyId} =parse(props.location.search);
    const redirect = companyId ? `/companies/${companyId}/show/currencyRates` : "show";
   return <Create {...props}  >
        <SimpleForm redirect={redirect}>
            <NumberInput source="usd" />
            <NumberInput source="eur" />
            <NumberInput source="gbp" />
            <TextInput defaultValue={companyId ?'Company':''} source="source" />
        </SimpleForm>
    </Create>
}

// export const CurrencyRateShow = props => (
//     <Create {...props}  >
//         <SimpleForm redirect='list' >
//             <TextField source="name" />
//         </SimpleForm>
//     </Create>
// );
