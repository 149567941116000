import React, {useEffect, useState} from 'react';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import {
    Grid as KGrid,
    GridColumn as Column,
    GridColumnMenuCheckboxFilter,
    GridToolbar
} from "@progress/kendo-react-grid";
import {useDispatch} from "react-redux";
import {filterBy, orderBy} from "@progress/kendo-data-query";
import {withStyles, Grid} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import {fetchEnd, fetchStart, useDataProvider, useNotify} from "react-admin"
import CloseIcon from '@material-ui/icons/Close';
import {Button as KButton} from "@progress/kendo-react-buttons"
import {getMonthlyAttendanceHours, getMonthlyTimeSheet} from "../../Helpers/timeAttendanceApi";
import {ImportButton} from "../import/importButton";
import {getMonthlyOTHours} from "../../Helpers/matesApi";
import {deductionTypes, earningTypes} from "../../Helpers/typeList";
import {CustomDialogTitle} from "../../UIComponents/CustomDialogTitle";
import moment from "moment";
import {DateRangePicker} from "@progress/kendo-react-dateinputs";
import DateRangeSimple from "../../UIComponents/DateRangeSimple";




const GetTimeFromPrevMonth = props => {
   const  {onTimeSheet} = props;
   const dataProvider = useDataProvider();
   const getTimeSheet = ()=> {
        dataProvider
            .getList('payrollRuns', {sort:{field:'runDate',order:'ASC'},filter:{filter:"Status eq 'Approved'",expand:"payrolls($expand= overtimes)"}})
            .then(response => {
                if(response.data.length)
                {
                   // console.log(response.data[0].payrolls); // { id: 123, title: "hello, world" }
                    onTimeSheet(response.data[0].payrolls.map(p=>({employeeId:p.employeeId, orgID:p.orgID, hoursWorked:p.hoursWorked, overtimes:p.overtimes}))
                    );

                }

            });
    }

    return <Button onClick={getTimeSheet}>Get From Last Month</Button>
}

const GetTimeFromTimeAttendanceButton= props  => {

    const  {onTimeSheet,fillRest, payrollDate, periodStart, periodEnd, maxHours, OvertimeTypes} = props;
    const dispatch = useDispatch();
    const notify = useNotify();
    const month = new Date(payrollDate).getMonth()+1
    const year = new Date(payrollDate).getFullYear()
    const getTimeSheet = ()=> {
        if(!localStorage.getItem('companyMateId')) {
            notify('Your company is not configured to integrate with time attendance system','info')
            return ;
        }


        dispatch(fetchStart())
        // getMonthlyAttendanceHours({month,year,fillRest,maxHours}).then(({data})=> {
        //     dispatch(fetchEnd())
        //     const ts = data.map(d=>({...d,hoursWorked:+(d.adustedFullPayHours||d.fullPayHours||d.payHours||d.actualHoursWorked||maxHours||0).toFixed(0)}))
        //     // notify('Sorry Something went wrong in fetching your company time attendance data','error')
        //     return onTimeSheet(ts, "mateId",'tik')
        // }).catch(e=>{
        //     dispatch(fetchEnd())
        //    // console.log(e)
        //     notify('Sorry Something went wrong in fetching your company time attendance data','error')
        // })

        getMonthlyTimeSheet({startDate:periodStart, endDate:periodEnd, month, year, currentTeam:props.site}).then(({data})=> {
            dispatch(fetchEnd())
            const ts = data.map(d=>{
               const overtimes = []

                d.ot&&Object.keys(d.ot).map(key=>{
                        const name = key==='restDay'?'sunday':key;
                        const type =  OvertimeTypes.find(o=>o.name.replace(/\s+/g, '').toLowerCase() === name.replace(/\s+/g, '').toLowerCase())
                        if(type && d.ot[key]>0 ) {
                            overtimes.push({typeId: type.id, rate: type.rate, hrs: +d.ot[key].toFixed(2)})
                        }
                })

                return { ...d,
                    overtimes,
                    absentDays: (d.absentDays||0) + (d.penaltyAbsent||0),
                    monthlyHours:d.fullExpectedHrs,
                    missedHours:d.missedHrs||0,
                   actualHoursWorked:d.workHrs,hoursWorked:+(d.adjustedFullPayHours||d.fullPayHours||d.payHrs||0).toFixed(1)

                }
                }


        )
            // notify('Sorry Something went wrong in fetching your company time attendance data','error')
            return onTimeSheet(ts, "mateId",'tik')
        }).catch(e=>{
            dispatch(fetchEnd())
            console.log(e)
            notify('Sorry Something went wrong in fetching your company time attendance data','error')
        })
    }

  return <Button onClick={getTimeSheet}>Get From Time Attendance</Button>
};

const GetTimeFromMatesButton= props  => {

    const  {onTimeSheet,payrollDate,   OvertimeTypes} = props;
    const dispatch = useDispatch();
    const notify = useNotify();
    const month = new Date(payrollDate).getMonth()+1
    const year = new Date(payrollDate).getFullYear()
    const getTimeSheet = ()=> {
        if(!localStorage.getItem('companyMateId')) {
            notify('Your company is not configured to integrate with Mates HCM','info')
            return ;
        }


        dispatch(fetchStart())
        getMonthlyOTHours({date:payrollDate}).then(({data})=> {
           // console.log(data)
            dispatch(fetchEnd())
            const ts = data.map(d=> {
                const OTs = []

                Object.keys(d).map(key=>{
                    if(key.startsWith("OT")&&key!=='OT') {
                        const name = key.split('.')[1]?key.split('.')[1]:""
                        const type =  OvertimeTypes.find(o=>o.name.replace(/\s+/g, '').toLowerCase() === name.replace(/\s+/g, '').toLowerCase())
                        if(type && d[key] ) {
                            OTs.push({typeId: type.id, rate: type.rate, hrs: d[key]})
                        }

                    }
                })
                return {mateId:d.mateId, overtimes:OTs,absentDays:d.absentDays, leaveDays:d.leaveDays, normalPayDays:d.daysWorked, transportPayDays:d.transportDays }
            })
            // notify('Sorry Something went wrong in fetching your company time attendance data','error')

            return onTimeSheet(ts, "mateId",'mates')
        }).catch(e=>{
            dispatch(fetchEnd())
            console.log(e)
            notify('Sorry Something went wrong in fetching your data','error')
        })
    }

    return <Button onClick={getTimeSheet}>Get From Mates</Button>
};


const ImportFromFile = ({onTimeSheet,OvertimeTypes, payrolls}) =>{
    const dataProvider = useDataProvider();
    const onImport = (res,values) => {
        const tss = values.map(ts=>{

            let e;
            if(ts.orgID)
            {
                e = payrolls.find(p=>p.orgID.replace(/\s+/g, '').toLowerCase() === ts.orgID.replace(/\s+/g, '').toLowerCase());


            } else {
                 e = payrolls.find(p=>p.fullName.replace(/\s+/g, '').toLowerCase() === ts.fullName.replace(/\s+/g, '').toLowerCase());

            }
            if(e)
            {
                const OTs = []
                Object.keys(ts).map(key=>{
                    if(key.startsWith("OT")&&key!=='OT') {
                        const name = key.split('.')[1]?key.split('.')[1]:""
                        const type =  OvertimeTypes.find(o=>o.name.replace(/\s+/g, '').toLowerCase() === name.replace(/\s+/g, '').toLowerCase())
                        if(type) {
                            OTs.push({typeId: type.id, rate: type.rate, hrs: ts[key]})
                        }

                    }
                })
                //
                return {employeeId:e.employeeId, orgID:e.orgID, absentDays:ts.absentDays, hoursWorked:+ts.hoursWorked||+ts.monthlyHours, monthlyHours:+ts.monthlyHours, actualHoursWorked:+ts.actualHoursWorked||+ts.hoursWorked||+ts.monthlyHours, leaveDays:ts.leaveDays,overtimes:OTs, ot:OTs}
            }
            else {
                console.log(ts)
            }
        })

        // console.log(tss)

        // console.log(response.data[0].payrolls); // { id: 123, title: "hello, world" }
        onTimeSheet( tss.filter(t=>!!t) );



        // console.log("importing OT")
        // dataProvider
        //     .getList('employees')
        //     .then(response => {
        //         if(response.data.length)
        //         {
        //           //  console.log(values)
        //            const tss = values.map(ts=>{
        //
        //                 const e = response.data.find(e=>e.fullname.replace(/\s+/g, '').toLowerCase() === ts.fullName.replace(/\s+/g, '').toLowerCase());
        //                 if(e)
        //                 {
        //                     const OTs = []
        //                     Object.keys(ts).map(key=>{
        //                         if(key.startsWith("OT")&&key!=='OT') {
        //                             const name = key.split('.')[1]?key.split('.')[1]:""
        //                             const type =  OvertimeTypes.find(o=>o.name.replace(/\s+/g, '').toLowerCase() === name.replace(/\s+/g, '').toLowerCase())
        //                             if(type) {
        //                                 OTs.push({typeId: type.id, rate: type.rate, hrs: ts[key]})
        //                             }
        //
        //                         }
        //                     })
        //                     //
        //                     return {employeeId:e.id, orgID:e.orgID, absentDays:ts.absentDays, hoursWorked:ts.regularHours, leaveDays:ts.leaveDays,overtimes:OTs}
        //                 }
        //             })
        //
        //            // console.log(tss)
        //
        //             // console.log(response.data[0].payrolls); // { id: 123, title: "hello, world" }
        //             onTimeSheet( tss );
        //
        //         }
        //
        //     });
        // return onTimeSheet(ts, "mateId")
    }

    return <ImportButton key={"otImport"} label='Get Timesheet from File' onImport={onImport}/>
}


const ImportShiftAllowance = ({payrolls}) => {
    // const dataProvider = useDataProvider();
    const dispatch = useDispatch()

    const lookUp = {}
    payrolls.forEach(p=>{
        lookUp[p.fullName.replace(/\s+/g, '').toLowerCase()] = {employeeId:p.employeeId,orgID:p.orgID}

    })

    // console.log({lookUp})


    const onImport = (res,values) => {
        // console.log({res, values})
        const earnings = []
        values.forEach(v=>{
            const emp  = lookUp[v.fullName.replace(/\s+/g, '').toLowerCase()]
          if(emp) {
              Object.keys(v).forEach(k=>{
                  const rate = k.split('.')[1]
                  const value = v[k]
                  if(k.toLowerCase().includes('shift')) {
                      earnings.push({...emp,category:'earning', type:'shiftAllowance', name:rate +'% Shift Allowance',  extraData:{shiftRate:+rate/100,shiftHour:+value} })
                  }
                  if(k.toLowerCase().includes('call')) {
                      earnings.push({...emp,category:'earning', type:'onCallAllowance', name:rate +'% On Call Allowance',  extraData:{shiftRate:+rate/100,shiftHour:+value} })
                  }
              })
          }

        })

        // console.log({earnings})

        dispatch({type:"PAYROLL_EARNINGS_DEDUCTIONS_UPDATED",payload: {items:earnings, idField:"employeeId", deductionTypes, earningTypes}})

        // const earnings = _.groupBy(values,'fullName')
        // console.log(earnings)

        // dataProvider
        //     .getList('employees')
        //     .then(response => {
        //         if(response.data.length)
        //         {
        //             // console.log({values})
        //             const tss = values.map(ts=>{
        //
        //                 const e = response.data.find(e=>e.fullname.replace(/\s+/g, '').toLowerCase() === ts.fullName.replace(/\s+/g, '').toLowerCase());
        //                 if(e&&+ts.value!==0)
        //                 {
        //                     const extraData = {}
        //                     Object.keys(ts).forEach(key=>{
        //                         if(key.startsWith("extra")&&key!=='extra') {
        //                             // ts.address.push(key);
        //                             const name = key.split('.')[1]?key.split('.')[1]:""
        //                             // console.log(name,"earnings")
        //
        //                             extraData[name]= (+ts[key])
        //
        //                         }
        //                     })
        //
        //                     return {employeeId:e.id, orgID:e.orgID,category:ts.category||ts.kind||'deduction',cash:ts.cash, type:ts.type, name:ts.name, value:ts.value, taxOption:ts.taxOption, extraData }
        //                 }
        //             }).filter(t=>t)
        //
        //             // console.log(tss)
        //
        //             // console.log(response.data[0].payrolls); // { id: 123, title: "hello, world" }
        //             // onPayrollDeductionEarnings( tss );
        //             dispatch({type:"PAYROLL_EARNINGS_DEDUCTIONS_UPDATED",payload: {items:tss, idField:"employeeId", deductionTypes, earningTypes}})
        //
        //         }
        //
        //     });

    }
    return <ImportButton key={"shiftImport"} label='Get Shift and Call Allowance from File' onImport={onImport}/>
}


const SetTimeSheetDialog = props => {

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const {payrollData,OvertimeTypes} = props;
    const [editID, setEditID] = useState( null );
    const dispatch = useDispatch();
    const maxHours = ( payrollData&&payrollData.length&&payrollData[0].monthlyHours)||196
  const totalDaysInPayPeriod = ( payrollData&&payrollData.length&&payrollData[0].totalDaysInPayPeriod)||30



    const onTimeSheet = (timeSheets,idField="employeeId", source) => {
      //  console.log("timeSheetArrived",timeSheets);
      //  const timeSheets = timeSheetsX.filter(t=>t); //remove undefined
        dispatch({type:"PAYROLL_TIMESHEET_UPDATED",payload: {timeSheets, idField, source}})
      // payrollData.map(p=>{
      //
      //    if(p) {
      //         const timeSheet = timeSheets.find(t => t && p[idField] && (t[idField] === p[idField]));
      //         if (timeSheet) {
      //             timeSheet.absentDays && dispatch({
      //                 type: 'PAYROLL_ITEM_CHANGED', payload: {
      //                     event: {
      //                         field: 'absentDays',
      //                         value: timeSheet.absentDays,
      //                         dataItem: p
      //                     }
      //                 }
      //             })
      //
      //
      //
      //
      //             timeSheet.overtimes && dispatch({
      //                 type: 'PAYROLL_ITEM_CHANGED', payload: {
      //                     event: {
      //                         field: 'overtimes',
      //                         value: timeSheet.overtimes,
      //                         dataItem: p
      //                     }
      //                 }
      //             })
      //             timeSheet.leaveDays && dispatch({
      //                 type: 'PAYROLL_ITEM_CHANGED', payload: {
      //                     event: {
      //                         field: 'leaveDays',
      //                         value: timeSheet.leaveDays,
      //                         dataItem: p
      //                     }
      //                 }
      //             })
      //             timeSheet.monthlyHours && dispatch({
      //                 type: 'PAYROLL_ITEM_CHANGED', payload: {
      //                     event: {
      //                         field: 'monthlyHours',
      //                         value: timeSheet.monthlyHours,
      //                         dataItem: p
      //                     }
      //                 }
      //             })
      //             timeSheet.normalPayDays && dispatch({
      //                 type: 'PAYROLL_ITEM_CHANGED', payload: {
      //                     event: {
      //                         field: 'normalPayDays',
      //                         value: timeSheet.normalPayDays,
      //                         dataItem: p
      //                     }
      //                 }
      //             })
      //             timeSheet.hoursWorked && dispatch({
      //                 type: 'PAYROLL_ITEM_CHANGED', payload: {
      //                     event: {
      //                         field: 'hoursWorked',
      //                         value: +(timeSheet.hoursWorked).toFixed(2),
      //                         dataItem: p
      //                     }
      //                 }
      //             })
      //             if(source === "tik") {
      //                 timeSheet.hoursWorked && dispatch({
      //                     type: 'PAYROLL_ITEM_CHANGED', payload: {
      //                         event: {
      //                             field: 'fromTimeAttendance',
      //                             value: +(timeSheet.hoursWorked).toFixed(2),
      //                             dataItem: p
      //                         }
      //                     }
      //                 })
      //             }
      //
      //             timeSheet.actualHoursWorked && dispatch({
      //                 type: 'PAYROLL_ITEM_CHANGED', payload: {
      //                     event: {
      //                         field: 'actualHoursWorked',
      //                         value: timeSheet.actualHoursWorked,
      //                         dataItem: p
      //                     }
      //                 }
      //             })
      //
      //             if(timeSheet.transportPayDays) {
      //                 //console.log(p)
      //                 const newEarnings = p.payrollFixedEarnings.slice(0); // Create a shallow copy of the roles
      //                 //const name = newRoles[index].name
      //
      //                 const index = newEarnings.findIndex(e=>e.name.toLowerCase().replace(/\s+/g, '')==="transportallowance")
      //                   //console.log(index)
      //                 if(index>=0) {
      //                     const val = (newEarnings[index].originalValue / totalDaysInPayPeriod) * timeSheet.transportPayDays;
      //
      //                     newEarnings[index] = {
      //                         ...newEarnings[index],
      //                         entitledDays: +timeSheet.transportPayDays,
      //                         fullValue: val,
      //                         value: val
      //                     }; // Set the new value
      //                     //console.log(newEarnings)
      //                     dispatch({
      //                         type: 'PAYROLL_ITEM_CHANGED', payload: {
      //                             event: {
      //                                 field: 'payrollFixedEarnings',
      //                                 value: newEarnings,
      //                                 dataItem: p
      //                             }
      //                         }
      //                     })
      //                 }
      //
      //
      //             }
      //
      //
      //         }
      //     }
      //
      // })
    }

    const onItemChange = (event) => {
        //console.log(event)
        const ev = event
        // if(event.field==='hrs'){
        //     ev.field = 'hoursWorked'
        //     ev.value =  event.value
        // }
        if(event.field.includes('ots.'))
        {
            const name = event.field.split('.')[1]
            const type = OvertimeTypes&&OvertimeTypes.find(o=>o.name.replace(/\s+/g, '').toLowerCase() === name)

            if(type) {
                const ot = ev.dataItem.overtimes ? ev.dataItem.overtimes.splice(0) : []
                const index = ot.findIndex(o => o.typeId === type.id)
                // console.log(index)
                if (index >= 0) {
                    ot[index] = {typeId: type.id, rate: type.rate, hrs: event.value}
                } else {
                    ot.push({typeId: type.id, rate: type.rate, hrs: event.value})
                }

                ev.field = 'overtimes'
                ev.value = ot
            }
        else {
                ev.field = 'overtimes'
                ev.value =  ev.dataItem.overtimes.splice(0)
            }
        }

        ev.value = +ev.value

       return dispatch({type:'PAYROLL_ITEM_CHANGED',payload:{event:ev}})
    }
    // const [oTType, setOTType] = React.useState(Overtimes[0]);
    // const [hrs, setHrs] = React.useState(0);
    //  console.log(overtimes)
    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };





    const [sort, setSort] = useState( [
        { field: 'orgID', dir: 'asc' }
    ]);

    const [filter, setFilter] = useState( undefined);
    const [periodRange, setPeriodRange] = React.useState({
        start: moment(props.periodStart).toDate(),
        end: moment(props.periodEnd).toDate()
    });
    useEffect(() => {
        setPeriodRange(
            {
                start: moment(props.periodStart).toDate(),
                end: moment(props.periodEnd).toDate()
            }
        )

    }, [props.periodStart,props.periodEnd])


    function onChangeTimeRange(e, type) {
        if(type === 'start') {
            setPeriodRange(
                {
                    start: e,
                    end: moment(e).add(moment(props.payrollDate).daysInMonth(),'days').toDate()
                }
            )
        }
        if(type === 'end') {
            setPeriodRange(
                {
                    start: periodRange.start,
                    end: e
                }
            )
        }
    }


    return (
        <>
            <KButton icon="clock" className="k-info-colored"  onClick={handleClickOpen}>
                TimeSheet
            </KButton>
            <Dialog maxWidth='lg'   fullWidth open={dialogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
                <CustomDialogTitle onClose={handleClose} id="form-dialog-title">Monthly Time Sheet    <DateRangeSimple range={periodRange} onChange={onChangeTimeRange} /> </CustomDialogTitle>
                <DialogContent dividers>

                    <div>
                        <KGrid style={{ maxHeight: '70vh' }} editField="inEdit"
                               onItemChange={onItemChange}
                               onRowClick={event => setEditID(event.dataItem.id)}



                               data={filterBy(orderBy(payrollData.map((item) => {
                                 const ots = {}
                                   OvertimeTypes&&OvertimeTypes.map(ot=>{
                                       ots[ot.name.replace(/\s+/g, '').toLowerCase()]=item.overtimes && item.overtimes.find(o => o.typeId === ot.id) && item.overtimes.find(o => o.typeId === ot.id).hrs}
                                   )
                                   //console.log(item.overtimes)
                                   //console.log(ots)

                                   // const ot = {
                                   //     afterWork: item.overtimes && item.overtimes.find(o => o.typeId === 1) && item.overtimes.find(o => o.typeId === 1).hrs
                                   // }

                                   //const hrs = (item.monthlyHours/item.totalWorkDays)*item.daysWorked


                                   return {...item, ots, inEdit: item.id === editID}

                               }),sort),filter)}


                               sortable
                               filter={filter}
                               sort={sort}
                               onSortChange={(e) => {
                                  //   console.log(e.sort)
                                   setSort(e.sort)

                               }}
                               onFilterChange={(e) => {
                           //   console.log(e)
                            setFilter(e.filter)
                        }}


                        >
                            <GridToolbar>
                                <div onClick={event => setEditID(null) }>
                                    <GetTimeFromTimeAttendanceButton site={props.site} maxHours={maxHours} periodEnd={periodRange.end} periodStart={periodRange.start} OvertimeTypes={OvertimeTypes} fillRest={true} payrollDate={props.payrollDate}  onTimeSheet={onTimeSheet} />

                                    <GetTimeFromPrevMonth onTimeSheet={onTimeSheet}/>
                                    <Grid container>
                                          <Grid item>
                                              <ImportFromFile OvertimeTypes={OvertimeTypes} onTimeSheet={onTimeSheet} payrolls={props.payrolls} />

                                          </Grid>
                                        <Grid item>
                                            <ImportShiftAllowance payrolls={props.payrolls}/>
                                        </Grid>
                                    </Grid>
                                    <GetTimeFromMatesButton payrollDate={props.payrollDate} OvertimeTypes={OvertimeTypes} onTimeSheet={onTimeSheet}  />

                                </div>
                            </GridToolbar>
                            {/*<Column field="orgID" width={120} title="Work ID" editable={false}/>*/}
                            <Column field="fullName" title="Name" editable={false} columnMenu={props=><GridColumnMenuCheckboxFilter   data={payrollData} expanded={true} {...props}/>} />
                             <Column field="absentDays" format="{0:n2}"  title="Absent Days" editable={true}/>
                            <Column field="leaveDays" format="{0:n2}"  title="Leave Days" editable={true}/>
                            <Column field="monthlyHours" format="{0:n2}"  title="Expected Hours" editable={true}/>
                            <Column field="hoursWorked" format="{0:n2}"  title="Payable Hours Worked" editable={true}/>
                            <Column field="actualHoursWorked" format="{0:n2}"  title="Actual Hours Worked" editable={true}/>
                            <Column field="ots.afterwork"  title="After Work" editable={true}/>
                            <Column field="ots.night"  title="Night" editable={true}/>
                            <Column field="ots.sunday"  title="Sunday" editable={true}/>
                            <Column field="ots.hollyday"  title="Holly Day" editable={true}/>
                            <Column field="ots.other"  title="Other" editable={true}/>
                             </KGrid>
                    </div>


                </DialogContent>
            </Dialog>

        </>
    );
};

export default SetTimeSheetDialog;
