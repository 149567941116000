import React, {useEffect, useRef, useState} from 'react';
import {DateInput} from 'react-admin-date-inputs2';
import {Select, TextField} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import {Typography} from "./Wrappers";
// const DateInputWrapper = props => {
//     props.input.onChange= ev=>console.log(ev)
//     return <DateInput input={props.input}   {...props} source={props.source}
//                options={{format: 'DD/MM/YYYY', inputVariant: 'filled'}}/>
// }
export  const EndDateInput = ({ source,onDateChange, startDate,employmentType, ...rest  }) => {
    const [period, setPeriod] = useState(employmentType==='Contract'?"months":"years");
    const [span, setSpan] = useState();
    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            setPeriod(employmentType === 'Contract' ? "months" : "years")
            if(span) {
                const date = moment(startDate).add(span, employmentType === 'Contract' ? "months" : "years")
                onDateChange(date.toDate())
            }

        }

    }, [employmentType])

    // console.log(rest)
    // if (!record) {
    //     return null;
    // }
    // const value = get(record, source);
    // if (value == null) {
    //     return null;
    // }

    return <div>
        <Typography>
            Ends In
        </Typography>
        <TextField variant='filled' value={span}
                    onChange={ev=>{
                       setSpan(ev.target.value);
                       const date = moment(startDate).add(ev.target.value,period)
                       onDateChange(date.toDate())

                   }
                   }/>
        <Select
            variant='filled'

            value={period}
            onChange={ev=>{
                setPeriod(ev.target.value)
                const date = moment(startDate).add(span,ev.target.value)
                onDateChange(date.toDate())
                // handelSort(sort.field,payrollRun.payrolls.filter(p=>ev.target.value===0||p.department===ev.target.value))
                // payrollRun.filteredPayrolls=filteredPayrolls
                //   props.onChange({type:ev.target.value,hrs})
            }}
        >
            <MenuItem key={0} value={'days'}>Days</MenuItem>
            <MenuItem key={1} value={'months'}>Months</MenuItem>
            <MenuItem key={2} value={'years'}>Years</MenuItem>
           </Select>
        {/*<Typography  variant="h6" gutterBottom>from</Typography>*/}
        <DateInput clearable onChange={ev=>{
            const month = moment(ev).diff(moment(startDate),"months")
            setSpan(month);
        }}   {...rest} source={source}
                   options={{format: 'DD/MM/YYYY', inputVariant: 'filled'}}/>
            </div>

}
