import React, {useEffect, useState} from 'react';
import {Button as KButton} from "@progress/kendo-react-buttons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import BonusItem from "./bonusItem";
import Button from "@material-ui/core/Button";
import DistEarningItem from "./distEarningItem";
import DialogActions from "@material-ui/core/DialogActions";
import {useDispatch} from "react-redux";
import Grid from "@material-ui/core/Grid";
import {ImportButton} from "../import/importButton";
import {useNotify, useQueryWithStore} from "react-admin";
import BonusMonths from "./bonusMonths";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MultiSelectAll from "../../UIComponents/multiSelectAll";
import _ from "lodash";
import moment from "moment";
import MultiDate from "../../UIComponents/multiDate";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {CustomDialogTitle} from "../../UIComponents/CustomDialogTitle";

const GroupBonus = props => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [items, setItems] = React.useState(props.groupBonuses);
    const[payrollRuns, setPayrollRuns] = useState([]);
    const[bonuses, setBonuses] = useState([]);
    const [scale,setScale] = useState(5);
    const dispatch = useDispatch();
    const notify = useNotify();
    const scales = [{
        id:1,
        name:"0-1 (in%)"
    },
        {
            id:5,
            name:"1-5"
        },

        {
            id:10,
            name:"1-10"
        },
        {
            id:100,
            name:"1-100"
        }
    ]
    // const [extraData, setExtraData] = React.useState(props.value.extraData||{});
    useEffect(() => {
        if(props.groupBonuses&&props.groupBonuses.length) {
            setItems(props.groupBonuses || [])
        }
    },[props.groupBonuses])


    const {data: approvedRuns } = useQueryWithStore({
        type: 'getList',
        resource: 'approvedPayrollRuns',
        payload: {filter:{expand:'payrollRun'}}
    });
    if(!approvedRuns) {
        return null
    }


    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };
    const handleOK = () =>{
        // console.log({payrollRuns, bonuses})
        if(!bonuses||bonuses.length===0){
            notify(`No Bonus Data loaded, Please load a file again`, 'warning')

        }
        else if(!payrollRuns||payrollRuns.length===0) {
            notify(`Please Select Backpay Months`, 'warning')
        }
       else {
            dispatch({
                type: "APPLY_GROUP_BONUSES",
                payload: {
                    payrollRuns,
                    bonuses,
                    scale,
                    lastDate: _.orderBy(approvedRuns.filter(p => payrollRuns.includes(p.payrollRun.id)), ['payrollRun.payrollDate'], ['desc'])[0].payrollRun.payrollDate
                }
            })
            handleClose()
        }
    }






    const onImport = (res,values) => {
       // console.log({res, values})
        setBonuses(values)
    }


    return (
        <>
            <KButton icon="filter-add-group" className="k-info-colored"  onClick={handleClickOpen}>
                Add Group Bonuses
            </KButton>
            <Dialog maxWidth='md'   fullWidth open={dialogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
                <CustomDialogTitle onClose={handleClose} id="form-dialog-title">Group Bonuses
                    {/*<Button color='primary' onClick={saveDefaults}>Save</Button>*/}
                </CustomDialogTitle>
                <DialogContent dividers>
                    <Grid container direction='row'>
                        <Grid item container direction='column'>
                            <ImportButton key={"bonuesImport"} label='Import Bonuses' onImport={onImport}/>
                            {bonuses&&bonuses.length>0&&<div>{bonuses.length} Items Loaded</div>}
                        </Grid>

                        <Grid item container direction='column'>
                            <FormControl margin='dense' fullWidth  >
                                <InputLabel>Back Pay Months</InputLabel>
                                {/*<Select*/}
                                {/*    value={extraData.backpayPayroll}*/}
                                {/*    onChange={ev=> getMonthData(ev.target.value)}*/}
                                {/*>*/}
                                {/*    {approvedRuns.map(r=><MenuItem key={r.id} value={r.payrollRun.id}><MultiDate gcDate={r.payrollRun.payrollDate} format={'MMMM YYYY'}/></MenuItem>)}*/}
                                {/*</Select>*/}
                                <MultiSelectAll
                                    value={payrollRuns}
                                    onChange={setPayrollRuns}
                                    options={_.orderBy(approvedRuns,['payrollRun.payrollDate'],['desc']).map(r=>({key:r.id, value:r.payrollRun.id, textLabel:moment(r.payrollRun.payrollDate).format('MMM'), label:<MultiDate gcDate={r.payrollRun.payrollDate} format={'MMMM YYYY'}/>}))} />

                            </FormControl>
                            <FormControl margin='dense' fullWidth  >
                                <InputLabel>Scale</InputLabel>
                                <Select
                                    value={scale}
                                    onChange={ev=>setScale(ev.target.value)}>
                                    {scales.map(to=><MenuItem key={to.id} value={to.id}>{to.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>

                    </Grid>


                </DialogContent>
                <DialogActions>
                    {/*<Button onClick={handleClose} color="primary">*/}
                    {/*    Close*/}
                    {/*</Button>*/}
                    <Button onClick={handleOK} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default GroupBonus;
