import {downloadCSV} from 'react-admin'
import jsonExport from 'jsonexport/dist';
import _ from "lodash";

const exporterCommon = resource => posts => {
    const data = posts.map(post => {
        // add a field from an embedded resource
          // (({id, createdDate, modifiedDate,version,'@odata.context' ...others}) => ({...others}))(params.data)
        // post.author_name = post.author.name;
        return _.omit(post, ["companyId", "isDeleted","createdDate", "createdBy","fullname", 'modifiedBy', "modifiedDate", "version", "@odata.context", "@odata.etag"]);
    });
    jsonExport(data, {
        // headers: ['id', 'title', 'author_name', 'body'] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, resource); // download as 'posts.csv` file
    });
};
export default exporterCommon
