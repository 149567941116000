import React, {useEffect} from 'react';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import SyncMates from "../sync/syncMates";
import {Button} from "@progress/kendo-react-buttons"
const SyncMatesDialog = ({firstRun}) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    useEffect(() => {
        setDialogOpen(firstRun)
    }, [firstRun])


    return (
        <>
            <Button className="k-info-colored" onClick={()=>setDialogOpen(true)}>Sync Mates</Button>
            <Dialog disableBackdropClick maxWidth='md'  fullWidth open={dialogOpen} onClose={()=> setDialogOpen(false)}>
                <DialogContent dividers>
                      <SyncMates handleClose={val=>setDialogOpen(!val)}/>
                </DialogContent>

            </Dialog>
        </>
    );
};

export default  SyncMatesDialog;
