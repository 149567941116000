import React, {useEffect, useState} from 'react';
import {Button} from "@progress/kendo-react-buttons";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import SyncTik from "../sync/syncTik";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import MultiSelectAll from "../../UIComponents/multiSelectAll";
import _ from "lodash";
import moment from "moment";
import MultiDate from "../../UIComponents/multiDate";
import FormControl from "@material-ui/core/FormControl";
import Divider from "@material-ui/core/Divider";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close"
import {useDispatch} from "react-redux";
import {CustomDialogTitle} from "../../UIComponents/CustomDialogTitle";
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const SyncTikDialog = ({ teams, title,autoOpen,newHires, terminations,notFoundTerminations, onAccept, onIgnore, onDeactivate, onClose, ...props}) => {

const [selectedSites, setSelectedSites] = useState(teams.map(t=>t.code.toLowerCase().replace(/\s+/g, '')));


    return (
            <Dialog disableBackdropClick maxWidth='md'  fullWidth {...props} onClose={onClose}>
                <CustomDialogTitle onClose={onClose} id="form-dialog-title">{title}</CustomDialogTitle>


                <FormControl margin='dense' fullWidth  >
                    <InputLabel>Sites</InputLabel>

                    <MultiSelectAll
                        value={selectedSites}
                        onChange={setSelectedSites}
                        options={teams.map(r=>({key:r.code, value:r.code.toLowerCase().replace(/\s+/g, ''), textLabel:r.name}))} />

                </FormControl>
                <Divider/>
                <DialogContent dividers>
                    <SyncTik  newHires={newHires.filter(n=>(selectedSites.includes(n.currentTeam.toLowerCase().replace(/\s+/g, ''))))} terminations={terminations.filter(n=>(selectedSites.includes(n.currentTeam.toLowerCase().replace(/\s+/g, ''))))} notFoundTerminations={notFoundTerminations} onAccept={onAccept} onDeactivate={onDeactivate} onIgnore={onIgnore}/>
                </DialogContent>
            </Dialog>
    );
};

export default SyncTikDialog;
