import React from 'react';
import Card from "@material-ui/core/Card";
import {Link} from "react-router-dom";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import makeStyles from "@material-ui/core/styles/makeStyles";
import stringToColor from "../../Helpers/colorString";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import CheckIcon from "@material-ui/icons/Check"
import BusyIcon from "@material-ui/icons/EventBusy"
import CancelIcon from "@material-ui/icons/Cancel"
import MultiCurrency from "../multiCurrency";
import CaptionedField from "../../UIComponents/captionedField";

const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto !important',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },

}));

const PayrollRunSummary = props => {
    const classes = useStyles();
    const {payrollRun} = props
    const [expanded, setExpanded] = React.useState(false);
    if(!payrollRun)
        return null;

    payrollRun.year =moment(payrollRun.payrollDate).format('YYYY ')
    payrollRun.month =moment(payrollRun.payrollDate).format('MMMM')



    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    return (

            <Card >
                <CardHeader
                    avatar={
                        <Avatar style={{backgroundColor:stringToColor(payrollRun.month)}} aria-label="recipe" >
                            {payrollRun.month.charAt(0)}
                        </Avatar>
                    }
                    action={
                        <IconButton aria-label="settings">
                            <MoreVertIcon />
                        </IconButton>
                    }
                    title={`${payrollRun.month} Payroll`}
                    subheader={moment(payrollRun.runDate).format('DD, MMMM YYYY')}
                />
                <CardContent>
                    <Typography  color="textSecondary" gutterBottom>

                    </Typography>
                    <Typography variant="h5" component="h2">

                    </Typography>
                    <Typography color="textSecondary">

                    </Typography>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="body2" component="p">
                                <CaptionedField value={payrollRun.cash} label='Net Pay' />
                                {/*<strong>Netpay</strong> {payrollRun.netPay}*/}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            {payrollRun.status ==='Approved'?<CheckIcon/>:
                                payrollRun.status ==='Waiting'?<BusyIcon/>:<CancelIcon/>}
                        </Grid>
                    </Grid>




                </CardContent>
                <CardActions>

                    <Button component={Link} to={`/reports/payroll/${payrollRun.id}/sheet`}  size="small">View Payroll Sheet</Button>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        {props.children}
                    </CardContent>
                </Collapse>
            </Card>

    );
};

export default PayrollRunSummary;
