// in src/posts.js
import React, {Fragment} from 'react';
import {
    AutocompleteInput, Create,
    Datagrid, Edit,
    EditButton, FormDataConsumer,
    List,
    ReferenceField,
    ReferenceInput, required, SelectInput,
    Show, SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
ShowButton
} from 'react-admin';
import exporterCommon from "../import/exporterCommon";
import {useForm} from "react-final-form";
import {parse} from "query-string";
import DefaultListActions, {Empty} from "./common";

export const SiteList = props => (
    <List   exporter={exporterCommon(props.resource)} actions={<DefaultListActions/>} {...props} empty={<Empty />} >
        <Datagrid rowClick='show' >
            <TextField source="name" />
            {/*<TextField source="title" />*/}
            {/*<ShowButton />*/}
            <EditButton />
        </Datagrid>
    </List>
);


export const SiteShow = props => (
    <Show  {...props}>
        <SimpleShowLayout>
            <TextField source='name' />
        </SimpleShowLayout>
    </Show>
);


export const SiteEdit = props => {

    return <Edit {...props}>
        <SimpleForm redirect='list'  >
            <TextInput source='name' />
        </SimpleForm>
    </Edit>
};


export const SiteCreate = props => {

    return <Create {...props}  >

        <SimpleForm redirect='list'   >
             <TextInput source='name' />
        </SimpleForm>

    </Create>
};
