// in src/MyLogoutButton.js
import React, {forwardRef} from 'react';
import {useLogout, useRedirect} from 'react-admin';
import MenuItem from '@material-ui/core/MenuItem';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';

const MyLogoutButton = forwardRef((props, ref) => {
    const logout = useLogout();
    const redirect = useRedirect();
    const handleClick = () => {
        redirect('/');
        return logout();
    }
    return (
        <MenuItem
            onClick={handleClick}
            ref={ref}
        >
            <ExitIcon /> Logout
        </MenuItem>
    );
});

export default MyLogoutButton;
