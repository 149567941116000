import React, {Fragment} from 'react';
import {Create, Edit, SimpleForm, TextInput, TextField, SelectInput} from 'react-admin';
import {parse} from "query-string";
import {NoDeleteToolbar} from "./common";
import {DynamicInput} from "../../UIComponents/dynamicInput";


export const CompanySettingEdit = props => {
    const { id, view } =parse(props.location.search);
    const redirect = id ? `/companies/${id}/show/${view?view:'settings'}` : "list";
    return <Edit {...props}>
        <SimpleForm redirect={redirect} toolbar={<NoDeleteToolbar/>}>
            <TextInput source="description"/>
           <DynamicInput source="value"/>
            {/*<TextInput source="valueType"/>*/}
            {/*<NullableBooleanInput format={val=>val==='true'} parse={val=>val?"true":"false"}  source="value"/>*/}
        </SimpleForm>
    </Edit>
};

export const CompanySettingCreate = props => {
    const { companyId} =parse(props.location.search);
    const redirect = companyId ? `/companies/${companyId}/show/settings` : "list";
  return  <Create {...props}  >
        <SimpleForm  redirect={redirect}>
            <TextInput source="name"/>
            <TextInput source="description"/>
            <SelectInput  source="valueType"  choices={[
                { id: 'text', name: 'Text' },
                { id: 'number', name: 'Number' },
                { id: 'boolean', name: 'Boolean' },
            ]}  />
            <TextInput source="value"/>
        </SimpleForm>
    </Create>
};
