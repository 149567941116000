import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import {Button, Chip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ViewIcon from '@material-ui/icons/Visibility';
const MaterialCheckBoxGroup = ({items, itemLabel, hint,label, onChange, value, valueKey="_id", onView, type, subType}) => {
    const handleChange = (checked,item) => {
      //  console.log(item)
        if(!checked)
        onChange(value.filter(i=>i[valueKey]!==item[valueKey]))
        else {
          onChange([...value,item])
        }
    }
    return (
        <FormControl component="fieldset" >
            <FormLabel component="legend">{label}</FormLabel>
            <FormGroup>

                {items.map(i=><div>

                    <FormControlLabel
                    control={

                        <Checkbox key={i[valueKey]} color={"primary"} checked={value.map(v=>v[valueKey]).includes(i[valueKey])} onChange={e=>handleChange(e.target.checked,i)} name="gilad" />

                    }
                    label={i[itemLabel]}
                />
                    {type&&<Chip size={"small"} label={i[type]}/> }
                    {subType&&<Chip size={"small"} label={i[subType]}/> }
                <IconButton aria-label="view"  size="small">
                    <ViewIcon fontSize="inherit" onClick={(e)=>onView(e,i)} />
                </IconButton></div>)}

            </FormGroup>
            <FormHelperText>{hint}</FormHelperText>
        </FormControl>
    );
};

export default MaterialCheckBoxGroup;
