import React, {Fragment} from 'react';
import {
    CheckboxGroupInput,
    Create,
    Datagrid,
    Edit,
    FormDataConsumer,
    List,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    BooleanInput,
    useQueryWithStore, AutocompleteInput, minLength
} from 'react-admin';
import {useForm} from "react-final-form";
import exporterCommon from "../import/exporterCommon";
import {RedirectToolbar} from "./common";
import {getScope, scopeResourcesEnum} from "./helperFunctions";
import {DateInput} from "react-admin-date-inputs2";
import Grid from "@material-ui/core/Grid";

export const EarningList= props => (
    <List  exporter={exporterCommon(props.resource)} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
        </Datagrid>
    </List>
);

export const EarningEdit = props => {
    // const { scopeId: scopeId_string, scope } =parse(props.location.search);
    // let scopeId = scopeId_string ? parseInt(scopeId_string, 10) : "";
    // if(+scope===0)
    //     scopeId = scopeId_string;
    // const redirect = scopeId ? `/${scopeResourcesEnum[+scope]}/${scopeId}/show/earnings` : "show";
    // const scopeText = scopeEnum[+scope] || '';
    const {redirect,scopeText,scopeId,scopeName,scope} = getScope(props,"earnings")

    return <Edit {...props}>
        <SimpleForm redirect={redirect} toolbar={<RedirectToolbar/>}>
            <FormDataConsumer>
                {formDataProps => (
                    <EarningForm {...formDataProps} scopeText={scopeText} scope={scope} />
                )}
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
};

const EarningForm  = ({ formData, scopeText,scope, ...rest }) => {
   // console.log("scope",scopeText)

    //const [data:templ]
    const {data: templateEarnings } = useQueryWithStore({
        type: 'getList',
        resource: 'earnings',
        payload: {filter:{filter:`Scope eq 'Template'`},pagination:{},sort:{}}
    });

    // if(scopeText.toLowerCase()==='template')
    // {
    //     form.change('value',0)
    // }



    const form = useForm();
    return <Fragment>
        <Grid container style={{width:"100%"}}>


        <ReferenceInput
            {...rest}
            source={`${scopeText.toLowerCase()}Id`}
            reference={scopeResourcesEnum[+scope]}
            validate={required()}
            disabled
        >

            <SelectInput    optionText="name" />
        </ReferenceInput>
        {+scope !== 3 &&< SelectInput onChange={event=>{
            const earning = templateEarnings.find(e=>e.name===event.target.value)
            if(earning) {
            form.change('name', earning.name)
            form.change('taxOption', earning.taxOption)
            form.change('limitByValue', earning.limitByValue)
            form.change('valueType', earning.valueType)
            form.change('dayBased', earning.dayBased)
            form.change('templateId', earning.id)
        }

            // form.change('earningType',earning.id.toString())
        }}   {...rest} source="earningType" optionValue='name'  choices={templateEarnings}  />
        }
        {/*<ReferenceInput*/}
            {/*{...rest}*/}
            {/*source="type"*/}
            {/*reference="earnings"*/}
            {/*filter={{filter:`Scope eq 'Template'`}}*/}
        {/*>*/}

            {/*<SelectInput    optionText="name" />*/}
        {/*</ReferenceInput>*/}
        <TextInput   {...rest} source="name" validate={[required(),minLength(2)]} />
            <ReferenceInput perPage={1000}    source="costCenterId" reference="costCenters" >
                {/*<SelectInput  validate={[required()]} optionText="name"  />*/}
                <AutocompleteInput  optionText="name" />

            </ReferenceInput>
        </Grid>

        <SelectInput  {...rest} source="taxOption"  choices={[
            { id: 'NonTaxable', name: 'NonTaxable' },
            { id: 'Taxable', name: 'Taxable' },
            { id: 'TaxableWithLimits', name: 'TaxableWithLimits' },
            { id: 'TaxCovered', name: 'Tax Covered By Employer (Add to Net)' }
        ]}  />

        {formData.taxOption === 'TaxableWithLimits' &&

        <NumberInput label="Non Taxable Amount"  {...rest} source="limitByValue" />
        }

        {formData.name&&formData.name.toLowerCase().includes('overtime') ?
        <SelectInput   {...rest} source="valueType" choices={[
            {id: 'Absolute', name: 'Absolute'},
            {id: 'Percentage', name: 'Percentage'},
            {id: 'Hour', name: 'Hours'},
        ]}/>:
            <SelectInput   {...rest} source="valueType" choices={[
                {id: 'Absolute', name: 'Absolute'},
                {id: 'Percentage', name: 'Percentage'},

            ]}/>
        }


        {+scope!==3&&<NumberInput validate={[required()]}
                      format={v => formData.valueType === "Percentage" ? ((v * 1000000) / 10000) : v}
                      parse={v => formData.valueType === "Percentage" ? (parseFloat(v) / 100) : v}  {...rest}
                      source="value"/>
        }        {/*<NumberInput source='value'/>*/}
         {/*scope is company or position (0, 1) */}
        {+scope<=1&&<><CheckboxGroupInput source="appliesTo"  choices={[
            { id: 'FullTime', name: 'FullTime' },
            { id: 'Contract', name: 'Contract' },
            { id: 'PartTime', name: 'PartTime' },
            { id: 'InternOrFellow', name: 'InternOrFellow' },
            { id: 'Casual', name: 'Casual' },
        ]}/>
            <CheckboxGroupInput   source="appliesToCitizens" choices = {[
                { id: "Ethiopian", name: 'Ethiopians' },
                { id: "Non-Ethiopian", name: 'Non Ethiopians' },
            ]} />
        </>}
        <Grid container style={{width:"100%"}}>
            <BooleanInput  source="dayBased" />
            {+scope!==3&&<DateInput     source={"startDate"} label="Start date" options={{ format: 'DD/MM/YYYY', inputVariant:'filled'  }}  />}

        </Grid>

        {+scope===3&& <BooleanInput  source="showOriginal" />}


    </Fragment>
}






export const EarningCreate = props =>
   {
      // console.log()

      //  const { scopeId: scopeId_string, scope } =parse(props.location.search);
      //  let scopeId = scopeId_string ? parseInt(scopeId_string, 10) : "";
      //  if(+scope===0||+scope===3) //company id is string
      //  {
      //      scopeId = scopeId_string;
      //  }
      //  const redirect = scopeId ? `/${scopeResourcesEnum[+scope]}/${scopeId}/show/earnings` : "show";
      //
      // // console.log(scope)
      //  const scopeText = +scope===3?'Company':scopeEnum[+scope] || '';

      const {redirect,scopeText,scopeId,scopeName,scope} = getScope(props,"earnings")
       //console.log(scopeText)
       return    (

    <Create {...props}>
        <SimpleForm  initialValues={{appliesTo:['FullTime'],appliesToCitizens:['Ethiopian','Non-Ethiopian'],[`${scopeText.toLowerCase()}Id`]:scopeId,scope:scopeName, dayBased:true}} redirect={redirect}>
            <FormDataConsumer>
                {formDataProps => (
                    <EarningForm {...formDataProps} scopeText={scopeText} scope={scope} />
                )}
            </FormDataConsumer>
        </SimpleForm>
    </Create>
)};
