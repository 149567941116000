import React, {useRef, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {Check, CheckBox} from "@material-ui/icons";
import {FormControl} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {Typography} from "../../../UIComponents/Wrappers";
import MyDatagrid from "../../accountsGrid";
import MultiCurrencyField from "../../../UIComponents/multiCurrencyField";
import {TextField, FunctionField} from "react-admin";
import MultiCurrency from "../../multiCurrency";
import ReportHeader from "../reportHeader";
import ReactToPrint from "react-to-print";
import Button from "@material-ui/core/Button";
import PrintIcon from "@material-ui/icons/Print";

import moment from "moment";
import PayrollLoanExcelExport from "./payrollLoanExcelExport";

const PayrollLoan = props => {
    const defaultFooterElements = [{field:"General Manager", value:""},{field:"HR Head", value:""}]
    const componentRef = useRef();
    const [filteredPayrolls,setFilteredPayrolls] = useState(props.filteredPayrolls)
    const [rowsPerPage, setRowsPerPage] = React.useState(1000);
    const [page, setPage] = React.useState(0);
    const [showFooter,setShowFooter] = useState(false);
    const [footerItems,setFooterItems] = useState(defaultFooterElements)
    const {sort, handelSort} = props;
  //  const {loanTemplates} = props;
    const initColumns = [{field:"sn", width:80, show:true, title:"SN", type:"Text"},{show:true,field:"fullName", width:200, title:"Full Name", type:"Text"}]
        .concat(props.templates.templateLoans.filter(l=>l.type!=='Deduction').map(l => ({id:l.id,title:l.name,field:l.name.replace(/\s+/g, '').toLowerCase(), type:"Loan"})))
        .concat(props.templates.templateLoans.filter(l=>l.type==='Deduction').map(l => ({id:l.id,title:l.name,field:l.name.replace(/\s+/g, '').toLowerCase(), type:"Deduction"})))
        // .concat(props.templates.templateLoans.map(l => ({id:l.id+'Remaining',title:l.name+'Remaining',field:l.name.replace(/\s+/g, '').toLowerCase()+'Remaining', type:"Loan"})))
        .concat(props.templates.templateDeductions.map(l=>({id:l.id,title:l.name, field:l.name.replace(/\s+/g, '').toLowerCase(), type:"Deduction"
    })))
    const [columns, setColumns] = useState(initColumns)
    const handleColumnChange = (event) => {

        const index = columns.findIndex(c=>c.field===event.target.name)
        const newCols = columns.slice(0).filter(c=>c.type!=="Total")
        newCols[index].show= event.target.checked
        if(newCols[index].type==='Loan' ) {
            const indexR = columns.findIndex(c=>c.field===event.target.name+'Remaining')
            if(indexR<0) {
                newCols.push({
                    title: 'Remaining ' + newCols[index].title,
                    field: newCols[index].field + 'Remaining',
                    type: newCols[index].type+'Remaining',
                    show: true
                })

            }


        else {
            newCols[indexR].show = event.target.checked
            }


            const indexA = columns.findIndex(c=>c.field===event.target.name+'Account')
            if(indexA<0) {
                newCols.push({
                    title: newCols[index].title+" Account",
                    field: newCols[index].field + 'Account',
                    type: 'Text',
                    show: true
                })

            }


            else {
                newCols[indexA].show = event.target.checked
            }




        }

        if(newCols.filter(c=>c.show&&c.type!=="Text"&&!c.type.includes('Remaining')).length>1) {

            newCols.push({
                title: "Total",
                field:"totalSelected",
                type:"Total",
                show:true
            })

            newCols.push({
                title: "Total Remaining",
                field:"totalRemaining",
                type:"Total",
                show:true
            })
        }

        setColumns(newCols)
        // console.log({newCols,filteredPayrolls})

        const newPayrolls =
            props.filteredPayrolls.map(record => {
                // console.log(record.employee.accounts)
            let sum = 0;
            // console.log({newCols})
           newCols.filter(c=>c.type!=="Text").forEach(c => {

              const account = record.employee&&record.employee.accounts&&record.employee.accounts.find(a=>(a.templateType&&c.field.includes(a.templateType.toLowerCase().replace(/\s+/g, '')))||(a.name&&a.name.toLowerCase().includes(c.field)))
               record[c.field+"Account"] = account&&account.code
               console.log(        record)
               if (c.show&&!c.field.includes('Remaining')&&c.field!=='totalSelected'&&c.field!=='totalRemaining') {
                    sum += record[c.field] || 0
                }

            });

                let remaining = 0;

                newCols.filter(c=>c.type!=="Text").forEach(c => {
                    if (c.show&&c.field.includes('Remaining')&&c.field!=='totalSelected'&&c.field!=='totalRemaining') {
                        // console.log({c,record,r:record[c.field]})
                        remaining += record[c.field] || 0
                    }

                });

            record.totalSelected= sum;
            record.totalRemaining = remaining;



            //if(record.totalSelected>0)
            return record;
        })
        setFilteredPayrolls(newPayrolls.filter(r=>r.totalSelected).map((r,i)=>{
            r.sn = i+1
            return r
        }))

        setTitle(getTitle(newCols))



    };
    let ids = filteredPayrolls.map((p,index) => index)
    ids=rowsPerPage > 0
        ? ids.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : ids
    const [title,setTitle] = useState('')

const getTitle = columns => {
    const cols = columns.filter(c=>c.show&&c.type!=="Text"&&c.type!=="Total")
    if(cols.length===1) return cols[0].title + ' List'
    else if (cols.length === 2) return `${cols[0].title} & ${cols[1].title} List`
    else {
        return 'Loans and Deduction List'
    }
}
// const getSummeryFields = () => {
//         const cols = columns.filter(c=>c.show).map(c=>c.field)
//         if(cols.length > 1)
//         return cols.concat('totalSelected')
//     else return  cols;
//     }
    return (
        <div style={{width:'80%'}}>
            <Grid container>
                <Grid item xs={4}>
                    <div>
                        Loans
                        {columns.filter(l=>l.type==="Loan").map(item=><div>
                            <FormControlLabel control={<Checkbox
                                checked={item.show}
                                color="primary"
                                onChange={handleColumnChange}
                                name={item.field}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />} label={<Typography size='sm' >{item.title}</Typography>} />

                        </div>)}
                    </div>

                </Grid>

                <Grid item xs={4}>
                    <div>


                    Deductions
                    {columns.filter(l=>l.type==="Deduction").map(item=><div>
                        <FormControlLabel control={<Checkbox
                            checked={item.show}
                            color="primary"
                            onChange={handleColumnChange}
                            name={item.field}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />} label={<Typography size='sm' >{item.title}</Typography>} />

                    </div>)}
                    </div>
                </Grid>

                <Grid item xs={4}>
                    <div>
                        Remaining Balance
                        {columns.filter(l=>l.type.includes('Remaining')).map(item=><div>
                            <FormControlLabel control={<Checkbox
                                checked={item.show}
                                color="primary"
                                onChange={handleColumnChange}
                                name={item.field}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />} label={<Typography size='sm' >{item.title}</Typography>} />

                        </div>)}
                    </div>

                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item>
                    <ReactToPrint
                        trigger={() => <Button variant="outlined"  color="primary"  startIcon={<PrintIcon/>}  >Print/PDF</Button>}
                        content={() => componentRef.current}
                        pageStyle={""}
                    />
                </Grid>
                <Grid item>

                    <PayrollLoanExcelExport  columns={columns} footerItems={footerItems} showFooter={showFooter} payrollDate={props.payrollRun.payrollDate} title={title} logo={props.logo} data={filteredPayrolls} fileName={`${title}- ${localStorage.getItem('companyName')} ${moment(props.payrollRun.payrollDate).format('MMMM YYYY')}.xlsx`}/>




                </Grid>
            </Grid>
            <div ref={componentRef} className="all-boarder">
                <ReportHeader logo={props.logo} payrollRun={props.payrollRun} title={title}/>

                <MyDatagrid fullHeight checkField={false} summeryFields={columns.filter(c=>c.show&&c.type!=='Text').map(c=>c.field).concat('totalSelected').concat('totalRemaining')}  ids={ids} currentSort={sort} setSort={handelSort} data={filteredPayrolls}>
                    {/*<TextField source="sn"  />*/}
                    {/*<TextField source="fullName" />*/}
                    {/*{*/}
                    {/*    columns.map(c=>c.show&&(<MultiCurrencyField   label={c.title} source={c.field}/>))*/}
                    {/*}*/}
                    {
                        columns.map(c=>c.show&&(c.type==="Text"?<TextField label={c.title} source={c.field} />:<MultiCurrencyField  label={c.title} source={c.field}/>))
                    }

                    {/*{columns.filter(c=>c.show).length>1&&<FunctionField label="Total" render={record => {*/}
                    {/*    let sum = 0;*/}
                    {/*    columns.forEach(c => {*/}
                    {/*        if (c.show) {*/}
                    {/*            sum += record[c.field] || 0*/}
                    {/*        }*/}

                    {/*    });*/}
                    {/*    return <MultiCurrency value={sum}/>*/}
                    {/*}*/}
                    {/*{columns.filter(c=>c.show).length>3&&<MultiCurrencyField label="Total" source="totalSelected"/>}*/}
                    {/*}/>}*/}
                </MyDatagrid>
            </div>
            </div>
    );
};

export default PayrollLoan;
