import React from 'react';
import {Edit, PasswordInput, SimpleForm, TextInput} from 'react-admin';
import {passwordValidator} from "./helperFunctions";

export const ProfileEdit = ({ staticContext, ...props }) => {
    return (
        <Edit
            id="me"
            resource="users"
            basePath="/my-profile"
            title="My profile"
            redirect='/' // I don't need any redirection here, there's no list page
            {...props}
        >
            <SimpleForm redirect='/'>
                <TextInput source="firstName"/>
                <TextInput source="lastName"/>
                <TextInput type="email" source="email"/>
                <PasswordInput label='Current Password' source="password.oldPassword" validate={passwordValidator}/>
                <PasswordInput label='New Password' source="password.newPassword" validate={passwordValidator}/>
                <TextInput type="email" source="email2" label="Secondary Email for Getting Copy of slips"/>
            </SimpleForm>
        </Edit>
    );
};
