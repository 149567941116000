import React from 'react';
import {Button, DeleteButton} from 'react-admin'
import {Link} from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/LibraryBooksOutlined"
import ApproveButton from "../components/payroll/approveButton";
import UnlockPayrollButton from "../components/payroll/unlockButton";

const DeleteRunField = (props) => {

        return  <div>

            <Button component={Link} to={`/reports/payroll/${props.record.id}/sheet`}  startIcon={<ViewIcon/>} />

            {props.record.status!=="Approved"&&props.record.createdBy.toLowerCase() ===localStorage.getItem('user').toLowerCase()&&<Button component={Link} to={`/runPayroll/${props.record.id}`}  startIcon={<EditIcon/>} disabled={props.record.status==='Approved'||props.record.createdBy.toLowerCase()!==localStorage.getItem('user').toLowerCase()}/>}

            <DeleteButton disabled={props.record.locked||(props.record.status==='Approved')||(!(props.permissions.includes("Admin")&&props.permissions.includes("Manager"))&&props.record.createdBy.toLowerCase()!==localStorage.getItem('user').toLowerCase())} label={null} {...props}/>

            {
                props.record.status!=="Approved" && props.permissions.includes('Manager') &&   props.record.status !== 'Approved' &&    <ApproveButton record={props.record}/>
            }

            <UnlockPayrollButton permissions={props.permissions} record={props.record}/>

        </div>



}

export default DeleteRunField;
