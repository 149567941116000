import React, {useState} from 'react';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {useDataProvider, useQueryWithStore} from "react-admin";
import CardHeader from "@material-ui/core/CardHeader";
import MultiDate from "../../UIComponents/multiDate";
import _ from "lodash";

const BackPayItem = ({payroll, onChange, extraData}) => {
    // const [month, setMonth] = useState()
    const dataProvider = useDataProvider();
    const {data: approvedRuns } = useQueryWithStore({
        type: 'getList',
        resource: 'approvedPayrollRuns',
        payload: {filter:{expand:'payrollRun'}}
    });
    if(!approvedRuns) {
        return null
    }

    // const payrollRuns = approvedRuns ? approvedRuns.map(a => a.payrollRun): [{}]
    const getMonthData = async (payrollRunId)=>{
        try {
           const {data} = await dataProvider.getList('payrolls', { filter:{filter:`payrollRunId eq ${payrollRunId} and employeeId eq ${payroll.employeeId}`} })
            if(data.length) {
                onChange({taxableIncome:data[0].taxableIncome, backpayPayroll:payrollRunId, incomeTax:data[0].incomeTax})
            }
        } catch (e) {

            console.log("backPayerror",e)

        }

    }

    return (
        <FormControl margin='dense' fullWidth  >
            <InputLabel>Back Pay Month</InputLabel>
            <Select
                value={extraData.backpayPayroll}
                onChange={ev=> getMonthData(ev.target.value)}
                >
                {_.orderBy(approvedRuns,['payrollRun.payrollDate'],['desc']).map(r=><MenuItem key={r.id} value={r.payrollRun.id}><MultiDate gcDate={r.payrollRun.payrollDate} format={'MMMM YYYY'}/></MenuItem>)}
            </Select>

        </FormControl>
    );
};

export default BackPayItem;
