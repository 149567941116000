import {call, put, takeLatest} from 'redux-saga/effects';
import {fetchEnd, fetchStart, refreshView, showNotification} from 'react-admin';
import OdataServiceProvider from "../Helpers/odataServiceProvider";


export default function* leaveSaga() {
    yield takeLatest('GENERATE_ANNUAL_LEAVE', function* (action) {
            const {payload} = action
            yield put(fetchStart());
            try {
                const res = yield call(OdataServiceProvider.serverCollectionAction,"leaves",{action:"GenerateAnnualLeave"})
                yield put(showNotification('Leaves Generated', "info"))
                yield put(fetchEnd());
                yield put(refreshView())
                // yield put(push(payload.basePath))
            }
            catch (e) {
                yield put(showNotification(`Sorry, failed to generate leaves. reason ${e.message} `, "error"))
                yield put(fetchEnd());
            }


        }
    )
}
