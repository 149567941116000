import React from 'react';
import {styled} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";

const Gridb = styled(Grid)({
    boxShadow: "0 0 0 0.5px grey"
});

export default Gridb;


//
// import React from 'react';
// import Grid from "@material-ui/core/Grid";
// import withStyles from "@material-ui/core/styles/withStyles";
// const styles = theme => ({
//     root : { border:true,
//         borderColor:"#000",
//         borderWidth:2,
//         borderStyle:"solid"}
// })
//
//
// const Gridb = ({classes, children, ...rest}) => {
//
//     return (
//         <Grid {...rest} className={classes.root}>
//             {children}
//         </Grid>
//     );
// };
//
// export default withStyles(styles)(Gridb)
