import {call, put, takeLatest} from 'redux-saga/effects';
import {fetchEnd, fetchStart, refreshView, showNotification} from 'react-admin';
import OdataServiceProvider from "../Helpers/odataServiceProvider";
import {replace} from 'connected-react-router'

export default function* companySaga() {
    yield takeLatest('ACTIVATE_COMPANY', function* (action) {
        //console.log('activation')
            const {payload} = action
            yield put(fetchStart());
            try {
                const onlineActivate = yield call(OdataServiceProvider.serverAction,"companies",{id:payload.id, data:{},action:"ActivateOnline"})
                yield put(showNotification('Company Activated Successfully', "info"))
                yield put(fetchEnd());
                yield put(refreshView())
              //  yield put(push(payload.basePath))
            }
            catch (e) {
                yield put({type:'COMPANY_ACTIVATION_FAILED'});
                yield put(showNotification(`Sorry, Company activation failed please contact the support team `, "error"))
                yield put(fetchEnd());
            }


        }
    )

    yield takeLatest('ACTIVATE_COMPANY_MANUAL', function* (action) {
          //  console.log('activation')
            const {payload} = action
            yield put(fetchStart());
            try {
                const activate = yield call(OdataServiceProvider.serverAction,"companies",{id:payload.id, data:{ActivationCode:payload.code},action:"Activate"})
                yield put(showNotification('Company Activated Successfully', "info"))
                yield put(fetchEnd());
                yield put(refreshView())
                //  yield put(push(payload.basePath))
            }
            catch (e) {
                yield put({type:'COMPANY_MANUAL_ACTIVATION_FAILED'});
                yield put(showNotification(`Sorry, Company activation failed please contact the support team `, "error"))
                yield put(fetchEnd());
            }


        }
    )

    yield takeLatest('COMPANY_SELECT', function* (action) {

        const {payload} = action
        yield put({type:'COMPANY_SELECTED',payload})


        yield put({type:'COMPANY_CURRENCY_REFRESH'})

        yield put({type:'RESET_PAYROLL'})
        yield put(replace('/'))
    })

    yield takeLatest('COMPANY_CURRENCY_REFRESH', function* (action) {
            //refresh curreny rate
            let  res =  yield  call(OdataServiceProvider.getList,'currencyRates',{pagination:{},sort:{},filter:{queryString:'$orderby=Date desc &$top=1'}})
            // console.log("currency",res)
            if(!res.data.length)
            {
                let  res =  yield  call(OdataServiceProvider.getList,'currencyRates',{company:"ROOT",pagination:{},sort:{},filter:{queryString:'$orderby=Date desc &$top=1'}})
                // console.log("currency",res)
                yield put({type:'CURRENCY_RATES_LOADED',payload:res.data});
            }
            else
            {
                yield put({type:'CURRENCY_RATES_LOADED',payload:res.data});
            }
    })
    yield takeLatest('COMPANY_SETTINGS_REFRESH', function* (action) {

        const {payload} = action

        try {
            yield put(fetchStart());
            yield call(OdataServiceProvider.serverAction,"companies",{id:payload.id, data:{},action:"RefreshSettings"})
            yield put(showNotification('Settings Are Refreshed', "info"))
            yield put(fetchEnd());
            yield put(refreshView())
        }
        catch (e) {
            yield put(showNotification(`Sorry, Cant Refresh Settings now `, "error"))
            yield put(fetchEnd());
        }

    })

    yield takeLatest('COMPANY_GENERAL_SETTINGS_REFRESH', function* (action) {

        const {payload} = action

        try {
            yield put(fetchStart());
            yield call(OdataServiceProvider.serverAction,"companies",{id:payload.id, data:{},action:"RefreshGeneralSettings"})
            yield put(showNotification('Settings Are Refreshed', "info"))
            yield put(fetchEnd());
            yield put(refreshView())
        }
        catch (e) {
            yield put(showNotification(`Sorry, Cant Refresh Settings now `, "error"))
            yield put(fetchEnd());
        }

    })

    yield takeLatest('RESET_DEFAULT_ACCOUNTS', function* (action) {
            const {payload} = action
            yield put(fetchStart());
            try {
                const res = yield call(OdataServiceProvider.serverAction,"companies",{id:(payload&&payload.companyId)||localStorage.getItem('companyId'),action:"ResetAccounts"})
                yield put(showNotification('All Accounts Reset to default', "info"))
                yield put(fetchEnd());
                yield put(refreshView())
                // yield put(push(payload.basePath))
            }
            catch (e) {
                yield put(showNotification(`Sorry, Resetting Accounts Failed ${e.message} `, "error"))
                yield put(fetchEnd());
            }


        }
    )
    yield takeLatest('LOAD_COMPANY_SETTINGS', function* (action) {
        yield put(fetchStart());
        try {
            let res = yield  call(OdataServiceProvider.getList, 'companySettings', {pagination: {}, sort: {}, filter: {}})
            yield put(fetchEnd());
            yield put({type:'COMPANY_SETTINGS_LOADED',payload:res.data})
        } catch (e) {
            yield put(fetchEnd());

        }

    })
}
