import React from 'react';
import ExcelExportColumn from "@progress/kendo-react-excel-export/dist/es/ExcelExportColumn";
import ExcelExportColumnGroup from "@progress/kendo-react-excel-export/dist/es/ExcelExportColumnGroup";
import _ from 'lodash'
import ExcelExport from "@progress/kendo-react-excel-export/dist/npm/ExcelExport";


const PayrollExcelExport = React.forwardRef((props, ref) => {

    // console.log({group:props.group, result:props.result})

    const getSum = ({column}) => {
        // console.log(field)
        // console.log(data)
        const items = data[0].items?data.flatMap(x => x.items&&[...x.items]):data;

        return items.reduce((sum,cv)=>sum+(cv[column.field]||0),0).toFixed(2)
    }
    //  if(!templateEarnings) return  null
    const CustomGroupFooter = ({column,aggregates}) => {

        return aggregates[column.field]?aggregates[column.field].sum:0
    };

    const {result, fileName, columns, group} = props

    const {data} = result;
    return (
        <ExcelExport
            group={group}
            data={data}
            ref={ref}
            fileName={fileName}
        >
            {
                _.orderBy(columns,'orderIndex').map(c=> c.show &&<ExcelExportColumn
                    width={c.width*1.35}
                    field={c.field}
                    title={c.title}
                    footer={c.footer||c.format==="{0:n2}"?getSum:null}
                    groupFooter={c.footer||c.format==="{0:n2}"?CustomGroupFooter:null}
                    cellOptions={c.format==="{0:n2}"?{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}:null}
                    footerCellOptions={c.format==="{0:n2}"?{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}:null}
                    groupFooterCellOptions={c.format==="{0:n2}"?{format: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'}:null}

                />)

            }

        </ExcelExport>
            )
})

export default PayrollExcelExport;
