import {fetchUtils} from 'react-admin';
import {stringify} from 'query-string';
import {o} from 'odata';
import _ from 'lodash';
import {convertFileToBase64} from "./b64toBiteArray";

//const httpClient = fetchUtils.fetchJson;
export const httpClient = (url, options = {}) => {
    const apiUrl = `${localStorage.getItem('domain')}/odata/`;
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    const company = localStorage.getItem('company');
    options.headers.set('Authorization', `Bearer ${token}`);
    options.headers.set('Bypass-Tunnel-Reminder', "true");

    // add your own headers here
    options.headers.set('X-COMPANY', options.x_company||company);
    return fetchUtils.fetchJson(apiUrl+url, options);
}

const oConfig = (c)=>{
    const token = localStorage.getItem('token');
    const company = c||localStorage.getItem('company');
    return {

        headers: new Headers({
            "Content-Type": "application/json",
            "X-COMPANY": company,
            'Authorization': `Bearer ${token}`,
            'Bypass-Tunnel-Reminder': "true"
        }),
    }}

export const oHandler = () => o(`${localStorage.getItem('domain')}/odata/`,oConfig());
export default {
    getList: (resource, params) => {
        //console.log({resource, params})
       // console.log('filter',resource,params)
      //  const { page, perPage } = params.pagination;
       // const { field, order } = params.sort;
        params = params || {}
        params.filter = params.filter || {}
        const {expand,filter,queryString, select} = params.filter
        const {sort,pagination} = params;
           // console.log('getting',params)
        // const query = {
        //     // sort: JSON.stringify([field, order]),
        //     // range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        //     // filter: JSON.stringify(params.filter),
        //     $count: true
        // };
        //const url = `${apiUrl}/${resource}?${stringify(query)}`;

        // return httpClient(url).then(({ headers, json }) => ({
        //         //     data: json.value,
        //         //     total: json['@odata.count']
        //         // }));
        const query = {}
        query.$count =  true
//        query.company=1
       if(expand)
       {
           query.$expand  = expand
       }
        if(select)
        {
            query.$select  = select
        }
        if(!select && resource==="employees") {
            query.$select = 'fullname,startDate, contractEndDate, orgID,name,fatherName,gFatherName,tinNo,pensionNo,companyBankId,bankBranch,bankAccountHolderName,employmentType,defaultOvertime,siteId,dateOfBirth,isSiteManager,startDate,contractEndDate,citizenship,gender,outstandingLoan,remainingLeaveDays,basicSalary,positionId,departmentId,costCenterId,bankAccountNumber,bank,defaultCurrency,mateId,active,excludeFromDistEarnings,sendPayslipVia,payGross,otBase,desiredNet,dailyServiceHour,nationalID,taxRegion,grade,function,subFunction,companyId,id,address,isDeleted, extraIncomeTax, customIncomeTaxExpression'
        }
       if(filter)
       {
           query.$filter = filter
       }
       if(sort && sort.order && !queryString ) {
        //   console.log(sort)
           query.$orderby = `${sort.field} ${sort.order.toLowerCase()}`
       }

       if(pagination && pagination.perPage && !queryString) {
           query.$skip = pagination.perPage * (pagination.page-1)
           query.$top = pagination.perPage
       }

       if(params.filter && !queryString)
       {
           const keys =  Object.keys(_.omit(params.filter, ["expand","filter","queryString","q", "select"]));
           keys.map((k,index)=>{
               const val = typeof params.filter[k] === 'string'? `'${params.filter[k]}'`:params.filter[k]
              // console.log('index',index)
               if(!query.$filter)
               {
                   query.$filter = `${k} eq ${val}`
               }
               else {
                   query.$filter += ` and ${k} eq  ${val}`
               }

           })
          // console.log(query.$filter)
       }
       //console.log(params)


       // return oHandler()
       //      .get(resource)
       //      .fetch(query).then(response => {
       //             if (response.status >= 200 && response.status <= 299) {
       //
       //                 return response.json().then(d => (
       //                     {
       //                         data: d.value,
       //                         total: d['@odata.count']
       //                     }))
       //             }
       //             else {
       //
       //                 throw Error({status:response.status})
       //
       //             }
       //
       //         }
       //
       //     )
      let q = stringify(query)
        if(queryString){
          q=  q+"&"+queryString
        }
       // console.log(`${resource}?${q}`)
         return   httpClient(`${resource}?${q}`,{x_company:params.company}).then(({ json }) => {
             // console.log(json)
             if(json) {
                 return {
                     data: json.value,
                     total: json['@odata.count']
                 }
             }
         })


    },

    getOne: (resource, params) =>
     {
       //  console.log('gettingOne',resource)
         const query = {}
         // if(resource==='employees')
         // {
         //     params.expand = 'address'
         // }
         const {expand,id} = params



         if(expand)
         {
             query.$expand  = expand
         }


         return oHandler()
             .get(`${resource}(${id})`)
             .query(query).then(data =>
                 {


                     return {data}

                 }




             )


        //  return   httpClient(`${apiUrl}/${resource}(${params.id})?${stringify(query)}`).then(({ json }) => ({
        //     data: json,
        // }))

     },

    getMany: (resource, params) => {
       // const {expand} = params.filter
            //console.log('xx',params)
        // const query = {
        //     filter: JSON.stringify({ id: params.ids }),
        // };
        // console.log("getting many", params)

        const url = `${resource}?$filter=id in (${params.ids.join(',')})`; //?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json.value }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { sort} = params;
      //  console.log("sort",sort)
    //  console.log('geting many ref',params)
        const query = {
            // sort: JSON.stringify([field, order]),
            // range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            // filter: JSON.stringify({
            //     ...params.filter,
            //     [params.target]: params.id,
            // }),
            $expand:resource,
           // company:1

        };
        const expandParams = [];
        if(sort && sort.order && sort.field!=='id' ) {
            //   console.log(sort)
            expandParams.push(`$orderby=${sort.field} ${sort.order.toLowerCase()}`)
          //  query.$orderby = ``
        }

        if(params.filter && params.filter.oFilter){
            //query.$filter =  params.filter.oFilter

            expandParams.push(`$filter=${params.filter.oFilter}`)
        }
        if(expandParams.length)
        {
            query.$expand += `(${expandParams.join()})`
        }

        const url = `${params.target}(${params.id})?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) =>  {
const res = json[resource].map(r=>({...r,parent:_.omit(json,resource)}))

            return {
            data: res,
            total: json[resource].length
        }});
    },

    update: (resource, params) =>
        // httpClient(`${apiUrl}/${resource}(${params.id})`, {
        //     method: 'PUT',
        //     body: JSON.stringify(params.data),
        // }).then(({ json }) => ({ data: json }))
        //
  {



      const data =  _.omit(params.data, ["id","deductions", "earnings", "createdDate", "createdBy",'modifiedBy',"modifiedDate", "version", "@odata.context", "@odata.etag","parent" ])  // (({id, createdDate, modifiedDate,version,'@odata.context' ...others}) => ({...others}))(params.data)
     // console.log(resource)


      if(resource==="companies") {

          if(data.logo&& data.logo.rawFile instanceof File){
             return convertFileToBase64(data.logo).then(res=>{
                 data.logo = res;
                 return oHandler().patch(`${resource}(${params.id})`,data).query().then(res => ({data:res}))

                 //   console.log(res)
             })
          }
      }

      // if(data.address && data.address.phoneNumber)
      // {
      //      data.address.phoneNumber = data.address.phoneNumber.map(p=>_.omit(p, ["id" ]))  // (({id, createdDate, modifiedDate,version,'@odata.context' ...others}) => ({...others}))(params.data)
      //
      // }


      //console.log(data)
    //  else {
          return oHandler().patch(`${resource}(${params.id})`, data).query().then(res => ({data: res}))
     // }
}
    ,

    updateMany: (resource, params) => {

        const data =  _.omit(params.data, ["id", "createdDate", "createdBy",'modifiedBy',"modifiedDate", "version", "@odata.context", "@odata.etag" ])  // (({id, createdDate, modifiedDate,version,'@odata.context' ...others}) => ({...others}))(params.data)

        return Promise.all(params.ids.map(function (id) {
            return oHandler().patch(`${resource}(${id})`,data).query().then(res => ({data:res}))
        })).then(function (responses) { return ({ data: responses.map(function (_a) {
                //var json = _a.json;
                return _a.data;
            }) }); });
    },

    create: (resource, params) =>
     {
         const query = {
            //company: 1
         };

         return httpClient(`${resource}?${stringify(query)}`, {

            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        }))},

    delete: (resource, params) =>
        httpClient(`${resource}(${params.id})`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json || {id:params.id} })),

    deleteMany: (resource, params) => {

        return Promise.all(params.ids.map(function (id) {
            return oHandler().delete(`${resource}(${id})`,params.data).query()
        })).then(function (responses) { return ({ data: responses.map(function (_a) {
               // const json = _a.json;
                return { data: _a}
            }) }); });



    },

    serverAction: (resource, params) => {
      return  httpClient(`${resource}(${params.id})/${params.action}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        })
            .then(({ json }) => ({ data: json }));
    },

    serverCollectionAction: (resource, params) => {
        return  httpClient(`${resource}/${params.action}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        })
            .then(({ json }) => ({ data: json }));
    },

    get: (path,params) => {
        const query = {
            //company: 1
        };
         return   httpClient(`${path}?${stringify(query)}`).then(({ json }) => ({
            data: json,
        }))
    },

    createMany: (resource, params) => {
        return Promise.all(params.data.map(function (item) {
            return httpClient(  resource, {
                method: 'POST',
                body: JSON.stringify(item),
            });
        })).then(function (responses) { return ({ data: responses.map(function (_a) {
                const json = _a.json;
                return { data: json}
            }) }); });
    }
};
