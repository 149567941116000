import React from 'react';
import {connect} from "react-redux";
import CaptionedField from "../../UIComponents/captionedField";
import {Grid} from "@material-ui/core";
import SavePayrollButton from './savePayroll'
import {Typography} from "../../UIComponents/Wrappers";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import moment from "moment";
import _ from "lodash"
const PayrollTotals = props => {
    const {payroll,filterdPayrolls} = props

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <Grid container direction='column'>
            <Grid container>
                {payroll.payrollCount} {!!payroll.employmentType&&payroll.employmentType} Payrolls {!!payroll.site&&<>in <strong>{payroll.site}</strong>      </>}



                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    {payroll.excluded.map(p=><div><strong>{p.fullname}</strong> {moment(p.contractEndDate).format('MMMM DD, YYYY')}</div>)}
                </Popover>

                {!!payroll.excluded.length&&<span style={{color:"red", marginLeft:30, cursor:"hand"}} onClick={handleClick}>{payroll.excluded.length} Payrolls Excluded!</span>}

            </Grid>

            <Grid container>
                <Grid item xs={3}>

                    {/*{payroll.distEarnings.length}*/}
                    <CaptionedField label='Total income' value={payroll.totalIncome}/>

                    {/*{payroll.absentDeduction>0&&<CaptionedField label='Absent Deduction' value={payroll.absentDeduction}/>*/}
                    {/*}*/}
                    <CaptionedField label='Taxable Income' value={payroll.taxableIncome}/>
                    <CaptionedField label='Income Tax' value={payroll.incomeTax}/>

                </Grid>
                <Grid item xs={3}>
                    <CaptionedField label='Net' value={payroll.netPay}/>
                    <CaptionedField label='Settlement' value={payroll.cashSettlement}/>
                    <CaptionedField label='NetPay' value={payroll.cash}/>
                    {((!!payroll.site)||(!!payroll.employmentType))&&<div>
                        <Typography size='sm'>
                            Results are only for {!!payroll.site&&<><strong>{payroll.site}</strong> site  </>} {payroll.employmentType?payroll.employmentType:''} employees

                        </Typography>
                    </div>}
                    <SavePayrollButton disabled={payroll.site||payroll.employmentType} />
                </Grid>
                <Grid item  xs={3}>
                    <CaptionedField label='Pension (Employee)' value={payroll.pensionEmployee}/>
                    <CaptionedField label='Pension (Company)' value={payroll.pensionCompany}/>
                    <CaptionedField label='Provident Fund' value={payroll.pf}/>
                    <CaptionedField label='Provident Fund (Company)' value={payroll.pfCompany}/>
                </Grid>
                <Grid item  xs={3}>
                    <CaptionedField label='Overtime Total' value={payroll.overtimeTotal}/>
                    <CaptionedField label='Absence Deduction' value={payroll.absentDeduction}/>
                </Grid>


            </Grid>
        </Grid>

    );
};

export default connect(state=>({payroll:state.runPayroll}))(PayrollTotals);
