import React from 'react';
import Card from "@material-ui/core/Card";
import {Title} from 'react-admin';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {deepOrange, green} from "@material-ui/core/colors";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import {Link} from "react-router-dom";


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    cardHeader: {
        backgroundColor: theme.palette.primary.main,
        color:'#fff'
    },
    cardHeader2: {
        backgroundColor: theme.palette.warning.main,
        color:'#fff'
    },
    square: {
        color: '#fff',
        backgroundColor: deepOrange[500],
    },
    rounded: {
        color: '#fff',
        backgroundColor: green[500],
    },
    tile: {
        backgroundColor: theme.palette.primary.main,
        color:'#fff',
        minHeight: 150,

    },
    topGrid: {
        padding: 20,
     }
}));


const AdminDashboard = () => {
    const classes = useStyles();
    return (
        <Card>
            <Title title="PayDay Admin" />
            <Grid className={classes.topGrid} container spacing={2} >
                <Grid item xs={4}>
                    <Link  to='/companies'>
                    <Card className={classes.tile}>
                        <CardHeader title="Companies"/>
                        <CardContent >
                            <Typography gutterBottom>
                                Add/Activate or Configure Companies
                            </Typography>
                        </CardContent>
                    </Card>
                    </Link>
                </Grid>
                <Grid item xs={4}>
                    <Link to='/users'>
                        <Card className={classes.tile}>
                            <CardHeader title="Users"/>
                            <CardContent >
                                <Typography gutterBottom>
                                    Add new Users, set Roles and Assign to companies
                                </Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
            </Grid>

        </Card>
    );
};

export default AdminDashboard;
