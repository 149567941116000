import React, {useEffect, useRef, useState} from 'react';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Grid from "@material-ui/core/Grid";
import MultiCurrency from "../../multiCurrency";
import {Divider, FormControlLabel, IconButton} from "@material-ui/core";
import {Add, Edit, Remove} from "@material-ui/icons";
import _ from 'lodash'
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import columns from "../payrollSheetGrid/columns";
import AddOrEditJournal from "./addOrEditJournal";
import Switch from "@material-ui/core/Switch";
import {useMutation, useNotify, useQueryWithStore} from "react-admin";
import SaveIcon from "@material-ui/icons/SaveOutlined";
import {blue} from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import ReportHeader from "../reportHeader";
import ReactToPrint from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";


const PayrollJournal = ({payrollRun, accounts, templates,logo, asFooter=false, readOnly=false, settingId, setPayrollJournal}) => {
   // const [journalEntries,setJournalEntries] = useState( [])
    const [editField,setEditField] = useState({});
    const [enableEdit,setEnableEdit] = useState(false)
    const [totalDebit,setTotalDebit] =  useState(0);
    const [totalCredit,setTotalCredit] =  useState(0);
    const [showHeader,setShowHeader]= useState(!asFooter);
    const componentRef = useRef();
    // const [settingId,setSettingId] = useState();
    const recalculate = (journalEntries,reset=true) => {
        // const x =  journalEntries.map(j=>{
        //     j.debit = j.debit.map(dr=>({...dr,amount: payrollRun.payrolls.reduce((sum,cv)=>sum+dr.plusColumns.reduce((tot,c)=>tot+cv[c],0)-dr.minusColumns.reduce((tot,c)=>tot+cv[c],0)+dr.customValues.reduce((tot,c)=>tot+c.amount,0),0)}))
        //     j.credit= j.credit.map(dr=>({...dr,amount: payrollRun.payrolls.reduce((sum,cv)=>sum+dr.plusColumns.reduce((tot,c)=>tot+cv[c],0)-dr.minusColumns.reduce((tot,c)=>tot+cv[c],0)+dr.customValues.reduce((tot,c)=>tot+c.amount,0),0)}))
        //
        //
        //     return j
        // })
        const companyJournal = journalEntries.find(c=>c.isCompany)
        // console.log({companyJournal})
       // setTotalDebit(journalEntries.reduce((sum,cv)=>sum+cv.debit.reduce((tot,r)=>tot + r.amount,0),0))
        setTotalDebit(companyJournal.debit.reduce((tot,r)=>tot + r.amount,0))
        setTotalCredit(companyJournal.credit.reduce((tot,r)=>tot + r.amount,0))
       if(setPayrollJournal&&reset) {
           setPayrollJournal(journalEntries)
       }
    }

    useEffect(()=>{
        // setTotalDebit(payrollRun.journalEntries.reduce((sum,cv)=>sum+cv.debit.reduce((tot,r)=>tot + r.amount,0),0))
        // setTotalCredit(payrollRun.journalEntries.reduce((sum,cv)=>sum+cv.credit.reduce((tot,r)=>tot + r.amount,0),0))
        recalculate(payrollRun.journalEntries,false)
    },[payrollRun])


    const editRecord = (journalIndex,journalType,accountCode) => {
        setEditField({journalIndex,journalType,accountCode})
        setDialogOpen(true);

    }

    const addRecord = (journalIndex,journalType) => {
        setEditField({journalIndex,journalType})
        setDialogOpen(true);

    }

    const removeRecord = (journalIndex,journalType,accountCode) => {
        const x = payrollRun.journalEntries.slice(0)

        const y =  x[journalIndex][journalType]
        const index = y.findIndex(j=>j.accountCode===accountCode)
        // console.log(account)
        if (index > -1) {
            y.splice(index, 1);
        }
        recalculate(x)
        // console.log('x',x)



    }
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const handleClose = () => {
        setDialogOpen(false);
    };


    // const { loadedSetting} = useQueryWithStore({
    //         type: 'getList',
    //         resource: 'payrollReportSettings',
    //     },{
    //         onSuccess: (res) =>{
    //
    //             //  console.log(res)
    //             if(res.data[0])
    //             {
    //                 if(res.data[0].journalEntries && res.data[0].journalEntries.length){
    //                     if(!payrollRun.journalEntries||!payrollRun.journalEntries.length) {
    //                            recalculate(res.data[0].journalEntries);
    //                     }
    //                 }
    //               //  setSettingId(res.data[0].id)
    //                 // setShowFooter(!!res.data[0].showFooter)
    //                 // if(res.data[0].bankFooters && res.data[0].bankFooters.length) setFooterItems(res.data[0].bankFooters)
    //                 // setColumnsId(res.data[0].id)
    //                 // // setColumnsId(res.data[0].id)
    //                 // if(res.data[0].bankColumns&&res.data[0].bankColumns.length) {
    //                 //     setColumns(res.data[0].bankColumns)
    //                 // }
    //             }
    //         }
    //     }
    // );

    const [mutate, { loading }] = useMutation();
    const notify = useNotify();
    const inBalance = () => totalDebit.toFixed(4)===totalCredit.toFixed(4)
   // const [inBalance,setInBalance] = useState(true)
    const saveJournals = ()=> {
        if(inBalance())
        mutate({
                type: settingId?'update':'create',
                resource: `payrollReportSettings`,
                payload:{id:settingId,data: {journalEntries:payrollRun.journalEntries.map(jv=>_.omit(jv,'costCenterCode'))}}
            },
            {
                onSuccess: ({ data }) => {
                    //console.log(data)


                    notify('Journal Entries Saved for the current company');
                    setEnableEdit(false)
                    //setOpen(false)
                    //  refresh()
                },
                onFailure: (error) => notify(`Unable to save this view`, 'warning'),
            })
        else {
            notify("The journal is not in balance, please check",'warning')
        }
    };


    const saveJournalsOnPayroll = ()=> {
        if(inBalance())
        mutate({
                type: 'update',
                resource: `payrollRuns`,
                payload:{id:payrollRun.id,data: {journalEntries:payrollRun.journalEntries}}
            },
            {
                onSuccess: ({ data }) => {
                    //console.log(data)


                    notify('Journal Entries Saved for this Payroll');
                    // setEnableEdit(false)
                    //setOpen(false)
                    //  refresh()
                },
                onFailure: (error) => notify(`Unable to save this view`, 'warning'),
            });
        else {
            notify("The journal is not in balance",'warning')
        }
    }

    return (
        <div>
            {!readOnly&&<FormControlLabel
                control={
                    <Switch
                        checked={enableEdit}
                        color="primary"
                        onChange={ev => setEnableEdit(ev.target.checked)}
                        name="Enable Edit"
                        inputProps={{'aria-label': 'secondary checkbox'}}
                    />
                }

                label="Enable Editing"/>}

            {enableEdit&&<Button variant="outlined" color="primary" startIcon={<SaveIcon style={{color: blue[500]}}/>}
                                 onClick={() => {
                                     //  updateColumnsWidth();
                                     saveJournals();
                                 }}> Save For Company</Button>}

            {enableEdit&&<Button variant="outlined" color="primary" startIcon={<SaveIcon style={{color: blue[500]}}/>}
                                 onClick={() => {
                                     //  updateColumnsWidth();
                                     saveJournalsOnPayroll();
                                 }}> Save for this payroll</Button>}
            <Grid container>
                {!enableEdit &&

                <Grid item>
                    <ReactToPrint
                        trigger={() => <Button variant="outlined" color="primary"
                                               startIcon={<PrintIcon/>}>Print/PDF</Button>}
                        content={() => componentRef.current}
                        pageStyle={""}
                    />
                </Grid>
                }

                {
                    !asFooter&&!enableEdit&&
                    <Grid item>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={showHeader}
                                onChange={ev=>setShowHeader(ev.target.checked)}
                                name="showHeader"
                                color="primary"
                            />
                        }
                        label="Show Header"
                    />
                    </Grid>
                }
            </Grid>


            <div ref={componentRef}>
                {payrollRun.journalEntries.map((j,index)=> {
                        const debit = j.debit.reduce((sum,cv)=>sum+cv.amount,0)
                        const credit = j.credit.reduce((sum,cv)=>sum+cv.amount,0)
                        return  <div>



                            <div >
                                {(!asFooter&&showHeader&&!enableEdit)?<ReportHeader logo={logo} payrollRun={payrollRun} title={`Payroll Journal ${j.description}`}/>:
                                <h4>{j.description}</h4>
                                }

                                <Grid container>
                                    <Grid item xs={6}>
                                        <Table size="small">
                                            <TableBody>
                                                {j.debit.map(dr =>

                                                    <>
                                                    <TableRow>
                                                        <TableCell>
                                                            {dr.code || dr.accountCode} - {dr.accountName}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <MultiCurrency value={dr.amount}/>

                                                            {enableEdit && <>
                                                                <IconButton size={"small"}
                                                                            onClick={() => editRecord(index, "debit", dr.accountCode)}>
                                                                    <Edit/>
                                                                </IconButton>
                                                                <IconButton
                                                                    onClick={() => removeRecord(index, "debit", dr.accountCode)}>
                                                                    <Remove/>
                                                                </IconButton>
                                                            </>}

                                                        </TableCell>

                                                    </TableRow>
                                                        {j.isCompany&&<>
                                                        {payrollRun.journalEntries.filter(jr=>jr.costCenterCode).map(jr=>
                                                            {
                                                                const acc =  jr.debit.find(jd=>jd.accountCode===dr.accountCode)||{}

                                                                return  acc&&<TableRow>
                                                                    <TableCell  align="right">
                                                                        {acc.code || acc.accountCode} - {acc.accountName}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        <MultiCurrency value={acc.amount}/>
                                                                    </TableCell>

                                                                </TableRow>
                                                            }

                                                        )}</>}
                                                    </>

                                                )}</TableBody>
                                        </Table>
                                    </Grid>
                                    <Grid item xs={6}>

                                    </Grid>
                                </Grid>
                                <Grid container>

                                    <Grid item xs={6}>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <Table size="small">
                                            <TableBody>
                                                {j.credit.map(dr =>

                                                    <TableRow>
                                                        <TableCell>
                                                            {dr.code || dr.accountCode} -  {dr.accountName}
                                                        </TableCell>
                                                        <TableCell align="right">

                                                            <MultiCurrency value={dr.amount}/>
                                                            {enableEdit && <>  <IconButton size={"small"}
                                                                                           onClick={() => editRecord(index, "credit", dr.accountCode)}>
                                                                <Edit/>
                                                            </IconButton>
                                                                <IconButton
                                                                    onClick={() => removeRecord(index, "credit", dr.accountCode)}>
                                                                    <Remove/>
                                                                </IconButton>
                                                            </>
                                                            }


                                                        </TableCell>

                                                    </TableRow>
                                                )}</TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={6}>
                                        <Grid container>
                                            <Grid item xs={8}>
                                                {enableEdit && <IconButton>
                                                    <Add onClick={() => addRecord(index, 'debit')}/>
                                                </IconButton>}
                                            </Grid>
                                            <Grid  container   justify="flex-end" item xs={4} style={{color:debit.toFixed(4)===credit.toFixed(4)?'green':'red'}}>

                                                <MultiCurrency value={debit}/>


                                            </Grid>


                                        </Grid>

                                    </Grid>
                                    <Grid item xs={6}>

                                        <Grid container>
                                            <Grid item xs={8}>
                                                {enableEdit && <IconButton>
                                                    <Add onClick={() => addRecord(index, 'credit')}/>
                                                </IconButton>}
                                            </Grid>
                                            <Grid  container   justify="flex-end" item xs={4} style={{color:debit.toFixed(4)===credit.toFixed(4)?'green':'red'}}>

                                                <MultiCurrency  value={credit}/>


                                            </Grid>


                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>

                    }


                )}
            </div>


<Divider/>
            <Grid container style={{color:inBalance()?"green":"red", marginTop:30}} >
                <Grid item xs={6}  >
                    <Grid   container  >
                        <Grid item xs={8}>

                        </Grid>
                        <Grid item   xs={4}  container   justify="flex-end" >
                            <strong>
                                <MultiCurrency value= {totalDebit} />

                            </strong>
                        </Grid>


                    </Grid>

                </Grid>
                <Grid item xs={6}>

                    <Grid   container  >
                        <Grid item xs={8}>

                        </Grid>
                        <Grid item   xs={4}   container   justify="flex-end" >
                            <strong>


                                <MultiCurrency value= {totalCredit} />

                            </strong>
                        </Grid>


                    </Grid>
                </Grid>
            </Grid>

            <AddOrEditJournal accounts={accounts} setJournalEntries={recalculate} journalEntries={payrollRun.journalEntries} editField={editField} dialogOpen={dialogOpen} handleClose={handleClose} templates={templates} />


        </div>
    );
};

export default PayrollJournal;
