import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import inflection from 'inflection';
import {makeStyles, useMediaQuery} from '@material-ui/core';
import DefaultIcon from '@material-ui/icons/ViewList';
import classnames from 'classnames';
import {getResources, useTranslate} from 'ra-core';
import {withRouter} from 'react-router-dom';
import {MenuItemLink, usePermissions} from 'react-admin';
import {ReactComponent as PayDayText} from "../UIComponents/icons/paydayTextWhite.svg";
import {RunPayRollIcon} from "./paydayIcons";
import ReportIcon from "@material-ui/icons/LibraryBooksOutlined"
import TuneIcon from "@material-ui/icons/TuneOutlined"
import SpeedIcon from "@material-ui/icons/DialpadOutlined"

import Divider from "@material-ui/core/Divider";
import {connect, useDispatch} from "react-redux";
import {Badge} from "./Wrappers";
import moment from "moment";

const useStyles = makeStyles( theme=> ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
       // backgroundColor: theme.palette.primary.main,
        //height: '100%'
    },
    icon:{
        color:'#FFF'
    },
    root: {
        color:'#FFF'
    }
}));

const translatedResourceName = (resource, translate) =>
    translate(`resources.${resource.name}.name`, {
        smart_count: 2,
        _:
            resource.options && resource.options.label
                ? translate(resource.options.label, {
                    smart_count: 2,
                    _: resource.options.label,
                })
                : inflection.humanize(inflection.pluralize(resource.name)),
    });

const Menu = ({
                  classes: classesOverride,
                  className,
                  dense,
                  hasDashboard,
                  onMenuClick,
                  logout,
                  payrollIsRun,
                  payrollDate,
                  company,
                  ...rest
              }) => {
    const p= usePermissions()
    const permissions = p.permissions
    const translate = useTranslate();

    const classes = useStyles({ classes: classesOverride });
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources, shallowEqual);
    useSelector(state => state.router.location.pathname); // used to force redraw on navigation

    return (
        <div className={classnames(classes.main, className)} {...rest}>
<div>
            {/*<PayDayLogo  width="50" height="50"/>*/}
             <PayDayText  width="200" height={50}/>
</div>
             <Divider style={{marginBottom:20}}/>


            {
                company.isParentCompany? <>
                    <MenuItemLink
                        to="/consolidatedReport"
                        classes={classes}
                        primaryText="Consolidated"
                        leftIcon={<SpeedIcon fontSize='large'/>}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                    />

                </>: <>

                    {permissions && !permissions.includes('Superuser') && <MenuItemLink
                        to="/"
                        classes={classes}
                        primaryText="Dashboard"
                        leftIcon={<SpeedIcon fontSize='large'/>}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                    />}
                    {permissions && !permissions.includes('Superuser') && <MenuItemLink
                        to="/runPayroll"
                        classes={classes}
                        primaryText={payrollIsRun? <Badge   badgeContent={moment(payrollDate).format('MMMM YYYY')} color="warning">Payroll</Badge>:'Payroll'}
                        leftIcon={<RunPayRollIcon fontSize='large'/>}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                    />

                    }
                    {/*{permissions && !permissions.includes('Superuser') && <MenuItemLink*/}
                    {/*to="/runHistory"*/}
                    {/*classes={classes}*/}
                    {/*primaryText="History"*/}
                    {/*leftIcon={<HistoryIcon fontSize='large'/>}*/}
                    {/*onClick={onMenuClick}*/}
                    {/*sidebarIsOpen={open}*/}
                    {/*/>*/}

                    {/*}*/}
                    {resources
                        .filter(r => r.hasList)
                        .map(resource => (
                            <MenuItemLink
                                classes={classes}
                                key={resource.name}
                                to={`/${resource.name}`}
                                primaryText={translatedResourceName(
                                    resource,
                                    translate
                                )}
                                leftIcon={
                                    resource.icon ? <resource.icon fontSize='large' /> : <DefaultIcon fontSize='large' />
                                }
                                onClick={onMenuClick}
                                dense={dense}
                                sidebarIsOpen={open}
                            />
                        ))}

                    {permissions && !permissions.includes('Superuser') && <MenuItemLink
                        to="/reports"
                        classes={classes}
                        primaryText="Reports"
                        leftIcon={<ReportIcon fontSize='large'/>}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                    />
                    }


                </>



            }
            {permissions && !permissions.includes('Superuser') && <MenuItemLink
                to={`/companies/${localStorage.getItem('companyId')}/show`}
                classes={classes}
                primaryText="Company Settings"
                leftIcon={<TuneIcon fontSize='large'/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            }





            {isXSmall && logout}
        </div>
    );
};

//export default  withRouter(Menu);
export default withRouter(connect(state=>({
    payrollIsRun: !!state.runPayroll.payrolls.length,
    payrollDate: state.runPayroll.payrollDate,
    company:state.user.currentCompany
}))(Menu));
